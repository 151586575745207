import { Component, Inject } from "@angular/core";
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { BaseDialog } from "../base-dialog/abstract.base-dialog.component";

@Component({
  selector: "app-input-dialog",
  templateUrl: "./input-dialog.component.html",
  styleUrls: ["./input-dialog.component.scss"],
})
/**
 * data: Title:string, placeholder:string[]
 */
export class InputDialogComponent extends BaseDialog {
  placeholder: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<InputDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(dialogRef);
  }

  implementOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dialogData) {
        this.dialogData.Title
          ? this.getBaseDialogHeader().setTitle(this.dialogData.Title)
          : null;
        if (this.dialogData.placeholder) {
          Array.isArray(this.dialogData.placeholder)
            ? (this.placeholder = this.dialogData.placeholder)
            : (this.placeholder = [this.dialogData.placeholder]);
        } else {
          this.placeholder = [];
        }
        this.placeholder?.forEach((item) => {
          this.addFormControl();
        });
      }
    });
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      formControl: this.fb.array([]),
    });
  }
  get Controls() {
    return (this.form.get("formControl") as UntypedFormArray)
      .controls as FormControl[];
  }
  initFormControl() {
    const control = new UntypedFormControl(
      null,
      Validators.compose([Validators.required, Validators.minLength(4)])
    );
    control.valueChanges.subscribe(() => {
      control.markAsTouched();
    });
    return control;
  }

  addFormControl() {
    const control = <UntypedFormArray>this.form.get("formControl");
    control.push(this.initFormControl());
  }
}
