<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <!-- <button mat-mini-fab color="primary" (click)="undefined" *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
      <mat-icon>add</mat-icon>
    </button> -->
    <button mat-mini-fab color="primary" (click)="loadData()" matTooltip="Aktualisieren">
      <mat-icon>autorenew</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" />
    </mat-form-field>
  </div>
  <div fxLayout="row" class="flex-p">
    <div *ngIf="data&&data.data && data.data.length > 0" @blockTransition fxFlex="100%">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" class="block">
      </mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="MQTT Daten">
        <ng-container matColumnDef="name">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Bezeichnung
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="devices">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Gerät/Gruppe
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ getDevices(element.resourceFilter) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="topic">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            MQTT Topic
          </th>
          <td mat-cell *matCellDef="let element">
            {{ getMQTopic(element.resourceFilter) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Erstellt am
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          >
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteSubscription(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
          >
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row" (click)="(undefined)">
        </tr>
      </table>
    </div>
    <div *ngIf="!data||!data?.data || data.data.length <= 0" fxFlex>
      <div fxLayoutAlign="center center">
        Sie haben noch keine MQTT Daten empfangen.
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
