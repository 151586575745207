import {
  AfterViewInit,
  Directive,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { DialogType } from "../../../../model/enum/dialogType";
import { IBaseDialog } from "../../../abstract/interface.basedialog";
import { BaseDialogFooterButtonComponent } from "./base-dialog-footer-button/base-dialog-footer-button.component";
import { BaseDialogFooterCancelButtonComponent } from "./base-dialog-footer-button/base-dialog-footer-cancel-button.component";
import { BaseDialogFooterSaveButtonComponent } from "./base-dialog-footer-button/base-dialog-footer-save-button.component";
import { BaseDialogFooterComponent } from "./base-dialog-footer/base-dialog-footer.component";
import { BaseDialogHeaderComponent } from "./base-dialog-header/base-dialog-header.component";

/**
 * Abstract base dialog which all dialogs should extend/implement
 */
@Directive()
export abstract class BaseDialog implements OnInit, AfterViewInit, IBaseDialog {
  @ViewChild("dialogHeader") baseDialogHeader: BaseDialogHeaderComponent;
  @ViewChild("dialogFooter") baseDialogFooter: BaseDialogFooterComponent;
  public form!: UntypedFormGroup;

  @HostBinding("class") class = "base-dialog-wrapper";
  constructor(
    private dialogReference: MatDialogRef<BaseDialog>,
    private dialogType: DialogType = DialogType.create
  ) {
    this.baseDialogHeader = new BaseDialogHeaderComponent();
    this.baseDialogFooter = new BaseDialogFooterComponent();
  }

  /**
   * Hook for implementations in ngOnInit() at the start
   */
  implementAtInitStart(): void {}

  /**
   * Hook for implementations in ngOnInit() at the end
   */
  abstract implementOnInit(): void;

  abstract ngAfterViewInit(): void;

  /**
   * Builds the dialog form which will can validated and persisted
   *
   * @return The dialog form or else undefined
   */
  buildForm(): UntypedFormGroup {
    return {} as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.implementAtInitStart();
    this.setForm(this.buildForm());
    setTimeout(() => {
      const footerButtons: BaseDialogFooterButtonComponent[] =
        this.buildFooterButtons();
      if (footerButtons !== undefined) {
        this.getBaseDialogFooter().setFooterButtons(footerButtons);
      } else {
        console.log("Footer buttons are not defined");
      }
      this.implementOnInit();
      document.addEventListener("keyup", (event) => {
        this.onKeyDown(event, this.form);
      });
    });
  }

  /**
   * Build footer buttons for the dialog which listens to actions
   */
  buildFooterButtons(): BaseDialogFooterButtonComponent[] {
    if (this.dialogType === DialogType.create) {
      return this.addCreateDeleteFooterButtons();
    } else if (this.dialogType === DialogType.confirm) {
      return this.addCreateDeleteFooterButtons(
        new BaseDialogFooterCancelButtonComponent()
      );
    } else {
      return [];
    }
  }

  /**
   * Adds the default footer buttons for a create dialog
   *
   * @param cancelButton - Default dialog cancel button
   */
  private addCreateDeleteFooterButtons(
    cancelButton?: BaseDialogFooterCancelButtonComponent
  ): BaseDialogFooterButtonComponent[] {
    const footerButtons: BaseDialogFooterButtonComponent[] = [];
    const saveButton = new BaseDialogFooterSaveButtonComponent();

    if (cancelButton) {
      cancelButton.setLabel("Nein");
      footerButtons.push(cancelButton);

      saveButton.setLabel("Ja");
      saveButton.setDisabled(false);
    }

    footerButtons.push(saveButton);

    if (this.form) {
      this.form.valueChanges.subscribe(() =>
        saveButton.updateSaveButtonByForm(this.form)
      );
    }

    return footerButtons;
  }

  /**
   * Adds key event listener which will either submit or close the dialog
   *
   * @param event - Button that has been pressedπ
   */
  onKeyDown(event: KeyboardEvent, form: UntypedFormGroup): void {
    if (event.code === "Enter" && form && form.valid) {
      this.onSubmit(form.value);
    } else if (event.code === "Escape") {
      this.onClose();
    }
  }

  /**
   * Closes the dialog without value to save
   */
  onClose(): void {
    this.dialogReference.close();
  }

  /**
   * Submits the corresponding value, that will be saved
   *
   * @param submitValue - Value that should be saved
   */
  onSubmit(submitValue: any): void {
    this.dialogReference.close(submitValue);
  }

  public getBaseDialogHeader(): BaseDialogHeaderComponent {
    return this.baseDialogHeader;
  }

  public setBaseDialogHeader(
    baseDialogHeader: BaseDialogHeaderComponent
  ): void {
    this.baseDialogHeader = baseDialogHeader;
  }

  public getBaseDialogFooter(): BaseDialogFooterComponent {
    return this.baseDialogFooter;
  }

  public setBaseDialogFooter(
    baseDialogFooter: BaseDialogFooterComponent
  ): void {
    this.baseDialogFooter = baseDialogFooter;
  }

  public getForm(): UntypedFormGroup {
    return this.form;
  }

  public setForm(form: UntypedFormGroup): void {
    this.form = form;
  }
}
