import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { AppSettings } from "../../../app.settings";
import { ILoraServiceProfile } from "../../../model/loraServiceProfile";
import { IPagination } from "../../../model/pagination";
import { LoraServiceProfilesService } from "../../../services/lora-service-profiles.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { debounce } from "../../../shared/abstract/utils";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  templateUrl: "./lora-service-profiles.component.html",
  styleUrls: ["./lora-service-profiles.component.scss"],
  animations: [blockTransition],
})
export class LoraServiceProfilesComponent implements OnInit {
  public loading = false;
  public EPermissions = EPermissions;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<ILoraServiceProfile>;
  public displayedColumns = [
    "_id",
    "name",
    "description",
    "drMin",
    "drMax",
    "createdAt",
    "action",
  ];
  private search?: string;
  private searchHandler: any;
  private profSettings = [
    {
      id: "ulRate",
      type: "number",
      desc: "Token bucket filling rate, including ACKs (packet/h)",
    },
    { id: "ulBucketSize", type: "number", desc: "Token bucket burst size" },
    {
      id: "ulRatePolicy",
      type: "select",
      desc: "Drop or mark when exceeding ULRate",
      options: [
        { value: 0, name: "Drop" },
        { value: 1, name: "Mark" },
      ],
    },
    {
      id: "dlRate",
      type: "number",
      desc: "Token bucket filling rate, including ACKs (packet/h)",
    },
    { id: "dlBucketSize", type: "number", desc: "Token bucket burst size" },
    {
      id: "dlRatePolicy",
      type: "select",
      desc: "Drop or mark when exceeding DLRate",
      options: [
        { value: 0, name: "Drop" },
        { value: 1, name: "Mark" },
      ],
    },
    {
      id: "devStatusReqFreq",
      type: "number",
      desc: "Frequency to initiate an End-Device status request (request/day)",
    },

    { id: "prAllowed", type: "checkbox", desc: "Passive Roaming allowed" },
    { id: "hrAllowed", type: "checkbox", desc: "Handover Roaming allowed" },
    { id: "raAllowed", type: "checkbox", desc: "Roaming Activation allowed" },
  ];

  constructor(
    public uiService: UIConfigService,
    private lspService: LoraServiceProfilesService,
    private appSettings: AppSettings
  ) {
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.getProfiles();
    }, 500);
  }

  ngOnInit(): void {
    this.getProfiles();
  }
  getProfiles() {
    this.loading = true;
    this.lspService
      .getLoraServiceProfiles(undefined, this.pageEvent, this.search)
      .subscribe({
        next: (res) => {
          res = res as IPagination<ILoraServiceProfile>;
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
          } else if (this.data && this.data.data.length <= 1) {
            this.data.data = [];
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
        complete: () => (this.loading = false),
      });
  }
  createServiceProfile() {
    this.addLoraServiceProfile();
  }
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.getProfiles();
  }
  delLoraServiceProfile(id: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Serviceprofil löschen?",
        "Möchten Sie wirklich den Serviceprofil entfernen?"
      )
      .then((sw) => {
        if (sw.isConfirmed) {
          this.lspService.delLoraServiceProfile(id).subscribe({
            next: (res) => {
              if (res.DeletedCount > 0) {
                this.appSettings.getSwalSuccess("Erfolgreich entfernt");
                this.getProfiles();
              } else {
                this.appSettings.getSwalWarn("Es wurde nichts entfernt");
              }
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  async addLoraServiceProfile(prof?: ILoraServiceProfile) {
    if (!prof) {
      prof = {} as ILoraServiceProfile;
    }
    const Queue = Swal.mixin({
      confirmButtonText: prof.name ? "Anpassen" : "Erstellen",
      showCloseButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: "Erweitert",
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
      customClass: this.appSettings.currentTheme(),
    });
    const getValues = () => {
      const values = {
        name: (document.getElementById("name") as any).value,
        description: (document.getElementById("description") as any).value,
        drMin: parseInt((document.getElementById("drMin") as any).value),
        drMax: parseInt((document.getElementById("drMax") as any).value),
      };
      if (values.name - length <= 1) {
        Swal.showValidationMessage(`Bitte einen Namen eingeben`);
        return false;
      }
      return values;
    };
    const first = await Queue.fire({
      title: "Lora Service Profile bestimmen",
      // optional class to show fade-in backdrop animation which was disabled in Queue mixin
      showClass: { backdrop: "swal2-noanimation" },
      html: `
      <div style="display:flex;flex-direction: column;width: 330px;padding:10px 0 20px 135px;gap:5px;">
          <input id="name" placeholder="Bezeichnung*" type="text"  class="swal2-input" value="${
            prof.name ?? ""
          }">
          <input id="description" placeholder="Beschreibung" type="text"  class="swal2-input" value="${
            prof.description ?? ""
          }">
      </div>
      <div class="sweet-container">
        <label for="drMin" class="swal2-label">Datenrate Min</label>
        <input id="drMin" type="number" min="0" class="swal2-input" value="${
          prof.drMin ?? 0
        }">
        <label for="drMax" class="swal2-label">Datenrate Max</label>
        <input id="drMax" type="number" class="swal2-input" max="5" value="${
          prof.drMax ?? 0
        }">
      </div>

      `,
      preConfirm: getValues,
      preDeny: getValues,
    });
    prof = { ...prof, ...first.value };
    let ready = false;
    if (first.isDenied) {
      const second = await Queue.fire({
        title: "Erweitert",
        // optional class to show fade-in backdrop animation which was disabled in Queue mixin
        showClass: { backdrop: "swal2-noanimation" },
        showDenyButton: false,
        html: this.appSettings.getSwalFormControl(this.profSettings, prof),
        preConfirm: () => {
          const data: any = {};
          this.profSettings.forEach((e) => {
            if (e.type === "number") {
              data[e.id] = parseInt(
                (document.getElementById(e.id) as any).value
              );
            } else if (e.type === "checkbox") {
              data[e.id] = (document.getElementById(e.id) as any).checked;
            } else if (e.type === "select") {
              data[e.id] = (document.getElementById(e.id) as any).value;
            }
          });
          return data;
        },
      });
      if (second.isConfirmed) {
        prof = { ...prof, ...second.value };
        ready = true;
      }
    } else if (first.isConfirmed) {
      ready = true;
    }
    if (ready && prof) {
      // set Defaultsettings
      prof.addGwMetadata = true;
      prof.reportDevStatusBattery = true;
      this.lspService.addLoraServiceProfile(prof).subscribe({
        next: (res) => {
          if (res.insertedIDs && res.insertedIDs.length > 0) {
            this.appSettings.getSwalSuccess(
              "Erfolgreich " + (prof ? "erstellt" : "angepasst")
            );
            this.getProfiles();
          } else {
            this.appSettings.getSwalWarn("Es ist ein Fehler aufgetretten.");
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    }
  }
}
