import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";
import {UserInfo} from '../../../../services/abstract.service';

@Component({
  selector: "app-expired-message",
  template: `
    <h1 mat-dialog-title>Ihre Sitzung läuft ab!</h1>
    <div mat-dialog-content>
      <p>Wollen Sie die aktuelle Sitzung verlängern?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="getData()">
        Verlängern
      </button>
    </div>
  `,
})
export class ExpiredMessageComponent {
  // private Info: ILogin;
  private _api = environment.api;
  // private jwt = new JwtHelperService();
  constructor(
    // private tService: TenantService,
    private dialogRef: MatDialogRef<ExpiredMessageComponent>,
    public http: HttpClient,
    private info: UserInfo
  ) { }

  getData() {
    const headers = new HttpHeaders().append(
      "Content-Type",
      `application/json`
    );
    const url = `${this._api}/users`;
    const params = new HttpParams({
      fromObject: {
        username: this.info.UserName,
      },
    });
    this.http.get(url, {headers, params, observe: 'response'}).subscribe((res) => {
      this.dialogRef.close(true);
    });
  }

}
