import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { IChartsData } from "../model/chartsDefaultConfig";
import { IData, IGetDeviceData } from "../model/devices";
import { AbstractService, UserInfo } from "./abstract.service";
@Injectable({
  providedIn: "root",
})
export class DeviceDataService extends AbstractService {
  constructor(public http: HttpClient, private uInfo: UserInfo) {
    super("device-data-query");
  }

  /**
   * Getting the data of the devices
   * @param devEUIs
   * @param tenantID
   * @param limitFrom
   * @param limitTo
   * @param limitLast
   */

  getDeviceDataForChart(
    devices: string[],
    dataType = 0,
    tenantID?: string,
    limitFrom = 0,
    limitTo = 0,
    limitLast?: boolean,
    xValue?: string,
    yValue?: string,
    zValue?: string | string[]
  ) {
    const url = `${this.url}`;
    const body: any = {
      devEUI: {
        $in: devices,
      },
    };
    if ((limitFrom === 0 && limitTo === 0) || limitLast) {
      body["limitLast"] = true;
    } else {
      if (limitFrom !== 0) {
        body["limitFrom"] = limitFrom.toString();
      }
      if (limitTo !== 0) {
        body["limitTo"] = limitTo.toString();
      }
    }
    body["tenantID"] = tenantID || "public";

    body["projection"] = {
      devEUI: 1,
      deviceName: 1,
    };
    // if (dataType === 0) {
    //   body["projection"]["rxInfo.rssi"] = "$data.rxInfo.rssi";
    //   body["projection"]["rxInfo.loraSnr"] = "$data.rxInfo.loraSnr";
    //   body["projection"]["rxInfo.dr"] = "$data.txInfo.dr";
    //   body["projection"]["rxInfo.codeRate"] =
    //     "$data.txInfo.loraModulationInfo.codeRate";
    //   body["projection"]["rxInfo.bandwidth"] =
    //     "$data.txInfo.loraModulationInfo.bandwidth";
    //   body["projection"]["rxInfo.spreadingFactor"] =
    //     "$data.txInfo.loraModulationInfo.spreadingFactor";
    // }
    if (!xValue && !yValue && !zValue) {
      body["projection"]["object"] = 1;
      body["projection"]["createdAt"] = 1;
    } else {
      body["projection"]["createdAt"] = "$createdAt";
      if (xValue && xValue !== "object") {
        if (xValue !== "createdAt") {
          body["projection"]["xValue"] = "$object." + xValue;
          body["object." + xValue] = { $exists: true };
        } else if (xValue === "createdAt") {
          body["projection"]["xValue"] = "$" + xValue;
        }
      } else if (xValue) {
        body["projection"]["object"] = "$object";
      }
      if (yValue && yValue !== "object") {
        if (yValue !== "createdAt") {
          body["projection"]["yValue"] = "$object." + yValue;
          body["object." + yValue] = { $exists: true };
        } else {
          body["projection"]["yValue"] = "$" + yValue;
        }
      } else if (yValue) {
        body["projection"]["object"] = "$object";
      }
      if (zValue) {
        if (Array.isArray(zValue)) {
          const tmp: any[] = [];
          zValue.some((val: any) => {
            if (val !== "object") {
              tmp.push("$object." + val);
              body["object." + val] = { $exists: true };
            } else {
              body["object"] = { $exists: true };
              tmp.push("$object");
            }
          });
          body["projection"]["zValue"] = tmp;
        } else if (zValue !== "object") {
          body["projection"]["zValue"] = "$object." + zValue;
          body["object." + zValue] = { $exists: true };
        } else {
          body["projection"]["object"] = "$object";
        }
      }
    }
    //  else {
    //   body["object"] = { $exists: true };
    //   body["projection"]["object"] = "$data.object";
    //   body["projection"]["createdAt"] = "$data.createdAt";
    // }
    const headers = this.headers;
    return this.http.post<IChartsData[]>(url, body, { headers });
  }
  getDeviceData(
    devEUIs: string[],
    tenantID?: string,
    limitFrom = 0,
    limitTo = 0,
    limitLast?: boolean,
    dataType?: string,
    project?: any
  ) {
    const url = `${this.url}`;
    const body: any = {
      devEUI: {
        $in: devEUIs,
      },
    };
    if ((limitFrom === 0 && limitTo === 0) || limitLast) {
      body["limitLast"] = 1;
    } else {
      if (limitFrom !== 0) {
        body["limitFrom"] = limitFrom.toString();
      }
      if (limitTo !== 0) {
        body["limitTo"] = limitTo.toString();
      }
    }
    if (tenantID) {
      body["tenantID"] = tenantID;
    } else {
      body["tenantID"] = this.uInfo.TenantID;
    }
    if (!project) {
      body["projection"] = {
        devEUI: 1,
        deviceName: 1,
        rxInfo: 1,
        object: 1,
        txInfo: 1,
        data: 1,
        error: 1,
        createdAt: 1,
      };
    } else {
      body["projection"] = project;
    }

    if (dataType && dataType === "lora") {
      body["projection"]["fCnt"] = 1;
      body["projection"]["fPort"] = 1;
    }

    const headers = this.headers;
    return this.http.post<IGetDeviceData[]>(url, body, { headers });
  }
  getDataByDevice(
    devEUI: string,
    limitFrom = 0,
    limitTo = 0,
    tenantID?: string,
    limitLast?: boolean,
    dataType?: string,
    project?: any
  ) {
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ?? this.uInfo.TenantID,
        devEUI,
        limitFrom,
        limitTo,
      },
    });
    if (dataType) {
      params = params.append("dataType", dataType);
    }
    if (limitLast) {
      params = params.append("limitLast", limitLast);
    }
    if (project && Object.keys(project).length > 0) {
      const keys = Object.keys(project).join(",");
      params = params.append("fields", keys);
    }
    return this.http.get<IGetDeviceData[]>(`${environment.api}/device-data`, {
      headers,
      params,
    });
  }
  queryDeviceData(form: any) {
    const headers = this.headers;
    return this.http.post<IData[]>(this.url, form, { headers });
  }
}
