<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="createAlert()"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="loadData()" matTooltip="Aktualisieren">
      <mat-icon>autorenew</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" />
    </mat-form-field>
  </div>
  <div fxLayout="row" class="flex-p">
    <div *ngIf="data&&data?.data && data.data.length > 0" @blockTransition fxFlex="100%">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" class="block">
      </mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="Alerting">
        <ng-container matColumnDef="name">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Bezeichnung
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="devices">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Geräte
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p"
            [matTooltip]="'devEUI: ' + getDeviceEUIs(element.devices)">
            {{ getDeviceNames(element.devices) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastAlertAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Zuletzt ausgelöst
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.lastAlertAt>0? (element.lastAlertAt | localizedDate: "dd.MM.yyyy HH:mm:ss"):'' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Erstellt am
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          >
          <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-slide-toggle class="noselect" color="primary" [checked]="!!element.subscriptionConfig?.active"
                (change)="disableAlert($event.checked, element)" (click)="$event.stopPropagation()" [matTooltip]="
                  element.subscriptionConfig?.active
                    ? 'Auschalten'
                    : 'Einschalten'
                "></mat-slide-toggle>
              <button mat-icon-button color="accent" [disabled]="!element.subscriptionConfig?.active"
                *ngIf="element.subscriptionConfig?.silent" matTooltip="Aktivieren"
                (click)="$event.stopPropagation(); muteAlert(element, false)">
                <mat-icon>alarm_off</mat-icon>
              </button>
              <button mat-icon-button color="primary" [disabled]="!element.subscriptionConfig?.active"
                *ngIf="!element.subscriptionConfig?.silent" matTooltip="Stummschalten"
                (click)="$event.stopPropagation(); muteAlert(element, true)">
                <mat-icon>alarm_on</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteAlert(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
          >
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row" (click)="createAlert(row)"
          style="cursor: pointer" [ngClass]="{ disabled: !row.subscriptionConfig?.active }"></tr>
      </table>
    </div>
    <div *ngIf="!data||!data?.data || data.data.length <= 0" fxFlex>
      <div fxLayoutAlign="center center">
        Sie haben noch keine Alarmierungen eingerichtet.
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
