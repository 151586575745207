import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { firstValueFrom } from "rxjs";
import Swal from "sweetalert2";
import { AppSettings, IControlls } from "../../../app.settings";
import { ILoraMulticastGroup } from "../../../model/loraMulticast";
import { ILoraServiceProfile } from "../../../model/loraServiceProfile";
import { IPagination } from "../../../model/pagination";
import { LoraMulticastGroupService } from "../../../services/lora-multicast-group.service";
import { LoraServiceProfilesService } from "../../../services/lora-service-profiles.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { debounce } from "../../../shared/abstract/utils";
import { blockTransition } from "../../../theme/utils/app-animation";
import { DevicesDialogComponent } from "../../groups/taged-devices/devices-dialog/devices-dialog.component";
import { CreateDeviceQueueComponent } from "../create-device-queue/create-device-queue.component";

@Component({
  templateUrl: "./lora-multicast-group.component.html",
  styleUrls: ["./lora-multicast-group.component.scss"],
  animations: [blockTransition],
})
export class LoraMulticastGroupComponent implements OnInit {
  public loading = false;
  public EPermissions = EPermissions;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<ILoraMulticastGroup>;
  public displayedColumns = [
    "_id",
    "name",
    "description",
    "dr",
    "groupType",
    "devices",
    "createdAt",
    "action",
  ];
  private search?: string;
  private searchHandler: any;
  private formControlls: IControlls[] = [
    {
      id: "dr",
      desc: "Datenrate",
      type: "number",
    },
    {
      id: "groupType",
      desc: "Gruppentype C oder B",
      type: "select",
      options: [
        { name: "CLASS C", value: 0, type: "number" },
        { name: "CLASS B", value: 1, type: "number" },
      ],
    },
    {
      id: "mcAddr",
      desc: "mcAddr",
      type: "text",
    },
    {
      id: "mcNwkSKey",
      desc: "Network Key for Multicast",
      type: "text",
    },
    {
      id: "mcAppSKey",
      desc: "Application Key for Multicast",
      type: "text",
    },
  ];

  constructor(
    public uiService: UIConfigService,
    private lmgService: LoraMulticastGroupService,
    private lspService: LoraServiceProfilesService,
    private dialog: MatDialog,
    private appSettings: AppSettings
  ) {
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.getMulticastGroup();
    }, 500);
  }

  ngOnInit(): void {
    this.getMulticastGroup();
  }
  getServiceProfile() {
    return this.lspService.getLoraServiceProfiles(
      undefined,
      undefined,
      undefined,
      {
        fields: "name,serviceProfileID",
      }
    );
  }
  getMulticastGroup() {
    this.loading = true;
    this.lmgService
      .getLoraMulticastGroup(undefined, this.pageEvent, this.search)
      .subscribe({
        next: (res) => {
          res = res as IPagination<ILoraMulticastGroup>;
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
          } else if (this.data && this.data.data.length <= 1) {
            this.data.data = [];
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
        complete: () => (this.loading = false),
      });
  }
  createMulticastGroup() {
    this.addMulticastGroup();
  }
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.getMulticastGroup();
  }
  delMulticastGroup(id: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Gruppe löschen?",
        "Möchten Sie wirklich die Multicast Gruppe  entfernen?"
      )
      .then((sw) => {
        if (sw.isConfirmed) {
          this.lmgService.delMulticastGroup(id).subscribe({
            next: (res) => {
              if (res.DeletedCount > 0) {
                this.appSettings.getSwalSuccess("Erfolgreich entfernt");
                this.getMulticastGroup();
              } else {
                this.appSettings.getSwalWarn("Es wurde nichts entfernt");
              }
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  async addMulticastGroup(gr?: any) {
    if (!gr) {
      gr = {} as ILoraServiceProfile;
    }
    const sProfiles = (await firstValueFrom(this.getServiceProfile())) as
      | ILoraServiceProfile[]
      | undefined;
    if (!sProfiles || sProfiles.length <= 0) {
      this.appSettings.getSwalInfo("Sie haben noch keine Service Profile.");
      return;
    }
    if (this.formControlls.length <= 5) {
      this.formControlls.push({
        id: "serviceProfileId",
        desc: "Service Profile",
        type: "select",
        options: sProfiles.map((p) => {
          return {
            name: p.name,
            value: p.serviceProfileID,
          };
        }),
      });
    }

    const Queue = Swal.mixin({
      confirmButtonText: gr ? "Anpassen" : "Erstellen",
      showCloseButton: true,
      allowOutsideClick: false,
      denyButtonText: "Erweitert",
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
      customClass: this.appSettings.currentTheme(),
    });
    const getValues = () => {
      const values: any = {
        name: (document.getElementById("name") as any).value,
        description: (document.getElementById("description") as any).value,
      };
      this.formControlls.forEach((e) => {
        if (e.type === "number") {
          values[e.id] = parseInt((document.getElementById(e.id) as any).value);
        } else if (e.type === "checkbox") {
          values[e.id] = (document.getElementById(e.id) as any).checked;
        } else if (e.type === "select" || e.type === "text") {
          if (e.options && e.options[0].type === "number") {
            values[e.id] = parseInt(
              (document.getElementById(e.id) as any).value
            );
          } else {
            values[e.id] = (document.getElementById(e.id) as any).value;
          }
        }
      });
      if (values.name?.length <= 1) {
        Swal.showValidationMessage(`Bitte einen Namen eingeben`);
        return false;
      }
      if (values.dr > 5) {
        Swal.showValidationMessage(`Datenrate(dr) darf nicht größer 5 sein.`);
        return false;
      }
      if (values.mcAddr.length <= 7 || values.mcAddr.length > 8) {
        Swal.showValidationMessage(`mcAddr muss 8 Zeichen lang sein.`);
        return false;
      }

      return values;
    };
    const first = await Queue.fire({
      title: "Lora Multicastgroup",
      showClass: { backdrop: "swal2-noanimation" },
      html: `
      <div style="display:flex;flex-direction: column;width: 330px;padding:10px 0 20px 135px;gap:5px;">
          <input id="name" placeholder="Bezeichnung*" type="text"  class="swal2-input" value="${
            gr?.name ?? ""
          }">
          <input id="description" placeholder="Beschreibung" type="text"  class="swal2-input" value="${
            gr?.description ?? ""
          }">
      </div>
      ${this.appSettings.getSwalFormControl(this.formControlls, gr)}
      `,
      preConfirm: getValues,
    });
    if (first.isConfirmed) {
      this.lmgService.addMulticastGroup(first.value).subscribe({
        next: (res) => {
          if (res.insertedIDs && res.insertedIDs.length > 0) {
            this.appSettings.getSwalSuccess(
              "Erfolgreich " + (gr ? "erstellt" : "angepasst")
            );
            this.getMulticastGroup();
          } else {
            this.appSettings.getSwalWarn("Es ist ein Fehler aufgetretten.");
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    }
  }
  addDevices(gr: ILoraMulticastGroup, del = false) {
    this.dialog
      .open(DevicesDialogComponent, {
        minWidth: 500,
        data: {
          devEUIs: gr.devices,
          multicast: { del, devices: gr.deviceInfo },
        },
      })
      .afterClosed()
      .subscribe({
        next: (result: { selected: string[] }) => {
          if (result && result.selected) {
            let observer = this.lmgService.addDeviceToGroup(
              gr.id,
              result.selected
            );
            if (del) {
              observer = this.lmgService.delDeviceFromGroup(
                gr.id,
                result.selected
              );
            }
            observer.subscribe({
              next: (res) => {
                if (res.ModifiedCount > 0) {
                  this.appSettings.getSwalSuccess(
                    `Geräte wurden ${del ? "entfernt" : "hinzugefügt"}`
                  );
                  this.getMulticastGroup();
                }
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  delDevices(gr: ILoraMulticastGroup) {
    this.addDevices(gr, true);
  }
  createGroupQueue(gr: ILoraMulticastGroup) {
    const dialogRef = this.dialog.open(CreateDeviceQueueComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          const data = res.payload;
          this.lmgService.addMulticastQueue(data, gr.id, res.fPort).subscribe({
            next: (resp: any) => {
              if (resp.success) {
                this.appSettings.getSwalSuccess(
                  "DownloadQueue wurde erfolgreich erstellt."
                );
              }
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
}
