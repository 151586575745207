import {AfterViewInit, Component, Inject} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {AppSettings} from "../../../../app.settings";
import {IUserInfo} from "../../../../model/user";
import {UserManagementService} from "../../../../services/user-management.service";
import {BaseDialog} from "../../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";
import {DevicesDialogComponent} from "../../../groups/taged-devices/devices-dialog/devices-dialog.component";

@Component({
  templateUrl: "./api-subscription-dialog.component.html",
  styleUrls: ["./api-subscription-dialog.component.scss"],
})
export class ApiSubscriptionDialogComponent
  extends BaseDialog
  implements AfterViewInit
{
  public apiUsers?: Observable<IUserInfo[]>;
  public selectedDevices: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<ApiSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public DATA: { users: IUserInfo[] },
    public fb: UntypedFormBuilder,
    private uService: UserManagementService,
    private dialog: MatDialog,
    private appSettings: AppSettings
  ) {
    super(dialogRef);
  }
  buildForm(): UntypedFormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
      username: ["", Validators.required],
      resourceType: "DEVICE_DATA_API",
      resourceFilter: ["", Validators.required],
      tenantID: this.appSettings.currentTenantID(),
    });
  }
  implementOnInit(): void {
    this.getBaseDialogHeader().setTitle("API Freigabe hinzufügen");
    this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Hinzufügen");
    return;
  }
  ngAfterViewInit(): void {
    return;
  }

  addDevices() {
    this.dialog
      .open(DevicesDialogComponent, {
        minWidth: 500,
        data: { devEUIs: this.selectedDevices },
      })
      .afterClosed()
      .subscribe({
        next: (result: { selected: string[] }) => {
          if (result) {
            this.selectedDevices = result.selected;
            this.form.patchValue({
              resourceFilter: {
                apiDevices: this.selectedDevices.map((x) => {
                  return { devEUI: x };
                }),
              },
            });
          } else {
            this.selectedDevices = [];
            this.form.patchValue({ resourceFilter: undefined });
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
}
