import { Component, Input } from "@angular/core";

@Component({
  // eslint-disable-next-line
  selector: "base-dialog-footer-button",
  templateUrl: "./base-dialog-footer-button.component.html",
  styleUrls: ["./base-dialog-footer-button.component.scss"],
})
export class BaseDialogFooterButtonComponent {
  @Input()
  id!: string;

  @Input()
  label!: string;

  @Input()
  color!: string;

  @Input()
  disabled!: boolean;

  @Input()
  closeValue: any;

  @Input()
  tabindex!: number;

  constructor() {}

  public getId(): string {
    return this.id;
  }

  public setId(id: string): void {
    this.id = id;
  }

  public getLabel(): string {
    return this.label;
  }

  public setLabel(label: string): void {
    this.label = label;
  }

  public getColor(): string {
    return this.color;
  }

  public setColor(color: string): void {
    this.color = color;
  }

  public getDisabled(): boolean {
    return this.disabled;
  }

  public setDisabled(disabled: boolean): void {
    this.disabled = disabled;
  }

  public getCloseValue(): any {
    return this.closeValue;
  }

  public setCloseValue(closeValue: any): void {
    this.closeValue = closeValue;
  }

  public getTabindex(): number {
    return this.tabindex;
  }

  public setTabindex(tabindex: number): void {
    this.tabindex = tabindex;
  }
}
