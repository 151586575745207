import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { IUserInfo, IUserInfoPagi } from "../model/user";
import { getParams } from "../shared/abstract/utils";
import { AbstractService } from "./abstract.service";
import { EPermissions } from "./uiconfig-service";

@Injectable({
  providedIn: "root",
})
export class UserManagementService extends AbstractService {
  constructor(private http: HttpClient, private appSettings: AppSettings) {
    super("users");
  }

  getUsers(
    tenantID?: string,
    pagi?: PageEvent,
    permissions?: EPermissions,
    par?: { [param: string]: string }
  ): Observable<IUserInfo[] | IUserInfoPagi> {
    let params = new HttpParams();
    if (pagi) {
      params = params.append("page", pagi.pageIndex.toString());
      params = params.append("pageSize", pagi.pageSize.toString());
    }
    if (permissions) {
      params = params.append(
        "permissions",
        permissions.toLowerCase().toString()
      );
    }
    if (tenantID) {
      params = params.append("tenantID", tenantID);
    }
    if (par) {
      params = getParams(params, par);
    }
    const url = this.url;
    const headers = this.headers;
    return this.http.get<IUserInfo[]>(url, { headers, params });
  }

  createUser(form: any, update = false) {
    const url = this.url;
    const headers = this.headers;
    if (update) {
      return this.http.put(url, form, { headers });
    }
    return this.http.post(url, form, { headers });
  }
  deleteUser(username: string) {
    const params = new HttpParams({
      fromObject: {
        tenantID: this.appSettings.currentTenantID(),
      },
    });
    return this.http.delete(`${this.url}/${username}`, {
      headers: this.headers,
      params,
    });
  }
  unblockUser(username: string) {
    return this.http.put(this.url, { username });
  }
  setUserPassword(username: string, password: string) {
    const params = new HttpParams({
      fromObject: {
        username,
      },
    });
    return this.http.put(this.url, { username, password }, { params });
  }
  sendNewUserPassword(password: string, token: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    return this.http.patch(
      this.url,
      { password },
      { headers, withCredentials: true }
    );
  }
  requestPasswordReset(username: string, callbackURL: string) {
    const params = new HttpParams({
      fromObject: {
        username,
        callbackURL,
      },
    });
    return this.http.get(`${this.api}/password-reset-token`, { params });
  }
  getTokenForApiUser(username: string) {
    const url = `${this.url}/api-token`;
    const headers = this.headers;
    return this.http.post(url, { username }, { headers });
  }
}
