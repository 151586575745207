import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LocalizedDatePipe } from "./date/date.pipe";
import { EnumToArrayPipe, EnumToStringArrayPipe } from "./enum-to-array.pipe";
import { PaginationPipe } from "./pagination/pagination.pipe";
import { ProfilePicturePipe } from "./profilePicture/profilePicture.pipe";
import { TruncatePipe } from "./truncate/truncate.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    PaginationPipe,
    ProfilePicturePipe,
    TruncatePipe,
    LocalizedDatePipe,
    EnumToArrayPipe,
    EnumToStringArrayPipe,
  ],
  exports: [
    PaginationPipe,
    ProfilePicturePipe,
    TruncatePipe,
    LocalizedDatePipe,
    EnumToArrayPipe,
    EnumToStringArrayPipe,
  ],
})
export class PipesModule {}
