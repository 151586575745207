import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AppSettings } from "../../../app.settings";
import { IStreamProfiles } from "../../../model/datatstream";
import { EDataType } from "../../../model/enum/dataType";
import { DatastreamService } from "../../../services/datastream.service";
import { DeviceDataService } from "../../../services/devicedata.services";
import { DeviceDataProfileService } from "../../../services/DeviceDataProfileService";
import { LoraDeviceService } from "../../../services/lora-devices";
import { NbiotDevicesService } from "../../../services/nbiot-devices.service";
import { VirtualDevicesService } from "../../../services/virtual-devices.service";
import {
  setMonacoDerfaults,
  setMonacoVirtualDefaults,
} from "../../../shared/abstract/completionItemProvider";
import {
  getStreamParser,
  initVirtualFunktion,
} from "../../../shared/abstract/utils";
import { AppConstants } from "../../../shared/app.constants";
import { ValidatorMessageService } from "../../../shared/components/validator-message.service";
import { MapViewDialogComponent } from "../../dynamicboard/map-view-dialog/map-view-dialog.component";
import { CreateDataStreamProfileComponent } from "../create-profile-dialog/CreateDataStream.component";

@Component({
  selector: "app-create-datastream-device",
  templateUrl: "./create-datastream-device.component.html",
  styleUrls: ["./create-datastream-device.component.scss"],
})
export class CreateDatastreamDeviceComponent implements OnInit {
  public form: UntypedFormGroup;
  public streamProfiles?: IStreamProfiles[];
  public selectedProfile?: IStreamProfiles;
  public loading = false;
  public requestData: any = {};
  public Turnus = AppConstants.TurnusVirtual;
  public ParserOptions = {
    theme: "vs-dark",
    language: "javascript",
    // automaticLayout: true,
    range: {
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: 1,
      endColumn: 1,
    },
  };
  public dataType = 2;
  public updateData: any;
  public buttonLabel = "Gerät erstellen";
  private parserCode = `
  // Java Script
  // Die function muss einen JSON Object zurück geben.
  /**
   * @param {any} _value_ die Variable welche Daten aus dem Request beinhaltet
   * @param {string} _devEUI_ die devEUI von dem Gerät
   * @returns {Record<string,any>}
   */
  function Parse(_value_,_devEUI_){
    return _value_;
  }
  `;
  private virtualDeviceCode = `
  // Java Script
  // _module_ beinhaltet Funktionen um die Daten abzufragen.
  /**
   * Create script for virtual Device Data.
   * @param {IFunction} _module_ Hilfsmittel die man brauchen könnte.
   * @returns {Promise<Record<string,any>>}
   */
  async function GetDataForDevice(_module_){
    return {};
  }
  `;
  private monacoEditor: any;
  private pageEvent?: PageEvent;
  public apiApp?: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private dsService: DatastreamService,
    private vService: ValidatorMessageService,
    private dialog: MatDialog,
    private router: Router,
    private appSettings: AppSettings,
    private ddpService: DeviceDataProfileService,
    private nbService: NbiotDevicesService,
    private loService: LoraDeviceService,
    private vdService: VirtualDevicesService,
    private ddService: DeviceDataService
  ) {
    this.form = this.fb.group({
      deviceName: [null, Validators.required],
      description: null,
      dataType: 2,
      devEUI: [null],
      dataStreamFunction: this.parserCode,
      deviceDataFunction: this.virtualDeviceCode,
      // dataStreamProfile: [null, Validators.required],
      // dataStreamProfileID: [null, Validators.required],
      defaultLocation: new UntypedFormGroup({
        latitude: new UntypedFormControl(),
        longitude: new UntypedFormControl(),
      }),
    });

    if (this.router.getCurrentNavigation()?.extras.state) {
      this.updateData = this.router.getCurrentNavigation()?.extras.state?.elem;
      this.pageEvent =
        this.router.getCurrentNavigation()?.extras.state?.pageEvent;
    }
    if (this.router.url.includes("virtual")) {
      this.dataType = 3;
      this.form.controls["dataType"].setValue(3);
      this.form.addControl("interval", new UntypedFormControl(60 * 60000));
    } else if (
      this.router.url.includes("data-input") ||
      this.updateData?.dataType == 4
    ) {
      this.dataType = 4;
      this.apiApp = true;
    } else {
      this.dataType = 2;
      this.form.addControl(
        "dataStreamProfileID",
        new UntypedFormControl(null, Validators.required)
      );
    }
  }

  ngOnInit(): void {
    if (this.updateData) {
      this.form.patchValue(this.updateData);
      if (!this.apiApp) {
        this.profileChanaged(this.form.value.dataStreamProfileID);
      }
      if (!this.updateData.copy) {
        this.buttonLabel = "Gerät anpassen";
      }
    }
  }
  getErrorMessage(validator: string, params: any) {
    return this.vService.getErrorMessage(validator, params);
  }
  onMonacoInit(editor: any) {
    this.monacoEditor = editor;
    if (this.dataType == 3) {
      setMonacoVirtualDefaults((window as any).monaco);
    } else {
      setMonacoDerfaults((window as any).monaco);
    }
    this.monacoEditor.trigger("any", "editor.action.formatDocument", "format");
  }
  loadStreamData() {
    this.loading = true;
    // const profile: IStreamProfiles = this.form.get("dataStreamProfile").value;
    this.ddpService
      .getDeviceDataProfilebyID(
        this.form.value.dataStreamProfileID,
        EDataType.datastream
      )
      .subscribe({
        next: (prof) => {
          prof = prof as IStreamProfiles;
          this.dsService.postRequest(prof).subscribe({
            next: (data) => {
              // console.log(data);
              getStreamParser(
                this.form.get("dataStreamFunction")?.value,
                data || {},
                this.form.get("devEUI")?.value
              )
                .then((result) => {
                  this.requestData = result;
                  this.loading = false;
                })
                .catch((err) => {
                  this.loading = false;
                  this.requestData = err;
                  this.appSettings.getSwalError(
                    err.error?.message || err.message
                  );
                });
            },
            error: (err) => {
              console.log("object", err);
              this.loading = false;
              this.appSettings.getSwalError(err.error?.message || err.message);
            },
          });
        },
      });
  }
  loadDeviceDataFunction() {}
  editDataStreamProfile() {
    this.ddpService
      .getDeviceDataProfilebyID(
        this.form.value.dataStreamProfileID,
        EDataType.datastream
      )
      .subscribe({
        next: (prof) => {
          prof = prof as IStreamProfiles;
          this.dialog
            .open(CreateDataStreamProfileComponent, {
              disableClose: true,
              minWidth: 500,
              data: { profileData: prof },
            })
            .afterClosed()
            .subscribe({
              next: (result) => {
                if (result) {
                  const form: IStreamProfiles = { ...{}, ...result };
                  form._id = prof._id;
                  const contentType = "application/json; charset=UTF-8";
                  // if (result.bodyType === "xml") {
                  //   contentType = "application/xml; charset=UTF-8";
                  // } else if (result.bodyType === "text") {
                  //   contentType = "text/html; charset=UTF-8";
                  // }
                  let headers: { [name: string]: string } =
                    result.apiType == "REST"
                      ? {
                          "Content-Type": contentType,
                        }
                      : {};
                  let parameters: { [name: string]: string } = {};
                  result.headers.map((item: any) => {
                    if (item.key !== "") {
                      headers = { ...headers, ...{ [item.key]: item.value } };
                    }
                  });
                  result.parameters.map((item: any) => {
                    if (item.key !== "") {
                      parameters = {
                        ...parameters,
                        ...{ [item.key]: item.value },
                      };
                    }
                  });
                  form.requestHeader = headers;
                  form.requestParameter = parameters;
                  if (result.requestBody !== null) {
                    if (result.apiType == "SOAP") {
                      form.requestBody = { soap: result.requestBody };
                    } else {
                      form.requestBody = JSON.parse(result.requestBody);
                    }
                  }

                  this.dsService.updateDataStreamProfile(form).subscribe({
                    next: (_) => {
                      // this.loadDataStreamProfiles();
                      this.appSettings.getSwalSuccess(
                        "Profil wurde aktualisiert"
                      );
                    },
                    error: (err) =>
                      this.appSettings.getSwalError(
                        err.error?.message || err.message
                      ),
                  });
                }
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  createStreamDevice() {
    const form = this.form.value;
    if (
      form.defaultLocation &&
      !form.defaultLocation.latitude &&
      !form.defaultLocation.longitude
    ) {
      delete form.defaultLocation;
    }
    delete form.dataStreamProfile;
    // if (!form.devEUI) {
    //   form.devEUI = Math.random().toString(36).substr(2, 16);
    // }
    if (form.devEUI) {
      form.devEUI = form.devEUI.replace(" ", "_");
    }

    const update = { ...(this.updateData || {}), ...form };
    update.dataType = this.dataType;
    // console.log("object", this.updateData);
    // update.tenantID = this.appSettings.currentTenantID();
    if (this.dataType === 3) {
      this.vdService
        .createVirtualDevice(update, this.updateData !== undefined)
        .subscribe({
          next: (res) => {
            this.abbrechen().then((_) => {
              if (this.updateData && !this.updateData.copy) {
                this.appSettings.getSwalSuccess(
                  "Virtuelles Gerät wurde angepasst"
                );
              } else {
                this.appSettings.getSwalSuccess(
                  "Virtuelles Gerät wurde angelegt"
                );
              }
            });
          },
          error: (err) =>
            this.appSettings.getSwalError(err.error?.message || err.message),
        });
    } else {
      this.dsService
        .updateDataStream(
          update,
          this.updateData === undefined || this.updateData.copy
        )
        .subscribe({
          next: (res) => {
            this.abbrechen().then((_) => {
              if (this.updateData && !this.updateData.copy) {
                this.appSettings.getSwalSuccess(
                  "Datastream Gerät wurde angepasst"
                );
              } else {
                this.appSettings.getSwalSuccess(
                  "Datastream Gerät wurde angelegt"
                );
              }
            });
          },
          error: (err) =>
            this.appSettings.getSwalError(err.error?.message || err.message),
        });
    }
  }
  setLocation() {
    this.dialog
      .open(MapViewDialogComponent, {
        data: this.form.get("defaultLocation")?.value,
        width: "600px",
        height: "870px",
      })
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res) {
            this.form.patchValue(res);
          }
        },
      });
  }
  abbrechen() {
    if (this.pageEvent) {
      this.pageEvent.previousPageIndex = this.pageEvent.pageIndex - 1;
    }

    return this.router.navigateByUrl(
      (this.dataType === 2 || this.dataType === 4 ? "datastreams" : "virtual") +
        "/devices",
      { state: { pageEvent: this.pageEvent } }
    );
  }
  profileChanaged(id: any) {
    this.selectedProfile = this.streamProfiles?.find((x) => x._id === id);
  }
  onProfileLoaded(profiles: IStreamProfiles[]) {
    this.streamProfiles = profiles;
  }

  //############################
  getDataForDevice(
    devices: IDevice,
    limitFrom?: number,
    projection?: Object,
    limitTo?: number,
    sort?: Record<string, any>,
    query?: Record<string, any>
  ): Promise<any> {
    let form: any = {
      projection,
      tenantID: this.appSettings.currentTenantID(),
    };
    if (!limitFrom || limitFrom === 0) {
      form["limitLast"] = true;
    } else {
      form["limitFrom"] = limitFrom.toString();
    }
    if (limitTo && limitTo !== 0) {
      form["limitTo"] = limitTo;
    }
    if (devices.device) {
      if (!Array.isArray(devices.device)) {
        devices.device = [devices.device];
      }
      form["devEUI"] = { $in: devices.device };
    } else if (devices.group && !Array.isArray(devices.group)) {
      if (!Array.isArray(devices.group)) {
        devices.group = [devices.group];
      }
      form["group"] = { $in: devices.group };
    } else if (devices.tags && !Array.isArray(devices.tags)) {
      if (!Array.isArray(devices.tags)) {
        devices.tags = [devices.tags];
      }
      form["tags"] = { $in: devices.tags };
    }
    if (sort) {
      form["sort"] = sort;
    }
    if (query) {
      form = Object.assign(form, query);
    }
    return firstValueFrom(this.ddService.queryDeviceData(form));
  }
  getInfoForDevice(devices: string | string[], dataType: number) {
    let service: any = this.loService;
    switch (dataType) {
      case 1:
        service = this.nbService;
        break;
      case 2:
        service = this.dsService;
        break;
      case 3:
        service = this.vdService;
        break;

      default:
        break;
    }
    return firstValueFrom(service.getDevice(devices));
  }
  getSkriptForVirtual() {
    try {
      const script = this.form.value.deviceDataFunction;
      initVirtualFunktion(script, {
        getDataForDevice: this.getDataForDevice.bind(this),
        getInfoForDevice: this.getInfoForDevice.bind(this),
      })
        .then((res) => {
          this.requestData = res;
        })
        .catch((err) => {
          this.appSettings.getSwalError(err);
        });
    } catch (error) {
      this.appSettings.getSwalError(error);
    }
  }
}
interface IDevice {
  dataType: number;
  device: string | string[];
  group?: string | string[];
  tags?: string | string[];
}
