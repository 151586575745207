import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { UIConfigService } from "../../services/uiconfig-service";
import { SharedUIModule } from "../../shared/components/sharedui/sharedui.module";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { CreateDatastreamDeviceComponent } from "../datastreams/create-datastream-device/create-datastream-device.component";
import { DeviceInfoComponent } from "../device-data/device-info.component";
import { DevicesComponent } from "./devices/devices.component";

export const routes: Routes = [
  {
    path: "devices",
    component: DevicesComponent,
    pathMatch: "full",
    data: { breadcrumb: "Geräte", dataType: "virtual" },
  },
  {
    path: "devices/:tenantID",
    component: DevicesComponent,
    pathMatch: "full",
    data: { breadcrumb: "Geräte", dataType: "virtual" },
  },
  {
    path: "createdevice",
    component: CreateDatastreamDeviceComponent,
    pathMatch: "full",
    data: { breadcrumb: "Virtuelles Gerät erstellen" },
  },
  {
    path: "info/:id/:tenantID",
    pathMatch: "full",
    component: DeviceInfoComponent,
    data: { isPublic: false, dataType: "virtual" },
  },
];

@NgModule({
  declarations: [DevicesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    SharedUIModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
  ],
  providers: [UIConfigService],
})
export class VirtualDevicesModule {}
