import * as echarts from "echarts";
import { graphic, matrix, util } from "echarts";
import * as L from "leaflet/src/Leaflet";
/**
 * constructor for Leaflet CoordSys
 * @param {L.map} map
 * @param {Object} api
 */
function LeafletCoordSys(map, api) {
  this._map = map;
  this.dimensions = ["lng", "lat"];
  this._mapOffset = [0, 0];
  this._api = api;
  this._projection = L.Projection.Mercator;
}

LeafletCoordSys.prototype.dimensions = ["lng", "lat"];

LeafletCoordSys.prototype.setZoom = function (zoom) {
  this._zoom = zoom;
};

LeafletCoordSys.prototype.setCenter = function (center) {
  this._center = this._projection.project(new L.LatLng(center[1], center[0]));
};

LeafletCoordSys.prototype.setMapOffset = function (mapOffset) {
  this._mapOffset = mapOffset;
};

LeafletCoordSys.prototype.getLeaflet = function () {
  return this._map;
};
LeafletCoordSys.prototype.prepareCustoms = function () {
  var rect = this.getViewRect();
  return {
    coordSys: {
      // The name exposed to user is always 'cartesian2d' but not 'grid'.
      type: "leaflet",
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    },
    api: {
      coord: util.bind(this.dataToPoint, this),
      size: util.bind(dataToCoordSize, this),
    },
  };
};
function dataToCoordSize(dataSize, dataItem) {
  dataItem = dataItem || [0, 0];
  return util.map(
    [0, 1],
    function (dimIdx) {
      var val = dataItem[dimIdx];
      var halfSize = dataSize[dimIdx] / 2;
      var p1 = [];
      var p2 = [];
      p1[dimIdx] = val - halfSize;
      p2[dimIdx] = val + halfSize;
      p1[1 - dimIdx] = p2[1 - dimIdx] = dataItem[1 - dimIdx];
      return Math.abs(
        this.dataToPoint(p1)[dimIdx] - this.dataToPoint(p2)[dimIdx]
      );
    },
    this
  );
}

LeafletCoordSys.prototype.dataToPoint = function (data) {
  let point = new L.LatLng(data[1], data[0]);
  let px = this._map.latLngToLayerPoint(point);
  let mapOffset = this._mapOffset;
  return [px.x - mapOffset[0], px.y - mapOffset[1]];
};

LeafletCoordSys.prototype.pointToData = function (pt) {
  let mapOffset = this._mapOffset;
  let coord = this._map.layerPointToLatLng({
    x: pt[0] + mapOffset[0],
    y: pt[1] + mapOffset[1],
  });
  return [coord.lng, coord.lat];
};

LeafletCoordSys.prototype.convertToPixel = util.curry(doConvert, "dataToPoint");

LeafletCoordSys.prototype.convertFromPixel = util.curry(
  doConvert,
  "pointToData"
);

/**
 * find appropriate coordinate system to convert
 * @param {*} methodName
 * @param {*} ecModel
 * @param {*} finder
 * @param {*} value
 * @return {*} converted value
 */
function doConvert(methodName, ecModel, finder, value) {
  var leafletModel = finder.leafletModel;
  var seriesModel = finder.seriesModel;

  var coordSys = leafletModel
    ? leafletModel.coordinateSystem
    : seriesModel
      ? seriesModel.coordinateSystem ||
      (seriesModel.getReferringComponents("leaflet")[0] || {}).coordinateSystem // For map.
      : null;
  /* eslint-disable no-invalid-this */
  return coordSys === this ? coordSys[methodName](value) : null;
}

LeafletCoordSys.prototype.getViewRect = function () {
  var api = this._api;
  return new graphic.BoundingRect(0, 0, api.getWidth(), api.getHeight());
};

LeafletCoordSys.prototype.getRoamTransform = function () {
  return matrix.create();
};

LeafletCoordSys.dimensions = LeafletCoordSys.prototype.dimensions;

var CustomOverlay = L.Layer.extend({
  initialize: function initialize(container) {
    this._container = container;
  },

  onAdd: function onAdd(map) {
    var pane = map.getPane(this.options.pane);
    pane.appendChild(this._container);

    // Calculate initial position of container with
    // `L.Map.latLngToLayerPoint()`, `getPixelOrigin()
    // and/or `getPixelBounds()`

    // L.DomUtil.setPosition(this._container, point);

    // Add and position children elements if needed

    // map.on('dispose', this._update, this);
  },

  onRemove: function onRemove(map) {
    L.DomUtil.remove(this._container);
    // map.off('zoomend viewreset', this._update, this);
  },

  // _update: function _update() {
  //   console.log('sdsdsdsdsdsdsdsdsd');
  //   // Recalculate position of container
  //   // L.DomUtil.setPosition(this._container, point);
  //   // Add/remove/reposition children elements if needed
  // },
});
LeafletCoordSys.create = function (ecModel, api) {
  var leafletCoordSys = void 0;
  var leafletList = [];

  var root = api.getDom();
  // TODO Dispose
  ecModel.eachComponent("leaflet", function (leafletModel) {
    var viewportRoot = api.getZr().painter.getViewportRoot();
    if (typeof L === "undefined") {
      throw new Error("Leaflet api is not loaded");
    }
    if (leafletCoordSys) {
      throw new Error("Only one leaflet component can exist");
    }
    if (!leafletModel.__map) {
      // Not support IE8
      var mapRoot = root.querySelector(".ec-extension-leaflet");
      if (mapRoot) {
        // Reset viewport left and top, which will be changed
        // in moving handler in LeafletView
        viewportRoot.style.left = "0px";
        viewportRoot.style.top = "0px";
        root.removeChild(mapRoot);
      }
      mapRoot = document.createElement("div");

      mapRoot.style.cssText =
        "width:calc(100% - 4px);height:calc(100% - 4px);left:2px";
      // Not support IE8
      mapRoot.classList.add("ec-extension-leaflet");
      root.appendChild(mapRoot);
      var _map = (leafletModel.__map = L.map(mapRoot));
      mapRoot.onresize = function () {
        _map.invalidateSize();
      };
      // mapRoot.addEventListener("maplayerchange", function (e) {
      //   console.log("TEST", e);
      // });
      var tiles = leafletModel.get("tiles");
      var defLayer = leafletModel.get("defaultLayer");
      var baseLayers = {};
      var baseLayerAdded = false;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (
          var _iterator = tiles[Symbol.iterator](), _step;
          !(_iteratorNormalCompletion = (_step = _iterator.next()).done);
          _iteratorNormalCompletion = true
        ) {
          var tile = _step.value;
          if (!tile.options) {
            tile.options = { maxZoom: 20 }; // default maxZoom
          }
          var tileLayer = L.tileLayer(tile.urlTemplate, tile.options);
          if (tile.label) {
            // only add one baseLayer
            if (!baseLayerAdded) {
              if (defLayer && tile.label === defLayer) {
                tileLayer.addTo(_map);
                baseLayerAdded = true;
              } else if (!defLayer) {
                tileLayer.addTo(_map);
                baseLayerAdded = true;
              }
            }
            baseLayers[tile.label] = tileLayer;
          } else {
            // add all tiles without labels into the map
            tileLayer.addTo(_map);
          }
        }
        // add layer control when there are more than two layers
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } catch (_) {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (tiles.length > 1) {
        var layerControlOpts = leafletModel.get("layerControl");
        L.control.layers(baseLayers, {}, layerControlOpts).addTo(_map);
      }
      new CustomOverlay(viewportRoot).addTo(_map);
    }
    var map = leafletModel.__map;

    // Set leaflet options
    // centerAndZoom before layout and render
    var center = leafletModel.get("center");
    var zoom = leafletModel.get("zoom");
    if (center && center.length > 0 && zoom) {
      map.setView([center[1], center[0]], zoom);
    } else {
      center = [6.754046, 51.428316];
    }

    leafletCoordSys = new LeafletCoordSys(map, api);
    leafletList.push(leafletCoordSys);
    leafletCoordSys.setMapOffset(leafletModel.__mapOffset || [0, 0]);
    leafletCoordSys.setZoom(zoom);
    leafletCoordSys.setCenter(center);

    leafletModel.coordinateSystem = leafletCoordSys;
  });

  ecModel.eachSeries(function (seriesModel) {
    if (seriesModel.get("coordinateSystem") === "leaflet") {
      seriesModel.coordinateSystem = leafletCoordSys;
    }
  });

  return leafletList;
};

/**
 * compare if two arrays of length 2 are equal
 * @param {Array} a array of length 2
 * @param {Array} b array of length 2
 * @return {Boolean}
 */
function v2Equal(a, b) {
  return a && b && a[0] === b[0] && a[1] === b[1];
}

echarts.extendComponentModel({
  type: "leaflet",

  getLeaflet: function getLeaflet() {
    // __map is injected when creating LeafletCoordSys
    return this.__map;
  },

  setCenterAndZoom: function setCenterAndZoom(center, zoom) {
    this.option.center = center;
    this.option.zoom = zoom;
  },

  centerOrZoomChanged: function centerOrZoomChanged(center, zoom) {
    var option = this.option;
    return !(v2Equal(center, option.center) && zoom === option.zoom);
  },

  defaultOption: {
    center: [51.114129, 6.550339],
    zoom: 10,
    mapStyle: {},
    roam: false,
    layerControl: {},
    tiles: [
      {
        urlTemplate: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        options: {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        },
      },
    ],
  },
});

echarts.extendComponentView({
  type: "leaflet",

  render: function render(leafletModel, ecModel, api) {
    var rendering = true;
    var leaflet = leafletModel.getLeaflet();
    if (!leaflet["rendered"]) {
      var viewportRoot = api.getZr().painter.getViewportRoot();
      var coordSys = leafletModel.coordinateSystem;
      var moveHandler = function moveHandler(type, target) {
        if (rendering) {
          return;
        }
        var offsetEl = viewportRoot.parentNode.parentNode;
        // calculate new mapOffset
        var transformStyle = offsetEl.style.transform;
        var dx = 0;
        var dy = 0;
        if (transformStyle) {
          transformStyle = transformStyle.replace("translate3d(", "");
          var parts = transformStyle.split(",");
          dx = -parseInt(parts[0], 10);
          dy = -parseInt(parts[1], 10);
        } else {
          // browsers that don't support transform: matrix
          dx = -parseInt(offsetEl.style.left, 10);
          dy = -parseInt(offsetEl.style.top, 10);
        }
        var mapOffset = [dx, dy];
        viewportRoot.style.left = mapOffset[0] + "px";
        viewportRoot.style.top = mapOffset[1] + "px";

        coordSys.setMapOffset(mapOffset);
        leafletModel.__mapOffset = mapOffset;
        const center = leafletModel.get("center");
        api.dispatchAction({
          type: "leafletRoam",
          move: true,
          newCenter: center,
        });
      };

      /**
       * handler for map zoomEnd event
       */
      function zoomEndHandler() {
        if (rendering) return;
        api.dispatchAction({
          type: "leafletRoam",
          zoomEnd: leafletModel.get("zoom"),
        });
      }
      /**
       * handler for baselayerchange event
       */
      function baseLayerChangeHandler(layer) {
        //{layer:..., name:..., target:...,type:"baselayerchange"}
        if (rendering) return;
        api.dispatchAction({
          type: "leafletRoam",
          baselayerchange: layer,
        });
      }

      /**
       * handler for map zoom event
       */
      function zoomHandler() {
        moveHandler();
      }

      // function clickHandler(event) {
      //   if (rendering) return;
      //   api.dispatchAction({
      //     type: "leafletRoam",
      //     clickEvent: event,
      //   });
      // }
      function dblclickHandler(event) {
        if (rendering) return;
        api.dispatchAction({
          type: "leafletRoam",
          dblclickEvent: event,
        });
      }

      // function updateLayout() {
      //   // var dom = api.getDom();
      //   // var _height = dom.querySelector('div').offsetHeight;
      //   // var _width = dom.querySelector('div').offsetWidth;
      //   // var viewportRoot = api.getZr().painter.getViewportRoot();
      //   // var _canvas = viewportRoot.querySelector('canvas');
      //   // _canvas.style.height = _height;
      //   // map.invalidateSize()
      //   // console.log(L);
      //   console.log("leaflet###");
      // }
      leaflet.off("dblclick");
      if (this._oldMoveHandler)
        leaflet.off("move", this._oldMoveHandler);
      if (this._oldZoomHandler)
        leaflet.off("zoom", this._oldZoomHandler);
      if (this._oldZoomEndHandler)
        leaflet.off("zoomend", this._oldZoomEndHandler);
      if (this._olddblclickEndHandler)
        leaflet.off("dblclick", this._olddblclickEndHandler);

      leaflet.off("baselayerchange", this._oldbaseLayerChangeHandler);

      leaflet.on("dblclick", dblclickHandler);
      leaflet.on("move", moveHandler);
      leaflet.on("zoom", zoomHandler);
      leaflet.on("zoomend", zoomEndHandler);
      // leaflet.on("click", clickHandler);


      leaflet.on("baselayerchange", baseLayerChangeHandler);
      // leaflet.on('dispose', disposeHandler);
      // api.on('dispose', disposeHandler);

      this._oldbaseLayerChangeHandler = baseLayerChangeHandler;
      this._oldMoveHandler = moveHandler;
      this._oldZoomEndHandler = zoomHandler;
      this._oldZoomEndHandler = zoomEndHandler;
      // this._oldClickEndHandler = clickHandler;
      this._olddblclickEndHandler = dblclickHandler;

      var roam = leafletModel.get("roam");
      var zoomControl = leafletModel.get("zoomControl");
      zoomControl ? undefined : leaflet.zoomControl.remove();
      // can move
      if (roam && roam !== "scale") {
        leaflet.dragging.enable();
      } else {
        leaflet.dragging.disable();
      }
      // can zoom (may need to be more fine-grained)
      if (roam && roam !== "move") {
        leaflet.scrollWheelZoom.enable();
        leaflet.doubleClickZoom.enable();
        leaflet.touchZoom.enable();
      } else {
        leaflet.scrollWheelZoom.disable();
        leaflet.doubleClickZoom.disable();
        leaflet.touchZoom.disable();
      }
      leaflet["rendered"] = true;
      rendering = false;
    }
  },
});

/**
 * Leftlet component extension
 */

echarts.registerCoordinateSystem("leaflet", LeafletCoordSys);

echarts.registerAction(
  {
    type: "leafletRoam",
    event: "leafletRoam",
    update: "updateLayout",
  },
  function (payload, ecModel) {
    ecModel.eachComponent("leaflet", function (leafletModel) {
      var leaflet = leafletModel.getLeaflet();
      var center = leaflet.getCenter();
      leafletModel.setCenterAndZoom(
        [center.lng, center.lat],
        leaflet.getZoom()
      );
    });
  }
);
// echarts.registerAction(
//   {
//     type: 'dispose',
//     event: 'dispose',
//   },
//   function(payload, ecModel) {
//     delete ecModel.option.leaflet;
//     delete ecModel.leaflet;
//   }
// );

var version = "1.0.0";

export { version };

