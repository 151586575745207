import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { IProduct } from "../../../model/products";
import { ProductsService } from "../../../services/products.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { WindowRef } from "../../../services/WindowRef";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  selector: "app-products",
  templateUrl: "./mycontract.component.html",
  styleUrls: ["./mycontract.component.scss"],
  // encapsulation: ViewEncapsulation.None,
  providers: [ProductsService, WindowRef],
  animations: [blockTransition],
})
export class MyContractComponent implements OnInit {
  public settings: Settings;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private publicdata = false;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public data?: MatTableDataSource<IProduct>;
  public loading = false;
  public displayedColumns = [
    "devEUI",
    "deviceName",
    "description",
    "applicationName",
    "action",
  ];
  public tenantID: any;

  /**
   *
   * @param uiService
   * @param appSettings
   * @param dialog
   * @param dataService
   * @param route
   */
  // TODO: Refactor
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    private dataService: ProductsService,
    private route: ActivatedRoute
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {
      if (params) {
        this.tenantID = params.tenantID;
      }
    });
  }

  ngOnInit() {
    this.loadProducts();
  }

  /**
   * Loading all devices
   */
  loadProducts() {
    this.loading = true;
    this.dataService.getData().subscribe((data) => {
      this.data = new MatTableDataSource(data);
      this.loading = false;
      if (!data) {
        return;
      }
      setTimeout(() => {
        if (this.data) {
          this.data.sort = this.sort;
          this.data.paginator = this.paginator;
        }
      });
    });
  }

  /**
   * Simple Searchvalue
   * @param filterValue Searchvalue
   */
  // TODO: Refactor
  applyFilter(filterValue: string) {
    if (this.data) this.data.filter = filterValue.trim().toLowerCase();
  }
}
