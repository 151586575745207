import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ITag, ITagPaginat } from "../model/tag";
import { AbstractService, UserInfo } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class TagsService extends AbstractService {
  /**
   * HTTP Client for communication
   * @param http initiated http client
   */
  constructor(public http: HttpClient, private info: UserInfo) {
    super("tags");
  }

  /**
   * Getting data of Applications
   */
  getTags(tenantID?: string) {
    const headers = this.headers;
    const url = this.url;
    const params = new HttpParams({
      fromObject: {
        tenantID: tenantID || this.info.TenantID,
      },
    });
    return this.http.get<ITag[]>(url, { headers, params });
  }
  getTagBykey(tagKey: string) {
    const headers = this.headers;
    const url = this.url + "/" + tagKey;
    return this.http.get<ITag>(url, { headers });
  }
  getTagsByTenantID(tenantID: string) {
    return this.getTags(tenantID);
  }
  getTagsByType(tagType: string, pagination?: PageEvent, search?: string) {
    const headers = this.headers;
    const url = this.url;
    let params = new HttpParams({
      fromObject: {
        tagType: tagType,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (tagType === "devices") {
      params = params.append("tenantID", this.info.TenantID);
    } else {
      params = params.append("tenantID", "*");
    }
    if (search) {
      params = params.append("search", search);
    }

    return this.http.get<ITag[] & ITagPaginat>(url, { headers, params });
  }
  createTag(key: string, name: string, tagType: string) {
    return this.http.post(
      this.url,
      { name, tagType, key, tenantID: this.info.TenantID },
      { headers: this.headers }
    );
  }

  setDevicesByTag(tagKey: string, devEUIs?: string[]) {
    const form = {
      key: tagKey,
      taggedIDs: devEUIs,
    };
    return this.http.put(this.url, form, { headers: this.headers });
  }
  changeTagName(tagKey: string, tagName: string) {
    const form = {
      key: tagKey,
      name: tagName,
    };
    return this.http.put(this.url, form, { headers: this.headers });
  }
  removeDeviceFromTag(devEUI: string) {}

  /**
   *
   * @param key
   */
  deleteTag(key: string) {
    const headers = this.headers;
    const url = `${this.url}/` + key;
    return this.http.delete(url, { headers });
  }
}
