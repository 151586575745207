<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="createDevice()" matTooltip="Datenquelle Geräte"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="createDevice(true)" matTooltip="API Application Gerät"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES) && uiService.getUIPermissions(EPermissions.API_APPLICATION)">
      <mat-icon>add_box</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" #search />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter('');search.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-checkbox (change)="apiAppFilter($event)" *ngIf="uiService.getUIPermissions(EPermissions.API_APPLICATION)">
    Nur Api Application Geräte
  </mat-checkbox>
  <div fxLayout="row">
    <div fxFill *ngIf="dataStreams && dataStreams.data.length > 0" @blockTransition>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" [pageIndex]="pageEvent.pageIndex" (page)="onPagination($event)" class="block">
      </mat-paginator>
      <table mat-table [dataSource]="dataStreams" matSort class="block" fxFill aria-describedby="Datastream Geräte"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="dataStreamName">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bezeichnung</th>
          <td mat-cell *matCellDef="let element">
            {{ element.deviceName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef scope="col">Beschreibung</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.dataType===2?"Datenquelle":"API Application" }}</td>
        </ng-container>

        <ng-container matColumnDef="dataStreamEUI">
          <th mat-header-cell *matHeaderCellDef scope="col">EUI</th>
          <td mat-cell *matCellDef="let element">
            {{ element.devEUI }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastSeenAt">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Zuletzt gesehen</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.lastSeenAt | localizedDate: "dd.MM.yy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Erstellt</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.createdAt | localizedDate: "dd.MM.yy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="defaultLocation">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Ort
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.defaultLocation !== undefined">
              <button mat-icon-button color="primary" (click)="$event.stopPropagation(); setLocation(element)">
                <mat-icon>map</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col">Aktion</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="devMenu" (click)="$event.stopPropagation()"
              *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="updateDataStream(element)">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Anpassen</span>
              </button>
              <button mat-menu-item (click)="updateDataStream(element,true)">
                <mat-icon class="color-primary">content_copy</mat-icon>
                <span>Kopieren</span>
              </button>
              <button mat-menu-item (click)="createMQTTSub(element)"
                *ngIf="uiService.getUIPermissions(EPermissions.MQTT)">
                <mat-icon class="color-primary"> cloud_upload</mat-icon>
                <span>Daten per MQTT empfangen</span>
              </button>
              <button mat-menu-item (click)="createAlertSub(element)"
                *ngIf="uiService.getUIPermissions(EPermissions.ALERTS)">
                <mat-icon class="color-primary">add_alert</mat-icon>
                <span>Alarmierung</span>
              </button>
              <button mat-menu-item (click)="deleteDataStream(element)">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"
          (click)="getDeviceInfo(row)" style="cursor: pointer"></tr>
      </table>
    </div>
    <div *ngIf="!dataStreams || dataStreams.data.length === 0" fxFlex fxLayoutAlign="center center">
      Sie haben noch keine Geräte angelegt.
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
