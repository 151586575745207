import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { ICreateDeviceDataProfile } from "../../../../model/devicedataprofile";
import { EDataType } from "../../../../model/enum/dataType";
import { ITag } from "../../../../model/tag";
import { DeviceDataProfileService } from "../../../../services/DeviceDataProfileService";
import { TagsService } from "../../../../services/tags.service";
import { UIConfigService } from "../../../../services/uiconfig-service";
import {
  getKeys,
  isBoolean,
  isObject,
} from "../../../../shared/abstract/utils";

export interface DialogData {
  appProfileName: string;
  decoderJS: string;
}

@Component({
  selector: "app-create-devicedataprofile",
  templateUrl: "./create-devicedataprofile.component.html",
  styleUrls: ["./create-devicedataprofile.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDeviceDataProfileComponent implements OnInit, AfterViewInit {
  getKeys = getKeys;
  isObject = isObject;
  isBoolean = isBoolean;
  decoderForm!: UntypedFormGroup;
  dialogData!: DialogData;
  tagsData!: ITag[];
  editorOptions = {
    theme: "vs-dark",
    language: "javascript",
    range: {
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: 1,
      endColumn: 1,
    },
  };
  decoder =
    '// Decode decodes an array of bytes into an object.\n//  - fPort contains the LoRaWAN fPort number\n//  - bytes is an array of bytes, e.g. [225, 230, 255, 0]\n// The function must return an object, e.g. {"temperature": 22.5}\nfunction Decode(fPort, bytes){\n    return {};\n}';
  encoder =
    '// Encode encodes the given object into an array of bytes.\n//  - fPort contains the LoRaWAN fPort number\n//  - obj is an object, e.g. {"temperature": 22.5}\n// The function must return an array of bytes, e.g. [225, 230, 255, 0]\n function Encode(fPort, obj) {\n\t  return [];\n}';
  public profileID?: string;
  public tenantID?: string;
  public EDataType = EDataType;
  private dataType!: EDataType;
  constructor(
    private fb: UntypedFormBuilder,
    private aService: DeviceDataProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DeviceDataProfileService,
    private tService: TagsService,
    private appSettings: AppSettings,
    private cdg: ChangeDetectorRef,
    private uiService: UIConfigService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.profileID = params._id;
        this.tenantID = params.tenantID;
        this.dataType = +params.dataType;
      }
    });
  }
  ngOnInit() {
    this.decoderForm = this.fb.group({
      name: [
        "",
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
      manufacturer: ["", Validators.required],
      deviceType: ["", Validators.required],
      dataType: [null, Validators.required],
      tags: [null],
    });
  }
  ngAfterViewInit(): void {
    this.decoderForm.patchValue({ dataType: this.dataType });
    if (this.profileID) {
      this.dataService
        .getDeviceDataProfilebyID(this.profileID, this.dataType)
        .subscribe({
          next: (res) => {
            res = res as ICreateDeviceDataProfile;
            if (res) {
              this.decoderForm.patchValue(res);
              this.decoder = `${res.payloadDecoderScript}`;
              this.encoder = `${res.payloadEncoderScript}`;
              this.tService
                .getTagsByType("device-data-profiles")
                .subscribe((result) => {
                  if (result) {
                    this.tagsData = result;
                    if ((res as ICreateDeviceDataProfile).tags) {
                      const data = result.filter((tag) =>
                        (res as ICreateDeviceDataProfile).tags.includes(tag.key)
                      );
                      this.decoderForm.patchValue(data);
                    }
                  }
                });
            }
          },
          error: (err) =>
            this.appSettings.getSwalError(err.error?.message || err.message),
        });
    } else {
      this.tService.getTagsByType("device-data-profiles").subscribe({
        next: (result) => {
          this.tagsData = result;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    }
    this.cdg.detectChanges();
  }

  onSaveClick(): void {
    const tenantID = this.aService.getMyTenantID();
    const form = { ...{}, ...this.decoderForm.value };
    form["payloadDecoderScript"] = this.decoder.toString().trim();
    form["payloadEncoderScript"] = this.encoder.toString().trim();
    form["tenantID"] = this.tenantID || tenantID;
    if (this.profileID) {
      form["_id"] = this.profileID;
      this.aService.updateDeviceDataProfile(form, form.dataType).subscribe({
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
        },
        next: () => {
          this.abbrechen();
        },
      });
    } else {
      this.aService.createDeviceDataProfile(form, form.dataType).subscribe({
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
        next: () => {
          this.abbrechen();
        },
      });
    }
  }
  abbrechen() {
    if (
      this.appSettings.currentTenantID() === "superadmin" &&
      this.router.url.includes("/superadmin/tenants")
    ) {
      // if (this.dataType === EDataType.nbiot) {
      //   this.router.navigate([
      //     "superadmin",
      //     "tenants",
      //     {
      //       outlets: {
      //         _management: ["devicedataprofiles", "nbiot", this.tenantID],
      //       },
      //     },
      //   ]);
      // } else {
      this.router.navigate([
        "superadmin",
        "tenants",
        {
          outlets: {
            _management: ["devicedataprofiles", "lora", this.tenantID],
          },
        },
      ]);
      // }
    } else {
      // if (this.dataType === EDataType.nbiot) {
      //   this.router.navigateByUrl("admin/devicedataprofiles/nbiot");
      // } else {
      this.router.navigateByUrl("lora/devicedataprofiles");
      // }
    }
  }
  isValid(control: string) {
    if (this.decoderForm.controls[control]) {
      return this.decoderForm.controls[control].invalid;
    } else {
      return (this.decoderForm.controls["deviceProfile"] as UntypedFormGroup)
        .controls[control].invalid;
    }
  }
}
