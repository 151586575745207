import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { BannerComponent } from "./banner/banner.component";
import { BaseDialogFooterButtonComponent } from "./base-dialog/base-dialog-footer-button/base-dialog-footer-button.component";
import { BaseDialogFooterComponent } from "./base-dialog/base-dialog-footer/base-dialog-footer.component";
import { BaseDialogHeaderComponent } from "./base-dialog/base-dialog-header/base-dialog-header.component";
import { ExpiredMessageComponent } from "./expired-message/expired-message.component";
import { InputDialogComponent } from "./input-dialog/input-dialog.component";
import { PasswordEyeComponent } from "./password-eye/password-eye.component";
import { ChartConfigSelectorComponent } from "./selectors/chartconfig-selector/chartconfig-selector";
import { DataProfileSelectComponent } from "./selectors/dataprofile-selector/profile-selector";
import { DataTypeSelectorComponent } from "./selectors/datatype-selector/datatype-selector";
import { DeviceSelectComponent } from "./selectors/device-selector/device-selector";
import { DiagrammSelectorComponent } from "./selectors/diagramm-selector/diagramm-selector";
import { LoraProfileSelectorComponent } from "./selectors/lora-profile-selector/lora-profile-selector";
import { LoraServiceSelectorComponent } from "./selectors/lora-service-selector/lora-service-selector";
import { RestapiProfileSelectComponent } from "./selectors/restapi-selector/restapi-selector";
import { TemplateSelectorComponent } from "./selectors/template-selector/template-selector";

@NgModule({
  declarations: [
    BannerComponent,
    InputDialogComponent,
    ExpiredMessageComponent,
    BaseDialogHeaderComponent,
    BaseDialogFooterComponent,
    BaseDialogFooterButtonComponent,
    PasswordEyeComponent,
    DeviceSelectComponent,
    DataProfileSelectComponent,
    DataTypeSelectorComponent,
    LoraServiceSelectorComponent,
    LoraProfileSelectorComponent,
    DiagrammSelectorComponent,
    ChartConfigSelectorComponent,
    RestapiProfileSelectComponent,
    TemplateSelectorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    NgSelectModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    BannerComponent,
    InputDialogComponent,
    BaseDialogHeaderComponent,
    BaseDialogFooterComponent,
    BaseDialogFooterButtonComponent,
    PasswordEyeComponent,
    DeviceSelectComponent,
    DataProfileSelectComponent,
    DataTypeSelectorComponent,
    LoraServiceSelectorComponent,
    LoraProfileSelectorComponent,
    DiagrammSelectorComponent,
    ChartConfigSelectorComponent,
    RestapiProfileSelectComponent,
    MatSelectModule,
    NgxMatSelectSearchModule,
    TemplateSelectorComponent,
  ],
  // entryComponents: [
  //   TableButtonbarComponent,
  //   InputDialogComponent,
  //   ExpiredMessageComponent,
  // ],
})
export class SharedUIModule {}
