import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ILogin } from "../model/login";
import { IUserInfo, IUserUIConfig } from "../model/user";
import { AbstractService, UserInfo } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class UserLoginService extends AbstractService {
  constructor(private http: HttpClient, private info: UserInfo) {
    super("users");
  }

  /**
   *User Authorization
   *
   * @param {*} username
   * @param {*} password
   * @returns {Observable<ILogin>}
   * @memberof UserLoginService
   */
  login(
    username: string,
    password: string,
    forever: string
  ): Observable<ILogin> {
    const url = `${this.api}/login`;
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let params = new HttpParams();
    if (forever) {
      params = params.append("tokenNonExpiring", "");
    }
    headers = headers.append(
      "Authorization",
      `Basic ${btoa(username + ":" + password)}`
    );

    return this.http.get<ILogin>(url, { headers, params });
  }
  login2Factor(token: string, passcode: string) {
    const url = `${this.api}/login`;
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let params = new HttpParams();
    params = params.append("passcode", passcode);
    headers = headers.append("Authorization", `Bearer ${token}`);
    return this.http.get<ILogin>(url, { headers, params });
  }
  // User Default UI Config     #####################
  getUserDefaultConfig(username: string) {
    const params = new HttpParams({
      fromObject: {
        // tenantID,
        username,
      },
    });

    return this.http.get<IUserInfo[]>(this.url, { params });
  }
  setUserDefaultConfig(uiconfig: IUserUIConfig) {
    return this.http.put(this.url, {
      username: this.info.UserName,
      uiconfig,
    });
  }
  // END User Default UI Config #####################
}
