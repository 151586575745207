<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="w-100">
  <div fxLayout="row" fxLayoutGap="5px">
    <button mat-raised-button (click)="goBack()">Zurück</button>
    <button mat-raised-button (click)="addDeviceToGroup()" color="primary">
      Geräte Hinzufügen
    </button>
  </div>
  <span *ngIf="Tag" class="w-100 bold-text" fxLayout="row" fxLayoutAlign="center center">{{ Tag.name }}
  </span>
  <div class="flex-p" @blockTransition fxFlexFill>
    <ng-container *ngIf="devices">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" class="block">
      </mat-paginator>
      <table mat-table [dataSource]="devices" matSort class="w-100 block" aria-describedby="Getagte Geräte" scope="col">
        <!-- <ng-container matColumnDef="key">
                    <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n>
                        Key
                    </th>
                    <td mat-cell *matCellDef="let element" class="w-max-100p">
                        {{ element.key }}
                    </td>
                </ng-container> -->

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Name
          </th>
          >
          <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
          >
        </ng-container>
        <ng-container matColumnDef="Type">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Gerätetyp
          </th>
          >
          <td mat-cell *matCellDef="let element">
            {{ getDataType(element.dataType) }}
          </td>
          >
        </ng-container>
        <ng-container matColumnDef="devEUI">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            devEUI
          </th>
          >
          <td mat-cell *matCellDef="let element">{{ element.devEUI }}</td>
          >
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          >
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="warn" (click)="deleteDeviceFromTag(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
          >
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"></tr>
      </table>
    </ng-container>
    <ng-container *ngIf="!devices && !loading">
      <div fxLayoutAlign="center center">
        Keine Geräte in der Gruppe. Sie können Geräte hinzufügen.
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
