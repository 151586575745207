import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { IEmailTemplate } from "src/app/model/email-template";
import { TemplateService } from "src/app/services/template.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "src/app/services/uiconfig-service";
import { blockTransition } from "src/app/theme/utils/app-animation";

@Component({
  selector: "email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
  animations: [blockTransition],
})
export class EmailComponent implements OnInit {
  public displayedColumns = ["name", "description", "action"];
  public settings: Settings;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public loading = false;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data: IEmailTemplate[] | undefined;
  public search: string | undefined;
  constructor(
    public uiService: UIConfigService,
    private appSettings: AppSettings,
    private tService: TemplateService,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    this.tService
      .getTemplates("email", this.pageEvent, this.search, {
        fields: "name,description",
      })
      .subscribe({
        next: (res) => {
          if (res) {
            this.pageEvent.length = res.total;
            this.data = res.data;
          }
        },
      });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.pageEvent.pageIndex = 0;
    this.loadData();
  }
  createTemplate(elem?: IEmailTemplate) {
    this.router.navigate(["subscriptions", "email_template", "create"], {
      queryParams: { id: elem?._id },
    });
  }
  deleteTemplate(elem: IEmailTemplate) {
    this.appSettings
      .getSwalDefaultConfirm("Template " + elem.name + " entfernen?")
      .then((result) => {
        if (result.isConfirmed && elem._id) {
          this.tService.deleteTemplate("email", elem._id).subscribe({
            next: (res) => {
              this.appSettings.getSwalSuccess("Erfolgreich enfernt");
              this.loadData();
            },
            error: (err) => {
              this.appSettings.getSwalError(err.error?.message || err.message);
            },
          });
        }
      });
  }
}
