import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";
import { SharedModule } from "../../../shared/shared.module";
import { PipesModule } from "../../../theme/pipes/pipes.module";
import { CreateDeviceDataProfileComponent } from "./create-devicedataprofile/create-devicedataprofile.component";
import { DeviceDataProfileComponent } from "./devicedataprofile.component";

export const routes: Routes = [
  {
    path: "",
    component: DeviceDataProfileComponent,
    pathMatch: "full",
    data: { dataType: 0 },
  },
  {
    path: "create-devicedata-profile",
    component: CreateDeviceDataProfileComponent,
    pathMatch: "full",
    data: { dataType: 0 },
  },
];
//MonacoEditorModule.forRoot(monacoConfig)
@NgModule({
  declarations: [DeviceDataProfileComponent, CreateDeviceDataProfileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MonacoEditorModule,
  ],
})
export class DeviceDataProfileModule {}
