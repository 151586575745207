import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { IChartDefaultConfig } from "../model/chartsDefaultConfig";
import { IDashboardUIConfig } from "../model/dashboardConfig";
import { EPermissionType } from "../model/login";
import { AppConstants } from "../shared/app.constants";
import { AbstractService } from "./abstract.service";

export enum ERole {
  "NONE",
  "READ",
  "WRITE",
  "API",
}
export enum EPermissions {
  "NONE",
  "TENANTMANAGEMENT" = "TENANTMANAGEMENT",
  "USERMANAGEMENT" = "USERMANAGEMENT",
  "USER" = "USER",
  "DEVICES" = "DEVICES",
  "BLEDEVICES" = "BLEDEVICES",
  "TAGS" = "TAGS",
  "DASHBOARD" = "DASHBOARD",
  "CONTRACT" = "CONTRACT",
  "MARKETPLACE" = "MARKETPLACE",
  "CHARTMANAGEMENT" = "CHARTMANAGEMENT",
  "CONTRACTMANAGEMENT" = "CONTRACTMANAGEMENT",
  "API" = "API",
  "DEVICE_DATA_API" = "DEVICE_DATA_API",
  "API_PROFILES" = "API_PROFILES",
  "API_APPLICATION" = "DEVICE_DATA_INPUT",
  "DATA_FORWARD" = "DEVICE_DATA_FORWARD",
  "ALERTS" = "DEVICE_DATA_ALERT",
  "MQTT" = "DEVICE_DATA_MQ",
}

@Injectable()
export class UIConfigService extends AbstractService {
  constructor(private http: HttpClient, private appSettings: AppSettings) {
    super("ui-configs");
  }

  getDashboards() {
    const url = `${this.url}`;
    const headers = this.headers;
    let params = new HttpParams();
    params = params.append("configType", "dashboard");
    params = params.append("fields", "name,_id,configType");
    params = params.append("tenantID", this.appSettings.currentTenantID());
    return this.http.get<any[]>(url, { headers, params });
  }

  getDashboardsByID(_id: string): Observable<IDashboardUIConfig[]> {
    const url = this.url;
    const headers = this.headers;
    let params = new HttpParams();
    params = params.append("configType", "dashboard");
    params = params.append("tenantID", this.appSettings.currentTenantID());
    if (_id) {
      params = params.append("_id", _id);
    }
    return this.http.get<IDashboardUIConfig[]>(url, { headers, params });
  }
  saveDashboard(data: any) {
    const url = this.url;
    const headers = this.headers;
    return this.http.post<any>(url, [data], { headers });
  }
  updateDashboard(data: any) {
    const url = this.url;
    const headers = this.headers;
    return this.http.put(url, data, { headers });
  }

  deleteDashboard(_id: string) {
    const url = `${this.url}/${_id}`;
    const headers = this.headers;
    return this.http.delete(url, { headers });
  }

  getChartsConfigs(type?: string, custom?: boolean) {
    const headers = this.headers;
    const url = `${this.url}`;
    const params = new HttpParams({
      fromObject: {
        configType: "chart",
        tenantID: "*",
        "config.chartType": type ? type : "",
        template: custom ? "false" : "true",
      },
    });
    return this.http.get<IChartDefaultConfig[]>(url, { params, headers });
  }
  getChartsConfigsByTenantID(tenantID: string, custom = false) {
    const headers = this.headers;
    const url = `${this.url}`;
    const params = new HttpParams({
      fromObject: {
        configType: "chart",
        tenantID: tenantID,
        template: custom ? "false" : "true",
      },
    });
    return this.http.get<IChartDefaultConfig[]>(url, { params, headers });
  }
  public currentUserName() {
    return this.appSettings.currentUserName();
  }
  // Create UI Config Item
  createConfigItem(form: any, update?: boolean): Observable<any> {
    const headers = this.headers;
    if (update) {
      return this.updateConfigItem(form);
    } else {
      return this.http.post(this.url, form, { headers });
    }
  }

  // Update an existing Config Item
  updateConfigItem(form: any, tenantID?: string) {
    const headers = this.headers;
    return this.http.put(this.url, form, { headers });
  }
  deleteConfigItem(_id: string) {
    const headers = this.headers;
    const url = `${this.url}/${_id}`;
    return this.http.delete(url, { headers });
  }

  // Set UI Config Item
  setConfigItem() {
    return;
  }

  /**
   *
   *
   * @param {string} UIPoint
   * @param {ERole} role
   * @returns
   * @memberof UIConfigService
   */
  getUIPermissions(UIPoint: EPermissions, role?: ERole, _public = false) {
    const tenant = this.appSettings.currentTenantID().toLocaleLowerCase();
    if (tenant === "superadmin") {
      return true;
    } else if (_public && tenant !== "public") {
      return false;
    }
    switch (UIPoint) {
      case EPermissions.TENANTMANAGEMENT:
        return (
          this.permTenantManagement == role ||
          this.permTenantManagement === ERole.WRITE
        );
      case EPermissions.CHARTMANAGEMENT:
        return (
          this.permChartManagement == role ||
          this.permChartManagement === ERole.WRITE
        );
      case EPermissions.CONTRACTMANAGEMENT:
        return (
          this.permContractManagement == role ||
          this.permContractManagement === ERole.WRITE
        );
      case EPermissions.USERMANAGEMENT:
        return this.permUserManagement;
      case EPermissions.USER:
        return this.permUser;
      case EPermissions.DEVICES:
        return this.permDevices == role || this.permDevices === ERole.WRITE;
      case EPermissions.BLEDEVICES:
        return (
          this.permBleDevices == role || this.permBleDevices === ERole.WRITE
        );
      case EPermissions.TAGS:
        return this.permTags == role || this.permTags === ERole.WRITE;
      case EPermissions.DASHBOARD:
        return this.permDashboard == role || this.permDashboard === ERole.WRITE;
      case EPermissions.MARKETPLACE:
        return (
          this.permMarketPlace == role || this.permMarketPlace === ERole.WRITE
        );
      case EPermissions.CONTRACT:
        return this.permContract == role || this.permContract === ERole.WRITE;
      case EPermissions.DATA_FORWARD:
      case EPermissions.MQTT:
      case EPermissions.ALERTS:
      case EPermissions.DEVICE_DATA_API:
      case EPermissions.API_APPLICATION:
        return this.getAboPermissions(UIPoint);
      case EPermissions.API_PROFILES:
      case EPermissions.API:
        return (
          this.getAboPermissions(EPermissions.DEVICE_DATA_API) ||
          this.getAboPermissions(EPermissions.API_APPLICATION) ||
          this.getAboPermissions(EPermissions.ALERTS)
        );
      default:
        return false;
    }
  }
  private getAboPermissions(p: EPermissions) {
    const perm = this.appSettings.getCustomerData();
    if (!perm || !perm.permissions) {
      return false;
    }
    return perm.permissions.includes(p.toString());
  }
  // TODO: Redundanter Code - refactoring
  private get permTenantManagement() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    const tenant = this.appSettings.currentTenantID().toLocaleLowerCase();
    if (perm === EPermissionType.Admin && tenant === AppConstants.superadmin) {
      return ERole.WRITE;
    } else if (
      perm === EPermissionType.User &&
      tenant === AppConstants.superadmin
    ) {
      return ERole.READ;
    } else {
      return ERole.NONE;
    }
  }
  private get permChartManagement() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    const tenant = this.appSettings.currentTenantID().toLocaleLowerCase();
    if (perm === EPermissionType.Admin) {
      return ERole.WRITE;
    } else if (
      perm === EPermissionType.User &&
      tenant === AppConstants.superadmin
    ) {
      return ERole.READ;
    } else {
      return ERole.NONE;
    }
  }

  private get permContractManagement() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    const tenant = this.appSettings.currentTenantID().toLocaleLowerCase();
    if (perm === EPermissionType.Admin && tenant === AppConstants.superadmin) {
      return ERole.WRITE;
    } else if (
      perm === EPermissionType.User &&
      tenant === AppConstants.superadmin
    ) {
      return ERole.READ;
    } else {
      return ERole.NONE;
    }
  }

  private get permUserManagement() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    if (perm === EPermissionType.Admin) {
      return true;
    } else {
      return false;
    }
  }
  private get permUser() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    if (perm === EPermissionType.Admin || perm === EPermissionType.User) {
      return true;
    } else {
      return false;
    }
  }

  // TODO: Redundanter Code - refactoring
  private get permDevices() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    switch (perm) {
      case EPermissionType.Admin:
        return ERole.WRITE;
      case EPermissionType.User:
        return ERole.READ;
      case EPermissionType.Viewer:
        return ERole.NONE;
      default:
        return ERole.NONE;
    }
  }
  private get permBleDevices() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    switch (perm) {
      case EPermissionType.Admin:
        return ERole.WRITE;
      case EPermissionType.User:
        return ERole.READ;
      case EPermissionType.Viewer:
        return ERole.NONE;
      default:
        return ERole.NONE;
    }
  }
  private get permTags() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    switch (perm) {
      case EPermissionType.Admin:
        return ERole.WRITE;
      case EPermissionType.User:
        return ERole.READ;
      case EPermissionType.Viewer:
        return ERole.NONE;
      default:
        return ERole.NONE;
    }
  }
  private get permDashboard() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    switch (perm) {
      case EPermissionType.Admin:
        return ERole.WRITE;
      case EPermissionType.User:
        return ERole.READ;
      case EPermissionType.Viewer:
        return ERole.READ;
      default:
        return ERole.NONE;
    }
  }
  private get permMarketPlace() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    switch (perm) {
      case EPermissionType.Admin:
        return ERole.WRITE;
      case EPermissionType.User:
        return ERole.READ;
      case EPermissionType.Viewer:
        return ERole.NONE;
      default:
        return ERole.NONE;
    }
  }
  private get permContract() {
    const perm = this.appSettings.persmissions().toLocaleLowerCase();
    switch (perm) {
      case EPermissionType.Admin:
        return ERole.WRITE;
      case EPermissionType.User:
        return ERole.READ;
      case EPermissionType.Viewer:
        return ERole.NONE;
      default:
        return ERole.NONE;
    }
  }
}
