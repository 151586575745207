import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import { Observable } from "rxjs/internal/Observable";
import { IVirtualDevice, IVirtualDevicePaginat } from "../model/virtual-device";
import { AbstractService, UserInfo } from "./abstract.service";
import { BrokerService } from "./broker.service";

@Injectable({
  providedIn: "root",
})
export class VirtualDevicesService extends AbstractService {
  constructor(
    public http: HttpClient,
    private info: UserInfo,
    private bService: BrokerService
  ) {
    super("virtual/devices");
  }
  getDevices(tenantID?: string, pagination?: PageEvent, search?: string) {
    const url = this.url;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : this.info.TenantID,
      },
    });
    const headers = this.headers;
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    return this.http.get<IVirtualDevicePaginat & IVirtualDevice[]>(url, {
      params,
      headers,
    });
  }
  getDevice(
    devEUI: string | string[]
  ): Observable<IVirtualDevice | IVirtualDevice[]> {
    const headers = this.headers;
    const params = new HttpParams({
      fromObject: {
        devEUI: devEUI,
        tenantID: this.info.TenantID,
      },
    });
    return this.http.get<IVirtualDevice | IVirtualDevice[]>(this.url, {
      headers,
      params,
    });
  }

  getDevicesQuery(body: any) {
    const headers = this.headers;
    return firstValueFrom(
      this.http.post<IVirtualDevice | IVirtualDevice[]>(
        this.url + "-query",
        body,
        { headers }
      )
    );
  }

  deleteDevice(deviceID: string) {
    const headers = this.headers;
    const url = `${this.url}/` + deviceID;
    return this.http.delete(url, { headers });
  }
  createVirtualDevice(form: any, update?: Boolean, tenantID?: string) {
    form.tenantID = this.info.TenantID;
    if (update) {
      return this.http.put(this.url, form);
    }
    return this.http.post(this.url, form);
  }
}
