<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" class="w-min-300p">
      <mat-form-field>
        <input matInput placeholder="Bezeichnung" formControlName="name" required />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Beschreibung" formControlName="description" />
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="addDevices()">Geräte auswählen</button>
      <mat-form-field *ngIf="selectedDevices">
        <input matInput placeholder="" [value]="selectedDevices.length+' Gerät(en) ausgewählt'" readonly>
      </mat-form-field>
    </div>
  </form>
</div>

<base-dialog-footer #dialogFooter></base-dialog-footer>
