import { Clipboard } from "@angular/cdk/clipboard";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";
import Swal from "sweetalert2";
import { AppSettings } from "../../../app.settings";
import { EPermissionType } from "../../../model/login";
import { IUserInfo, IUserInfoPagi } from "../../../model/user";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { UserManagementService } from "../../../services/user-management.service";
import { isDefined } from "../../../shared/abstract/utils";
import { ValidatorMessageService } from "../../../shared/components/validator-message.service";
import { blockTransition } from "../../../theme/utils/app-animation";
import { passwordValidator } from "../../../validators/main-validators";
import { CreateUserDialogComponent } from "../create-user-dialog/create-user-dialog.component";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
  animations: [blockTransition],
})
export class UserManagementComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;

  public data?: MatTableDataSource<IUserInfo>;
  public displayedColumns = [
    "username",
    "permissions",
    "createdAt",
    "tenantID",
    "lastLogin",
    "action",
  ];
  public loading = false;
  private tenantID!: string;
  public routerSubscription?: Subscription;
  public EPermissions = EPermissions;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public token?: string;
  constructor(
    private dialog: MatDialog,
    private uService: UserManagementService,
    private route: ActivatedRoute,
    private appSettings: AppSettings,
    public uiService: UIConfigService,
    private router: Router,
    private vService: ValidatorMessageService,
    private clipB: Clipboard
  ) {
    this.route.params.subscribe((params) => {
      if (params && params.tenantID) {
        this.tenantID = params.tenantID;
      } else {
        this.tenantID = this.appSettings.currentTenantID();
      }
    });
  }

  ngOnInit() {
    this.getUsers();
  }
  applyFilter(filterValue: string) {
    if (this.data) {
      this.data.filter = filterValue.trim().toLowerCase();
    }
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.getUsers();
  }
  getUsers() {
    this.loading = true;
    let par;
    if (
      this.appSettings.persmissions().toLocaleLowerCase() ===
      EPermissionType.User
    ) {
      par = { username: this.appSettings.currentUserName() };
    }
    this.uService
      .getUsers(this.tenantID, this.pageEvent, undefined, par)
      .subscribe((users) => {
        users = users as IUserInfoPagi;
        if (users && users.data) {
          this.data = new MatTableDataSource(users.data);
          this.pageEvent.length = users.total;
          this.loading = false;
          setTimeout(() => {
            if (this.data) this.data.sort = this.sort;
          });
        }
      });
  }
  createUserDialog(user?: IUserInfo) {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {
      data: { user: user },
      disableClose: true,
      closeOnNavigation: true,
      height: "370px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const perm = result.permissions;
        const form = {
          username: result.username,
          tenantID: this.tenantID,
          permissions: perm,
          password: result.password,
        };
        this.uService.createUser(form, isDefined(user)).subscribe({
          next: (res) => {
            this.appSettings.getSwalSuccess(
              isDefined(user)
                ? "Benutzer wurde angepasst"
                : "Benutzer wurde Erstellt"
            );
            this.getUsers();
          },
          error: (err) => {
            if (err.error && err.error.apiError && err.error.apiError === 334) {
              this.appSettings.getSwalSuccess(
                "Benutzer existiert! Wurde zu dem Tenant hinzugefügt"
              );
              return this.getUsers();
            } else {
              this.appSettings.getSwalError(err.error?.message || err.message);
            }
          },
        });
      }
    });
  }
  deleteUser(user: IUserInfo) {
    if (this.data && this.data.data.length === 1) {
      return;
    }
    const apiUSer =
      user.permissions !== "api"
        ? ""
        : "<br><br>Es werden alle REST API Freigaben für diesen Benutzer entfernt!";
    this.appSettings
      .getSwalDefaultConfirm(
        "User löschen",
        'Wollen Sie den User "' +
          user.username +
          '" wirklich löschen?' +
          apiUSer
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.appSettings.getSwalSuccess("Erfolgreich gelöscht");
          this.uService.deleteUser(user.username).subscribe({
            next: (res) => {
              this.getUsers();
            },
            error: (err) => {
              if (
                err.error &&
                err.error.apiError &&
                err.error.apiError === 333
              ) {
                this.appSettings.getSwalSuccess(
                  "Benutzer wurde aus dem Tenant enfernt"
                );
                this.getUsers();
              } else {
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                );
              }
            },
          });
        }
      });
  }
  resetPassword(username: string) {
    this.passwordDialog("Geben Sie neuen Passwort ein").then((result) => {
      if (result.isConfirmed) {
        this.passwordDialog(
          "Geben Sie erneut den Passwort ein",
          result.value
        ).then((res2) => {
          if (res2.isConfirmed) {
            this.uService.setUserPassword(username, res2.value).subscribe({
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
              complete: () => {
                this.appSettings.getSwalSuccess(
                  "Das Passwort wurde erfolgreich geändert."
                );
              },
            });
          }
        });
      }
    });
  }
  passwordDialog(title: string, passOld?: string) {
    return Swal.fire({
      title: title,
      input: "password",
      showCloseButton: true,
      inputPlaceholder: "Passwort eingeben",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
        autocomplete: "new-password",
      },
      inputValidator: (pass) => {
        if (passOld && pass !== passOld) {
          return this.vService.getErrorMessage("mismatchedPasswords");
        }
        if (passwordValidator(new UntypedFormControl(pass)).invalidPassword) {
          return this.vService.getErrorMessage("invalidPassword");
        }
        return "";
      },
    });
  }
  getToken(username: string) {
    if (username) {
      return firstValueFrom(this.uService.getTokenForApiUser(username));
      // .subscribe({
      //   next: (res: any) => {
      //     if (res && res.token) {
      //       this.token = res.token;
      //     } else {
      //       this.appSettings.getSwalError(
      //         "Es wurde kein Token gefunden. Erstellen Sie eventuell den User nochmal."
      //       );
      //     }
      //   },
      //   error: (err) => {
      //     this.appSettings.getSwalError(err.error?.message || err.message);
      //   },
      // });
    }
    return Promise.resolve("");
  }
  restDevices(username: string) {
    this.router.navigate(["subscriptions", "api-devices"], {
      queryParams: { username },
    });
  }
  showToken(username: string) {
    this.getToken(username).then((result: any) => {
      if (result) {
        AppSettings.Swal.fire({
          input: "textarea",
          inputLabel: "Token",
          inputValue: result.token,
          confirmButtonText: "Kopieren",
        }).then((result) => {
          this.clipB.copy(result.value);
        });
      }
    });
  }
}
