import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { IApiRestProfile } from "../../../../model/apiRestProfile";
import { IDevice } from "../../../../model/devices";
import { EDataType } from "../../../../model/enum/dataType";
import { ESubscriptionType } from "../../../../model/enum/subscriptions";
import {
  IAction,
  IResourceConfig,
  IResourceFilter,
  ISubscription,
} from "../../../../model/subscriptions";
import { SubscriptionsService } from "../../../../services/subscriptions.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../../services/uiconfig-service";
import { AppConstants } from "../../../../shared/app.constants";
import { RestAlertProfilesService } from "../../rest-alert-profiles.service";

@Component({
  templateUrl: "./create-forwarding.component.html",
  styleUrls: ["./create-forwarding.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateForwardingComponent implements OnInit, AfterViewInit {
  public form: UntypedFormGroup;
  public apiProfiles: IApiRestProfile[] | undefined;
  public _devices_: IDevice[] | undefined;
  public buttonLabel = "Erstellen";
  private SUB: ISubscription | undefined;
  public forwardTypes = AppConstants.forwardTypes;
  public EPermissions = EPermissions;
  constructor(
    private appSettings: AppSettings,
    private fb: UntypedFormBuilder,
    private router: Router,
    private rService: RestAlertProfilesService,
    private cdr: ChangeDetectorRef,
    private sService: SubscriptionsService,
    private route: ActivatedRoute,
    public uiService: UIConfigService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.SUB = this.router.getCurrentNavigation()?.extras
        .state as ISubscription;
    }
    this.form = this.fb.group({
      name: new FormControl<string>(
        "",
        Validators.compose([Validators.required])
      ),
      forwardType: new FormControl<number>(0, Validators.required),
      restAPI: new FormControl<string>("", Validators.required),
      dataType: [null],
      deviceDataProfile: [null],
      devices: [null],
      mqttForward: new FormControl<boolean>(false, Validators.requiredTrue),
      apiForward: new FormControl<boolean>(false, Validators.requiredTrue),
      saveData: new FormControl<boolean>(false),
      dataLog: new FormControl<boolean>(false),
    });
    this.ForwardType?.valueChanges.subscribe((val) => {
      if (val > 0) {
        this.form.controls.dataType.setValidators(Validators.required);
      } else {
        this.form.controls.dataType.patchValue(null);
        this.form.controls.dataType.setValidators(null);
      }
      if (val > 1) {
        this.form.controls.deviceDataProfile.setValidators(Validators.required);
      } else {
        this.form.controls.deviceDataProfile.setValidators(null);
      }
      if (val > 2) {
        this.form.controls.devices.setValidators(Validators.required);
      } else {
        this.form.controls.devices.setValidators(null);
      }
      this.form.updateValueAndValidity();
    });
    this.form.controls.dataType.valueChanges.subscribe((val) => {
      if (val >= 3) {
        this.form.controls.deviceDataProfile.setValidators(null);
      } else if (this.ForwardType?.value > 2) {
        this.form.controls.deviceDataProfile.setValidators(Validators.required);
      }
    });
    this.form.controls.mqttForward.valueChanges.subscribe((val) => {
      if (val) {
        this.form.controls.apiForward.setValidators(null);
        this.form.controls.restAPI.setValidators(null);
        this.form.controls.apiForward.updateValueAndValidity();
        this.form.controls.restAPI.updateValueAndValidity();
      } else {
        this.form.controls.apiForward.setValidators(Validators.requiredTrue);
        this.form.controls.restAPI.setValidators(Validators.required);
      }
      this.form.updateValueAndValidity();
    });
    this.form.controls.apiForward.valueChanges.subscribe((val) => {
      if (val) {
        this.form.controls.restAPI.setValidators(Validators.required);
        this.form.controls.mqttForward.setValidators(null);
      } else {
        this.form.controls.restAPI.setValidators(null);
        this.form.controls.mqttForward.setValidators(Validators.requiredTrue);
      }
      this.form.updateValueAndValidity();
    });
  }
  get ForwardType() {
    return this.form.get("forwardType")!;
  }
  ngOnInit(): void {
    this.init();
  }
  ngAfterViewInit(): void {
    if (this.SUB) {
      this.buttonLabel = "Anpassen";
      let forwardType = 0;
      if (
        this.SUB.resourceFilter.dataType &&
        this.SUB.resourceFilter.dataType >= 0
      ) {
        forwardType = 1;
      } else if (this.SUB.resourceFilter.deviceDataProfile) {
        this.SUB.resourceFilter.dataType =
          this.SUB.resourceFilter.forwardData?.dataType;
        forwardType = 2;
      } else if (this.SUB.resourceFilter.devEUI) {
        this.SUB.resourceFilter.dataType =
          this.SUB.resourceFilter.forwardData?.dataType;
        this.SUB.resourceFilter.deviceDataProfile =
          this.SUB.resourceFilter.forwardData?.deviceDataProfile;
        forwardType = 3;
      }
      if (forwardType > 0) {
        this.form.controls.dataType.setValue(this.SUB.resourceFilter.dataType);
      }
      if (forwardType > 1) {
        this.form.controls.deviceDataProfile.patchValue(
          this.SUB.resourceFilter.deviceDataProfile
        );
      }
      if (forwardType > 2) {
        this._devices_ = this.SUB.devices;
        this.form.controls.devices.setValue(this.SUB.devices);
      }

      this.form.patchValue({
        name: this.SUB.name,
        forwardType: forwardType,
        saveData: this.SUB.resourceConfig.forwardConfig?.saveData,
        dataLog: this.SUB.resourceConfig.forwardConfig?.dataLog,
      });
      this.SUB.resourceConfig.actions.forEach((a) => {
        if (a.actionType === "REST_API") {
          this.form.controls.restAPI.setValue(a.actionConfig.rest);
          this.form.controls.apiForward.setValue(true);
        } else if (a.actionType === "MQTT") {
          this.form.controls.mqttForward.setValue(true);
        }
      });
      this.cdr.detectChanges();
      this.form.updateValueAndValidity();
    }
  }
  init() {}

  abbrechen() {
    this.router.navigate(["subscriptions", "forwarding"]);
  }
  create() {
    const form = this.form.value;
    const resourceFilter: IResourceFilter = {};
    if (this.ForwardType.value === 0) {
      resourceFilter.global = true;
    } else if (this.ForwardType.value === 1) {
      resourceFilter.dataType = form.dataType;
    } else if (this.ForwardType.value === 2) {
      resourceFilter.forwardData = {};
      resourceFilter.forwardData.dataType = form.dataType;
      resourceFilter.deviceDataProfile = form.deviceDataProfile;
    } else if (this.ForwardType.value === 3) {
      resourceFilter.forwardData = {};
      resourceFilter.forwardData.dataType = form.dataType;
      resourceFilter.forwardData.deviceDataProfile = form.deviceDataProfile;
      resourceFilter.devEUI = form.devices.map((i: any) => i.devEUI);
    }
    const resourceConfig: IResourceConfig = {
      actions: [] as IAction[],
      forwardConfig: { saveData: form.saveData, dataLog: form.dataLog },
    };
    if (form.apiForward) {
      resourceConfig.actions.push({
        actionType: "REST_API",
        actionConfig: { rest: form.restAPI },
      });
    }
    if (form.mqttForward) {
      resourceConfig.actions.push({
        actionConfig: { topic: this.getMqttTopic() },
        actionType: "MQTT",
      });
      if (resourceConfig.forwardConfig) {
        resourceConfig.forwardConfig.topic = this.getMqttTopic();
      }
    }
    const sub: ISubscription = {
      name: form.name,
      tenantID: this.appSettings.currentTenantID(),
      owner: this.appSettings.currentUserName(),
      resourceFilter,
      resourceType: ESubscriptionType.DeviceDataForward,
      resourceConfig,
      subscriptionConfig: {
        active: true,
      },
    };
    this.sService.createSubscription(sub, this.SUB?._id).subscribe({
      next: () => {
        this.appSettings.getSwalSuccess(
          "Weiterleitung wurde erfolgreich " +
            (this.SUB ? "angepasst." : "erstellt.")
        );
        this.abbrechen();
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
  onDeviceTypeChange(type: any) {
    if (type != 3) {
      this.form.addControl(
        "deviceDataProfile",
        new UntypedFormControl(null, Validators.compose([Validators.required]))
      );
      this.form.patchValue({
        deviceDataProfile: null,
        devices: null,
      });
    }
  }
  getMqttTopic(): string {
    let topic = "/" + this.appSettings.currentTenantID() + "/";
    if (this.ForwardType.value > 0) {
      topic += EDataType[this.form.controls.dataType.value] + "/";
    }
    if (this.ForwardType.value > 1) {
      topic += this.form.controls.deviceDataProfile.value + "/";
    }
    return topic + "#";
  }
  onProfileChange(prof: any) {
    this.form.patchValue({
      devices: null,
    });
  }
}
