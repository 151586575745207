import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";
import { SharedUIModule } from "../../shared/components/sharedui/sharedui.module";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { DeviceInfoComponent } from "../device-data/device-info.component";
import { CreateDatastreamDeviceComponent } from "./create-datastream-device/create-datastream-device.component";
import { CreateDataStreamProfileComponent } from "./create-profile-dialog/CreateDataStream.component";
import { DatastreamDevicesComponent } from "./datastream-devices/datastream-devices.component";
import { DataStreamsProfileComponent } from "./datastream-profile/datastreams-profile.component";

export const routes: Routes = [
  {
    path: "profile",
    component: DataStreamsProfileComponent,
    pathMatch: "full",
    data: { breadcrumb: "Profile" },
  },
  {
    path: "devices",
    component: DatastreamDevicesComponent,
    pathMatch: "full",
    data: { breadcrumb: "Geräte", dataType: "datastreams" },
  },
  {
    path: "devices/:tenantID",
    component: DatastreamDevicesComponent,
    pathMatch: "full",
    data: { breadcrumb: "Geräte", dataType: "datastreams" },
  },
  {
    path: "createdevice",
    component: CreateDatastreamDeviceComponent,
    pathMatch: "full",
    data: { breadcrumb: "Datenquellengerät erstellen" },
  },
  {
    path: "info/:id/:tenantID",
    pathMatch: "full",
    component: DeviceInfoComponent,
    data: { isPublic: false, dataType: "datastreams" },
  },
];

@NgModule({
  declarations: [
    DataStreamsProfileComponent,
    CreateDataStreamProfileComponent,
    DatastreamDevicesComponent,
    CreateDatastreamDeviceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    SharedUIModule,
    PipesModule,
    NgxJsonViewerModule,
    MonacoEditorModule,
  ],
  // entryComponents: [CreateDataStreamProfileComponent],
})
export class DataStreamsModule {}
