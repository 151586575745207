import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import Swal from "sweetalert2";
import { Settings } from "./app.settings.model";
import { ChartTheme } from "./model/enum/chartTheme";
import { ICustomerMetaData, ITenant } from "./model/tenant";
import { UserInfo } from "./services/abstract.service";
import { TenantService } from "./services/tenant.service";
import { UserLoginService } from "./services/user-login.service";
import { debounce, isUndefined } from "./shared/abstract/utils";

@Injectable({
  providedIn: "root",
})
export class AppSettings {
  static SettingsInstance?: AppSettings = undefined;
  static Swal = Swal;
  public Permissions!: BehaviorSubject<string>;
  private changed: BehaviorSubject<string>;
  private Settings = new Settings(
    "IoT Plattform", // theme name
    true, // loadingSpinner
    true, // fixedHeader
    true, // sidenavIsOpened
    true, // sidenavIsPinned
    true, // sidenavUserBlock
    "vertical", // horizontal , vertical
    "default", // default, compact, mini
    ChartTheme.dvvlight, // indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
    false, // true = rtl, false = ltr
    false,
    this.info.TenantID || ""
  );
  private updateHandler;
  private customerMetaData: ICustomerMetaData = {} as ICustomerMetaData;
  constructor(
    private uService: UserLoginService,
    private info: UserInfo,
    private tService: TenantService
  ) {
    const config = localStorage.getItem("config-" + this.info.UserName);
    if (config && config !== "undefined") {
      const conf = JSON.parse(config);
      this.settings.tenant = conf?.config?.tenant || this.info.TenantID;
      this.info.TenantID = JSON.parse(config).config?.tenant;
    } else {
      this.settings.tenant = this.info.TenantID;
    }
    this.changed = new BehaviorSubject<string>("");
    this.Permissions = new BehaviorSubject<string>("");

    this.updateHandler = debounce(() => {
      localStorage.setItem(
        "config-" + this.info.UserName,
        JSON.stringify({ config: this.settings })
      );
      this.uService
        .setUserDefaultConfig({
          config: this.settings,
        })
        .subscribe();
    }, 2000);
    if (isUndefined(AppSettings.SettingsInstance)) {
      this.getUserDefaultConfig()?.subscribe();
    }

    return (AppSettings.SettingsInstance =
      AppSettings.SettingsInstance || this);
  }
  getTenants() {
    return this.tService.getData().pipe(
      map((t) => {
        t.map((x: ITenant) => {
          this.customerMetaData[x.tenantID] = x.customerMetaData || {};
        });
        return t;
      })
    );
  }
  getCustomerData(tenantID?: string): ICustomerMetaData {
    if (!tenantID) {
      tenantID = this.currentTenantID();
    }
    return this.customerMetaData[tenantID];
  }
  getUserInfo() {
    return this.info.userInfo;
  }
  setTimeOut() {
    this.info._setTimeOut();
  }
  isTotpSet() {
    return this.info.isTotpSet();
  }
  removeTotpSet() {
    return this.info.removeTotpSet();
  }
  getUserDefaultConfig() {
    if (!this.info.userInfo) {
      return;
    }
    return this.uService
      .getUserDefaultConfig(
        // this.info.TenantID,
        this.info.UserName
      )
      .pipe(
        map((result) => {
          this.settings.multiTenant = result[0]?.tenants?.length > 0;
          if (this.settings.multiTenant) {
            this.settings.tenant =
              result[0]?.uiconfig?.config?.tenant || result[0]?.tenantID;
            this.info.TenantID = this.settings.tenant;
          } else {
            this.info.TenantID = result[0].tenantID;
            this.settings.tenant = result[0].tenantID;
          }

          if (result && result[0] && result[0].totpId) {
            sessionStorage.setItem("totp", result[0].totpId);
          }
          if (result && result[0].uiconfig && result[0].uiconfig.config) {
            this.Settings.theme = result[0].uiconfig.config.theme;
            localStorage.setItem(
              "config-" + this.info.UserName,
              JSON.stringify(result[0].uiconfig)
            );
          } else {
            this.setDefaultTheme();
          }
          this.checkPermissions();
        })
      );
  }
  checkPermissions() {
    if (this.settings.multiTenant) {
      if (this.info.userInfo.tenant.tenantID === this.info.TenantID) {
        this.info.Permissions = this.info.userInfo.loginUser.permissions;
      } else {
        const ten = this.info.userInfo.tenants?.find(
          (t) => t.tenantID === this.info.TenantID
        );

        if (ten && ten.permissions) {
          this.info.Permissions = ten.permissions;
        }
      }
    }
    this.setPermission(this.info.Permissions);
  }
  getSwalDefaultConfirm(title: string, html?: string) {
    return AppSettings.Swal.fire({
      title,
      html,
      customClass: this.Settings.theme,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "JA",
      cancelButtonText: "NEIN",
    });
  }
  getSwalInfoWindow(title: string, html?: string) {
    return AppSettings.Swal.fire({
      title,
      html,
      customClass: this.Settings.theme,
      showCloseButton: true,
      showCancelButton: false,
      confirmButtonText: "OK",
    });
  }
  getSwalError(text: any) {
    if (typeof text === "object" && text["message"]) {
      text = text.message;
    }
    return AppSettings.Swal.fire({
      position: "top-end",
      timer: 7000,
      customClass: this.Settings.theme,
      text: text,
      icon: "error",
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }
  getSwalSuccess(text: string) {
    return AppSettings.Swal.fire({
      position: "top-end",
      timer: 3000,
      text: text,
      customClass: this.Settings.theme,
      timerProgressBar: true,
      icon: "success",
      toast: true,
      showConfirmButton: false,
    });
  }
  getSwalInfo(text: string) {
    return AppSettings.Swal.fire({
      position: "top-end",
      timer: 3000,
      text: text,
      customClass: this.Settings.theme,
      timerProgressBar: true,
      icon: "info",
      toast: true,
      showConfirmButton: false,
    });
  }
  getSwalWarn(text: string) {
    return AppSettings.Swal.fire({
      position: "top-end",
      timer: 5000,
      text: text,
      customClass: this.Settings.theme,
      timerProgressBar: true,
      icon: "warning",
      showConfirmButton: false,
    });
  }
  getSwalFormControl(controlls: IControlls[], data: any) {
    if (!data) {
      data = {};
    }
    let num = `<div class="sweet-container">`;
    let check = `<div class="checkbox-container" style="margin-top:20px;margin-left:12rem">`;
    let sel = `<div class="sweet-container" style="margin-top:10px">`;
    controlls.forEach((e) => {
      switch (e.type) {
        case "number":
          num += `
        <label for="${e.id}" class="swal2-label">${e.id}</label>
        <input id="${e.id}" type="number" class="swal2-input" title="${
            e.desc
          }" value="${data[e.id] ?? 0}">

      `;
          break;
        case "text":
          num += `
        <label for="${e.id}" class="swal2-label">${e.id}</label>
        <input id="${e.id}" type="text" placeholder="${
            e.desc
          }" class="swal2-input" title="${e.desc}" value="${data[e.id] ?? ""}">

      `;
          break;
        case "checkbox":
          check += `
            <input type="checkbox" id="${e.id}" title="${
            e.desc
          }" class="checkbox-item" ${data[e.id] ? "checked" : ""}>
            <label for="${e.id}" class="checkbox-item">${e.id}</label>
          `;
          break;
        case "select":
          sel += `
          <label for="${e.id}" class="swal2-label">${e.id}</label>
          <select  id="${e.id}" class="swal2-select" style="margin:0" title="${
            e.desc
          }">
          <option style="display:none">
          ${e.options
            ?.map((o) => {
              return `<option value="${o.value}" ${
                data[e.id] == o.value ? "selected" : ""
              }>${o.name}</option>`;
            })
            .join("")}
          </select>
          `;
          break;
        default:
          break;
      }
    });
    num += "</div>";
    sel += "</div>";
    check += "</div>";
    return num + sel + check;
  }
  get settings() {
    return this.Settings;
  }
  getThemeObserver() {
    return this.changed.asObservable();
  }
  getPermissionsObserver() {
    return this.Permissions.asObservable();
  }
  setPermission(perm: string) {
    this.Permissions.next(perm);
  }
  setDefaultTheme() {
    this.Settings.theme = ChartTheme.dvvlight;
    this.changed.next(ChartTheme.dvvlight);
    this.updateHandler();
  }
  currentTheme() {
    return this.settings.theme;
  }
  setSettingChanged(key: string, value: any) {
    if (key === "tenant") {
      this.info.TenantID = value;
      this.settings.tenant = value;
      this.checkPermissions();
    }
    (this.Settings as any)[key] = value;
    if (key === "theme") {
      // console.log("change theme");
      this.changed.next(value);
    }
    this.updateHandler();
  }
  currentTenantID() {
    return this.Settings.tenant;
  }
  currentUserName() {
    return this.info.UserName;
  }
  persmissions() {
    return this.info.Permissions || "";
  }
}
export interface IControlls {
  id: string;
  type: string;
  desc: string;
  options?: {
    value: string | number;
    name: string;
    type?: string;
  }[];
}
