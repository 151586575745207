import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EDataType } from "../../../../../model/enum/dataType";

@Component({
  selector: "app-datatype-selector",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          [formControlName]="controlName"
          placeholder="Geräteart"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-option *ngFor="let item of items" [value]="item.index">
            {{ item.name }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="controlName && group.get(controlName)?.invalid"
          >Wählen Sie zuerst eine Geräteart</mat-hint
        >
      </mat-form-field>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTypeSelectorComponent {
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Output() selectionChange = new EventEmitter();
  public loading = false;
  public EDataType = EDataType;
  public items: { index: number; name: any }[];
  constructor(private ref: ChangeDetectorRef) {
    this.items = Object.keys(EDataType)
      .filter((e) => !isNaN(+e))
      .map((o: any) => {
        return { index: +o, name: EDataType[o].toLocaleUpperCase() };
      });
  }

  onChanges(evt: any): void {
    this.ref.detectChanges();
    this.selectionChange.emit(evt);
  }
}
