<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="addTag()" *ngIf="uiService.getUIPermissions(EPermissions.TAGS)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" i18n #search />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter('');search.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="flex-p" fxLayout="row">
    <div *ngIf="data" fxFlex @blockTransition>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" class="block">
      </mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="Datengruppen">
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Name
          </th>
          >
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          >
        </ng-container>
        <ng-container matColumnDef="key">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Key
          </th>
          >
          <td mat-cell *matCellDef="let element">{{ element.key }}</td>
          >
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef scope="col">Erstellt</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.createdAt | localizedDate: "dd.MM.yy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef scope="col">Geändert</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.updatedAt | localizedDate: "dd.MM.yy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          >
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="devMenu" *ngIf="uiService.getUIPermissions(EPermissions.TAGS)"
              (click)="$event.stopPropagation()">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="tagSelected(element)">
                <mat-icon class="color-primary"> edit</mat-icon>
                <span>Geräte anpassen</span>
              </button>
              <button mat-menu-item (click)="createMQTTSub(element)"
                *ngIf="uiService.getUIPermissions(EPermissions.MQTT)">
                <mat-icon class="color-primary"> cloud_upload</mat-icon>
                <span>Daten per MQTT empfangen</span>
              </button>
              <button mat-menu-item (click)="deleteTag(element, $event)" color="warn">
                <mat-icon class="color-warn">delete</mat-icon>
                <span>Gruppe löschen</span>
              </button>
            </mat-menu>
          </td>
          >
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row pointer"
          (click)="tagSelected(row)"></tr>
      </table>
    </div>
    <div *ngIf="!data" fxFlex>
      <div fxLayoutAlign="center center">
        Keine Gruppen definiert! Sie können eine Gruppe erstellen.
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
