import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { subDays } from "date-fns";
import { dispose, ECharts, init } from "echarts";
import { fromEvent, timer } from "rxjs";
import { skip, takeWhile } from "rxjs/operators";
import { isString } from "underscore";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { IChartConfig, IChartsData } from "../../../model/chartsDefaultConfig";
import { IGetDeviceData } from "../../../model/devices";
import { DeviceDataService } from "../../../services/devicedata.services";
import { UIConfigService } from "../../../services/uiconfig-service";
import {
  debounce,
  getRandom,
  initChartConfig,
} from "../../../shared/abstract/utils";
import { ChangeFilter } from "./change-filter";

@Component({
  selector: "app-dynamic-charts",
  templateUrl: "./dynamicCharts.component.html",
  styleUrls: ["./dynamicCharts.component.scss"],
  providers: [AppSettings, UIConfigService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicChartsComponent
  implements OnDestroy, OnChanges, AfterViewInit
{
  public settings: Settings;
  alive = true;
  @ViewChild("Dvvchart", { static: true }) Dvvchart!: ElementRef;
  @Input() item!: IChartConfig;
  @Input() userConfig: any;
  @Input() loading!: boolean;
  @Input() stopUpdate = false;
  @Output() userChanges: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onInit: EventEmitter<ECharts> = new EventEmitter();

  private showNoData = false;
  public jsonData: any;
  echartsInstance?: ECharts;
  resizeHandler: Function;
  updateHandler: Function;
  private lastUpdateTime?: number;
  private firstLoad = true;
  private resetTimer: any;
  private updateTimer: any;
  private chartOptions: any;
  private itemInit = false;
  constructor(
    private appSettings: AppSettings,
    private ddService: DeviceDataService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    // private uiService: UIConfigService,
    private cd: ChangeDetectorRef
  ) {
    this.settings = this.appSettings.settings;

    this.resizeHandler = debounce(this.resize.bind(this), 200);
    this.updateHandler = debounce((val: any) => {
      // todo testen ob val nich undefined ist!
      // console.log("updateHandler", val);
      this.userChanges.emit(val);
    }, 500);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.item.chartData && !this.itemInit) {
        this.initChart();
      }
    }, 150);
    fromEvent(this.Dvvchart.nativeElement, "resize")
      .pipe(takeWhile(() => this.alive))
      .subscribe((_) => {
        this.resizeHandler();
      });
    this.appSettings
      .getThemeObserver()
      .pipe(
        takeWhile(() => this.alive),
        skip(1)
      )
      .subscribe((theme) => {
        if (theme) {
          this.settings.theme = theme;
          this.initChart();
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const filter = ChangeFilter.of(changes);
    filter.has<boolean>("loading").subscribe((v) => this.toggleLoading(v));
    filter.notFirstAndEmpty<any>("userConfig").subscribe((opt) => {
      this.setUserOptions(opt);
    });
    filter.notFirstAndEmpty<any>("item").subscribe((_) => {
      if (this.item) {
        this.itemInit = true;
        setTimeout(() => {
          this.initChart();
        }, 5);
      }
    });
    // filter.notFirstAndEmpty<any>("Theme").subscribe((opt) => {
    //   console.log("theme", opt);
    //   if (opt) {
    //     this.settings.theme = opt;
    //   }

    //   this.dispose();
    //   setTimeout(() => {
    //     this.initChart();
    //   }, 5);
    // });
  }
  ngOnDestroy() {
    this.dispose();
  }
  resize() {
    if (this.echartsInstance) {
      setTimeout(() => {
        this.echartsInstance?.resize({
          silent: true,
        });
      });
      if (this.item.chartData.chartType === "map") {
        const map = this.echartsInstance
          .getDom()
          .querySelector(".ec-extension-leaflet");
        if (map) {
          map.dispatchEvent(new Event("resize"));
          // map.dispatchEvent(
          //   new CustomEvent("maplayerchange", { detail: { name: "map" } })
          // );
        }
      }
    }
  }
  initChart(reload?: boolean) {
    if (this.echartsInstance) {
      this.dispose();
    }
    this.lastUpdateTime = undefined;
    this.firstLoad = true;
    this.alive = true;
    this.echartsInstance = undefined;
    if (this.isJson()) {
      this.initJSON();
    } else if (this.isHtml()) {
      this.initHTML();
    } else {
      this.initDefault(reload);
    }
  }
  initJSON(reload?: boolean) {
    const range = this.item.timeRange || 3; // default 3 Tage
    const limitFrom =
      this.lastUpdateTime || subDays(new Date(), range).getTime();
    const limitLast =
      this.item.chartData.achsen! <= 1 || this.item.timeRange === 0;
    if (!this.item.devices) {
      this.initDummyConfig();
      return;
    }

    this.ddService
      .getDeviceData(
        this.item.devices,
        undefined,
        limitFrom,
        0,
        limitLast,
        this.item.dataType!.toString()
      )
      .subscribe((res) => {
        this.initChartConfig(res)
          .then((opt) => {
            this.jsonData = opt;
            this.cd.detectChanges();
            this.onInitEvent();
          })
          .catch((err) =>
            this.appSettings.getSwalError(err.error?.message || err.message)
          );
      });
  }
  initDefault(reload?: boolean) {
    if (this.resetTimer) {
      clearTimeout(this.resetTimer);
    }
    this.echartsInstance = this.ngZone.runOutsideAngular(() =>
      init(this.Dvvchart.nativeElement, this.settings.theme, {
        renderer: "canvas",
        // height: this.Dvvchart.nativeElement.offsetHeight - 35
      })
    );
    this.initChartEvents();
    if (reload) {
      this.initChartConfig([])
        .then((opt) => {
          opt.series = this.chartOptions.series;
          this.setOption(opt);
          // this.resizeHandler();
        })
        .catch((err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
          // console.log("getChartData.ERROR", err);
          //
        })
        .finally(() => {
          this.toggleLoading(false);
          this.onInitEvent();
          if (this.chartOptions?.static) {
            this.alive = false;
          }
          this.firstLoad = false;
          this.resetTimer = setTimeout(() => {
            if (this.item)
              this.item.chartData.userOptions = this.item.userOptions || {};
            this.initChart();
          }, 60000);
        });
    } else if (
      this.item &&
      this.item.devices &&
      this.item.chartData &&
      this.item.chartData.achsen! >= 1 &&
      (this.item.yAchse || this.item.xAchse || this.item.zAchse)
    ) {
      this.toggleLoading(true);
      this.setUpdateTimer();
    } else {
      this.initDummyConfig();
    }
  }
  isJson() {
    return this.item.chartData.chartType === "json";
  }
  isHtml() {
    return this.item.chartData.chartType === "html";
  }
  setUpdateTimer(delay1?: number, delay2 = 60000) {
    this.updateTimer = timer(delay1 || getRandom(), delay2)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.getDataforChart();
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  initHTML(reload?: boolean) {
    this.updateTimer = timer(getRandom(), 300000)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: () => {
          this.getDataForHtml();
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  initChartEvents() {
    if (this.echartsInstance) {
      this.echartsInstance.on("leafletRoam", (params: any) => {
        if (params["reload"]) {
          if (this.item.chartData.chartType === "map" && params["newCenter"]) {
            this.item.chartData.userOptions.leaflet!.center =
              params["newCenter"];
            this.item.chartData.userOptions.leaflet!.zoom =
              params["zoom"] || 16;
          }
          this.initChart(true);
        } else {
          this.updateHandler(params);
        }
        // this.resizeHandler();
      });
      this.echartsInstance.dispatchAction({
        type: "baselayerchange",
        name: "test",
      });
      // this.echartsInstance.on("click", params => {
      //   console.log("click");
      //   this.updateHandler(params);
      // });
      // this.echartsInstance.on("dataZoom", params => {
      //   console.log("dataZoom");
      //   // this.updateHandler(params);
      // });
    }
    window.addEventListener("error", (ev: ErrorEvent) => {
      console.log("An uncaught error happened");
      this.echartsInstance?.dispose();
    });
  }
  getDataForHtml() {
    this.lastUpdateTime = 0;
    this.getChartData().then((result) => {
      if (result && result.length > 0) {
        this.initChartConfig(result)
          .then((opt) => {
            this.renderer.setProperty(
              this.Dvvchart.nativeElement,
              "innerHTML",
              opt
            );
            this.onInitEvent();
            return;
          })
          .catch((err) => {
            this.appSettings.getSwalError(err.error?.message || err.message);
            this.onInitEvent();
          });
      } else {
        this.initDummyConfig();
      }
    });
  }
  getDataforChart() {
    this.getChartData().then((result) => {
      this.alive = !this.stopUpdate;
      if (result && result.length > 0) {
        if (this.firstLoad || this.item.chartData.achsen === 1) {
          this.initChartConfig(result)
            .then((opt) => {
              this.setOption(opt);
              // this.resizeHandler();
            })
            .catch((err) => {
              this.appSettings.getSwalError(err.error?.message || err.message);
              // console.log("getChartData.ERROR", err);
              //
            })
            .finally(() => {
              this.toggleLoading(false);
              this.onInitEvent();
              if (this.chartOptions?.static) {
                this.alive = false;
              }
              this.firstLoad = false;
            });
        } else {
          let Series = this.chartOptions.series || [];
          let dataChanged = false;
          const maxData = this.item.timeRange * 60 * 24; // Message 1 Min per Hour and Day
          if (!Array.isArray(Series)) {
            Series = [Series];
          }
          new Promise((resolve) => {
            result.map((item) => {
              const serie = Series.find(
                (x: any) =>
                  item.deviceName === x.name || item.devEUI === x.devEUI
              );
              if (serie) {
                dataChanged = true;
                this.initChartConfig([item])
                  .then((opt: any) => {
                    while (serie.data.length >= maxData) {
                      serie.data.shift();
                    }
                    if (this.item.chartData.chartType === "time") {
                      serie.data.pop();
                    }
                    serie.data.push(...opt.series[0].data);
                  })
                  .catch((err) => {
                    this.appSettings.getSwalError(
                      err.message || err.error.message
                    );
                  });
              }
            });
            resolve(undefined);
          }).then(() => {
            if (dataChanged) {
              this.setOption({ series: Series });
              if (this.item.chartData.chartType === "map") {
              }
            }
          });
        }
        this.toggleLoading(false);
      } else if (this.firstLoad) {
        this.initDummyConfig();
      }
    });
  }
  initChartConfig(data: IChartsData[] | IGetDeviceData[]) {
    return initChartConfig(
      data,
      this.item.chartData.options as string,
      this.echartsInstance
    );
  }
  initDummyConfig() {
    this.toggleLoading(false);
    if (!isString(this.item.chartData.options)) {
      this.setOption(this.item.chartData.options);
      this.onInitEvent();
    } else {
      this.initChartConfig([])
        .then((opt) => {
          if (this.item.chartData.chartType === "html") {
            this.renderer.setProperty(
              this.Dvvchart.nativeElement,
              "innerHTML",
              opt
            );
          } else if (this.item.chartData.chartType === "json") {
            this.jsonData = opt;
            this.cd.detectChanges();
          } else {
            this.setOption(opt);
          }
        })
        .catch(() => {
          return;
        })
        .finally(() => this.onInitEvent());
    }
  }
  private onInitEvent() {
    this.onInit.emit(this.echartsInstance);
  }
  getChartData(): Promise<IChartsData[]> {
    let tenant = this.appSettings.currentTenantID();
    if (this.item.tenantID) {
      tenant = this.item.tenantID;
    }
    return new Promise((resolve) => {
      const xValue = this.item.xAchse;
      const yValue = this.item.yAchse;
      const zValue = this.item.zAchse;
      const range = this.item.timeRange || 3; // default 3 Tage
      const limitFrom =
        this.lastUpdateTime || subDays(new Date(), range).getTime();
      const limitLast =
        this.item.chartData.achsen! < 1 || this.item.timeRange === 0;
      if (!this.item.devices) {
        return resolve([]);
      }

      this.ddService
        .getDeviceDataForChart(
          this.item.devices,
          this.item.dataType,
          tenant,
          limitFrom,
          0,
          limitLast,
          xValue,
          yValue,
          zValue
        )
        .subscribe({
          next: (devData) => {
            this.lastUpdateTime = new Date().getTime();
            if (devData && (xValue || yValue || zValue)) {
              const seriesData: any[] = [];
              devData.forEach((_data) => {
                seriesData.push({
                  xValue: _data.xValue,
                  yValue: _data.yValue,
                  zValue: _data.zValue,
                  object: _data.object,
                  rxInfo: _data.rxInfo,
                  createdAt: _data.createdAt,
                  devEUI: _data.devEUI,
                  deviceName: _data.deviceName,
                });
              });
              resolve(seriesData);
            } else {
              resolve([]);
            }
          },
          error: (err) => {
            this.appSettings.getSwalError(err.error?.message || err.message);
            return resolve([]);
          },
        });
    });
  }
  disposeChartEvents() {
    this.echartsInstance?.off("leafletRoam");
  }
  setOption(option: any, notMerge?: boolean, lazyUpdate?: boolean) {
    if (this.echartsInstance) {
      if (option) {
        this.firstLoad && option.onInit && option.onInit();
        this.chartOptions = option;
        this.setUserOptions();
        // this.echartsInstance.setOption(this.chartOptions, notMerge, lazyUpdate);
      }
    }
  }
  setUserOptions(options?: any, notMerge?: boolean, lazyUpdate?: boolean) {
    if (this.echartsInstance) {
      const opt = this.item.chartData.userOptions || options;
      if (opt) {
        Object.keys(this.item.chartData.userOptions).forEach((key) => {
          if (key === "series") {
            this.item.chartData.userOptions["series"]!.forEach(
              (s: any, i: number) => {
                const serie = this.chartOptions["series"][i];
                Object.keys(s).forEach((k: any) => {
                  serie[k] = s[k];
                });
              }
            );
          } else {
            this.chartOptions[key] = {
              ...this.chartOptions[key],
              ...(opt[key] as {}),
            };
          }
        });
      }
      this.echartsInstance.setOption(
        options || (this.chartOptions as any),
        undefined,
        true
      );
    }
  }
  dispose() {
    this.alive = false; // switches your IntervalObservable off
    window.removeEventListener("error", () => {});
    if (this.updateTimer) {
      this.updateTimer.unsubscribe();
    }
    if (this.echartsInstance) {
      this.chartOptions &&
        this.chartOptions.onDispose &&
        this.chartOptions.onDispose();
      this.echartsInstance.clear();
      this.Dvvchart.nativeElement.dispatchEvent(new Event("dispose"));
      this.disposeChartEvents();
      this.echartsInstance.dispose();
      this.firstLoad = true;
      dispose(this.echartsInstance);
    }
  }
  toggleLoading(loading: boolean) {
    if (this.echartsInstance) {
      const color = this.settings.theme.includes("dark")
        ? "#004481"
        : "#009499";
      const textColor = this.settings.theme.includes("light") ? "#333" : "#ddd";
      loading
        ? this.echartsInstance.showLoading("default", {
            text: this.showNoData ? "Keine Daten" : "Daten werden geladen",
            color: color,
            textColor: textColor,
            maskColor: "rgba(51,51,51,0.1)",
            zlevel: 0,
          })
        : this.echartsInstance.hideLoading();
    }
  }
  // TODO: Is Utls verschieben.
  isValidDate(timestamp: number) {
    return new Date(timestamp).getTime() > 1000000000;
  }
  onScroll(evt: any) {
    evt.stopImmediatePropagation();
  }
}
