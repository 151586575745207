import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { IVirtualDevice } from "../../../model/virtual-device";
import { SubscriptionsService } from "../../../services/subscriptions.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { VirtualDevicesService } from "../../../services/virtual-devices.service";
import { changeRouteData, debounce } from "../../../shared/abstract/utils";
import { CreateAlert } from "../../../shared/components/create-alert/create-alert";
import { CreateMqttSubscription } from "../../../shared/components/create-mqttsub/create-mqttsub";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.scss"],
  animations: [blockTransition],
})
export class DevicesComponent implements OnInit {
  public settings: Settings;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public data?: MatTableDataSource<IVirtualDevice>;
  public loading = false;
  public displayedColumns = [
    "deviceName",
    "devEUI",
    "description",
    "lastSeenAt",
    "defaultLocation",
    "action",
  ];
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  private searchHandler: any;
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    private dialog: MatDialog,
    private dService: VirtualDevicesService,
    private router: Router,
    private sService: SubscriptionsService
  ) {
    this.settings = this.appSettings.settings;
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadDevices();
    }, 500);
    if (this.router.getCurrentNavigation()?.extras.state?.pageEvent) {
      this.pageEvent =
        this.router.getCurrentNavigation()?.extras.state?.pageEvent;
    }
  }

  ngOnInit(): void {
    this.loadDevices();
  }
  loadDevices() {
    this.dService
      .getDevices(this.appSettings.currentTenantID(), this.pageEvent)
      .subscribe({
        next: (res) => {
          if (res) {
            this.data = new MatTableDataSource(res.data);
            this.pageEvent.length = res.total;
          } else {
            this.data = undefined;
          }
        },
      });
  }
  applyFilter(filterValue: string) {
    // this.search = filterValue;
    this.searchHandler();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDevices();
  }
  createDevice() {
    this.router.navigateByUrl("virtual/createdevice", {
      state: { pageEvent: this.pageEvent },
    });
  }
  setLocation(d: IVirtualDevice) {}
  editDevice(elem: IVirtualDevice, copy = false) {
    if (copy) {
      elem["copy"] = true;
    }
    this.router.navigateByUrl("virtual/createdevice", {
      state: { elem, pageEvent: this.pageEvent },
    });
  }
  createMQTTSub(row: IVirtualDevice) {
    new CreateMqttSubscription(
      this.dialog,
      this.uiService,
      this.sService,
      this.appSettings
    )
      .createMqttSub(row)
      .subscribe({
        next: (res) => {
          if (res) {
            this.appSettings.getSwalSuccess(
              "MQTT Subscription wurde erstellt."
            );
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  createAlertSub(row: IVirtualDevice) {
    new CreateAlert(this.dialog, this.sService)
      .createAlert(this.appSettings.currentTenantID(), undefined, [
        row,
      ] as IVirtualDevice[])
      .subscribe({
        next: (res) => {
          this.appSettings.getSwalSuccess("Alarmierung wurde erstellt.");
        },
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
        },
      });
  }
  deleteDevice(devEUI: string, deviceName: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Virtelles Gerät löschen?",
        "Wollen Sie wirklich Gerät <strong>" +
          deviceName +
          "</strong>" +
          " löschen?<br><br>"
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.dService.deleteDevice(devEUI).subscribe({
            next: (_) => {
              this.loadDevices();
              this.appSettings.getSwalSuccess("Gerät wurde gelöscht");
            },
          });
        }
      });
  }
  getDeviceInfo(d: IVirtualDevice) {
    changeRouteData(
      this.router,
      "Gerät/" + d.deviceName,
      "virtual",
      "info/:id/:tenantID"
    );
    this.router
      .navigateByUrl(
        "virtual/info/" + d.devEUI + "/" + this.appSettings.currentTenantID(),
        { state: { pageEvent: this.pageEvent } }
      )
      .then((_) => {});
  }
}
