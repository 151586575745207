import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { ESubscriptionType } from "../../../model/enum/subscriptions";
import {
  IAlertSubscrPaginat,
  ISubscription,
} from "../../../model/subscriptions";
import { SubscriptionsService } from "../../../services/subscriptions.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { AppConstants } from "../../../shared/app.constants";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  templateUrl: "./forwarding.component.html",
  styleUrls: ["./forwarding.component.scss"],
  animations: [blockTransition],
})
export class ForwardingComponent implements OnInit {
  public displayedColumns = [
    "name",
    "forward",
    "forwardType",
    "topic",
    "saveData",
    "createdAt",
    "action",
  ];
  public loading = false;
  public pageSizeOptions = [5, 10, 25, 50];
  public settings!: Settings;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<ISubscription>;
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    private sService: SubscriptionsService,
    private dialog: MatDialog,

    private router: Router
  ) {}

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    this.loading = true;
    this.sService
      .getSubscriptions(
        undefined,
        ESubscriptionType.DeviceDataForward,
        this.pageEvent
      )
      .subscribe({
        next: (res) => {
          res = res as IAlertSubscrPaginat;
          // console.log(res);
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
            this.pageEvent.length = res.total;
          } else {
            this.data = new MatTableDataSource<ISubscription>([]);
          }
          this.loading = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  addForwardConfig(elem?: ISubscription) {
    this.router.navigate(["subscriptions", "forwarding-add"], {
      state: elem,
    });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
  applyFilter(filterValue: string) {
    if (this.data) this.data.filter = filterValue.trim().toLowerCase();
  }
  deleteSubscription(elem: ISubscription) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Weiterleitung löschen?",
        "Wollen Sie wirklich keine Daten mehr weiterleiten?"
      )
      .then((result) => {
        if (result.isConfirmed && elem._id) {
          this.sService.deleteSubscription(elem._id).subscribe({
            next: () => {
              this.loadData();
              this.appSettings.getSwalSuccess(
                "Datenweiterleitung wurde entfernt"
              );
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  getForward(elem: ISubscription) {
    let type = "";
    elem.resourceConfig.actions.forEach((a) => {
      if (a.actionType === "REST_API") {
        type += " REST API,";
      } else if (a.actionType === "MQTT") {
        type += " MQTT,";
      }
    });
    return type;
  }
  getForwardType(elem: ISubscription) {
    if (elem.resourceFilter.dataType && elem.resourceFilter.dataType >= 0) {
      return AppConstants.forwardTypes[1].name;
    } else if (elem.resourceFilter.deviceDataProfile) {
      return AppConstants.forwardTypes[2].name;
    } else if (elem.resourceFilter.devEUI) {
      return AppConstants.forwardTypes[3].name;
    }
    return AppConstants.forwardTypes[0].name;
  }
}
