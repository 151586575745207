import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "src/app/app.settings";
import { TemplateService } from "src/app/services/template.service";
import { setMonacoTemplateDefaults } from "src/app/shared/abstract/completionItemProvider";

@Component({
  selector: "create-template",
  templateUrl: "./create-template.component.html",
  styleUrls: ["./create-template.component.scss"],
})
export class CreateTemplateComponent implements OnInit {
  public ParserOptions = {
    theme: "vs-dark",
    language: "html",
    // automaticLayout: true,
    range: {
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: 1,
      endColumn: 1,
    },
  };
  private monacoEditor: any;
  public name: string | undefined;
  public description: string | undefined;
  public template: string | undefined;
  public update = false;
  public _id: string | undefined;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tService: TemplateService,
    private appSettings: AppSettings
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.update = true;
        this._id = params.id;
        this.tService.getTemplateByID("email", params.id).subscribe({
          next: (res) => {
            if (res) {
              this.template = res.template;
              this.name = res.name;
              this.description = res.description;
            }
          },
          error: (err) => {
            this.appSettings.getSwalError(err.error?.message || err.message);
          },
        });
      }
    });
  }

  ngOnInit(): void {}
  onMonacoInit(editor: any) {
    this.monacoEditor = editor;
    setMonacoTemplateDefaults((window as any).monaco);
    setTimeout(() => {
      this.monacoEditor.trigger(
        "any",
        "editor.action.formatDocument",
        "format"
      );
    }, 500);
    // this.monacoEditor.trigger("any", "editor.action.formatDocument", "format");
  }
  abbrechen() {
    this.router.navigate(["subscriptions", "email_template"]);
  }
  add() {
    if (this.name && this.template) {
      const data = {
        name: this.name,
        description: this.description,
        template: this.template,
        _id: this._id,
      };
      this.tService.createOrUpdateTemplate("email", data).subscribe({
        next: () => {
          this.appSettings.getSwalSuccess(
            "Wurde" + (this._id ? " geändert" : " erstellt")
          );
          return this.abbrechen();
        },
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
        },
      });
    }
  }
}
