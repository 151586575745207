import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import {
  IAddResponse,
  IDeletedResponse,
  IUpdateResponse,
} from "../model/broker";
import { IEmailTemplate } from "../model/email-template";
import { IPagination } from "../model/pagination";
import { getParams } from "../shared/abstract/utils";
import { AbstractService } from "./abstract.service";

@Injectable({ providedIn: "root" })
export class TemplateService extends AbstractService {
  constructor(public http: HttpClient, private appSetting: AppSettings) {
    super("templates");
  }
  getTemplates(
    type: string,
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string }
  ) {
    let params = new HttpParams();
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    params = params.append("tenantID", this.appSetting.currentTenantID());
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IPagination<IEmailTemplate>>(`${this.url}/${type}`, {
      params,
    });
  }
  getTemplateByID(type: string, id: string, par?: { [param: string]: string }) {
    let params = new HttpParams();
    params = params.append("tenantID", this.appSetting.currentTenantID());
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IEmailTemplate>(`${this.url}/${type}/${id}`, {
      params,
    });
  }
  createOrUpdateTemplate(type: string, form: any): Observable<any> {
    let params = new HttpParams();
    params = params.append("tenantID", this.appSetting.currentTenantID());
    if (form._id) {
      return this.http.put<IUpdateResponse>(`${this.url}/${type}`, form, {
        params,
      });
    } else {
      return this.http.post<IAddResponse>(`${this.url}/${type}`, form, {
        params,
      });
    }
  }
  deleteTemplate(type: string, id: string) {
    let params = new HttpParams();
    params = params.append("tenantID", this.appSetting.currentTenantID());
    return this.http.delete<IDeletedResponse>(`${this.url}/${type}/${id}`, {
      params,
    });
  }
}
