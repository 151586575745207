import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  // eslint-disable-next-line
  selector: "image-tooltip",
  templateUrl: "./image-tooltip.component.html",
  styleUrls: ["./image-tooltip.component.scss"],
  animations: [
    trigger("tooltip", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(":leave", [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTooltipComponent {
  @Input() image = "";
  @Input() text = "";
  constructor() {}
}
