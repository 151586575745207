import { Clipboard } from "@angular/cdk/clipboard";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { ESubscriptionType } from "../../../model/enum/subscriptions";
import {
  IAlertDevice,
  IAlertSubscrPaginat,
  ISubscription,
} from "../../../model/subscriptions";
import { SubscriptionsService } from "../../../services/subscriptions.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { debounce } from "../../../shared/abstract/utils";
import { AppConstants } from "../../../shared/app.constants";
import { blockTransition } from "../../../theme/utils/app-animation";
import { CreateDataInputComponent } from "./create-data-input/create-data-input.component";

@Component({
  templateUrl: "./data-input.component.html",
  styleUrls: ["./data-input.component.scss"],
  animations: [blockTransition],
})
export class DataInputComponent implements OnInit {
  public displayedColumns = [
    "name",
    "devices",
    "resourceFilter",
    "topic",
    "createdAt",
    "action",
  ];
  public loading = false;
  public pageSizeOptions = [5, 10, 25, 50];
  public settings!: Settings;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<ISubscription>;
  private defPar = {
    fields:
      "name,resourceFilter,createdAt,description,devices,subscriptionConfig",
  };
  private updateHandler: Function;
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    private sService: SubscriptionsService,
    private dialog: MatDialog,
    private clipB: Clipboard,
    private router: Router
  ) {
    this.updateHandler = debounce((val: any) => {
      this.loadData({ search: val, ...this.defPar });
    }, 500);
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.pageEvent = this.router.getCurrentNavigation()?.extras.state
        ?.pageEvent || { length: 0, pageIndex: 0, pageSize: 10 };
    }
  }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(par?: { [param: string]: string }) {
    this.loading = true;
    if (!par) {
      par = this.defPar;
    }
    this.sService
      .getSubscriptions(
        undefined,
        ESubscriptionType.DEviceDataInput,
        this.pageEvent,
        undefined,
        par
      )
      .subscribe({
        next: (res) => {
          res = res as IAlertSubscrPaginat;
          // console.log(res);
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
            this.pageEvent.length = res.total;
          } else {
            this.data = new MatTableDataSource<ISubscription>([]);
          }
          this.loading = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  getSubscription(par?: { [param: string]: string }) {
    return this.sService.getSubscriptions(
      undefined,
      ESubscriptionType.DEviceDataInput,
      undefined,
      undefined,
      par
    );
  }
  addApplication(elem?: ISubscription) {
    this.dialog
      .open(CreateDataInputComponent, {
        disableClose: true,
        data: { elem },
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result) {
            if (!elem) {
              result.subscriptionConfig = { active: true };
            }
            this.sService.createSubscription(result, elem?._id).subscribe({
              next: () => {
                this.appSettings.getSwalSuccess(
                  elem
                    ? "Application wurde angepasst"
                    : "Application wurde hinzugefügt"
                );
                this.loadData();
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
          }
        },
      });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
  applyFilter(filterValue: string) {
    // this.data.filter = filterValue.trim().toLowerCase();
    if (filterValue.length > 2 || filterValue.length == 0) {
      this.pageEvent.pageIndex = 0;
      this.updateHandler(filterValue);
    }
  }
  deleteSubscription(elem: ISubscription) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Subscription löschen?",
        "Wollen Sie wirklich Api Application <strong>" +
          elem.name +
          "</strong></b>" +
          " löschen?"
      )
      .then((result) => {
        if (result.isConfirmed && elem._id) {
          this.sService.deleteSubscription(elem._id).subscribe({
            next: () => {
              this.loadData();
              this.appSettings.getSwalSuccess("API Application wurde entfernt");
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  deaktivateSubscription(elem: ISubscription, disable: boolean) {
    let confirm: Promise<any> = this.appSettings.getSwalDefaultConfirm(
      "Möchten Sie wirklich '" + elem.name + "' deaktivieren?",
      `
  Bitte beachten Sie dass es keine weitere Daten empfanhen werden können!
  `
    );
    if (!disable) {
      confirm = Promise.resolve({ isConfirmed: true });
    }
    confirm.then((res) => {
      if (res.isConfirmed) {
        this.sService.deaktivateSubscription(elem, !disable).subscribe({
          next: (res) => {
            this.loadData();
            this.appSettings.getSwalSuccess(
              `Applications ${disable ? "Deaktiviert" : "Aktiviert"}`
            );
          },
          error: (err) =>
            this.appSettings.getSwalError(err.error?.message || err.message),
        });
      }
    });
  }
  getTopic() {
    let topic =
      "https://iot.rhinecloud.de/data-input/" +
      this.appSettings.currentTenantID();

    return topic;
  }
  getToken() {
    this.getSubscription({ fields: "resourceConfig.token" }).subscribe({
      next: (res) => {
        res = res as ISubscription[];
        if (res.length <= 0) {
          this.appSettings.getSwalError("Kein Token gefunden");
        }
        const token = res[0].resourceConfig.token || "";
        AppSettings.Swal.fire({
          input: "textarea",
          inputLabel: "Token",
          inputValue: token,
          confirmButtonText: "Kopieren",
        }).then((result) => {
          this.clipB.copy(result.value);
        });
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
  getHostname(): string {
    return window.location.origin;
  }
  getForwardType(elem: ISubscription) {
    if (elem.resourceFilter.dataType && elem.resourceFilter.dataType >= 0) {
      return AppConstants.forwardTypes[1].name;
    } else if (elem.resourceFilter.deviceDataProfile) {
      return AppConstants.forwardTypes[2].name;
    } else if (elem.resourceFilter.devEUI) {
      return AppConstants.forwardTypes[3].name;
    }
    return AppConstants.forwardTypes[0].name;
  }
  getDeviceNames(devices: IAlertDevice[]) {
    const names = [];
    let i = 0;
    for (const dev of devices) {
      names.push(dev.deviceName);
      i++;
      if (i >= 10) {
        break;
      }
    }
    let txt = names.join(",");
    if (i >= 10 && devices.length > 10) {
      txt += ", +" + (devices.length - 10) + "weitere...";
    }
    return txt;
  }
  getDeviceEUIs(devices: IAlertDevice[]) {
    const names: string[] = [];
    devices?.forEach((dev) => names.push(dev.devEUI));
    return names.join(", ");
  }
}
