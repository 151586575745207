import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IProduct } from "../model/products";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class ProductsService extends AbstractService {
  /**
   * Getting all products
   * @param http httpclient
   */
  constructor(public http: HttpClient) {
    super("products");
  }

  /**
   * Get the products
   * @param isPublic if tenant specific products or public products
   */
  getData() {
    const headers = this.headers;
    return this.http.get<IProduct[]>(this.url, { headers });
  }

  /**
   *
   *
   * @param {string} tenantID
   * @returns IProduct[]
   * @memberof ProductService
   */
  getProductsByTenantId(tenantID: string) {
    const url = this.url;
    const headers = this.headers;
    const params = new HttpParams({
      fromObject: {
        tenantID: tenantID,
      },
    });
    return this.http.get<IProduct[]>(url, { headers, params });
  }

  /**
   * Creating a product
   * @todo implementing different key types
   * @param deviceName
   * @param deviceDescription
   * @param nwkKey
   */
  public createProduct({ tenantID }: any): Observable<any> {
    const url = `${this.url}?tenantID=${tenantID}`;
    const headers = this.headers;
    const data: any[] = [];
    return this.http.post<any>(url, data, { headers: headers });
  }

  /**
   * Deleting a product
   * @param productID Id fo the product to be deleted
   */
  public deleteProduct(productID: string) {
    const headers = this.headers;
    const url = `${this.url}/` + productID;
    return this.http.delete(url, { headers });
  }
}
