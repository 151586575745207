import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {MatSelect} from "@angular/material/select";
import {Subscription} from "rxjs";
import {isUndefined} from "underscore";
import {AppSettings} from "../../../../../app.settings";
import {MIDeviceProfiles} from "../../../../../model/loraDeviceProfile";
import {IPagination} from "../../../../../model/pagination";
import {LoraDeviceProfilesService} from "../../../../../services/lora-device-profiles.service";
import {debounce} from "../../../../abstract/utils";

@Component({
  selector: "app-lora-device-profile-selector",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          #lora_profile_select
          [formControlName]="controlName"
          [placeholder]="placeholder"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="search"
              [searching]="loading"
              [disableScrollToActiveOnOptionsChanged]="true"
              [clearSearchInput]="false"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let item of profileList"
            [value]="item.deviceProfile.id"
          >
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoraProfileSelectorComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() join?: boolean;
  @Input() placeholder: string = "Lora Gerät Profile";
  @Input() tenantID?: string;
  @Output() selectionChange = new EventEmitter();
  public loading = false;
  public profileList?: MIDeviceProfiles[] | null;
  public search: UntypedFormControl = new UntypedFormControl();
  public pageEvent: PageEvent = {length: 0, pageIndex: 0, pageSize: 10};
  private updateHandler: Function;
  private subs?: Subscription;
  @ViewChild("lora_profile_select") selectElem?: MatSelect;
  constructor(
    private ref: ChangeDetectorRef,
    private ldpService: LoraDeviceProfilesService,
    private appSettings: AppSettings
  ) {
    this.updateHandler = debounce((val: any) => {
      this.pageEvent.pageIndex = 0;
      this.profileList = [];
      this.loadProfiles();
    }, 500);
  }
  ngOnInit(): void {
    this.search.valueChanges.subscribe({
      next: (value) => {
        if (value.length >= 2 || value.length <= 0) {
          this.updateHandler();
        }
      },
    });
    // this.loadProfiles(false);
  }
  loadProfiles(fetch = false) {
    if (isUndefined(this.join)) {
      return;
    }
    this.loading = true;
    this.profileList = fetch ? this.profileList : null;
    this.ldpService
      .getLoraDeviceProfiles(undefined, this.pageEvent, this.search.value, {
        supportJoin: this.join,
      })
      .subscribe({
        next: (result) => {
          result = result as IPagination<MIDeviceProfiles>;
          if (result && result.data) {
            this.profileList = fetch
              ? this.profileList?.concat(result.data)
              : result.data;
            this.pageEvent.length = result.total;
          }
          this.loading = false;
          this.ref.detectChanges();
          this.subs = this.subs
            ? this.subs
            : this.selectElem?.openedChange.subscribe(() => {
              if (this.selectElem?.panelOpen) {
                this.registerPanelScrollEvent();
              }
            });
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
        complete: () => (this.loading = false),
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["join"]) {
      this.loadProfiles();
    }
  }
  onChanges(evt: any): void {
    this.ref.detectChanges();
    this.selectionChange.emit(evt);
  }
  private fetchMore() {
    this.pageEvent.pageIndex++;
    if (
      this.pageEvent.length >
      this.pageEvent.pageIndex * this.pageEvent.pageSize
    ) {
      this.loadProfiles(true);
    }
  }
  registerPanelScrollEvent() {
    if (this.loading) {
      return;
    }
    const panel = this.selectElem?.panel.nativeElement;
    panel.removeEventListener("scroll", () => { });
    panel.addEventListener("scroll", (event: any) => {
      if (event.target.scrollTop > 160 * (this.pageEvent.pageIndex + 1)) {
        this.fetchMore();
      }
    });
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
