import {Component, Inject, OnInit} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {MatDialog, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface
} from "angular-gridster2";
import {EChartsOption} from "echarts";
import * as monaco from "monaco-editor";
import {AppSettings} from "../../app.settings";
import {
  IChartDefaultConfig,
  IChartsData,
  IEChartConfig,
  WidgetData
} from "../../model/chartsDefaultConfig";
import {IDeviceDataProfileList} from "../../model/devicedataprofile";
import {IDevice} from "../../model/devices";
import {EDataType} from "../../model/enum/dataType";
import {ITag} from "../../model/tag";
import {ITenant} from "../../model/tenant";
import {LoraDeviceService} from "../../services/lora-devices";
import {EPermissions, UIConfigService} from "../../services/uiconfig-service";
import {setMonacoEchartsDefaults} from "../../shared/abstract/completionItemProvider";
import {debounce, isDefined} from "../../shared/abstract/utils";
import {AppConstants} from "../../shared/app.constants";

@Component({
  selector: "app-charts-admin",
  templateUrl: "./charts-admin.component.html",
  styleUrls: ["./charts-admin.component.scss"],
})
export class ChartsAdminComponent implements OnInit {
  public userChartConfig?: EChartsOption;
  public itemData: any;
  public _chartData?: IEChartConfig;
  public achsData: any;
  public selectedDevices: string[] = [];
  public DeviceList?: IDevice[];
  public DeviceData?: IChartsData[];
  public tags?: ITag[];
  public dataPofiles?: IDeviceDataProfileList[];
  public diagramDataDef?: IChartDefaultConfig[]; // default
  public diagramDataCus?: IChartDefaultConfig[]; // custom data
  public Tenants?: ITenant[];
  public code?: string;
  public selectedChart?: IChartDefaultConfig;
  public selectedTenantID: string;
  public myTenantID: string;
  public EDataType = EDataType;
  // TODO: Refactor - redundant zusammenfassen
  public TimeRangeOptions = AppConstants.TimeRangeOptions;
  public editorOptions = {
    theme: "vs-dark",
    language: "javascript",
    range: {
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: 1,
      endColumn: 1,
    },
  } as monaco.editor.IEditorOptions;
  public chartTypes = AppConstants.chartTypes;
  public options!: GridsterConfig;
  public form: UntypedFormGroup;
  public currentDataType = 0;
  private optionsHandler: Function;
  public standardOut = JSON.stringify(
    [
      {
        object: ["wenn ausgewählt"],
        devEUI: "",
        deviceName: "",
        xValue: [],
        yValue: [],
        zValue: [],
        rxInfo: [{rssi: "", loraSnr: ""}],
        createdAt: [],
      },
    ],
    undefined,
    2
  );
  public widgetData?: WidgetData;
  public EPermissions = EPermissions;
  private _chartOptions = [
    "// Dokumentation https://echarts.apache.org/en/option.html#title",
    "// Die Function muss die Chart-Konfiguration ausgeben.",
    "function getChartConfig(_value_, _option_, _module_) {",
    "/**@type echarts.EChartsOption*/",
    " return {};",
    "}",
  ];
  private Editor!: monaco.editor.ICodeEditor;
  constructor(
    private appSettings: AppSettings,
    private fb: UntypedFormBuilder,
    public uiService: UIConfigService,
    private dService: LoraDeviceService,
    private dialog: MatDialog
  ) {
    this.form = fb.group({
      title: null,
      tags: null,
      dataProfile: [null, Validators.required],
      tenantID: this.appSettings.currentTenantID(),
      devices: [null, Validators.required],
      chartType: [null, Validators.required],
      chartConfig: [null, Validators.required],
      chartData: null,
      xAchse: "",
      dataType: [null, Validators.required],
      yAchse: null,
      zAchse: "",
      chartOptions: null,
      timeRange: 1, // 1 Tag Default
    });
    this.optionsHandler = debounce((options: any) => {
      const opts = this.form.value;
      opts.devices = opts.devices?.map((d: any) => {
        if (typeof d !== "string") {
          return d.devEUI;
        } else return d;
      });
      if (options) {
        opts.chartData.options = options;
      }
      this.widgetData = this.widgetData || {
        x: 0,
        y: 0,
        cols: 60,
        rows: 20,
        name: "",
      };
      this.itemData = opts;
    }, 1000);
    this.selectedTenantID = this.myTenantID =
      this.appSettings.currentTenantID();
  }
  get ChartData(): UntypedFormControl {
    return this.form.get("chartData") as UntypedFormControl;
  }
  ngOnInit() {
    this.form
      .get("dataProfile")
      ?.valueChanges.subscribe((value) => this.ondataProfileChange(value));
    // this.form
    //   .get("devices")
    //   .valueChanges.subscribe((value) => this.onDevicesChanged(value));
    this.form
      .get("tenantID")
      ?.valueChanges.subscribe((value) => this.onTenantsChanged(value));
    this.form
      .get("xAchse")
      ?.valueChanges.subscribe((value) =>
        this.onAchsenChanged("xAchse", value)
      );
    this.form
      .get("yAchse")
      ?.valueChanges.subscribe((value) =>
        this.onAchsenChanged("yAchse", value)
      );
    this.form
      .get("zAchse")
      ?.valueChanges.subscribe((value) =>
        this.onAchsenChanged("zAchse", value)
      );
    this.form.get("chartOptions")?.valueChanges.subscribe((value) => {
      // this.optionsHandler(value);
    });
    this.options = {...{}, ...AppConstants.gridsterConfig};
    this.options.itemResizeCallback = (
      item: GridsterItem,
      itemComp: GridsterItemComponentInterface
    ) => {
      const el = itemComp.el.querySelector(".chart-object");
      if (el) {
        el.dispatchEvent(new Event("resize"));
      }
    };
    // this.options.itemChangeCallback = (item) => {
    //   this.widgetData = {
    //     x: item.x,
    //     y: item.y,
    //     cols: item.cols,
    //     rows: item.rows,
    //     name: "",
    //   };
    // };
    this.options.resizable!.enabled = true;
  }

  loadData() {
    this.achsData = undefined;
    this.DeviceList = undefined;
    this.form.patchValue(
      {
        dataProfile: null,
        xAchse: "",
        yAchse: null,
        zAchse: "",
      },
      {emitEvent: false}
    );
  }
  onTimeRangeChanged(range: any) {
    this.form.get("timeRange")?.setValue(range);
    this.onAchsenChanged();
  }
  onTenantsChanged(val: string) {
    this.selectedTenantID = val;
  }
  onChartTypeChange() {
    this.form.patchValue(
      {
        chartConfig: null,
        chartData: null,
      },
      {emitEvent: false}
    );
  }
  ondataTypeChange(value: number) {
    this.currentDataType = value;
    this.form.patchValue(
      {
        dataProfile: null,
        devices: null,
      },
      {emitEvent: false}
    );
    // this.loadData(value);
  }
  ondataProfileChange(value: any) {
    this.achsData = null;
  }
  onDevicesChanged(devices: IDevice[]) {
    if (!devices) {
      return;
    }
    this.form.patchValue(
      {
        xAchse: null,
        yAchse: null,
        zAchse: null,
        // devices: devices.map((item) => item.devEUI),
      },
      {emitEvent: false}
    );

    if (devices.length <= 0) {
      this.achsData = null;
      return;
    }

    this.dService
      .getDeviceKeys(devices, this.selectedTenantID)
      .subscribe((keys) => {
        if (keys) {
          this.achsData = keys;
        } else {
          this.form.setErrors({required: true});
        }
      });
    // }
  }
  chartConfigSelected(data: IChartDefaultConfig) {
    this.widgetData = undefined;
    this.selectedChart = data;
    this._chartData = data.config;
    this.achsData = null;
    this.form.patchValue(
      {
        chartOptions: `${AppConstants.jsDOC}${this._chartData.options}`,
        chartData: this._chartData,
        devices: null,
        xAchse: null,
        yAchse: null,
        zAchse: null,
      },
      {emitEvent: false}
    );
    this.widgetData = data.config.widgetData;
    if (data.config.chartType === "html") {
      const model = this.Editor.getModel();
      monaco.editor.setModelLanguage(model as monaco.editor.ITextModel, "html");
    } else {
      const model = this.Editor.getModel();
      monaco.editor.setModelLanguage(
        model as monaco.editor.ITextModel,
        "javascript"
      );
    }
    setTimeout(() => {
      this.Editor.trigger("any", "editor.action.formatDocument", "format");
    }, 300);
    this.optionsHandler(data.config.options);
  }
  onMonacoInit(editor: monaco.editor.ICodeEditor) {
    this.Editor = editor;
    setMonacoEchartsDefaults((window as any).monaco);
  }
  onAchsenChanged(achse?: string, value?: any) {
    if (!this._chartData || !this.ChartData || !this.ChartData.value) {
      return;
    }
    if (achse && value) {
      this.form.patchValue({[achse]: value}, {emitEvent: false});
    }
    let loadOption = true;
    // TODO: Dieses IF ELSE wird niemals benutzt, wegen dem IF ELSE Block danach und kann vereinfacht werden, wie im nächsten block.
    /*if (this.showXAchse() && this.form.get("xAchse").value) {
      loadOption = true;
    } else {
      loadOption = false;
    }*/
    loadOption =
      (this.showXAchse() && isDefined(this.form.get("xAchse")?.value)) || false;
    loadOption = isDefined(this.form.get("yAchse")?.value) && loadOption;
    loadOption =
      loadOption ||
      (this.showZAchse() && isDefined(this.form.get("zAchse")?.value)) ||
      false;
    if (loadOption) {
      this.optionsHandler();
    }
  }
  isArray(val: any) {
    return Array.isArray(val);
  }
  getKeys(val: any) {
    if (val) {
      return Object.keys(val);
    } else {
      return [];
    }
  }

  showXAchse() {
    if (!this.selectedChart) {
      return;
    }
    return (
      (this.selectedChart.config.achsen! > 1 &&
        this.selectedChart.config.chartType !== "time") ||
      this.selectedChart.config.chartType === "map"
    );
  }
  showZAchse() {
    if (!this.selectedChart) {
      return;
    }
    return (
      this.selectedChart.config.chartType === "map" ||
      (this.selectedChart.config.achsen! > 1 &&
        (this.selectedChart.config.chartType === "custom" ||
          this.selectedChart.config.chartType === "html"))
    );
  }

  generateConfig() {
    this.optionsHandler(this.form.get("chartOptions")?.value);
  }

  saveChartConfig(update = false) {
    let chartOptions = "";
    if (this.form.get("chartOptions")?.value) {
      chartOptions = this.form
        .get("chartOptions")
        ?.value.toString()
        .trim()
        .replace(/\W*\/\*\*[\s\W\S]*(?=function getChartConfig)/, "")
        .replace(/[\r\t\f\v ]+/gm, " ");
    }
    if (this.checkChartPermissions(update)) {
      return;
    }
    const chartData = {
      config: {
        options: chartOptions,
        chartType: this.form.get("chartType")?.value,
        userOptions: {},
      },
      name: "New",
      template: false,
    };
    this.selectedChart = {...chartData, ...this.selectedChart};
    this.selectedChart.config.options = chartOptions;

    const dialogRef = this.dialog.open(SaveChartConfigComponent, {
      data: {
        configName: this.selectedChart.name,
        chartType: this.selectedChart.config.chartType,
        template: this.selectedChart.template,
        achsen: this.selectedChart.config.achsen,
        tenant: this.myTenantID,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.selectedChart) {
        this.selectedChart.name = result.configName;
        this.selectedChart.config.chartType = result.chartType;
        this.selectedChart.config.achsen = result.achsen;
        this.selectedChart.template = result.template;
        this.selectedChart.configType = "chart";
        this.selectedChart.config.widgetData = this.widgetData;
        this.selectedChart["tenantID"] = this.appSettings.currentTenantID();
        this.selectedChart["username"] = this.appSettings.currentUserName();
        if (this.selectedTenantID && this.myTenantID === "superadmin") {
          this.selectedChart["tenantID"] = this.selectedTenantID;
        }
        if (!update) {
          delete this.selectedChart._id;
        }
        this.uiService.createConfigItem(this.selectedChart, update).subscribe({
          next: (result) => {
            if (!update && result && this.selectedChart) {
              this.selectedChart._id = result.insertedIDs[0];
              this.form.patchValue({chartType: null});
              this.onChartTypeChange();
              this.loadData();
            }
          },
        });
      }
    });
  }
  checkChartPermissions(update: boolean) {
    if (
      this.myTenantID !== "superadmin" &&
      this.myTenantID !== "public" &&
      update &&
      this.selectedChart?.tenantID !== this.myTenantID
    ) {
      this.appSettings.getSwalWarn(
        "Sie besitzen nicht die Berechtigung, um dieses Diagramm zu verändern"
      );
      return true;
    }
    return false;
  }
  deleteChartConfig() {
    if (!this.selectedChart) {
      return;
    }
    if (this.checkChartPermissions(true)) {
      return;
    }
    this.appSettings
      .getSwalDefaultConfirm(
        "Diagramm Löschen!",
        "Wollen Sie wirklich das Diagramm <b>" +
        this.selectedChart.name +
        "</b> Löschen?"
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.form.patchValue({chartType: null});
          if (this.selectedChart && this.selectedChart._id) {
            this.uiService.deleteConfigItem(this.selectedChart._id).subscribe({
              next: () => {
                this.form.get("chartOptions")?.patchValue("");
                this.onChartTypeChange();
                this.loadData();
              },
            });
          }
        }
      });
  }
  publicDevChanged(val: boolean) {
    if (val) {
      this.selectedTenantID = "public";
      // this.form.patchValue({tenantID: "public"});
    } else {
      this.selectedTenantID = this.myTenantID;
      // this.form.patchValue({tenantID: this.myTenantID});
    }
    this.form.patchValue({dataType: null}, {emitEvent: false});
    this.loadData();
  }
}

@Component({
  selector: "app-save-chart-component",
  template: `
    <div fxLayout="column" [formGroup]="form">
      <h1 mat-dialog-title>
        <span>Chart erstellen / aktualisieren</span>
      </h1>
      <mat-form-field>
        <input
          matInput
          placeholder="Bezeichnung"
          formControlName="configName"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select formControlName="chartType" placeholder="Diagramm-Art">
          <mat-option *ngFor="let type of chartTypes" [value]="type.value">{{
            type.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Achsen"
          formControlName="achsen"
          type="number"
          required
        />
      </mat-form-field>
      <mat-checkbox
        formControlName="template"
        *ngIf="tenantID === 'superadmin' || tenantID === 'public'"
        >Template</mat-checkbox
      >
    </div>

    <div mat-dialog-actions fxLayoutAlign="space-between start">
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="form.value"
        [disabled]="form.invalid"
      >
        <span>Speichern</span>
      </button>
      <button mat-raised-button color="warn" [mat-dialog-close]="null">
        Abbrechen
      </button>
    </div>
  `,
})
export class SaveChartConfigComponent implements OnInit {
  // configName = new FormControl("");
  // chartType = new FormControl("");
  // template = new FormControl();
  // achsen = new FormControl();
  public form: UntypedFormGroup;
  public tenantID: string;
  public chartTypes = AppConstants.chartTypes;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      configName: string;
      chartType: string;
      template: boolean;
      tenant: string;
    },
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      configName: "",
      chartType: "",
      template: null,
      achsen: 1,
    });
    this.tenantID = this.data.tenant;
  }

  ngOnInit() {
    if (this.data) {
      this.form.patchValue(this.data);
      if (this.tenantID !== "superadmin" && this.tenantID !== "public") {
        this.form.patchValue({template: false});
      }
    }
  }
}
