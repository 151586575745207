import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Inject,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { delay } from "rxjs/operators";
import { AppSettings } from "../../../app.settings";
import { IDeviceDataProfileList } from "../../../model/devicedataprofile";
import { EDataType } from "../../../model/enum/dataType";
import { ITag } from "../../../model/tag";
import { BrokerService } from "../../../services/broker.service";
import { DeviceDataProfileService } from "../../../services/DeviceDataProfileService";
import { TagsService } from "../../../services/tags.service";
import { BaseStepDialog } from "../../../shared/components/sharedui/base-dialog/abstract.base-step-dialog.component";

export interface DialogData {
  name: string;
  devEUI: string;
  description: string;
  nwkKey: string;
}

@Component({
  templateUrl: "./create-device-dialog.component.html",
  styleUrls: ["./create-device-dialog.component.scss"],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDeviceDialogComponent
  extends BaseStepDialog
  implements DoCheck
{
  dataProfile!: UntypedFormGroup;
  deviceData!: UntypedFormGroup;
  dialogData!: DialogData;
  tagsData?: ITag[];
  deviceDataProfiles?: IDeviceDataProfileList[];
  macVersions = ["1.0.1", "1.0.2", "1.0.3"];
  deviceClasses = ["ClassA", "ClassB", "ClassC"];
  loadProfile = false;
  defLoc = false;
  customProfiles = false;

  constructor(
    private fb: UntypedFormBuilder,
    private bService: BrokerService,
    private tService: TagsService,
    private dialogRef: MatDialogRef<CreateDeviceDialogComponent>,
    private devicedataprofileService: DeviceDataProfileService,
    private appSettings: AppSettings,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { profile?: IDeviceDataProfileList }
  ) {
    super(dialogRef);
  }

  buildForms(): UntypedFormGroup[] {
    this.dataProfile = this.fb.group({
      macVersion: [null, Validators.required],
      joinType: [true, Validators.required],
      deviceClass: [null, Validators.required],
      deviceProfileID: "",
      deviceDataProfileID: [null, Validators.required],
      serviceProfileID: "",
    });

    this.deviceData = this.fb.group({
      devEUI: ["", Validators.compose([Validators.required])],
      deviceName: ["", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required])],
      appKey: [null, Validators.compose([Validators.required])],
      nwkKey: [null],
      devAddr: [null],
      defaultLocation: new UntypedFormGroup({
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null),
      }),
    });

    this.dataProfile.controls["macVersion"].valueChanges
      .pipe(delay(100))
      .subscribe({
        next: () => {
          this.loadDeviceProfiles();
        },
      });
    this.dataProfile.controls["deviceClass"].valueChanges
      .pipe(delay(100))
      .subscribe({
        next: () => {
          this.loadDeviceProfiles();
        },
      });
    this.dataProfile.controls["joinType"].valueChanges
      .pipe(delay(100))
      .subscribe({
        next: (value) => {
          this.loadDeviceProfiles();
        },
      });

    return [this.deviceData, this.dataProfile];
  }

  get DevEUIControl() {
    return this.deviceData.controls["devEUI"];
  }
  get AppKeyControl() {
    return this.deviceData.controls["appKey"];
  }
  get DevAddrControl() {
    return this.deviceData.controls["devAddr"];
  }
  get NwkKeyControl() {
    return this.deviceData.controls["nwkKey"];
  }
  get joinType() {
    return this.dataProfile.controls["joinType"].value;
  }
  ngDoCheck(): void {
    this.changeDetectorRef.detectChanges();
  }

  filterByJoinType() {
    const join = this.dataProfile.controls["joinType"].value;
    if (join) {
    }
  }

  implementOnInit(): void {
    this.loadDeviceDataProfiles();
    this.getBaseDialogHeader().setTitle("Neues LoRa IoT Gerät");
    this.getBaseDialogHeader().setDescription(
      "Legen Sie ein neues Gerät an, um weitere Messdaten zu erhalten. Nach Anlage stehen Ihnen diese umgehend in den Dashboards zur Verfügung."
    );
  }

  loadDeviceProfiles() {
    if (
      this.dataProfile.value.macVersion &&
      this.dataProfile.value.deviceClass
    ) {
      const joinType = this.dataProfile.value.joinType ? "OTAA" : "ABP";
      const name = `${this.dataProfile.value.deviceClass}-${joinType}-${this.dataProfile.value.macVersion}`;
      this.loadProfile = true;
      this.bService.getDeviceProfile(name).subscribe({
        next: (res) => {
          this.dataProfile.controls["deviceProfileID"].setValue(
            res.deviceProfile.id
          );
          this.loadProfile = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    }
  }
  onCustomProfiles(val: MatCheckboxChange) {
    this.customProfiles = val.checked;
    if (this.customProfiles) {
      this.dataProfile.controls["deviceProfileID"].setValue("");
      this.dataProfile.controls["deviceProfileID"].setValidators([
        Validators.required,
      ]);
      this.dataProfile.controls["serviceProfileID"].setValidators([
        Validators.required,
      ]);
      this.dataProfile.get("macVersion")?.clearValidators();
      this.dataProfile.get("joinType")?.clearValidators();
      this.dataProfile.get("deviceClass")?.clearValidators();
    } else {
      this.dataProfile.get("deviceProfileID")?.clearValidators();
      this.dataProfile.get("serviceProfileID")?.clearValidators();
      this.dataProfile.get("macVersion")?.setValidators([Validators.required]);
      this.dataProfile.get("joinType")?.setValidators([Validators.required]);
      this.dataProfile.get("deviceClass")?.setValidators([Validators.required]);
    }
    this.dataProfile.updateValueAndValidity();
  }
  loadDeviceTags() {
    this.tService.getTagsByType("device-data-profiles").subscribe({
      next: (result) => {
        if (result) {
          this.tagsData = result;
        }
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }

  loadDeviceDataProfiles() {
    this.devicedataprofileService
      .getDeviceDataProfile(EDataType.lora, "*")
      .subscribe({
        next: (data) => {
          if (!data) {
            data = [] as any;
          }
          data.push({
            _id: "new",
            deviceType: "new",
            manufacturer: "new",
            name: "+ Neues Profile",
            tenantID: "new",
          });
          this.deviceDataProfiles = data;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    if (this.data.profile) {
      this.dataProfile.patchValue(
        {
          deviceDataProfileID: this.data.profile._id,
        },
        { emitEvent: false }
      );
    }
  }

  onChangeDefLoc(val: boolean) {
    this.defLoc = val;
  }
}
