import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MarketplaceTile } from "../../model/marketplacetile";
@Component({
  selector: "app-marketplace",
  templateUrl: "./marketplace.component.html",
  styleUrls: ["./marketplace.component.scss"],
})
export class MarketplaceComponent {
  //TODO: UIConfigs nehmen, wenn api sauber läuft
  private dataData: MarketplaceTile[] = [
    {
      title: "Farming",
      text: "assets/img/products/data_farming.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Logistik",
      text: "assets/img/products/data_logistic.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Smart Office",
      text: "assets/img/products/data_office.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Verkehrsdaten",
      text: "assets/img/products/data_traffic.jpg",
      cols: 1,
      rows: 2,
    },
  ];
  private dataServices: MarketplaceTile[] = [
    {
      title: "3D Diagramme",
      text: "assets/img/products/service_3d.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Real Estate Übersichten",
      text: "assets/img/products/service_estate.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Lichtsteuerung",
      text: "assets/img/products/service_lights.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Verkehrsensoren",
      text: "assets/img/products/service_traffic.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Umweltdaten",
      text: "assets/img/products/data_environment.jpg",
      cols: 1,
      rows: 2,
    },
    {
      title: "Wasserdaten",
      text: "assets/img/products/data_water.jpg",
      cols: 1,
      rows: 2,
    },
  ];
  data: MarketplaceTile[];

  constructor(private router: Router) {
    if (this.router.url.includes("diagrams")) {
      this.data = this.dataServices;
    } else {
      this.data = this.dataData;
    }
  }

  openDetails() {
    //TODO: Mal richtiges Marketing überlegen - so ist es eine Behilfslösung.
    window.open("https://www.rhinecloud.de/iot/");
  }
}
