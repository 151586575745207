import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { forkJoin } from "rxjs";
import { IChartDefaultConfig } from "../../../../../model/chartsDefaultConfig";
import { UIConfigService } from "../../../../../services/uiconfig-service";

@Component({
  selector: "app-chartconfig-selector",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          [formControlName]="controlName"
          placeholder="Diagramm-Art"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-optgroup *ngFor="let group of diagramData" [label]="group.name">
            <mat-option *ngFor="let item of group.data" [value]="item">
              {{ item.name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  `,
})
export class ChartConfigSelectorComponent implements OnChanges {
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() chartType?: string;
  @Output() selectionChange = new EventEmitter();
  public loading = true;
  public diagramData:
    | {
        name: string;
        data: IChartDefaultConfig[];
      }[]
    | undefined;
  constructor(private uiService: UIConfigService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartType"]) {
      this.loadConfigs();
    }
  }
  loadConfigs() {
    this.loading = true;
    forkJoin(
      [
        this.uiService.getChartsConfigs(this.chartType, false), // default
        this.uiService.getChartsConfigs(this.chartType, true),
      ] // custom
    ).subscribe(([res1, res2]) => {
      this.diagramData = [];
      if (res1) {
        this.diagramData.push({ name: "Default", data: res1 });
      }
      if (res2) {
        this.diagramData.push({ name: "Custom", data: res2 });
      }
      this.loading = false;
    });
  }
  onChanges(evt: any): void {
    this.selectionChange.emit(evt);
  }
}
