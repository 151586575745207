<form [formGroup]="form" fxLayout="column" autocomplete="off" class="w-100" fxFlex>
  <div fxLayout="row">
    <mat-checkbox (change)="publicDevChanged($event.checked)">Öffentliche Geräte</mat-checkbox>
  </div>
  <div fxLayout="row" fxLayoutGap="8px">
    <div>
      <mat-form-field>
        <mat-select (valueChange)="onTimeRangeChanged($event)" placeholder="Intervall" [multiple]="false">
          <mat-option *ngFor="let item of TimeRangeOptions" [value]="item.value">
            {{ item.key }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="flex-p" *ngIf="myTenantID === 'superadmin'">
      <mat-select placeholder="Tenants" formControlName="tenantID">
        <mat-option *ngFor="let tenant of Tenants" [value]="tenant.tenantID">
          {{ tenant.tenantName }}</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container>
      <app-diagramm-selector [group]="form" controlName="chartType" (selectionChange)="onChartTypeChange()">
      </app-diagramm-selector>
    </ng-container>
    <ng-container *ngIf="form.get('chartType')?.valid">
      <app-chartconfig-selector [group]="form" controlName="chartConfig" [chartType]="form.get('chartType')?.value"
        (selectionChange)="chartConfigSelected($event)"></app-chartconfig-selector>
    </ng-container>
    <ng-container *ngIf="
        (form.get('chartConfig')?.valid && form.get('chartType')?.valid) ||
        form.get('dataType')?.valid
      ">
      <app-datatype-selector [group]="form" controlName="dataType" (selectionChange)="ondataTypeChange($event)">
      </app-datatype-selector>
    </ng-container>

    <ng-container *ngIf="form.get('dataType')?.valid&&form.get('dataType')?.value<=2">
      <app-profile-select [group]="form" controlName="dataProfile" [dataType]="currentDataType"
        [tenantID]="selectedTenantID"></app-profile-select>
    </ng-container>
    <!-- <mat-form-field class="flex-p" *ngIf="dataPofiles">
      <mat-select formControlName="dataProfile" placeholder="Data Profile">
        <mat-option *ngFor="let profile of dataPofiles" [value]="profile._id">{{
          profile.name
        }}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <ng-container *ngIf="form.get('dataProfile')?.valid||form.get('dataType')?.value>2">
      <app-device-select [group]="form" controlName="devices" bindValue="devEUI" [dataType]="currentDataType"
        [tenantID]="selectedTenantID" [dataProfileID]="form.get('dataProfile')?.value" style="max-width: 400px"
        (selectionChange)="onDevicesChanged($event)">
      </app-device-select>
    </ng-container>
    <!-- <mat-form-field class="flex-p" *ngIf="DeviceList">
      <mat-select formControlName="devices" placeholder="Devices" multiple>
        <mat-option [value]="'allDevices'"> Alle auswählen </mat-option>
        <mat-option [value]="'noDevices'"> Keine auswählen </mat-option>
        <mat-option *ngFor="let dev of DeviceList" [value]="dev.devEUI">{{
          dev.deviceName
        }}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field *ngIf="achsData && showXAchse()">
      <mat-select formControlName="xAchse" [placeholder]="
          ChartData.value?.chartType === 'map' ? 'Latitude(xValue)' : 'xValue'
        ">
        <mat-option *ngFor="let key of achsData" [value]="key">{{
          key
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="achsData">
      <mat-select formControlName="yAchse" [placeholder]="
          ChartData.value?.chartType === 'map' ? 'Longitude(yValue)' : 'yValue'
        ">
        <mat-option *ngFor="let key of achsData" [value]="key">{{
          key
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="achsData && showZAchse()">
      <mat-select formControlName="zAchse" placeholder="zValue" [multiple]="true">
        <mat-option *ngFor="let key of achsData" [value]="key">{{
          key
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field class="flex-p" *ngIf="achsData && ChartData.value?.achsen == 1">
      <mat-select formControlName="Achse" placeholder="Value">
        <mat-option *ngFor="let key of achsData" [value]="key">{{ key }}</mat-option>
      </mat-select>
    </mat-form-field>  -->
  </div>

  <div fxLayout="row" class="chart-container-holder" *ngIf="_chartData">
    <div fxLayout="column" fxLayoutGap="5px" fxFlex="0 1 260px" fxFlexAlign="center">
      <div fxFlexAlign="center">
        <h3>"_value_" Variable</h3>
      </div>
      <div>
        <textarea cols="30" rows="15" style="width: 100%; height: 100%; resize: none"
          [textContent]="standardOut"></textarea>
      </div>
    </div>
    <gridster #MainDashboard [options]="options" class="gridster">
      <gridster-item class="gridster-item" [item]="widgetData" *ngIf="widgetData" [class]="
          form.value.chartData?.chartType === 'widget' ? 'widget' : 'chart-item'
        ">
        <app-dynamic-charts [item]="itemData" *ngIf="itemData">
        </app-dynamic-charts>
      </gridster-item>
    </gridster>
  </div>
  <h3 fxLayoutAlign="center center">Diagramm Optionen</h3>
  <div fxLayout="row" fxFlex>
    <ngx-monaco-editor #monacoEditor class="dvv-code-editor w-100" [options]="editorOptions"
      (onInit)="onMonacoInit($event)" formControlName="chartOptions"></ngx-monaco-editor>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" *ngIf="uiService.getUIPermissions(EPermissions.CHARTMANAGEMENT)">
    <button mat-raised-button (click)="saveChartConfig()">
      Diagrammkonfiguration erstellen
    </button>
    <button mat-raised-button (click)="saveChartConfig(true)">
      Diagrammkonfiguration aktualisieren
    </button>
    <button mat-raised-button (click)="deleteChartConfig()">
      Diagrammkonfiguration löschen
    </button>
    <button mat-raised-button (click)="generateConfig()" color="primary">
      Diagrammkonfiguration generieren
    </button>
  </div>
</form>
