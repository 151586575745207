<div fxLayout="column" fxFill [formGroup]="form" class="flex-p">
  <div fxLayout="row" fxLayoutAlign="start start" class="flex-p" fxLayoutGap="8px">
    <button mat-raised-button color="warn" (click)="abbrechen()">
      Abbrechen
    </button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="create()">
      {{buttonLabel}}
    </button>
  </div>
  <mat-divider> </mat-divider>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <input matInput placeholder="Bezeichnung" formControlName="name" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <mat-select placeholder="Typ der Weiterleitung" formControlName="forwardType">
        <mat-option *ngFor="let item of forwardTypes" [value]="item.value">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="ForwardType.value>0">
    <app-datatype-selector class="w-min-300p" [group]="form" controlName="dataType"
      (selectionChange)="onDeviceTypeChange($event)">
    </app-datatype-selector>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-profile-select class="w-min-300p"
      *ngIf="form.get('dataType')?.valid&&form.get('dataType')?.value<3&&ForwardType.value>1" [group]="form"
      [controlName]="'deviceDataProfile'" [dataType]="form.value.dataType" (selectionChange)="onProfileChange($event)">
    </app-profile-select>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-device-select class="w-min-300p"
      *ngIf="form.get('dataType')?.value>=3&&ForwardType.value>2||form.get('deviceDataProfile')?.valid&&ForwardType.value>2"
      controlName="devices" [group]="form" [multiple]="true" [dataType]="form.value.dataType"
      [dataProfileID]="form.value.deviceDataProfile" [devices]="_devices_">
    </app-device-select>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" class="w-max-300p" fxFlexAlign="start">
      <mat-checkbox class="w-min-300p" formControlName="apiForward"
        *ngIf="uiService.getUIPermissions(EPermissions.API)">REST API Weiterleitung
      </mat-checkbox>
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="form.get('apiForward')?.value">
        <app-restapi-select class="w-min-300p" [dataType]="2" controlName="restAPI" [group]="form"
          placeholder="Rest API Profile" (selectionChange)="null" (profileLoaded)="null">
        </app-restapi-select>
      </div>
      <mat-checkbox class="w-min-300p" formControlName="mqttForward"
        *ngIf="uiService.getUIPermissions(EPermissions.MQTT)">MQTT Weiterleitung
      </mat-checkbox>
      <div *ngIf="form.get('mqttForward')?.value">
        <p>Subscribe Topic</p>
        <p>{{getMqttTopic()}}</p>
        <br>
        <p>Bei Einzelnen Geräten kann man auch den devEUI am Ende des Topics hinzufügen;</p>
      </div>
      <mat-checkbox class="w-min-300p" style="margin-top: 10px;" formControlName="saveData">Kopie der Daten speichern
      </mat-checkbox>
      <mat-checkbox class="w-min-300p" formControlName="dataLog">Daten im Log anzeigen
      </mat-checkbox>
    </div>
  </div>
</div>
