import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-set-silent",
  template: `
    <mat-sidenav-container>
      <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
        <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
          <mat-card class="p-0 mat-elevation-z24 box" *ngIf="!loading">
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="box-header"
              [ngClass]="{ 'bg-primary': !error, 'bg-warn': error }"
            >
              <button
                mat-fab
                [color]="error ? 'primary' : 'warn'"
                class="mat-elevation-z12"
              >
                <mat-icon>{{
                  error ? "error" : silent === "1" ? "alarm_off" : "alarm_on"
                }}</mat-icon>
              </button>
              <h1 class="error" *ngIf="!error">
                Alarm wurde {{ silent === "1" ? "stumm" : "aktiv" }} geschaltet
              </h1>
              <h1 class="error" *ngIf="error">
                Es ist ein Fehler aufgetretten.
              </h1>
            </div>
            <mat-card-content
              fxLayout="column"
              fxLayoutAlign="end center"
              class="box-content"
            >
              <mat-card
                fxLayout="column"
                fxLayoutAlign="center center"
                class="mat-elevation-z12 box-content-inner"
              >
                <p class="box-text" *ngIf="!error">
                  Ein Alarm wird für die Dauer stumm geschaltet, bis der Sensor
                  keine weiteren Alarme mehr &uuml;bermittelt.
                </p>
                <p class="box-text" *ngIf="error">
                  Sie haben noch die Moglichkeit sich auf der Seite anzumelden
                  und, unter Abonnements/Alarmierung, das Alarm stumm zu
                  schalten.
                </p>
              </mat-card>
              <button
                *ngIf="error"
                mat-raised-button
                color="primary"
                class="mat-elevation-z12 box-button"
                type="button"
                (click)="goHome()"
              >
                Zur Anmeldung
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-sidenav-container>
  `,
  styles: [],
})
export class SetSilentComponent implements OnInit {
  silent?: string;
  alertID?: string;
  loading = true;
  error = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((par) => {
      this.alertID = par["alertID"];
      this.silent = par["silent"];
      if (this.alertID && this.silent) {
        this.http
          .get(
            `${environment.api}/subscriptions/alarms/${this.alertID}?silent=${this.silent}`
          )
          .subscribe({
            next: (res) => {
              this.loading = false;
            },
            error: (err) => {
              this.loading = false;
              this.error = true;
            },
          });
      }
    });
  }
  goHome() {
    this.router.navigate(["/login"]);
  }
}
