import { AfterViewInit, Component, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { delay } from "rxjs/operators";
import { AppSettings } from "../../../app.settings";
import { IDeviceDataProfileList } from "../../../model/devicedataprofile";
import { IDevice } from "../../../model/devices";
import { DeviceDataProfileService } from "../../../services/DeviceDataProfileService";
import { BaseDialog } from "../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";

@Component({
  templateUrl: "./create-nbiotdev-dialog.component.html",
  styleUrls: ["./create-nbiotdev-dialog.component.scss"],
})
export class CreateNbiotdevDialogComponent
  extends BaseDialog
  implements AfterViewInit
{
  public deviceDataProfiles?: IDeviceDataProfileList[];
  public loading = false;
  constructor(
    private dialogRef: MatDialogRef<CreateNbiotdevDialogComponent>,
    private fb: UntypedFormBuilder,
    private appSettings: AppSettings,
    private devicedataprofileService: DeviceDataProfileService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private device: IDevice
  ) {
    super(dialogRef);
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      deviceName: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([])],
      devEUI: [null, Validators.compose([Validators.required])],
      deviceDataProfileID: [null, Validators.compose([Validators.required])],
      dataType: 1,
    });
  }
  implementOnInit(): void {
    this.loading = true;
    this.loadProfile();
    let title = "Neues NB IoT Gerät";
    let butLabel = "Hinzufügen";
    let desc =
      "Legen Sie ein neues Gerät an, um weitere Messdaten zu erhalten. Nach Anlage stehen Ihnen diese umgehend in den Dashboards zur Verfügung.";
    if (this.device) {
      this.form.patchValue({
        deviceName: this.device.deviceName,
        description: this.device.description,
        devEUI: this.device.devEUI,
        deviceDataProfileID: this.device.deviceDataProfileID,
      });
      if (this.device.devEUI) {
        title = "Gerät Verändern";
        butLabel = "Ändern";
        desc = "";
      }
    }
    this.getBaseDialogHeader().setTitle(title);
    this.getBaseDialogFooter().getFooterButtons()[0].setLabel(butLabel);
    this.getBaseDialogHeader().setDescription(desc);
    this.form
      .get("deviceDataProfileID")
      ?.valueChanges.pipe(delay(100))
      .subscribe({
        next: (value) => {
          if (value === "new") {
            this.createNewDeviceDataProfile();
          }
        },
      });
  }
  ngAfterViewInit(): void {
    return;
  }
  loadProfile() {
    // this.devicedataprofileService
    //   .getDeviceDataProfile(EDataType.nbiot, "*")
    //   .subscribe({
    //     next: (data) => {
    //       if (!data) {
    //         data = [] as any;
    //       }
    //       data.push({
    //         _id: "new",
    //         deviceType: "new",
    //         manufacturer: "new",
    //         name: "+ Neues Profile",
    //         tenantID: "new",
    //       });
    //       this.deviceDataProfiles = data;
    //       this.loading = false;
    //     },
    //     error: (err) =>
    //       this.appSettings.getSwalError(err.error.message || err.message),
    //   });
  }
  createNewDeviceDataProfile() {
    this.router.navigate(["lora/devicedataprofiles/create-devicedata-profile"]);
    this.onClose();
  }
}
