<div fxLayout="column" fxFill fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <button mat-raised-button (click)="goBack()" color="accent">Zurück</button>
    <button mat-raised-button *ngIf="tenantID!=='public'" (click)="createDeviceQueue()" color="primary">An Gerät
      Senden</button>
    <mat-form-field>
      <mat-select placeholder="Mehr Sensorwerte laden" (selectionChange)="timeRangeChanged($event.value)" [value]="0">
        <mat-option *ngFor="let item of TimeRangeOptions" [value]="item.value">
          {{ item.key }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
    <ng-container *ngIf="showLoraData">
      <mat-card style="width: 200px; height: 100px;">
        <div fxFill fxLayout="column">
          <div fxFlex fxLayout="row">
            <div fxFlex="20" fxLayoutAlign="center center" class="bold-text">
              RSSI
            </div>
            <div fxFlex="80" fxLayoutAlign="center center" #rssiDIV></div>
          </div>
        </div>
      </mat-card>
    </ng-container>

    <mat-card style="width: 200px; height: 100px;" *ngIf="showLoraData">
      <div fxFill fxLayout="column">
        <div fxFlex fxLayout="row">
          <div fxFlex="20" fxLayoutAlign="center center" class="bold-text">
            SNR
          </div>
          <div fxFlex="80" fxLayoutAlign="center center" #loraSnrDIV></div>
        </div>
      </div>
    </mat-card>
    <mat-card style="width: 200px; height: 100px;" *ngIf="showLoraData">
      <div fxFill fxLayout="column" *ngIf="deviceData">
        <div fxFlex fxLayout="row">
          <div fxFlex="30" fxLayoutAlign="center center" class="bold-text">
            Frequenz
          </div>
          <div fxFlex="70" fxLayoutAlign="center center">
            {{ deviceData[deviceData.length - 1].txInfo.frequency }}
          </div>
        </div>
        <div fxFlex fxLayout="row">
          <div fxFlex="30" fxLayoutAlign="center center" class="bold-text">
            DataRate
          </div>
          <div fxFlex="70" fxLayoutAlign="center center">
            {{ deviceData[deviceData.length - 1].txInfo.dr }}
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card style="width: 200px; height: 100px;" *ngIf="showLoraData">
      <div fxFill fxLayout="column" *ngIf="deviceData">
        <div fxFlex fxLayout="row">
          <div fxFlex="30" fxLayoutAlign="center center" class="bold-text">
            ADR
          </div>
          <div fxFlex="70" fxLayoutAlign="center center">
            {{ deviceData[deviceData.length - 1].adr }}
          </div>
        </div>
        <div fxFlex fxLayout="row">
          <div fxFlex="30" fxLayoutAlign="center center" class="bold-text">
            fCnt
          </div>
          <div fxFlex="70" fxLayoutAlign="center center">
            {{ deviceData[deviceData.length - 1].fCnt }}
          </div>
        </div>
        <div fxFlex fxLayout="row">
          <div fxFlex="30" fxLayoutAlign="center center" class="bold-text">
            fPort
          </div>
          <div fxFlex="70" fxLayoutAlign="center center">
            {{ deviceData[deviceData.length - 1].fPort }}
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card style="width: 200px; height: 100px;">
      <div fxFill fxLayout="column" *ngIf="deviceData">
        <div fxFlex="20" fxLayoutAlign="center center" class="bold-text">
          Zuletzt gesehen
        </div>
        <div fxFlex="80" fxLayoutAlign="center center">
          {{
          deviceData[deviceData.length - 1].createdAt
          | localizedDate: "dd.MM.yyyy HH:mm:ss"
          }}
        </div>
      </div>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="bold-text">
    Device Daten
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="bold-text">
    <mat-progress-bar mode="query" color="accend"></mat-progress-bar>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" *ngIf="deviceData">
    <ngx-json-viewer [json]="deviceData" [expanded]="false" style="padding: 10px;"></ngx-json-viewer>
  </div>
</div>
