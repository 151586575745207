import { AfterViewInit, Component, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogType } from "src/app/model/enum/dialogType";
import { AppSettings } from "../../../../app.settings";
import { ISubscription } from "../../../../model/subscriptions";
import { UserManagementService } from "../../../../services/user-management.service";
import { BaseDialog } from "../../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";
import { DevicesDialogComponent } from "../../../groups/taged-devices/devices-dialog/devices-dialog.component";

@Component({
  selector: "app-create-data-input",
  templateUrl: "./create-data-input.component.html",
  styleUrls: ["./create-data-input.component.scss"],
})
export class CreateDataInputComponent
  extends BaseDialog
  implements AfterViewInit
{
  public selectedDevices: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<CreateDataInputComponent>,
    @Inject(MAT_DIALOG_DATA)
    public DATA: { elem: ISubscription },
    public fb: UntypedFormBuilder,
    private uService: UserManagementService,
    private dialog: MatDialog,
    private appSettings: AppSettings
  ) {
    super(dialogRef, DialogType.create);
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      description: [""],
      resourceType: "DEVICE_DATA_INPUT",
      resourceFilter: ["", Validators.required],
      tenantID: this.appSettings.currentTenantID(),
    });
  }
  implementOnInit(): void {
    this.getBaseDialogHeader().setTitle("API Application hinzufügen");
    this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Hinzufügen");
    if (this.DATA.elem) {
      this.getBaseDialogHeader().setTitle("API Application anpassen");
      this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Anpassen");
      const { name, description, resourceFilter } = this.DATA.elem;
      this.form.patchValue({ name, description, resourceFilter });
      if (resourceFilter && resourceFilter.devEUI) {
        this.selectedDevices = resourceFilter.devEUI as string[];
      }
    }

    return;
  }
  ngAfterViewInit(): void {
    return;
  }
  addDevices() {
    this.dialog
      .open(DevicesDialogComponent, {
        minWidth: 500,
        data: { devEUIs: this.selectedDevices, apiApp: true },
      })
      .afterClosed()
      .subscribe({
        next: (result: { selected: string[] }) => {
          if (result) {
            this.selectedDevices = result.selected;
            const filter = Object.assign({}, this.form.value.resourceFilter, {
              devEUI: this.selectedDevices,
            });
            this.form.patchValue({
              resourceFilter: filter,
            });
          } else if (!this.DATA.elem) {
            this.selectedDevices = [];
            this.form.patchValue({ resourceFilter: undefined });
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
}
