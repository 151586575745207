import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { IDeviceDataProfileList } from "../../../model/devicedataprofile";
import { EDataType } from "../../../model/enum/dataType";
import { DeviceDataProfileService } from "../../../services/DeviceDataProfileService";
import { LoraDeviceService } from "../../../services/lora-devices";
import { NbiotDevicesService } from "../../../services/nbiot-devices.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { changeRouteData, debounce } from "../../../shared/abstract/utils";
import { blockTransition } from "../../../theme/utils/app-animation";
import { CreateDeviceDialogComponent } from "../create-device-dialog/create-device-dialog.component";

@Component({
  selector: "app-devicedataprofile",
  templateUrl: "./devicedataprofile.component.html",
  styleUrls: ["./devicedataprofile.component.scss"],
  animations: [blockTransition],
})
export class DeviceDataProfileComponent implements OnInit {
  public loading = false;
  public settings: Settings;
  @ViewChild(MatSort) sort!: MatSort;
  public EPermissions = EPermissions;
  public data?: MatTableDataSource<IDeviceDataProfileList>;
  public myTenant?: string;
  public displayedColumns = [
    "_id",
    "name",
    "manufacturer",
    "deviceType",
    "tenantID",
    "tags",
    "action",
  ];
  public tenantID?: string;
  // Pagination
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public dataType = 0;
  public isPublic = true;
  private search?: string;
  private searchHandler: any;
  // Pagination Ende

  constructor(
    public appSettings: AppSettings,
    public uiService: UIConfigService,
    private dataService: DeviceDataProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private dService: LoraDeviceService,
    private ndService: NbiotDevicesService
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {
      if (params && params.tenantID) {
        this.tenantID = params.tenantID;
      } else {
        this.tenantID = this.appSettings.currentTenantID();
      }
    });
    this.route.data.subscribe({
      next: (data) => {
        console.log({ data });
        if (data.dataType) {
          this.dataType = data.dataType;
        }
      },
    });
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadDeviceDataProfiles();
    }, 500);
  }

  ngOnInit() {
    this.myTenant = this.appSettings.currentTenantID();
    this.loadDeviceDataProfiles();
  }
  onPublicChange(val: boolean) {
    this.isPublic = val;
    this.data = new MatTableDataSource<IDeviceDataProfileList>([]);
    this.loadDeviceDataProfiles();
  }
  loadDeviceDataProfiles() {
    this.loading = true;
    let tenant = this.tenantID;
    if (this.isPublic) {
      tenant = "public";
    }
    this.dataService
      .getDeviceDataProfile(this.dataType, tenant, this.pageEvent, this.search)
      .subscribe({
        next: (data) => {
          if (data && data.data) {
            this.data = new MatTableDataSource(data.data);
            this.pageEvent.length = data.total;
            this.data.filterPredicate = (
              item: IDeviceDataProfileList,
              filter: string
            ) => {
              if (!filter || !item) {
                return true;
              } else if (
                item.tenantID?.toLowerCase().includes(filter) ||
                item.deviceType.toLowerCase().includes(filter) ||
                item.manufacturer.toLowerCase().includes(filter)
              ) {
                return true;
              } else {
                return false;
              }
            };
            setTimeout(() => {
              if (this.data) this.data.sort = this.sort;
            });
            this.loading = false;
          } else {
            this.data = new MatTableDataSource<IDeviceDataProfileList>([]);
          }
          this.loading = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDeviceDataProfiles();
  }

  createDataProfile() {
    changeRouteData(this.router, "Erstellen", 0, "create-devicedata-profile");
    this.router.navigate(
      ["lora/devicedataprofiles/create-devicedata-profile"],
      { queryParams: { tenantID: this.tenantID, dataType: this.dataType } }
    );
  }

  changeProfile(row: IDeviceDataProfileList) {
    changeRouteData(
      this.router,
      row.name + "/Anpassen",
      0,
      "create-devicedata-profile"
    );
    if (this.router.url.includes("/superadmin/tenants")) {
      if (this.tenantID) {
        this.router.navigate(
          [
            "superadmin",
            "tenants",
            { outlets: { _management: ["create-devicedata-profile"] } },
          ],
          {
            queryParams: { tenantID: this.tenantID, _id: row._id },
          }
        );
      }
    } else {
      if (row.tenantID !== this.myTenant && this.myTenant !== "superadmin") {
        return;
      }
      this.router.navigate(
        ["lora", "devicedataprofiles", "create-devicedata-profile"],
        {
          queryParams: {
            _id: row._id,
            tenantID: row.tenantID,
            dataType: this.dataType,
          },
        }
      );
    }
  }

  deleteDataProfile(profileID: string, name: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Dataprofil löschen!",
        'Wollen Sie wirklich das Profile "' + name + '" löschen?'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.dataService
            .deleteDeviceDataProfile(profileID, this.dataType)
            .subscribe({
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
              complete: () => {
                this.loadDeviceDataProfiles();
              },
            });
        }
      });
  }
  // Suche
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  showDataTypes(value: string[]) {
    if (value) {
      return value.join(", ");
    } else {
      return "";
    }
  }
  addDevice(profile: IDeviceDataProfileList) {
    if (this.dataType == EDataType.lora) {
      const dialogRef = this.dialog.open(CreateDeviceDialogComponent, {
        data: { profile: profile },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((values) => {
        if (values) {
          this.dService.createDevice(values, this.tenantID).subscribe({
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
            next: () =>
              this.appSettings.getSwalSuccess("Gerät wurde hinzugefügt"),
          });
        }
      });
    }
    // else if (this.dataType == EDataType.nbiot) {
    //   const dialogRef = this.dialog.open(CreateNbiotdevDialogComponent, {
    //     data: { deviceDataProfileID: profile._id },
    //     disableClose: true,
    //   });
    //   dialogRef.afterClosed().subscribe((values) => {
    //     if (values) {
    //       const deviceDataProfileID = values.deviceDataProfileID;
    //       values.deviceKeys = { devEUI: values.devEUI };
    //       delete values.deviceDataProfileID;
    //       delete values.devEUI;
    //       this.ndService
    //         .createNbiotDevice(
    //           {
    //             device: values,
    //             deviceDataProfileID,
    //           },
    //           true
    //         )
    //         .subscribe({
    //           next: () =>
    //             this.appSettings.getSwalSuccess("Gerät wurde hinzugefügt"),
    //           error: (err) => {
    //             this.appSettings.getSwalError(
    //               err.error?.message || err.message
    //             );
    //           },
    //         });
    //     }
    //   });
    // }
  }
}
