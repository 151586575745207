export enum ChartTheme {
  dvvlight = "dvv-light",
  dvvdark = "dvv-dark",
  "indigolight" = "indigo-light",
  "teallight" = "teal-light",
  "redlight" = "red-light",
  "bluedark" = "blue-dark",
  "greendark" = "green-dark",
  "pinkdark" = "pink-dark",
}
