<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
  <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
  <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->
  <mat-toolbar color="primary">
    <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
      <!--<img [src]="userImage" alt="user-image" width="80" class="rounded mat-elevation-z6">-->
      <p *ngIf="Username">
        {{ Username }} <br />
        <small *ngIf="Permissions"> {{ Permissions }}</small>
      </p>
    </div>
  </mat-toolbar>
  <!-- <a mat-menu-item routerLink="profile">
    <mat-icon>person</mat-icon>
    <span>Profile</span>
  </a> -->
  <a mat-menu-item routerLink="/user/settings">
    <mat-icon>settings</mat-icon>
    <span>Einstellungen</span>
  </a>
  <!--        <a mat-menu-item routerLink="/">
            <mat-icon>lock</mat-icon>
            <span>Lock screen</span>
        </a>-->
  <!-- <a mat-menu-item routerLink="help">
            <mat-icon>help</mat-icon>
            <span>Hilfe</span>
        </a> -->
  <div class="divider"></div>
  <a mat-menu-item routerLink="logout">
    <mat-icon>power_settings_new</mat-icon>
    <span>Abmelden</span>
  </a>
  <!--</span>-->
</mat-menu>
