<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="openDeviceDialog()"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES, undefined, publicdata)">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="loadDevices()" matTooltip="Aktualisieren">
      <mat-icon>autorenew</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" />
    </mat-form-field>
  </div>
  <div fxLayout="row" class="flex-p">
    <div *ngIf="data&&data?.data && data.data.length > 0" @blockTransition fxFlex="100%">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" class="block">
      </mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="NBIOT Geräte">
        <ng-container matColumnDef="devEUI">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            DeviceEUI
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.devEUI }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deviceName">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Beschreibung
          </th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <ng-container matColumnDef="lastSeenAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Zuletzt gesehen
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastSeenAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell matRipple *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="devMenu" *ngIf="
                uiService.getUIPermissions(
                  EPermissions.DEVICES,
                  undefined,
                  publicdata
                )
              " (click)="$event.stopPropagation()">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="editDevice(element)">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Gerät anpassen</span>
              </button>
              <button mat-menu-item (click)="deleteDevice(element.devEUI, element.deviceName)">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Gerät löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"
          (click)="getDeviceInfo(row)" style="cursor: pointer;"></tr>
      </table>
    </div>
    <div *ngIf="!data||!data?.data || data.data.length <= 0" fxFlex>
      <div fxLayoutAlign="center center">
        Sie haben noch keine Geräte angelegt.
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
