<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="addSubscription()"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field>
      <mat-select placeholder="Benutzer auswählen" #userName>
        <mat-option *ngFor="let user of users" [value]="user.username">
          {{ user.username }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayout="row" class="w-100" fxLayoutGap="8px" fxLayoutAlign="center center"
      *ngIf="data&&data.data.length > 0">
      <h4>API Freigaben für den ausgewählten Benutzer</h4>
    </div>
  </div>
  <div fxLayout="row" class="flex-p">
    <div *ngIf="data&&data?.data && data.data.length > 0" @blockTransition fxFlex="100%">
      <div class="block">
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
          [length]="pageEvent.length" (page)="onPagination($event)">
        </mat-paginator>
        <table mat-table [dataSource]="data" multiTemplateDataRows class="w-100" aria-describedby="API Benutzer">
          <ng-container matColumnDef="devices">
            <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
              Geräte
            </th>
            <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
              {{ element.devices.length }}
            </td>
          </ng-container>
          <ng-container matColumnDef="username">
            <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
              Api Benutzer
            </th>
            <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
              {{ element.user.username }}
            </td>
          </ng-container>
          <!-- Expanded Content Column - The devices row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDevices">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-devices" [@expandTableRow]="
                  element === expandedElement ? 'expanded' : 'collapsed'
                ">
                <table mat-table [dataSource]="element.devices" class="w-100 block">
                  <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                  <!-- deviceName -->
                  <ng-container matColumnDef="deviceName">
                    <th mat-header-cell *matHeaderCellDef>Gerätename</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.deviceName }}
                    </td>
                  </ng-container>
                  <!-- description -->
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.description }}
                    </td>
                  </ng-container>
                  <!-- devEUI -->
                  <ng-container matColumnDef="dataType">
                    <th mat-header-cell *matHeaderCellDef>Geräteart</th>
                    <td mat-cell *matCellDef="let element">
                      {{ EDataType[element.dataType] || EDataType[0] }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="devEUI">
                    <th mat-header-cell *matHeaderCellDef>devEUI</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.devEUI }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="[
                      'deviceName',
                      'description',
                      'devEUI',
                      'dataType'
                    ]"></tr>
                  <tr mat-row *matRowDef="
                      let row;
                      columns: [
                        'deviceName',
                        'description',
                        'devEUI',
                        'dataType'
                      ]
                    "></tr>
                </table>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
              Name
            </th>
            <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
              Beschreibung
            </th>
            <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
              Erstellt am
            </th>
            <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
              {{ element.createdAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell matRipple *matHeaderCellDef scope="col"></th>
            <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
              <button mat-icon-button [matMenuTriggerFor]="devMenu" (click)="$event.stopPropagation()">
                <mat-icon class="color-primary">more_vert</mat-icon>
              </button>
              <mat-menu #devMenu="matMenu">
                <button mat-menu-item (click)="addDevices(element)">
                  <mat-icon class="color-ascend">edit</mat-icon>
                  <span>Freigabe anpassen</span>
                </button><button mat-menu-item (click)="deleteSubscription(element._id)">
                  <mat-icon class="color-ascend">delete</mat-icon>
                  <span>Freigabe löschen</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns" class="table-element-row"
            style="cursor: pointer" [class.expanded-row]="expandedElement === element" (click)="
              expandedElement = expandedElement === element ? null : element
            "></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDevices']" class="devices-row"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
