import { Component } from "@angular/core";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
  animations: [blockTransition],
})
export class InvoicesComponent {
  public loading: boolean = false;
  public data: any[] = [];

  constructor() {}
}
