import { Directive, HostBinding, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import * as _ from "underscore";
import { IBaseDialog } from "../../../abstract/interface.basedialog";
import { BaseDialogHeaderComponent } from "./base-dialog-header/base-dialog-header.component";

/**
 * Abstract base dialog which all dialogs should extend/implement
 */
@Directive()
export abstract class BaseStepDialog implements OnInit, IBaseDialog {
  @ViewChild("dialogHeader") baseDialogHeader: BaseDialogHeaderComponent;
  public forms: UntypedFormGroup[] = [];

  @HostBinding("class") class = "base-step-dialog-wrapper";
  constructor(private dialogReference: MatDialogRef<BaseStepDialog>) {
    this.baseDialogHeader = new BaseDialogHeaderComponent();
  }

  /**
   * Hook for implementations in ngOnInit() at the end
   */
  abstract implementOnInit(): void;

  /**
   * Builds the dialog form which will can validated and persisted
   *
   * @return The dialog form or else undefined
   */
  abstract buildForms(...FormGroup: undefined[]): UntypedFormGroup[];

  ngOnInit(): void {
    this.setForms(this.buildForms());

    setTimeout(() => {
      this.implementOnInit();

      document.addEventListener("keyup", (event) => {
        this.onKeyDown(event, this.forms);
      });
    });
  }

  /**
   * Adds key event listener which will either submit or close the dialog
   *
   * @param event - Button that has been pressedπ
   */
  onKeyDown(event: KeyboardEvent, forms: UntypedFormGroup[]): void {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  /**
   * Checks if any form is invalid
   *
   * @param forms True if any form is invalid, else false
   */
  private anyFormInvalid(forms: UntypedFormGroup[]): boolean {
    return _.any(forms, (form) => {
      return form.invalid;
    });
  }

  /**
   * Closes the dialog without value to save
   */
  onClose(): void {
    this.dialogReference.close();
  }

  /**
   * Submits the corresponding value, that will be saved
   */
  onSubmit(): void {
    if (!this.anyFormInvalid(this.forms)) {
      const valueArr: any[] = _.map(this.forms, _.property("value"));
      const values: any = valueArr.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {});

      this.dialogReference.close(values);
    }
  }

  public getBaseDialogHeader(): BaseDialogHeaderComponent {
    return this.baseDialogHeader;
  }

  public setBaseDialogHeader(
    baseDialogHeader: BaseDialogHeaderComponent
  ): void {
    this.baseDialogHeader = baseDialogHeader;
  }

  public getForms(): UntypedFormGroup[] {
    return this.forms;
  }

  public setForms(forms: UntypedFormGroup[]): void {
    this.forms = forms;
  }
}
