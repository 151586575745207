import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SetSilentComponent } from "./subscriptions/set-silent";

const routes: Routes = [
  {
    path: "subscriptions",
    children: [
      {
        path: "alerting",
        component: SetSilentComponent,
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingPageRoutingModule {}
