<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="15px">
    <button mat-mini-fab color="primary" (click)="createDataProfile()"
      *ngIf="uiService.getUIPermissions(EPermissions.TAGS)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-checkbox [checked]="isPublic" (change)="onPublicChange($event.checked)" fxFlexAlign="center">{{ isPublic ?
      "Öffentliche" : "Eigene" }}</mat-checkbox>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" #search />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter('');search.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <div *ngIf="data" @blockTransition fxFlex="100%">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)"></mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="Geräteprofile">
        <ng-container matColumnDef="_id">
          <th scope="col" mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n>
            ID
          </th>
          <td mat-cell *matCellDef="let element" class="text-muted">
            {{ element._id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="deviceType">
          <th scope="col" mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n>
            Gerätetyp
          </th>
          <td mat-cell *matCellDef="let element">{{ element.deviceType }}</td>
        </ng-container>
        <ng-container matColumnDef="tenantID">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Tenant
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.tenantID === "public" ? "Öffentlich" : "Eigene" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="manufacturer">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Hersteller
          </th>
          <td mat-cell *matCellDef="let element">{{ element.manufacturer }}</td>
        </ng-container>
        <ng-container matColumnDef="tags">
          <th mat-header-cell mat-sort-header matRipple *matHeaderCellDef i18n scope="col">
            Datentyp
          </th>
          <td mat-cell *matCellDef="let element">
            {{ showDataTypes(element.tags) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="userMenu"
              *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item (click)="addDevice(element)">
                <mat-icon class="color-primary">add_to_photos</mat-icon>
                <span>Gerät mit diesem Profile hinzufügen</span>
              </button>
              <button mat-menu-item (click)="changeProfile(element)"
                *ngIf="myTenant === 'superadmin' || myTenant === element.tenantID">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Profil bearbeiten</span>
              </button>
              <button mat-menu-item (click)="deleteDataProfile(element._id, element.name)"
                *ngIf="myTenant === 'superadmin' || myTenant === element.tenantID">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Profil löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"></tr>
      </table>
    </div>
    <div *ngIf="!data&&!loading" fxFlex fxLayoutAlign="center center">
      Keine Datenprofile
    </div>
  </div>
</div>

<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
