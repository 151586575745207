<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="dataProfile">
    <form [formGroup]="dataProfile" class="dialog-form">
      <div flex-direction="column">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <span> Eigene Lora Profile</span>
          <mat-checkbox [checked]="customProfiles" style="margin-right: 5px;margin-bottom: 10px;"
            (change)="onCustomProfiles($event)">
          </mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <span> Gerät Join Verfahren</span>
          <mat-checkbox #_joinType [checked]="true" style="margin-right: 5px;" formControlName="joinType"
            labelPosition="before">
            <span *ngIf="_joinType.checked">OTAA</span>
            <span *ngIf="!_joinType.checked">ABP</span>
          </mat-checkbox>
        </div>
        <ng-container *ngIf="!customProfiles">
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <mat-select placeholder="LORAWAN Version" formControlName="macVersion">
                <mat-option *ngFor="let item of macVersions" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <mat-select placeholder="Geräteklasse" formControlName="deviceClass">
                <mat-option *ngFor="let item of deviceClasses" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="customProfiles">
          <app-lora-service-selector [group]="dataProfile" controlName="serviceProfileID"></app-lora-service-selector>
          <app-lora-device-profile-selector [group]="dataProfile" [join]="joinType"
            controlName="deviceProfileID"></app-lora-device-profile-selector>
        </ng-container>

        <div fxLayout="row">
          <mat-progress-bar mode="indeterminate" *ngIf="loadProfile"></mat-progress-bar>
          <app-profile-select *ngIf="dataProfile.get('deviceProfileID')?.valid||customProfiles" fxFill
            [group]="dataProfile" [controlName]="'deviceDataProfileID'" [dataType]="0"></app-profile-select>
          <!-- <mat-form-field *ngIf="dataProfile.get('deviceProfileID').valid||customProfiles" fxFlex>
            <mat-select placeholder="Data Profile" formControlName="deviceDataProfileID">
              <mat-option *ngFor="let item of this.deviceDataProfiles" [value]="item._id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button matStepperNext color="primary" [disabled]="dataProfile.invalid"
            class="dialog-button right">
            Weiter
          </button>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="deviceData">
    <form class="dialog-form" [formGroup]="deviceData">
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="Gerätename" value="" formControlName="deviceName" />
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="Gerätebeschreibung" value="" formControlName="description" />
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="DeviceEUI" value="" minlength="16" maxlength="16" formControlName="devEUI" />
          <mat-error *ngIf="DevEUIControl.hasError('minlength')||DevEUIControl.hasError('maxlength')">Der Key muss 16
            Zeichen lang sein.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="App Key" value="" minlength="32" maxlength="32" formControlName="appKey" />
          <mat-error *ngIf="AppKeyControl.hasError('minlength')||AppKeyControl.hasError('maxlength')">Der Key muss 32
            Zeichen lang sein.</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!_joinType.checked">
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input matInput placeholder="Network Key" value="" minlength="32" maxlength="32" formControlName="nwkKey" />
            <mat-error *ngIf="NwkKeyControl.hasError('minlength')||NwkKeyControl.hasError('maxlength')">Der Key muss 32
              Zeichen lang sein.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input matInput placeholder="Geräteadresse" value="" minlength="8" maxlength="8"
              formControlName="devAddr" />
            <mat-error *ngIf="DevAddrControl.hasError('minlength')||DevAddrControl.hasError('maxlength')">Der Key muss 6
              Zeichen lang sein.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <mat-checkbox (change)="onChangeDefLoc($event.checked)">Position eingeben</mat-checkbox>
      </div>
      <div fxLayout="row" formGroupName="defaultLocation" fxLayoutGap="8px" *ngIf="defLoc">
        <mat-form-field>
          <input matInput placeholder="latitude" formControlName="latitude" />
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="longitude" formControlName="longitude" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <button mat-raised-button color="accent" matStepperPrevious class="dialog-button left">
          Zurück
        </button>
        <button mat-raised-button i18n="btnOK|OK" color="primary" class="dialog-button right"
          [disabled]="deviceData.invalid || dataProfile.invalid" (click)="onSubmit()">
          Anlegen
        </button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
<base-dialog-footer #dialogFooter></base-dialog-footer>
