<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content [formGroup]="form" style="overflow: auto; min-height: 692px; max-width: 792px;width: 792px;">
  <mat-form-field>
    <mat-select placeholder="Api Type" formControlName="apiType">
      <mat-option *ngFor="let item of ['REST', 'SOAP']" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Bezeichnung" formControlName="name" required />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Adresse" formControlName="requestURL" type="url" required
      (paste)="onPasteEvent($event)" />
    <mat-error *ngIf="form.get('requestURL')?.hasError('pattern')">{{
      getErrorMessage("invalidLink")
      }}</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="!SOAP && !expertMode">
    <mat-select placeholder="Request-Method" formControlName="requestMethod" (selectionChange)="onMethodChange($event)">
      <mat-option *ngFor="let item of ERequestMethod | enumToStringArray" [value]="item.name">
        {{ item.index }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="!ApiProfile">
    <mat-select placeholder="Häufigkeit" formControlName="dataInterval">
      <mat-option *ngFor="let item of Turnus" [value]="item.value">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox fxLayout="row" formControlName="expertMode" *ngIf="!SOAP">Erweiterter
    Modus</mat-checkbox>
  <mat-checkbox fxLayout="row" formControlName="rejectUnauthorized" *ngIf="!SOAP">SSL Validierung</mat-checkbox>
  <div fxLayout="column" *ngIf="!expertMode" class="headers">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <h3>Headers</h3>
      <button mat-icon-button (click)="addHeader()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div formArrayName="headers" *ngFor="let item of Headers.controls; let i = index">
      <div [formGroupName]="i">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
          <mat-form-field fxFlex="30%">
            <input matInput placeholder="Key" value="" formControlName="key" />
          </mat-form-field>
          <mat-form-field fxFlex="70%">
            <input matInput placeholder="Value" value="" formControlName="value" />
          </mat-form-field>
          <button mat-icon-button color="warn" (click)="removeHeader(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" *ngIf="!expertMode" class="params">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <h3>Parameter</h3>
      <button mat-icon-button (click)="addParams()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div formArrayName="parameters" *ngFor="let item of Params.controls; let i = index">
      <div [formGroupName]="i">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
          <mat-form-field fxFlex="30%">
            <input matInput placeholder="Key" value="" formControlName="key" />
          </mat-form-field>
          <mat-form-field fxFlex="70%">
            <input matInput placeholder="Value" value="" formControlName="value" />
          </mat-form-field>
          <button mat-icon-button color="warn" (click)="removeParams(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showBody || SOAP || expertMode" fxLayout="column">
    <div fxLayout="row" style="height: 100%;">
      <ngx-monaco-editor class="dvv-code-editor w-100" [options]="bodyOptions" (onInit)="onBodyInit($event)"
        formControlName="requestBody"></ngx-monaco-editor>
    </div>
  </div>
  <button mat-raised-button color="primary" (click)="testProfile()" *ngIf="!request">Test</button>
  <mat-spinner *ngIf="request" diameter="40"></mat-spinner>
</div>
<base-dialog-footer #dialogFooter></base-dialog-footer>
