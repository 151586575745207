<div class="dialog-header">
  <h1 mat-dialog-title>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center">
      <span>{{ title }}</span>
      <button mat-mini-fab color="warn" class="window-button" style="left:33px;bottom:31px">
        <mat-icon class="window-button-ico" (click)="closeDialog()">clear</mat-icon>
      </button>
    </div>
  </h1>
  <div class="banner" *ngIf="description">
    <mat-icon class="teaser">devices_other</mat-icon>
    <div class="description">{{ description }}</div>
  </div>
</div>