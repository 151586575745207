import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { isUndefined } from "underscore";
import { ILogEvent, ILogEventPaginate } from "../model/logEvents";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class SystemLogService extends AbstractService {
  constructor(public http: HttpClient) {
    super("logs");
  }
  getLogs(tenantID?: string, logEvent?: number, pagination?: PageEvent) {
    const headers = this.headers;
    const url = this.url;
    let params = new HttpParams();
    if (tenantID) {
      params = params.append("tenantID", tenantID);
    }
    if (!isUndefined(logEvent)) {
      params = params.append("logEvent", logEvent.toString());
    }
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    return this.http.get<ILogEvent[] | ILogEventPaginate>(url, {
      headers,
      params,
    });
  }
}
