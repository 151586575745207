import { Component } from '@angular/core';
import { BaseDialogFooterButtonComponent } from './base-dialog-footer-button.component';
import { MatDialogModule } from '@angular/material/dialog';

export class BaseDialogFooterCancelButtonComponent extends BaseDialogFooterButtonComponent {    
    constructor() {
        super();
        
        this.setId("input-dialog-cancel");
        this.setLabel("Abbrechen");
        this.setColor("warn");
        this.setCloseValue(undefined);
        this.setDisabled(false);
        this.setTabindex(-1);
    }
}