import { OverlayContainer } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomOverlayContainer extends OverlayContainer {
  _createContainer(): void {
    let container = document.createElement("div");
    container.classList.add("cdk-overlay-container");
    document.getElementById("app")?.appendChild(container);
    this._containerElement = container;
  }
}
