import { AbstractService } from './abstract.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ITenant } from '../model/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends AbstractService {
  /**
   * @ignore
   */
  constructor(public http: HttpClient) {
    super('tenants');
  }

  /**
   * Delivers all Tenants
   *
   * @returns {Tenant} a list of {Tenant}
   */
  getData(deleted?: boolean) {
    const headers = this.headers;
    const params = new HttpParams({
      fromObject: {
        tenantID: '*',
        deleted: deleted ? deleted.toString() : 'false',
      },
    });

    return this.http.get<ITenant[]>(this.url, { headers, params });
  }

  /**
   *Get Tenant by TenantID.
   *
   * @param {string} tenantID
   * @returns Observable<Tenant>
   * @memberof TenantService
   */
  getTenantByID(tenantID: string) {
    const headers = this.headers;
    const url = `${this.url}/${tenantID}`;
    return this.http.get<ITenant>(url, { headers });
  }

  /**
   * Deletes a tenant by its id
   * @param tenantID
   */
  deleteTenant(tenantID: string, purge?: boolean) {
    const headers = this.headers;
    let url = `${this.url}/` + tenantID;
    if (purge) {
      url += '?purge';
    }
    return this.http.delete(url, { headers });
  }
}

@Injectable({
  providedIn: 'root',
})
export class TenantCreateService extends AbstractService {
  /**
   * @ignore
   */
  constructor(public http: HttpClient) {
    super('tenants');
  }

  /**
   * Create a tenant
   * @param tenantName the wanted tenant name
   */
  createTenant(tenantName: string) {
    const url = `${this.url}`;
    const headers = this.headers;
    const data = `[ {"tenantName": "${tenantName}" }]`;
    return this.http.post(url, data, { headers: headers });
  }
}
