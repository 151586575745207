import { EPermissions } from "../../../services/uiconfig-service";

export class Menu {
  constructor(
    public id: number,
    public title: string,
    public routerLink: string,
    public href: string | null,
    public icon: string,
    public target: string | null,
    public hasSubMenu: boolean,
    public parentId: number,
    public permission: EPermissions
  ) {}
}
