import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { ESubscriptionType } from "../model/enum/subscriptions";
import { IAlertSubscrPaginat, ISubscription } from "../model/subscriptions";
import { getParams } from "../shared/abstract/utils";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionsService extends AbstractService {
  constructor(private http: HttpClient, private appSettings: AppSettings) {
    super("subscriptions");
  }
  getSubscriptions(
    tenantID?: string,
    resourceType?: ESubscriptionType,
    pagination?: PageEvent,
    username?: string,
    par?: { [param: string]: string }
  ): Observable<IAlertSubscrPaginat | ISubscription[]> {
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : this.appSettings.currentTenantID(),
      },
    });
    if (resourceType) {
      params = params.append("resourceType", resourceType);
    }
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (username) {
      params = params.append("username", username);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IAlertSubscrPaginat | ISubscription[]>(this.url, {
      headers,
      params,
    });
  }
  createSubscription(form: ISubscription, _id?: string) {
    if (_id) {
      form["_id"] = _id;
      delete form.devices;
      delete form.user;
      return this.http.put(this.url, form, { headers: this.headers });
    } else {
      return this.http.post(this.url, form, { headers: this.headers });
    }
  }
  deaktivateSubscription(
    sub: ISubscription,
    active: boolean,
    silent?: boolean
  ) {
    const conf = Object.assign({}, sub.subscriptionConfig);
    conf.active = active;
    conf.silent = silent;
    if (active === false || silent === true) {
      delete conf.devEUI;
    }
    const form: ISubscription = {
      _id: sub._id,
      subscriptionConfig: conf,
      tenantID: sub.tenantID,
    } as ISubscription;
    return this.http.put(this.url, form, { headers: this.headers });
  }
  deleteSubscription(id: string) {
    return this.http.delete(this.url + "/" + id, { headers: this.headers });
  }
  editApiSubscription(id: string, devEUIs: string[]) {
    const form = {
      _id: id,
      resourceFilter: {
        apiDevices: devEUIs.map((x) => {
          return {
            devEUI: x,
          };
        }),
      },
    };
    const url = `${this.url}`;
    const headers = this.headers;
    return this.http.put(url, form, { headers });
  }
}
