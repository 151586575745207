import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "enumToArray",
})
export class EnumToArrayPipe implements PipeTransform {
  transform(value: Record<string, any>): { index: number; name: string }[] {
    return Object.keys(value)
      .filter((e) => !isNaN(+e))
      .map((o) => {
        return { index: +o, name: value[o].toLocaleUpperCase() };
      });
  }
}

@Pipe({
  name: "enumToStringArray",
})
export class EnumToStringArrayPipe implements PipeTransform {
  transform(value: Record<string, any>): { index: string; name: string }[] {
    return Object.keys(value).map((o) => {
      return { index: o, name: value[o] };
    });
  }
}
