<mat-sidenav-container>
  <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
      <mat-card class="p-0 mat-elevation-z24 box">
        <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
          <img class="brandLogo"[src]="cloudImage" alt="cloud-image" />
        </div>
        <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
          <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner">
            <span class="box-content-header">{{ LOGIN.PasswordReset }}</span>
            <mat-form-field class="w-100">
              <input matInput placeholder="Benutzername" [formControl]="userName" />
              <mat-error *ngIf="userName.hasError('required')">{{ LOGIN.UserNameRequired }} </mat-error>
              <mat-error *ngIf="userName.hasError('userNameWrong')"> Benutzer existiert nicht! </mat-error>
              <!-- <mat-error *ngIf="userName.hasError('invalidEmail')">{{ LOGIN.EmailValid }} </mat-error> -->
            </mat-form-field>
            <span>Bitte geben Sie ihren Benutzernamen ein.</span>
          </mat-card>
          <button mat-raised-button color="primary" class="mat-elevation-z12 box-button" [disabled]="userName.invalid" (click)="onSubmit()">
            Absenden
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-sidenav-container>
