import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConstants } from "../shared/app.constants";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  // private Error: ExceptionMap;
  private jwt = new JwtHelperService();
  constructor() {
    // this.Error = new ExceptionMap();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has("Authorization")) {
      return next
        .handle(req)
        .pipe(
          map(this.httpHandler.bind(this)),
          catchError(this.errorHandler.bind(this))
        );
    } else {
      let token =
        sessionStorage.getItem(AppConstants.loraApiToken) ||
        localStorage.getItem(AppConstants.loraApiToken);
      return next
        .handle(
          req.clone({
            headers: req.headers.append("Authorization", `Bearer ${token}`),
          })
        )
        .pipe(
          map(this.httpHandler.bind(this)),
          catchError(this.errorHandler.bind(this))
        );
    }
  }

  httpHandler(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      const tok = event.headers.get("Token");
      const token = this.jwt.decodeToken(
        sessionStorage.getItem(AppConstants.loraApiToken) || undefined
      );
      if (tok) {
        const obj = this.jwt.decodeToken(tok);
        if (obj) {
          if (!obj.exp) {
            localStorage.setItem(AppConstants.loraApiToken, tok);
            return event;
          }
          if (!token) {
            sessionStorage.setItem(AppConstants.loraApiToken, tok);
            return event;
          }
          if (obj.exp > token.exp + 130) {
            sessionStorage.setItem(AppConstants.loraApiToken, tok);
          }
        }
      }
    }
    return event;
  }
  errorHandler(error: HttpErrorResponse) {
    // const data = {
    //   reason:
    //     error && error.error && error.error.message
    //       ? error.error.message
    //       : error && error.statusText
    //       ? error.statusText
    //       : "",
    //   status: error.status
    // };
    return throwError(() => {
      return error;
    });
  }
}
