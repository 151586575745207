<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content [formGroup]="form" fxLayout="column">
  <ng-container formArrayName="formControl" *ngFor="let control of Controls; let i = index">
    <div fxLayout="row">
      <mat-form-field [formGroupName]="i">
        <input matInput type="text" [placeholder]="placeholder[i]||''" [formControl]="control"
          (keydown)="onKeyDown($event,form)" />
        <mat-error *ngIf="control.hasError('minlength')">Min. 4 Zeichen</mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</div>
<base-dialog-footer #dialogFooter></base-dialog-footer>
