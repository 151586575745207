import { Component, HostListener, ViewEncapsulation } from "@angular/core";

declare global {
  interface Document {
    mozCancelFullScreen: any;
    webkitExitFullscreen: any;
    fullscreenElement: any;
    mozFullScreenElement: any;
    webkitFullscreenElement: any;
    msExitFullscreen: any;
    msFullscreenElement: any;
    ".mozFullScreenElement": any;
  }
}

@Component({
  selector: "app-fullscreen",
  encapsulation: ViewEncapsulation.None,
  template: `
    <button mat-icon-button class="full-screen">
      <mat-icon *ngIf="!toggle" #expand>fullscreen</mat-icon>
      <mat-icon *ngIf="toggle" #compress>fullscreen_exit</mat-icon>
    </button>
  `,
})
export class FullScreenComponent {
  toggle = false;
  // @ViewChild("expand") private expand: ElementRef;
  // @ViewChild("compress") private compress: ElementRef;

  requestFullscreen(elem: any): void {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else {
      console.log("Fullscreen API is not supported.");
    }
  }

  exitFullscreen(): void {
    document = document as Document;
    if (document.fullscreenElement !== null) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document["webkitExitFullscreen"]) {
        document["webkitExitFullscreen"]();
      } else if (document["mozCancelFullScreen"]) {
        document["mozCancelFullScreen"]();
      } else if (document["msExitFullscreen"]) {
        document["msExitFullscreen"]();
      } else {
        console.log("Fullscreen API is not supported.");
      }
    }
  }

  /**
   * Receives full screen element for given browser or else null
   *
   * @returns {Element | null} - Found browser full screen element or else null
   * @todo Momentarily unused, maybe delete
   */
  private receiveFullScreenElement(): Element | null {
    return (
      document["fullscreenElement"] ||
      document[".mozFullScreenElement"] ||
      document["webkitFullscreenElement"] ||
      document["msFullscreenElement"]
    );
  }
  isFullScreen() {
    return this.receiveFullScreenElement() !== undefined;
  }

  @HostListener("click") getFullscreen(): void {
    if (this.isFullScreen()) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen(document.documentElement);
    }
  }

  @HostListener("window:resize") onFullScreenChange(): void {
    this.toggle = this.isFullScreen();
  }
}
