import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { forkJoin, Subscription } from "rxjs";
import { AppSettings } from "../../../../../app.settings";
import {
  ICreateDeviceDataProfile,
  IDeviceDataProfileList,
} from "../../../../../model/devicedataprofile";
import { EDataType } from "../../../../../model/enum/dataType";
import { DeviceDataProfileService } from "../../../../../services/DeviceDataProfileService";
import { debounce, isDefined } from "../../../../abstract/utils";

@Component({
  selector: "app-profile-select",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          #profile_select
          [formControlName]="controlName"
          [placeholder]="placeholder"
          [multiple]="false"
          [value]="value"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="search"
              [searching]="loading"
              [disableScrollToActiveOnOptionsChanged]="true"
              [clearSearchInput]="false"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of profileList" [value]="item._id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataProfileSelectComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() placeholder: string = "Datenprofil";
  @Input() dataType?: EDataType;
  @Input() tenantID?: string;
  @Input() value?: string;
  @Output() selectionChange = new EventEmitter();
  @Output() profileLoaded = new EventEmitter();
  public loading = false;
  public search: UntypedFormControl = new UntypedFormControl();
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public profileList?: IDeviceDataProfileList[] | null;
  private subs?: Subscription;
  private updateHandler: Function;

  @ViewChild("profile_select") selectElem?: MatSelect;
  constructor(
    private ddpService: DeviceDataProfileService,
    private appSettings: AppSettings,
    private ref: ChangeDetectorRef
  ) {
    this.updateHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.profileList = [];
      this.loadProfiles(true);
    }, 500);
    if (!this.tenantID) {
      this.tenantID = this.appSettings.currentTenantID();
    }
  }
  ngOnInit(): void {
    this.search.valueChanges.subscribe({
      next: (value) => {
        if (value.length >= 2 || value.length <= 0) {
          this.updateHandler();
        }
      },
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dataType"]) {
      this.pageEvent.pageIndex = 0;
      if (isDefined(changes["dataType"].currentValue)) {
        this.loadProfiles();
      }
    }
  }
  loadProfiles(fetch = false) {
    this.loading = true;
    this.profileList = fetch ? this.profileList : null;
    if (
      !fetch &&
      this.group &&
      this.group.get(this.controlName)?.value &&
      this.dataType != undefined
    ) {
      this.ddpService
        .getDeviceDataProfilebyID(
          this.group.get(this.controlName)?.value,
          this.dataType,
          "name"
        )
        .subscribe({
          next: (prof) => {
            if (prof) {
              prof = prof as ICreateDeviceDataProfile;
              this.profileList = [prof];
              this.loading = false;
              this.ref.detectChanges();
              this.pageEvent.length = 1;
              this.subs = this.subs
                ? this.subs
                : this.selectElem?.openedChange.subscribe(() => {
                    if (this.selectElem?.panelOpen) {
                      this.registerPanelScrollEvent();
                    }
                  });
              this.profileLoaded.emit(this.profileList);
            }
            return;
          },
          error: (err) =>
            this.appSettings.getSwalError(err.error?.message || err.message),
        });
    } else {
      forkJoin([
        this.ddpService.getDeviceDataProfile(
          this.dataType,
          this.tenantID,
          this.pageEvent,
          this.search.value,
          { fields: "_id,name" }
        ),
        this.ddpService.getDeviceDataProfile(
          this.dataType,
          "public",
          this.pageEvent,
          this.search.value,
          { fields: "_id,name" }
        ),
      ]).subscribe({
        next: (result) => {
          if (result[0] && result[0].data) {
            this.profileList = fetch
              ? this.profileList?.concat(result[0].data)
              : result[0].data;
            this.pageEvent.length = result[0].total;
          }
          if (result[1] && result[1].data && this.tenantID !== "public") {
            this.profileList = this.profileList || [];

            this.profileList = this.profileList.concat(result[1].data);
            this.pageEvent.length += result[1].total;
          }
          this.profileLoaded.emit(this.profileList);
          this.loading = false;
          this.ref.detectChanges();
          this.subs = this.subs
            ? this.subs
            : this.selectElem?.openedChange.subscribe(() => {
                if (this.selectElem?.panelOpen) {
                  this.registerPanelScrollEvent();
                }
              });
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    }
  }
  onScrollToEnd() {
    this.fetchMore();
  }
  private fetchMore() {
    this.pageEvent.pageIndex++;
    if (
      this.pageEvent.length >
      this.pageEvent.pageIndex * this.pageEvent.pageSize
    ) {
      this.loadProfiles(true);
    }
  }
  onChanges(evt: any): void {
    this.selectionChange.emit(evt);
  }
  registerPanelScrollEvent() {
    if (this.loading) {
      return;
    }
    const panel = this.selectElem?.panel.nativeElement;
    panel.removeEventListener("scroll", () => {});
    panel.addEventListener("scroll", (event: any) => {
      if (event.target.scrollTop > 160 * (this.pageEvent.pageIndex + 1)) {
        this.fetchMore();
      }
    });
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
