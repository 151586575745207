import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChartsAdminComponent } from "./pages/charts-admin/charts-admin.component";
import { GroupsComponent } from "./pages/groups/groups.component";
import { TagedDevicesComponent } from "./pages/groups/taged-devices/taged-devices.component";
import { LogViewerComponent } from "./pages/log-viewer/log-viewer.component";
import { LogOutComponent } from "./pages/logout/logout.component";
import { PagesComponent } from "./pages/pages.component";
import { UserManagementComponent } from "./pages/users/user-management/user-management.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { CloudLoginGuard } from "./services/cloud-login.guard";
import { SuperAdminGuard } from "./services/superadmin.guard";

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    component: PagesComponent,
    canActivate: [CloudLoginGuard],
    children: [
      {
        path: "welcome",
        component: WelcomeComponent,
        data: { breadcrumb: "Herzlich willkommen" },
      },
      {
        path: "dashboards",
        loadChildren: () =>
          import("./pages/dynamicboard/dvv-charts.module").then(
            (m) => m.DvvChartsModule
          ),
        data: { breadcrumb: "Dashboards" },
      },
      {
        path: "superadmin",
        canActivateChild: [SuperAdminGuard],
        loadChildren: () =>
          import("./pages/administration/administration.module").then(
            (m) => m.AdministrationModule
          ),
        data: { breadcrumb: "SuperAdmin" },
      },
      {
        path: "subscriptions",
        loadChildren: () =>
          import("./pages/subscriptions/subscription.module").then(
            (m) => m.SubscriptionModule
          ),
        data: { breadcrumb: "Abonnements" },
      },
      {
        path: "lora",
        loadChildren: () =>
          import("./pages/lora-devices/devices-lora.module").then(
            (m) => m.DevicesModule
          ),
        data: { breadcrumb: "Lora" },
      },
      {
        path: "datastreams",
        loadChildren: () =>
          import("./pages/datastreams/datastreams.module").then(
            (m) => m.DataStreamsModule
          ),
        data: { breadcrumb: "Datenquellen" },
      },
      {
        path: "virtual",
        loadChildren: () =>
          import("./pages/virtual-devices/virtual-devices.module").then(
            (m) => m.VirtualDevicesModule
          ),
        data: { breadcrumb: "Virtuell" },
      },
      {
        path: "admin",
        data: { breadcrumb: "" },
        children: [
          {
            path: "users",
            component: UserManagementComponent,
            data: { breadcrumb: "Benutzerverwaltung" },
          },
          {
            path: "groups",
            component: GroupsComponent,
            data: { breadcrumb: "Geräte Gruppen" },
          },
          {
            path: "groups/devices",
            component: TagedDevicesComponent,
            data: { breadcrumb: "Gruppe Anpassen" },
          },

          {
            path: "logs",
            component: LogViewerComponent,
            data: { breadcrumb: "Logs" },
          },
          {
            path: "chartsadmin",
            component: ChartsAdminComponent,
            data: { breadcrumb: "Diagrammverwaltung" },
          },
        ],
      },

      // {
      //   path: "marketplace",
      //   component: MarketplaceComponent,
      //   data: { breadcrumb: "Marktplatz" },
      // },
      // {
      //   path: "marketplace/data",
      //   component: MarketplaceComponent,
      //   data: { breadcrumb: "Marktplatz/Diagramme" },
      // },
      // {
      //   path: "marketplace/diagrams",
      //   component: MarketplaceComponent,
      //   data: { breadcrumb: "Marktplatz/Diagramme" },
      // },
      // {
      //   path: "contract/options",
      //   component: MyContractComponent,
      //   data: { breadcrumb: "Mein Vertrag" },
      // },
      // {
      //   path: "contract/invoices",
      //   component: InvoicesComponent,
      //   data: { breadcrumb: "Meine Rechnungen" },
      // },

      {
        path: "user",
        loadChildren: () =>
          import(
            "./theme/components/user-menu/user-settings/user-settings.module"
          ).then((m) => m.UserSettingsModule),
        data: { breadcrumb: "Benutzer" },
      },
    ],
  },
  {
    path: "resetpassword",
    loadChildren: () =>
      import("./pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "renewpassword",
    loadChildren: () =>
      import("./pages/renew-password/renew-password.module").then(
        (m) => m.RenewPasswordModule
      ),
  },
  // {
  //   path: "register",
  //   loadChildren: () =>
  //     import("./pages/register/register.module").then((m) => m.RegisterModule),
  // },

  {
    path: "logout",
    component: LogOutComponent,
    data: { breadcrumb: "logout" },
  },
  {
    path: "landing-page",
    loadChildren: () =>
      import("./pages/landing-page/landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
