import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {MatSelect} from "@angular/material/select";
import {Subscription} from "rxjs";
import {AppSettings} from "../../../../../app.settings";
import {ILoraServiceProfile} from "../../../../../model/loraServiceProfile";
import {IPagination} from "../../../../../model/pagination";
import {LoraServiceProfilesService} from "../../../../../services/lora-service-profiles.service";
import {debounce} from "../../../../abstract/utils";

@Component({
  selector: "app-lora-service-selector",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          #service_profile_select
          [formControlName]="controlName"
          [placeholder]="placeholder"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="search"
              [searching]="loading"
              [disableScrollToActiveOnOptionsChanged]="true"
              [clearSearchInput]="false"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let item of profileList"
            [value]="item.serviceProfileID"
          >
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoraServiceSelectorComponent implements OnInit, OnDestroy {
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() placeholder: string = "Lora Service Profile";
  @Input() tenantID?: string;
  @Output() selectionChange = new EventEmitter();
  public loading = false;
  public profileList?: ILoraServiceProfile[] | null;
  public search: UntypedFormControl = new UntypedFormControl();
  public pageEvent: PageEvent = {length: 0, pageIndex: 0, pageSize: 10};
  private updateHandler: Function;
  private subs?: Subscription;
  @ViewChild("service_profile_select") selectElem?: MatSelect;
  constructor(
    private ref: ChangeDetectorRef,
    private lspService: LoraServiceProfilesService,
    private appSettings: AppSettings
  ) {
    this.updateHandler = debounce((val: any) => {
      this.pageEvent.pageIndex = 0;
      this.profileList = [];
      this.loadProfiles();
    }, 500);
  }
  ngOnInit(): void {
    this.search.valueChanges.subscribe({
      next: (value) => {
        if (value.length >= 2 || value.length <= 0) {
          this.updateHandler();
        }
      },
    });
    this.loadProfiles(false);
  }
  loadProfiles(fetch = false) {
    this.loading = true;
    this.profileList = fetch ? this.profileList : null;
    this.lspService
      .getLoraServiceProfiles(undefined, this.pageEvent, this.search.value)
      .subscribe({
        next: (result) => {
          result = result as IPagination<ILoraServiceProfile>;
          if (result && result.data) {
            this.profileList = fetch
              ? this.profileList?.concat(result.data)
              : result.data;
            this.pageEvent.length = result.total;
          }
          this.loading = false;
          this.ref.detectChanges();
          this.subs = this.subs
            ? this.subs
            : this.selectElem?.openedChange.subscribe(() => {
              if (this.selectElem?.panelOpen) {
                this.registerPanelScrollEvent();
              }
            });
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
        complete: () => (this.loading = false),
      });
  }
  onChanges(evt: any): void {
    this.ref.detectChanges();
    this.selectionChange.emit(evt);
  }
  private fetchMore() {
    this.pageEvent.pageIndex++;
    if (
      this.pageEvent.length >
      this.pageEvent.pageIndex * this.pageEvent.pageSize
    ) {
      this.loadProfiles(true);
    }
  }
  registerPanelScrollEvent() {
    if (this.loading) {
      return;
    }
    const panel = this.selectElem?.panel.nativeElement;
    panel.removeEventListener("scroll", () => { });
    panel.addEventListener("scroll", (event: any) => {
      if (event.target.scrollTop > 160 * (this.pageEvent.pageIndex + 1)) {
        this.fetchMore();
      }
    });
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
