import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppSettings } from "../app.settings";
import { Settings } from "../app.settings.model";
import { MenuIcon } from "../model/enum/menuIcon";
import { UIConfigService } from "../services/uiconfig-service";
import { MenuService } from "../theme/components/menu/menu.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [MenuService, UIConfigService],
})
export class PagesComponent implements OnInit, AfterViewInit {
  @ViewChild("sidenav") sidenav: any;
  public settings!: Settings;
  public menus = ["vertical", "horizontal"];
  public menuOption!: string;
  public menuTypes = ["default", "compact", "mini"];
  public menuTypeOption!: string;
  public isStickyMenu = false;
  public lastScrollTop = 0;
  public showBackToTop = false;
  public toggleSearchBar = false;
  public scrolledContent: any;
  public menuIcon: MenuIcon = MenuIcon.collapsed;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.settings.menu = "vertical";
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    setTimeout(() => {
      this.menuService.expandActiveSubMenu();
    }, 300);
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.router.navigate(["/"]);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
    this.appSettings.setSettingChanged("menuType", this.menuTypeOption);
  }

  changeSettings(key: string, value: any) {
    (this.settings as any)[key] = value;
    this.appSettings.setSettingChanged(key, value);
  }

  public toggleSidenav() {
    if (this.menuIcon === MenuIcon.collapsed) {
      this.menuIcon = MenuIcon.expanded;
    } else {
      this.menuIcon = MenuIcon.collapsed;
    }

    this.sidenav.toggle();
  }

  public onPsScrollY(event: any) {
    this.scrolledContent = event.target;
    this.scrolledContent.scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
    //    if(this.settings.menu == 'horizontal'){
    //      if(this.settings.fixedHeader){
    //        var currentScrollTop = (this.scrolledContent.scrollTop > 56) ? this.scrolledContent.scrollTop : 0;
    //        (currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
    //        this.lastScrollTop = currentScrollTop;
    //      }
    //      else{
    //        (this.scrolledContent.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
    //      }
    //    }
  }

  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (this.scrolledContent.scrollTop !== 0) {
        this.scrolledContent.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      this.scrolledContent.scrollTop = 0;
    }
  }

  @HostListener("window:resize")
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = "vertical";
    } else {
      this.settings.menu = "vertical";
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    const menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }
}
