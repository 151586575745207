<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content [formGroup]="_form">
  <mat-form-field>
    <input matInput placeholder="Bezeichnung" value="" formControlName="name" />
  </mat-form-field>
  <app-datatype-selector
    [group]="_form"
    controlName="dataType"
    (selectionChange)="onDeviceTypeChange($event)"
  >
  </app-datatype-selector>
  <app-profile-select
    *ngIf="_form.get('dataType')?.valid && _form.get('dataType')?.value < 3"
    fxFill
    [group]="_form"
    [controlName]="'deviceDataProfile'"
    [tenantID]="tenantID"
    [dataType]="_form.value.dataType"
    (selectionChange)="onProfileChange($event)"
  >
  </app-profile-select>
  <app-device-select
    *ngIf="
      _form.get('deviceDataProfile')?.valid || _form.get('dataType')?.value >= 3
    "
    controlName="devices"
    [tenantID]="tenantID"
    [group]="_form"
    [multiple]="true"
    [dataType]="_form.value.dataType"
    [dataProfileID]="_form.value.deviceDataProfile"
    [devices]="_devices_"
    (selectionChange)="onDeviceSelected($event)"
  >
  </app-device-select>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"> </mat-progress-bar>
  <div *ngIf="dataKeys">
    <ng-container
      formArrayName="resourceFilter"
      *ngFor="let item of resourceFilter.controls; let i = index"
    >
      <div [formGroupName]="i">
        <div fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex="0 1 10" *ngIf="i > 0">
            <mat-select placeholder="Option" formControlName="g">
              <mat-option *ngFor="let item of gatter" [value]="item.key">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="0 1 70">
            <mat-select
              placeholder="Datenpunkt"
              formControlName="key"
              (selectionChange)="keyChanged($event.value, i)"
            >
              <mat-option *ngFor="let item of dataKeys" [value]="item">
                {{ item }}
              </mat-option>
              <mat-option [value]="'Eingeben'">-- Eingeben --</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="0 1 10">
            <mat-select placeholder="Option" formControlName="func">
              <mat-option
                *ngFor="let item of compFunction"
                [value]="item.key"
                [matTooltip]="item.tip"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="0 1 10">
            <input
              matInput
              placeholder="Wert"
              formControlName="value"
              autocomplete="off"
              value=""
              [attr.type]="
                item.get('func')?.value === 'regex' ? 'text' : 'number'
              "
            />
            <mat-hint *ngIf="showHelp">Tagen</mat-hint>
          </mat-form-field>

          <div fxLayoutAlign="center center" fxLayout="row" fxFlex="0 1 10">
            <button
              mat-icon-button
              (click)="addResourceFilter()"
              color="primary"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="removeResourceFilter(i)"
              color="primary"
              [disabled]="i <= 0"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      formArrayName="actions"
      *ngFor="let item of Actions.controls; let i = index"
    >
      <div [formGroupName]="i" fxLayoutGap="8px">
        <mat-form-field fxFlex="0 1 20">
          <mat-select
            placeholder="Aktion"
            formControlName="actionType"
            (selectionChange)="onActionChange(i, $event.value)"
            required
          >
            <mat-option *ngFor="let item of actionType" [value]="item.key">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="0 1 35">
          <input
            matInput
            placeholder=""
            value=""
            *ngIf="!Actions.controls[i].value.actionType"
          />
          <input
            matInput
            placeholder="Email Adresse"
            formControlName="address"
            value=""
            type="email"
            *ngIf="
              Actions.controls[i].value.actionType === EActionType.SEND_EMAIL
            "
            required
          />
          <input
            matInput
            placeholder="SMS Versand ist noch nicht implementiert!"
            formControlName="number"
            value=""
            *ngIf="
              Actions.controls[i].value.actionType === EActionType.SEND_SMS
            "
            required
          />
          <mat-select
            placeholder="Rest-Profile"
            formControlName="rest"
            name="item"
            *ngIf="
              Actions.controls[i].value.actionType === EActionType.REST_API
            "
            required
          >
            <mat-option
              *ngFor="let item of restApiProfile | async"
              [value]="item._id"
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-template-selector
          fxFlex="0 1 35"
          [type]="'email'"
          [value]="item.value.template"
          *ngIf="
            Actions.controls[i].value.actionType === EActionType.SEND_EMAIL
          "
          (selectionChange)="templateSelected($event, i)"
        ></app-template-selector>
        <div fxLayoutAlign="center center" fxLayout="row" fxFlex="0 1 10">
          <button mat-icon-button (click)="addAction()" color="primary">
            <mat-icon>add</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="removeAction(i)"
            color="primary"
            [disabled]="i <= 0"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<base-dialog-footer #dialogFooter></base-dialog-footer>
