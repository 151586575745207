import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketplaceComponent } from './marketplace.component';
import { SharedModule } from '../../shared/shared.module';
import { PipesModule } from '../../theme/pipes/pipes.module';


@NgModule({
  declarations: [
    MarketplaceComponent
  ],
  imports: [
    CommonModule, SharedModule, PipesModule
  ]
})
export class MarketplaceModule { }
