<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="15px">
    <button mat-mini-fab color="primary" (click)="createMulticastGroup()"
      *ngIf="uiService.getUIPermissions(EPermissions.TAGS)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" #search />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter('');search.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <div *ngIf="data" @blockTransition fxFlex=" 100%">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)"></mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="Geräteprofile">
        <ng-container matColumnDef="_id">
          <th scope="col" mat-header-cell matRipple *matHeaderCellDef i18n>
            ID
          </th>
          <td mat-cell *matCellDef="let element" class="text-muted w-max-100p">
            {{ element._id }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Beschreibung
          </th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <ng-container matColumnDef="dr">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Datenrate
          </th>
          <td mat-cell *matCellDef="let element">{{ element.dr }}</td>
        </ng-container>
        <ng-container matColumnDef="groupType">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Device Typ
          </th>
          <td mat-cell *matCellDef="let element">{{ element.groupType===0?"CLASS C":"CLASS B" }}</td>
        </ng-container>
        <ng-container matColumnDef="devices">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Geräteanzahl
          </th>
          <td mat-cell *matCellDef="let element">{{ element.devices.length }}</td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Erstellt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.createdAt | localizedDate: "dd.MM.yyyy HH:mm:ss"}}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="userMenu"
              *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item (click)="createGroupQueue(element)">
                <mat-icon class="color-primary">send</mat-icon>
                <span>Daten senden</span>
              </button>
              <button mat-menu-item (click)="addDevices(element)">
                <mat-icon class="color-primary">add_to_photos</mat-icon>
                <span>Geräte hinzufügen</span>
              </button>
              <button mat-menu-item (click)="delDevices(element)">
                <mat-icon class="color-warn">delete_forever</mat-icon>
                <span>Geräte entfernen</span>
              </button>
              <button mat-menu-item (click)="addMulticastGroup(element)">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Gruppe bearbeiten</span>
              </button>
              <button mat-menu-item (click)="delMulticastGroup(element.id)">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Gruppe löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"></tr>
      </table>
    </div>
    <div *ngIf="!data&&!loading" fxFlex fxLayoutAlign="center center">
      Keine Multicast Gruppen gefunden
    </div>
  </div>
</div>

<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
