import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { AppSettings } from "../../../app.settings";
import { IDevice } from "../../../model/devices";
import { ITag } from "../../../model/tag";
import { DatastreamService } from "../../../services/datastream.service";
import { LoraDeviceService } from "../../../services/lora-devices";
import { NbiotDevicesService } from "../../../services/nbiot-devices.service";
import { TagsService } from "../../../services/tags.service";
import { changeRouteData } from "../../../shared/abstract/utils";
import { blockTransition } from "../../../theme/utils/app-animation";
import { DevicesDialogComponent } from "./devices-dialog/devices-dialog.component";

@Component({
  selector: "app-taged-devices",
  templateUrl: "./taged-devices.component.html",
  styleUrls: ["./taged-devices.component.scss"],
  animations: [blockTransition],
})
export class TagedDevicesComponent implements OnInit {
  private TagKey!: string;
  public Tag?: ITag;
  public loading = false;
  public devices?: IDevice[];
  public displayedColumns = ["name", "Type", "devEUI", "action"];
  // Pagination
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  // Pagination Ende
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dService: LoraDeviceService,
    private nService: NbiotDevicesService,
    private dsService: DatastreamService,
    private dialog: MatDialog,
    private tService: TagsService,
    private appSettings: AppSettings
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.TagKey = params.key;
        if (this.TagKey) {
          this.loading = true;
          this.loadData();
        }
      }
    });
  }
  loadData() {
    forkJoin([
      this.dService.getDevicesByTag(this.TagKey, this.pageEvent), //lora & nbiot
      this.dsService.getDevicesByTag(this.TagKey, this.pageEvent),
      this.tService.getTagBykey(this.TagKey),
    ]).subscribe({
      next: (result) => {
        this.devices = [];
        if (result[0] && result[0].data) {
          this.devices = result[0].data;
          this.pageEvent.length = result[0].total;
        } else if (Array.isArray(result[0])) {
          this.devices = result[0];
          this.pageEvent.length = result[0].length;
        }
        if (result[1] && result[1].data) {
          this.devices.push(...result[1].data);
          this.pageEvent.length += result[1].total || 0;
        } else if (Array.isArray(result[1])) {
          this.devices.push(...result[1]);
          this.pageEvent.length += result[1].length;
        }
        this.Tag = result[2];
        if (!this.Tag.taggedIDs) {
          this.Tag.taggedIDs = [];
        }
        this.loading = false;
      },
      error: (err) => {
        this.appSettings.getSwalError(err.error?.message || err.message);
        this.loading = false;
      },
    });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
  goBack() {
    changeRouteData(
      this.router,
      `Administration/Gruppen/Geräte`,
      "admin/groups/devices"
    );
    this.router.navigate(["admin", "groups"]);
  }
  addDeviceToGroup() {
    this.dialog
      .open(DevicesDialogComponent, {
        minWidth: 500,
        data: { devEUIs: this.Tag?.taggedIDs },
      })
      .afterClosed()
      .subscribe({
        next: (result: { selected: string[] }) => {
          if (result) {
            // console.log(result);
            this.tService
              .setDevicesByTag(this.TagKey, result.selected)
              .subscribe((res) => {
                this.loadData();
              });
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  deleteDeviceFromTag(device: IDevice) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Gerät aus der Gruppe entfernen.",
        "Wollen Sie wirklich das Gerät <b>" +
          device.deviceName +
          "</b> aus der Gruppe <b>" +
          this.Tag?.name +
          "</b> entfernen?"
      )
      .then((result) => {
        if (result.isConfirmed) {
          let newData = this.Tag?.taggedIDs.filter((item) => {
            return item !== device.devEUI;
          });
          if (newData && newData.length <= 0) {
            newData = [""];
          }
          this.tService.setDevicesByTag(this.TagKey, newData).subscribe({
            next: (res) => {
              this.loading = true;
              setTimeout(() => {
                this.loadData();
              }, 500);
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  getDataType(type: number) {
    switch (type) {
      case 0:
        return "LORA";
      case 1:
        return "NBIOT";
      case 2:
        return "STREAM";
      default:
        return "LORA";
    }
  }
}
