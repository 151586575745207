export enum ERequestMethod {
  "GET" = "get",
  "POST" = "post",
  "PUT" = "put",
}
export enum EResponseType {
  "JSON" = "json",
  "Buffer" = "buffer",
  "TEXT" = "text",
}
export enum EBodyType {
  "JSON" = "json",
  "XML" = "xml",
  "TEXT" = "text",
}
