import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { ESubscriptionType } from "../../../../model/enum/subscriptions";
import {
  IAlertDevice,
  IAlertSubscrPaginat,
  ISubscription,
} from "../../../../model/subscriptions";
import { SubscriptionsService } from "../../../../services/subscriptions.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../../services/uiconfig-service";
import { CreateAlert } from "../../../../shared/components/create-alert/create-alert";
import {
  blockTransition,
  expandTableRow,
} from "../../../../theme/utils/app-animation";

@Component({
  selector: "app-manage-alerting",
  templateUrl: "./manage-alerting.component.html",
  styleUrls: ["./manage-alerting.component.scss"],
  animations: [blockTransition, expandTableRow],
})
export class ManageAlertingComponent implements OnInit {
  public displayedColumns = [
    "name",
    "devices",
    "lastAlertAt",
    "createdAt",
    "action",
  ];
  public settings: Settings;
  public EPermissions = EPermissions;
  public ERole = ERole;
  @ViewChild(MatSort) sort!: MatSort;
  public loading = false;
  public expandedRow?: ISubscription;
  // Pagination
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };

  public data?: MatTableDataSource<ISubscription>;

  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    private sService: SubscriptionsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {});
  }

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    this.loading = true;
    this.sService
      .getSubscriptions(
        undefined,
        ESubscriptionType.DeviceDataAlert,
        this.pageEvent
      )
      .subscribe({
        next: (res) => {
          res = res as IAlertSubscrPaginat;
          // console.log(res);
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
            this.pageEvent.length = res.total;
          } else {
            this.data = new MatTableDataSource<ISubscription>([]);
          }
          this.loading = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
  getDeviceNames(devices: IAlertDevice[]) {
    if (!devices) {
      return;
    }
    const names = [];
    let i = 0;
    for (const dev of devices) {
      names.push(dev.deviceName);
      i++;
      if (i >= 10) {
        break;
      }
    }
    let txt = names.join(",");
    if (i >= 10 && devices.length > 10) {
      txt += ", +" + (devices.length - 10) + "weitere...";
    }
    return txt;
  }
  getDeviceEUIs(devices: IAlertDevice[]) {
    if (!devices) {
      return;
    }
    const names: string[] = [];
    devices?.forEach((dev) => names.push(dev.devEUI));
    return names.join(", ");
  }
  createAlert(alert?: ISubscription) {
    new CreateAlert(this.dialog, this.sService)
      .createAlert(this.appSettings.currentTenantID(), alert)
      .subscribe({
        next: (res) => {
          this.loadData();
          this.appSettings.getSwalSuccess(
            alert ? "Alarmierung wurde geändert" : "Alarmierung wurde erstellt."
          );
        },
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
        },
      });
  }
  deleteAlert(elem: ISubscription) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Alarmierung löschen?",
        "Wollen Sie wirklich die Alarmierung <b>" +
          elem.name +
          "</b>" +
          " entfernen?"
      )
      .then((result) => {
        if (result.isConfirmed && elem._id) {
          this.sService.deleteSubscription(elem._id).subscribe({
            next: (res) => {
              this.loadData();
              this.appSettings.getSwalSuccess("Alarmierung wurde entfernt.");
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  applyFilter(filterValue: string) {
    if (this.data) this.data.filter = filterValue.trim().toLowerCase();
  }
  muteAlert(elem: ISubscription, mute: boolean) {
    this.sService.deaktivateSubscription(elem, true, mute).subscribe({
      next: (res) => {
        this.loadData();
        this.appSettings.getSwalSuccess(
          `Alarmierung wurde ${mute ? "Stummgeschaltet" : "Aktiviert"}`
        );
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }

  disableAlert(evt: boolean, elem: ISubscription) {
    try {
      elem.subscriptionConfig.active = evt;
    } catch (error) {
      elem.subscriptionConfig = { active: evt };
    }
    this.sService.deaktivateSubscription(elem, evt).subscribe({
      next: (res) => {
        this.loadData();
        this.appSettings.getSwalSuccess(
          `Alarmierung wurde ${evt ? "Eingeschaltet" : "Ausgeschaltet"}`
        );
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
}
