import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ValidatorMessageService {
  constructor() {}

  /**
   *
   * @param validator('', minlength, required, invalidEmail, invalidPassword,
   * mismatchedPasswords)
   * @param params  Parameter für Validator z.B für minlength=6 ist paramas: 6
   * für required ist prams Name des Feldes z.b 'Email'
   */
  public getErrorMessage(validator: string, params?: any): string {
    switch (validator) {
      case "minlength":
        return `Mindestens ${params} Zeichen!`;
      case "required":
        return params ? `${params} ist erforderlich!` : `Eingabe erforderlich!`;
      case "invalidEmail":
        return `Bitte eine E-Mail-Adresse eingeben! Nur Kleinbuchstaben`;
      case "invalidPassword":
        return `Min 8 Zeichen & 1 Großbuch., 1 Zahl und 1 Sonderzeichen!`;
      case "mismatchedPasswords":
        return `Passwörter stimmen nicht überein!`;
      case "invalidLink":
        return `Geben Sie eine richtige Adresse ein. Es muss mit http:// oder https:// beginnen. (?,&...) sind nicht erlaubt. `;
      default:
        return `Bitte prüfen Sie Ihre Eingabe!`;
    }
  }
}
