import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { ESubscriptionType } from "../../model/enum/subscriptions";
import { ISubscription } from "../../model/subscriptions";
import { ITag } from "../../model/tag";
import { SubscriptionsService } from "../../services/subscriptions.service";
import { TagsService } from "../../services/tags.service";
import { EPermissions, UIConfigService } from "../../services/uiconfig-service";
import { changeRouteData, debounce } from "../../shared/abstract/utils";
import { InputDialogComponent } from "../../shared/components/sharedui/input-dialog/input-dialog.component";
import { blockTransition } from "../../theme/utils/app-animation";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"],
  animations: [blockTransition],
})
export class GroupsComponent implements OnInit {
  public loading = false;
  public settings: Settings;
  public EPermissions = EPermissions;

  @ViewChild(MatSort) sort!: MatSort;

  public data?: MatTableDataSource<ITag>;
  public displayedColumns = ["name", "key", "createdAt", "updatedAt", "action"];
  // Pagination
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  // Pagination Ende
  private searchHandler: any;
  private search?: string;
  constructor(
    public appSettings: AppSettings,
    public uiService: UIConfigService,
    private dataService: TagsService,
    private dialog: MatDialog,
    private tService: TagsService,
    private router: Router,
    private sService: SubscriptionsService
  ) {
    this.settings = this.appSettings.settings;
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadTags();
    }, 500);
  }

  ngOnInit() {
    this.loadTags();
  }

  loadTags() {
    this.loading = true;
    this.data = new MatTableDataSource<ITag>([]);
    this.dataService
      .getTagsByType("devices", this.pageEvent, this.search)
      .subscribe({
        next: (data) => {
          this.loading = false;
          if (!data) {
            return;
          }
          if (data.data) {
            this.data = new MatTableDataSource(data.data);
            this.pageEvent.length = data.total;
          } else if (Array.isArray(data)) {
            this.data = new MatTableDataSource(data);
            this.pageEvent.length = data.length;
          }
          setTimeout(() => {
            if (this.data) this.data.sort = this.sort;
          });
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadTags();
  }

  addTag() {
    const dialog = this.dialog.open(InputDialogComponent, {
      data: {
        Title: "Gruppe Erstellen",
        placeholder: ["Gruppenbezeichnung"],
      },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.tService
          .createTag(
            Math.random().toString(36).substr(2, 16),
            result.formControl[0],
            "devices"
          )
          .subscribe({
            next: (res) => {
              this.appSettings.getSwalSuccess(
                "Gruppe wurde erfolgreich erstellt"
              );
              this.loadTags();
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
      }
    });
  }

  deleteTag(elem: ITag, evt: any) {
    evt.stopPropagation();
    this.appSettings
      .getSwalDefaultConfirm(
        "Gruppe Löschen",
        "Wollen Sie wirklich die Gruppe <b>" + elem.name + "</b> löschen?"
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.dataService.deleteTag(elem.key).subscribe({
            next: (_) => {
              this.appSettings.getSwalSuccess(
                "Gruppe wurde erfolgreich gelöscht"
              );
              this.loadTags();
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }

  tagSelected(elem: ITag) {
    changeRouteData(
      this.router,
      `Administration/Gruppen/${elem.name}/Geräte`,
      "admin/groups/devices"
    );
    this.router.navigate(["admin", "groups", "devices"], {
      queryParams: { key: elem.key },
    });
  }

  // Suche
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  createMQTTSub(row: ITag) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Subscription erstellen",
        "Wollen Sie wirklich Daten per MQTT <br>von allen Geräten in der Gruppe <b>" +
          row.name +
          "</b>" +
          " bekommen?"
      )
      .then((result) => {
        if (result.isConfirmed) {
          const form: ISubscription = {
            name: "MQTT für Gruppe " + row.name,
            resourceType: ESubscriptionType.DeviceDataMQ,
            resourceConfig: { actions: [] },
            owner: this.uiService.currentUserName(),
            tenantID: this.appSettings.currentTenantID(),
            username: this.uiService.currentUserName(),
            resourceFilter: {
              groupKey: row.key,
              groupName: row.name,
            },
            subscriptionConfig: {},
          };
          this.sService.createSubscription(form).subscribe({
            next: () => {
              this.appSettings.getSwalSuccess(
                "MQTT Subscription wurde erstellt."
              );
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
}
