import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {AppSettings} from "../../../app.settings";
import {IDevice} from "../../../model/devices";
import {ESubscriptionType} from "../../../model/enum/subscriptions";
import {IAlertDevice, ISubscription} from "../../../model/subscriptions";
import {IVirtualDevice} from '../../../model/virtual-device';
import {SubscriptionsService} from "../../../services/subscriptions.service";
import {UIConfigService} from "../../../services/uiconfig-service";

export class CreateMqttSubscription {
  constructor(
    private dialog: MatDialog,
    private uiService: UIConfigService,
    private sService: SubscriptionsService,
    private appSettings: AppSettings
  ) { }
  createMqttSub(row: IDevice | IAlertDevice | IVirtualDevice) {
    return new Observable((observer) => {
      this.appSettings.getSwalDefaultConfirm("Subscription erstellen", "Wollen Sie wirklich Daten per MQTT von <b>" +
        row.deviceName +
        "</b>" +
        " bekommen?").then(result => {
          if (result.isConfirmed) {
            const form: ISubscription = {
              name: "MQTT für Device " + row.deviceName,
              resourceType: ESubscriptionType.DeviceDataMQ,
              resourceConfig: {actions: []},
              owner: this.uiService.currentUserName(),
              tenantID: this.appSettings.currentTenantID(),
              username: this.uiService.currentUserName(),
              resourceFilter: {
                devEUI: row.devEUI,
                deviceName: row.deviceName,
              },
              subscriptionConfig: {active: true},
            };
            this.sService.createSubscription(form).subscribe({
              next: (res) => observer.next(res),
              error: (err) => observer.error(err),
              complete: () => observer.complete(),
            });
          } else {
            observer.next();
            observer.complete();
          }
        })
    });
  }
}
