import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { UserManagementService } from "../../services/user-management.service";
import { AppConstants } from "../../shared/app.constants";
import { ValidatorMessageService } from "../../shared/components/validator-message.service";
import {
  matchingPasswords,
  passwordValidator,
} from "../../validators/main-validators";

@Component({
  selector: "app-renew-password",
  templateUrl: "./renew-password.component.html",
  styleUrls: ["./renew-password.component.scss"],
})
export class RenewPasswordComponent implements OnInit {
  public cloudImage = "./assets/img/logo_rhinecloud_iot.png";
  public settings: Settings;
  public form: FormGroup;
  private token!: string;
  public showTokenReset = false;
  constructor(
    public appSettings: AppSettings,
    private route: ActivatedRoute,
    private vService: ValidatorMessageService,
    private router: Router,
    private fb: FormBuilder,
    private uService: UserManagementService
  ) {
    this.settings = this.appSettings.settings;
    this.route.queryParams.subscribe((params) => {
      if (params && params.token) {
        this.token = params.token;
      } else {
        this.router.navigateByUrl("/");
      }
    });
    this.form = this.fb.group(
      {
        password: [
          null,
          Validators.compose([Validators.required, passwordValidator]),
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
      },
      { validator: matchingPasswords("password", "confirmPassword") }
    );
  }

  ngOnInit() {
    localStorage.removeItem(AppConstants.loraApiToken);
    sessionStorage.removeItem(AppConstants.loraApiToken);
    this.form.controls["password"].valueChanges.subscribe((value) => {
      this.form.controls["password"].markAsTouched();
    });
    this.form.controls["confirmPassword"].valueChanges.subscribe((value) => {
      this.form.controls["confirmPassword"].markAsTouched();
    });
  }
  getErrorMessage(validator: string, params?: any) {
    return this.vService.getErrorMessage(validator, params);
  }
  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }
  onSubmit() {
    this.uService
      .sendNewUserPassword(this.form.controls["password"].value, this.token)
      .subscribe({
        next: (result) => {
          this.router.navigateByUrl("/login");
        },
        error: (err) => {
          if (
            err.error &&
            err.error.message &&
            err.error.message.includes("invalid token found")
          ) {
            this.showTokenReset = true;
          }
        },
      });
  }
}
