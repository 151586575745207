export const LOGIN = {
  MemberLogin: 'Anmeldung',
  MemberNewPassword: 'Eine Änderung des Passworts ist erforderlich',
  SignIn: 'anmelden',
  PasswordReset: 'Passwort zurücksetzen',
  PasswordValid: 'Das Passwort ist zu kurz, mindestens 6 Zeichen',
  PasswordRequired: 'Ein Passwort ist notwendig',
  EmailRequired: 'Eine E-Mail-Adresse notwendig',
  UserNameRequired: 'Bitte einen Benutzernamen eingeben',
  EmailValid: 'Die E-Mail-Adresse ist ungültig',
};
export const REGISTER = {};

export const DEVICES = {};
