import { Component, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { IDataStream } from "../../../model/datatstream";
import { ISortEvent } from "../../../model/sort";
import { IAlertDevice } from "../../../model/subscriptions";
import { DatastreamService } from "../../../services/datastream.service";
import { SubscriptionsService } from "../../../services/subscriptions.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { changeRouteData, debounce } from "../../../shared/abstract/utils";
import { CreateAlert } from "../../../shared/components/create-alert/create-alert";
import { CreateMqttSubscription } from "../../../shared/components/create-mqttsub/create-mqttsub";
import { blockTransition } from "../../../theme/utils/app-animation";
import { MapViewDialogComponent } from "../../dynamicboard/map-view-dialog/map-view-dialog.component";

@Component({
  selector: "app-datastream-devices",
  templateUrl: "./datastream-devices.component.html",
  styleUrls: ["./datastream-devices.component.scss"],
  animations: [blockTransition],
})
export class DatastreamDevicesComponent implements OnInit {
  public loading = false;
  public displayedColumns = [
    "dataStreamName",
    "description",
    "type",
    "dataStreamEUI",
    "lastSeenAt",
    "createdAt",
    "defaultLocation",
    "action",
  ];
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public dataStreams?: MatTableDataSource<IDataStream>;
  public EPermissions = EPermissions;
  private searchHandler: any;
  private search?: string;
  private params?: { [param: string]: string };
  constructor(
    private router: Router,
    private dsService: DatastreamService,
    private dialog: MatDialog,
    private appSettings: AppSettings,
    private sService: SubscriptionsService,
    public uiService: UIConfigService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.pageEvent = this.router.getCurrentNavigation()?.extras.state
        ?.pageEvent || { length: 0, pageIndex: 0, pageSize: 10 };
    }
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadDevices();
    }, 500);
    if (this.router.getCurrentNavigation()?.extras.state?.pageEvent) {
      this.pageEvent =
        this.router.getCurrentNavigation()?.extras.state?.pageEvent;
    }
  }

  ngOnInit(): void {
    this.loadDevices();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDevices();
  }
  apiAppFilter(evt: MatCheckboxChange) {
    this.pageEvent.pageIndex = 0;
    if (evt.checked) {
      this.params = { dataType: "4" };
    } else {
      this.params = undefined;
    }
    this.loadDevices();
  }
  loadDevices() {
    this.loading = true;
    this.dsService
      .loadDataStreams(
        this.appSettings.currentTenantID(),
        this.pageEvent,
        this.search,
        this.params
      )
      .subscribe({
        next: (res) => {
          res = res as IDataStream;
          if (res && res.data) {
            this.dataStreams = new MatTableDataSource(res.data);
            this.pageEvent.length = res.total || 0;
            this.pageEvent.pageSize = res.pageSize || 10;
          } else {
            this.dataStreams = new MatTableDataSource();
          }
          this.loading = false;
        },
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
          this.loading = false;
        },
      });
  }
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  createDevice(apiApp?: boolean) {
    if (apiApp) {
      this.router.navigateByUrl("subscriptions/data-input/createdevice", {
        state: { pageEvent: this.pageEvent },
      });
    } else {
      this.router.navigateByUrl("datastreams/createdevice", {
        state: { pageEvent: this.pageEvent },
      });
    }
  }
  updateDataStream(elem: any, copy = false) {
    if (copy) {
      elem["copy"] = true;
      delete elem.devEUI;
      delete elem.deviceName;
    }
    this.router.navigateByUrl("datastreams/createdevice", {
      state: { elem, pageEvent: this.pageEvent },
    });
  }

  deleteDataStream(elem: IDataStream) {
    this.appSettings
      .getSwalDefaultConfirm(
        "DataStream Gerät löschen?",
        "Wollen Sie wirklich Gerät <strong>" +
          elem.deviceName +
          "</strong>" +
          " löschen?<br><br>"
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.dsService.deleteDataStream(elem.devEUI).subscribe({
            next: (_) => {
              this.loadDevices();
              this.appSettings.getSwalSuccess("Gerät wurde gelöscht");
            },
          });
        }
      });
  }
  getDeviceInfo(row: IDataStream) {
    changeRouteData(
      this.router,
      "Gerät/" + row.deviceName,
      "datastreams",
      "info/:id/:tenantID"
    );
    this.router
      .navigateByUrl(
        "datastreams/info/" +
          row.devEUI +
          "/" +
          this.appSettings.currentTenantID(),
        { state: { pageEvent: this.pageEvent } }
      )
      .then((_) => {});
  }
  setLocation(device: IDataStream) {
    this.dialog
      .open(MapViewDialogComponent, {
        data: { ...device.defaultLocation, readOnly: true },
        width: "100%",
        height: "100%",
      })
      .afterClosed()
      .subscribe({
        next: (_) => {},
      });
  }
  createMQTTSub(row: IDataStream) {
    new CreateMqttSubscription(
      this.dialog,
      this.uiService,
      this.sService,
      this.appSettings
    )
      .createMqttSub(row)
      .subscribe({
        next: (res) => {
          if (res) {
            this.appSettings.getSwalSuccess(
              "MQTT Subscription wurde erstellt."
            );
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  createAlertSub(row: IDataStream) {
    new CreateAlert(this.dialog, this.sService)
      .createAlert(this.appSettings.currentTenantID(), undefined, [
        row,
      ] as IAlertDevice[])
      .subscribe({
        next: (res) => {
          this.appSettings.getSwalSuccess("Alarmierung wurde erstellt.");
        },
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
        },
      });
  }
  sortData(evt: ISortEvent) {
    this.params = {};
    if (evt.direction !== "") {
      this.params["sort"] = `${evt.direction == "desc" ? "-" : ""}${
        evt.active
      }`;
    }
    this.loadDevices();
  }
}
