<div fxLayout="row wrap" *ngIf="router.url !== '/'">
  <div fxFlex="100" class="flex-p">
    <mat-card fxLayout="row" class="breadcrumb mat-elevation-z">
      <!-- <div *ngIf="router.url != '/'" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
                <mat-icon>home</mat-icon>
                <span class="breadcrumb-title">Home</span>
            </div> -->
      <div
        *ngFor="let breadcrumb of breadcrumbs; let i = index"
        class="breadcrumb-item"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div [hidden]="i === breadcrumbs.length - 1">{{ breadcrumb.name }}</div>
        <span
          [hidden]="i !== breadcrumbs.length - 1"
          class="breadcrumb-title active"
          >{{ breadcrumb.name }}</span
        >
      </div>
    </mat-card>
  </div>
</div>
