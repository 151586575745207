import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import FileSaver from "file-saver";
import Swal from "sweetalert2";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { IDevice } from "../../model/devices";
import { ISortEvent } from "../../model/sort";
import { IAlertDevice } from "../../model/subscriptions";
import { BrokerService } from "../../services/broker.service";
import { LoraDeviceService } from "../../services/lora-devices";
import { SubscriptionsService } from "../../services/subscriptions.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../services/uiconfig-service";
import { WindowRef } from "../../services/WindowRef";
import { changeRouteData, debounce } from "../../shared/abstract/utils";
import { CreateAlert } from "../../shared/components/create-alert/create-alert";
import { CreateMqttSubscription } from "../../shared/components/create-mqttsub/create-mqttsub";
import { blockTransition } from "../../theme/utils/app-animation";
import { MapViewDialogComponent } from "../dynamicboard/map-view-dialog/map-view-dialog.component";
import { ChangeDeviceDataComponent } from "./change-device-data/change-device-data.component";
import { CreateDeviceDialogComponent } from "./create-device-dialog/create-device-dialog.component";
import { CreateDeviceQueueComponent } from "./create-device-queue/create-device-queue.component";

@Component({
  selector: "app-devices",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"],
  // encapsulation: ViewEncapsulation.None,
  providers: [LoraDeviceService, WindowRef],
  animations: [blockTransition],
})

/**
 * Component for managing the IOT devices
 */
export class DeviceComponent implements OnInit {
  public settings: Settings;
  @ViewChild(MatSort) sort!: MatSort;

  public publicdata = false;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public data?: MatTableDataSource<IDevice>;
  public loading = false;
  public displayedColumns = [
    "deviceName",
    "devEUI",
    "description",
    "lastSeenAt",
    "createdAt",
    "deviceProfileID",
    "defaultLocation",
    "action",
  ];
  public tenantID: any;
  // Pagination
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  // Pagination Ende

  /**
   *
   * @param uiService
   * @param appSettings
   * @param dialog
   * @param dataService
   * @param route
   */
  private searchHandler: any;
  private search?: string;
  private params?: { [param: string]: string };
  // TODO: Refactor
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    private dService: LoraDeviceService,
    private bService: BrokerService,
    private route: ActivatedRoute,
    private sService: SubscriptionsService,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {
      if (params && params.tenantID) {
        this.tenantID = params.tenantID;
        this.publicdata = this.tenantID === "public";
      } else {
        this.tenantID = this.appSettings.currentTenantID();
      }
    });
    this.route.data.subscribe({
      next: (data) => {
        if (data.isPublic) {
          this.publicdata = true;
          this.tenantID = "public";
        }
      },
    });
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadDevices();
    }, 500);
    if (this.router.getCurrentNavigation()?.extras.state?.pageEvent) {
      this.pageEvent =
        this.router.getCurrentNavigation()?.extras.state?.pageEvent;
    }
  }

  /**
   * @ignore
   */
  ngOnInit() {
    // this._window.nativeWindow.dispatchEvent(new Event('resize'));
    this.loadDevices();
  }

  /**
   * Loading all devices
   */
  loadDevices() {
    this.loading = true;
    this.dService
      .getDevices(
        this.publicdata,
        this.tenantID,
        this.pageEvent,
        this.search,
        this.params
      )
      .subscribe({
        next: (data) => {
          if (!data) {
            this.loading = false;
            return;
          }
          if (data.total) {
            this.data = new MatTableDataSource(data.data);
            this.pageEvent.length = data.total;
          } else if (Array.isArray(data)) {
            this.data = new MatTableDataSource(data);
            this.pageEvent.length = data.length;
          }
          this.loading = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }

  /**
   * Open the popup to create a device
   */
  openDeviceDialog() {
    const dialogRef = this.dialog.open(CreateDeviceDialogComponent, {
      data: { isPublicData: this.publicdata },
      disableClose: true,
      width: "450px",
    });
    dialogRef.afterClosed().subscribe((values) => {
      if (values) {
        if (
          values.defaultLocation.latitude == null &&
          values.defaultLocation.longitude == null
        ) {
          values.defaultLocation = undefined;
        }
        this.dService.createDevice(values, this.tenantID).subscribe({
          next: () => {
            this.appSettings.getSwalSuccess("Erfolgreich");
          },
          error: (err) =>
            this.appSettings.getSwalError(err.error?.message || err.message),
          complete: () => this.loadDevices(),
        });
      }
    });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDevices();
  }

  /**
   * Deleting a device
   * @param deviceID the Device that needs to be deleted
   */
  deleteDevice(deviceID: string, name: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Gerät Löschen",
        `Wollen Sie wirklich das Gerät <strong>${name}</strong> löschen?`
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.dService.deleteDevice(deviceID).subscribe({
            next: (_) => {
              this.loadDevices();
            },
            error: (err) => {
              this.appSettings.getSwalError(err.error?.message || err.message);
              this.loadDevices();
            },
          });
        }
      });
  }
  setLocation(device: IDevice) {
    this.dialog
      .open(MapViewDialogComponent, {
        data: { readOnly: true, ...device.defaultLocation },
        width: "100%",
        height: "85%",
      })
      .afterClosed()
      .subscribe({
        next: (_) => {},
      });
  }
  editDevice(device: IDevice) {
    const dialogRef = this.dialog.open(ChangeDeviceDataComponent, {
      data: device,
      disableClose: true,
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dService.createDevice(result, this.tenantID, true).subscribe({
          next: (res) => {
            this.appSettings.getSwalSuccess("Erfolgreich");
            this.loadDevices();
          },
          error: (err) => {
            this.appSettings.getSwalError(err);
          },
        });
      }
    });
  }

  /**
   * Simple Searchvalue
   * @param filterValue Searchvalue
   */
  // TODO: Refactor
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }

  getDeviceInfo(row: IDevice) {
    changeRouteData(
      this.router,
      "Gerät/" + row.deviceName,
      "lora",
      "info/:id/:tenantID"
    );
    if (
      this.appSettings.currentTenantID() === "superadmin" &&
      row.tenantID !== "superadmin" &&
      !this.publicdata
    ) {
      this.router.navigate(
        [
          "superadmin",
          "tenants",
          {
            outlets: {
              _management: ["lora", "info", row.devEUI, this.tenantID],
            },
          },
        ],
        { state: { pageEvent: this.pageEvent } }
      );
    } else {
      if (this.publicdata) {
        this.router.navigateByUrl("lora/info/" + row.devEUI + "/public");
      } else {
        this.router.navigateByUrl(
          "lora/info/" + row.devEUI + "/" + this.appSettings.currentTenantID(),
          { state: { pageEvent: this.pageEvent } }
        );
      }
    }
  }
  createDeviceQueue(row: IDevice) {
    const dialogRef = this.dialog.open(CreateDeviceQueueComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          const data = res.payload;
          this.bService
            .createDeviceQueue(data, row.devEUI, res.fPort)
            .subscribe({
              next: (resp: any) => {
                if (resp.success) {
                  this.appSettings.getSwalSuccess(
                    "DownloadQueue wurde erfolgreich erstellt."
                  );
                }
                // console.log(resp);
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
        }
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
  createMQTTSub(row: IDevice) {
    new CreateMqttSubscription(
      this.dialog,
      this.uiService,
      this.sService,
      this.appSettings
    )
      .createMqttSub(row)
      .subscribe({
        next: (res) => {
          if (res) {
            this.appSettings.getSwalSuccess(
              "MQTT Subscription wurde erstellt."
            );
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  createAlertSub(row: IDevice) {
    new CreateAlert(this.dialog, this.sService)
      .createAlert(this.appSettings.currentTenantID(), undefined, [
        row,
      ] as IAlertDevice[])
      .subscribe({
        next: (res) => {
          this.appSettings.getSwalSuccess("Alarmierung wurde erstellt.");
        },
        error: (err) => {
          this.appSettings.getSwalError(err.error?.message || err.message);
        },
      });
  }
  downloadExample() {
    this.appSettings
      .getSwalDefaultConfirm(
        "Template Runterladen",
        `
    <br>
    Hiermit wird eine Excel Datei heruntergeladen.
    <br>
    Bitte beachten Sie dass die ersten 2 Zeilen ignoriert werden.
    <br>
    <br>
    Möchten Sie mit dem Download beginen?
    `
      )
      .then((s) => {
        if (s.isConfirmed) {
          this.loading = true;
          this.dService.downloadExample().subscribe({
            next: (res) => {
              if (res) {
                FileSaver.saveAs(res, "deviceImport.xlsx");
              }
            },
            error: (err) => this.appSettings.getSwalError(err),
            complete: () => (this.loading = false),
          });
        }
      });
  }
  async handleFileInput() {
    const { value: file } = await Swal.fire({
      title: "Die Excel Datei",
      input: "file",
      inputAttributes: {
        accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "aria-label": "Liste mit den Geräten",
      },
    });

    if (file) {
      this.loading = true;
      this.dService.uploadImportFile(file).subscribe({
        next: (res) => {
          if (res) {
            this.appSettings.getSwalInfoWindow(
              "Die Datei wurde hochgeladen.",
              `
            ${this.parseResponse(res)}
            `
            );
          }
        },
        error: (err) => this.appSettings.getSwalError(err),
        complete: () => (this.loading = false),
      });
    }
  }
  parseResponse(res: { errors: any[]; results: any[]; rows: number }) {
    let html = "";
    if (res.errors && res.errors.length > 0) {
      html += "Es liegen " + res.errors.length + " Fehler vor:<br><br>";
      res.errors.forEach((e) => {
        html += `<div style="padding-left:10px">
        <span style="font-size: initial;">${e.message}</span>
        <br>
        <span style="font-style: italic;font-size: small;">${e.context?.message}</span>
        </div>`;
      });
    }
    if (res.rows > 0) {
      html += `Es wurden ${res.rows} Gerät(e) hinzugefügt:<br>`;
    }
    return html;
  }
  sortData(evt: ISortEvent) {
    this.params = {};
    if (evt.direction !== "") {
      this.params["sort"] = `${evt.direction == "desc" ? "-" : ""}${
        evt.active
      }`;
    }
    this.loadDevices();
  }
}
