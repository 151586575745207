import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";
import { SharedUIModule } from "../../shared/components/sharedui/sharedui.module";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { CreateAlertComponent } from "./alerting/create-alert/create-alert.component";
import { ManageAlertingComponent } from "./alerting/manage-alerting/manage-alerting.component";
import { RestApiAlertComponent } from "./alerting/rest-api-alert/rest-api-alert.component";
import { ApiSubscriptionDialogComponent } from "./api-user/api-subscription-dialog/api-subscription-dialog.component";
import { ApiUserComponent } from "./api-user/api-user.component";
import { CreateDataInputComponent } from "./data-input/create-data-input/create-data-input.component";
import { DataInputComponent } from "./data-input/data-input.component";
import { CreateForwardingComponent } from "./forwarding/create-forwarding/create-forwarding.component";
import { ForwardingComponent } from "./forwarding/forwarding.component";
import { MqdataComponent } from "./mqdata/mqdata.component";
import { SubscriptionRoutingModule } from "./subscription-routing.module";
import { CreateTemplateComponent } from "./template/email/create-template/create-template.component";
import { EmailComponent } from "./template/email/email.component";

@NgModule({
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    SharedUIModule,
    PipesModule,
    MonacoEditorModule,
  ],
  declarations: [
    ManageAlertingComponent,
    CreateAlertComponent,
    MqdataComponent,
    ApiUserComponent,
    ApiSubscriptionDialogComponent,
    RestApiAlertComponent,
    ForwardingComponent,
    CreateForwardingComponent,
    DataInputComponent,
    CreateDataInputComponent,
    EmailComponent,
    CreateTemplateComponent,
  ],
})
export class SubscriptionModule {}
