import {
  GridsterComponentInterface,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
} from "angular-gridster2";

export abstract class AppConstants {
  static readonly superadmin: string = "superadmin";
  static readonly admin: string = "admin";
  static readonly user: string = "user";
  static readonly loraApiToken: string = "token";
  static readonly chartTypes = [
    { name: "Widgets", value: "widget" },
    { name: "Kartendiagramme", value: "map" },
    { name: "Standard Zeitdiagramme", value: "time" },
    { name: "Balkendiagramme", value: "bar" },
    { name: "Line/Area Diagramme", value: "line" },
    { name: "Gaugediagramme", value: "gauge" },
    { name: "Spezifische Diagramme", value: "custom" },
    { name: "HTML Darstellung", value: "html" },
    { name: "JSON Darstellung", value: "json" },
  ];
  static readonly TimeRangeOptions = [
    { key: "Letzten Wert", value: 0 },
    { key: "24 Std", value: 1 },
    { key: "3 Tage", value: 3 },
    { key: "7 Tage", value: 7 },
    { key: "1 Monat", value: 31 },
    { key: "3 Monate", value: 92 },
    { key: "6 Monate", value: 182 },
    { key: "1 Jahr", value: 365 },
  ];
  static readonly Turnus = [
    { name: "5 min", value: 5 * 60000 },
    { name: "10 min", value: 10 * 60000 },
    { name: "15 min", value: 15 * 60000 },
    { name: "30 min", value: 30 * 60000 },
    { name: "1 Std", value: 60 * 60000 },
    { name: "12 Std", value: 720 * 60000 },
    { name: "24 Std", value: 1440 * 60000 },
  ];
  static readonly TurnusVirtual = [
    { name: "5 Min", value: 60 * 5000 },
    { name: "10 Min", value: 60 * 10000 },
    { name: "30 Min", value: 60 * 30000 },
    { name: "1 Std", value: 60 * 60000 },
    { name: "3 Std", value: 180 * 60000 },
    { name: "6 Std", value: 360 * 60000 },
    { name: "12 Std", value: 720 * 60000 },
    { name: "24 Std", value: 1440 * 60000 },
  ];
  static readonly gridsterConfig: GridsterConfig = {
    // gridSizeChangedCallback: () => {
    //   this.winRef.nativeWindow.dispatchEvent(new Event('resize'));
    // },
    itemChangeCallback: () => {},
    itemResizeCallback: (
      item: GridsterItem,
      itemComp: GridsterItemComponentInterface
    ) => {},
    gridSizeChangedCallback: (itemComp: GridsterComponentInterface) => {},
    pushItems: true,
    pushDirections: {
      north: true,
      east: true,
      south: true,
      west: false,
    },
    swap: true,
    keepFixedWidthInMobile: false,
    keepFixedHeightInMobile: false,
    draggable: {
      dragHandleClass: "draggable-holder",
      delayStart: 50,
      enabled: false,
      ignoreContent: true,
      dropOverItems: false,
    },
    pushResizeItems: false,
    resizable: {
      enabled: false,
      stop: (
        item: GridsterItem,
        itemComp: GridsterItemComponentInterface,
        event
      ) => {},
    },
    api: {
      optionsChanged: () => {},
    },
    useTransformPositioning: true,
    mobileBreakpoint: 640,
    compactType: "none",
    minCols: 1,
    maxCols: 135,
    minRows: 1,
    maxRows: 166,
    maxItemArea: 66 * 135,
    maxItemCols: 135,
    maxItemRows: 66,
    allowMultiLayer: false,
    fixedRowHeight: 0,
    fixedColWidth: 0,
    margin: 15,
    disableWarnings: true,
    // outerMargin: false,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    displayGrid: "none",
    gridType: "fixed",
    setGridSize: false,
  };
  static readonly jsDOC = `
  /**
     * Create Options for Echarts.
     * @param {IValue[]} _value_  Daten von dem(den) Sensor(en).
     * @param {any} _option_ reserviert.
     * @param {IModule} _module_ Hilfsmittel die man brauchen könnte.
     * @returns {echarts.EChartsOption}
     */
  `;
  static readonly forwardTypes = [
    { value: 0, name: "Alle Daten weiterleiten" },
    { value: 1, name: "Alle Daten eines Gerätetyps weiterleiten" },
    { value: 2, name: "Alle Daten eines Geräteprofiles weiterleiten" },
    { value: 3, name: "Alle Daten von bestimten Geräten weiterleiten" },
  ];
}
