<mat-sidenav-container>
  <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
      <mat-card class="p-0 mat-elevation-z24 box">
        <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
          <img class="brandLogo" [src]="cloudImage" alt="cloud-image" />
        </div>
        <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
          <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner"
            [formGroup]="form">
            <span class="box-content-header">Neues Passwort vergeben</span>
            <mat-form-field class="w-100">
              <input matInput placeholder="Passwort" formControlName="password" type="password" minlength="6" />
              <mat-error *ngIf="form.controls['password'].hasError('required')">{{ getErrorMessage('required') }}
              </mat-error>
              <mat-error *ngIf="form.controls['password'].hasError('invalidPassword')">
                {{ getErrorMessage('invalidPassword') }}
              </mat-error>
              <mat-error *ngIf="form.controls['password'].hasError('minlength')"> {{ getErrorMessage('minlength', 6) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="Passwort wiederholen" formControlName="confirmPassword" type="password" />
              <mat-error *ngIf="form.controls['confirmPassword'].hasError('required')"> {{ getErrorMessage('required')
                }}</mat-error>
              <mat-error *ngIf="form.controls['confirmPassword'].hasError('mismatchedPasswords')"> {{
                getErrorMessage('mismatchedPasswords') }}</mat-error>
            </mat-form-field>
            <span *ngIf="!showTokenReset">Bitte geben Sie Ihr neues Passwort ein.</span>
            <a mat-button routerLink="/resetpassword" *ngIf="showTokenReset" i18n>Neuen Anmeldelink anfordern.</a>
          </mat-card>
          <button mat-raised-button color="primary" class="mat-elevation-z12 box-button" [disabled]="form.invalid"
            (click)="onSubmit()">
            Passwort ändern
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-sidenav-container>
