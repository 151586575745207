<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content [formGroup]="form" style="height: 100%; overflow: hidden; padding: 0 !important">
  <mat-hint *ngIf="defLoc&&!defLoc.readOnly">Mit Doppelclick neue Position setzen</mat-hint>
  <div fxLayout="row" formGroupName="defaultLocation" fxLayoutGap="8px" *ngIf="defLoc&&!defLoc.readOnly">
    <mat-form-field>
      <input matInput placeholder="latitude" formControlName="latitude" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="longitude" formControlName="longitude" />
    </mat-form-field>
  </div>
  <div fxLayout="row" style="height: 100%">
    <app-dynamic-charts *ngIf="defLoc" style="min-width: 450px; width: 100%"
      [ngStyle]="{ height: !defLoc.readOnly ? '88%' : '100%' }" [item]="itemData"
      (userChanges)="onChartsChanges($event)" (onInit)="setChartInstance($event)" [userConfig]="userConfig"
      [stopUpdate]="true"></app-dynamic-charts>
  </div>
</div>

<base-dialog-footer class="flex-p" #dialogFooter></base-dialog-footer>
