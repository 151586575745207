import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { UserManagementService } from "../../services/user-management.service";
import { WindowRef } from "../../services/WindowRef";
import { AppConstants } from "../../shared/app.constants";
import { LOGIN } from "../../shared/components/i18n/de-DE";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
//TODO: Konstruktor und Co könnten in Abstrakte Superklasse
export class ResetPasswordComponent implements OnInit {
  public userName = new UntypedFormControl(
    "",
    Validators.compose([Validators.required])
  );
  public settings: Settings;
  public LOGIN = LOGIN;
  public cloudImage = "./assets/img/logo_rhinecloud_iot.png";
  constructor(
    public appSettings: AppSettings,
    private uService: UserManagementService,
    private refWindow: WindowRef,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings.loadingSpinner = false;
    localStorage.removeItem(AppConstants.loraApiToken);
    sessionStorage.removeItem(AppConstants.loraApiToken);
    this.userName.valueChanges.subscribe((value) => {
      if (this.userName.hasError("userNameWrong")) {
        this.userName.setErrors(null);
      }
    });
  }

  onSubmit() {
    const url = `${this.refWindow.nativeWindow.location.href}`.replace(
      "resetpassword",
      "renewpassword"
    );
    this.uService.requestPasswordReset(this.userName.value, url).subscribe({
      next: (res) => {
        this.router.navigateByUrl("/login");
      },
      error: (err) => {
        if (err.error && err.error.apiError == 2 && err.error.status == 400) {
          this.userName.setErrors({ userNameWrong: true });
        }
      },
    });
    // hier wird die Api benachrichtigt
  }
}
