import { AfterViewInit, Component, Inject } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import * as monaco from "monaco-editor";
import { AppSettings } from "../../../app.settings";
import { IStreamProfiles } from "../../../model/datatstream";
import { ERequestMethod } from "../../../model/enum/datastream.enum";
import { DatastreamService } from "../../../services/datastream.service";
import { setMonacoGOTDefaults } from "../../../shared/abstract/completionItemProvider";
import { getUrlVars } from "../../../shared/abstract/utils";
import { AppConstants } from "../../../shared/app.constants";
import { BaseDialog } from "../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";
import { ValidatorMessageService } from "../../../shared/components/validator-message.service";

@Component({
  templateUrl: "./CreateDataStream.component.html",
  styleUrls: ["./CreateDataStream.component.scss"],
})
export class CreateDataStreamProfileComponent
  extends BaseDialog
  implements AfterViewInit
{
  ngAfterViewInit(): void {
    return;
  }
  public ERequestMethod = ERequestMethod;

  public bodyOptions = {
    theme: "vs-dark",
    language: "javascript",
    range: {
      startLineNumber: 10,
      startColumn: 1,
      endLineNumber: 1,
      endColumn: 1,
    },
  };
  public Turnus = AppConstants.Turnus;
  public showBody = false;
  public loading = false;
  public SOAP = false;
  public expertMode = false;
  public ApiProfile = false;
  public request = false;
  private bodyEditor!: monaco.editor.ICodeEditor;
  private soapHelp = `// Hilfe unter https://www.npmjs.com/package/soap
  // function muss ein Promise<object>|object zurück geben
  // alle Services ausgeben client.describe()
  //_value_ Gerätedaten, so wie sie von DataProfile kommen.
  async function soapRequest(client, _soap_,_value_){
    return client.describe();
  }
  `;
  private restHelp = `// Hilfe unter https://www.npmjs.com/package/got
  // function muss einen Object zurück geben.
  // Agent führt dann 'got(Adresse,{Ihre Konfiguration...})' aus.
  // oder'got({Ihre Configuration...})' wenn in der Konfiguration der Key 'url' vorhanden ist.
  /**
   * Create Options for got.
   * Bitte nur diese Funktion für Weiterleitung/Alarm benutzen.
   * @param {IModule} _module_ Hilfsmittel.
   * @param {IDeviceData} _value_ Gerätedaten. DeviceData befindet sich im "object" Feld. "_value_.object"
   * @returns {got.PlainOptions}
   */
  function restRequest(_module_,_value_) {
    return {
      method: "POST", // GET, PUT, PATCH,...
      json: _value_, // z.B
    };
  }

  /**
   * OPTIONAL nur wenn nötig benutzen
   *
   * Einen request für einen Token durchführen.
   * function muss einen Object zurück geben.
   * *** "url" Parameter muss gesetzt werden! ***
   * @param {IDeviceData} _value_ Gerätedaten. DeviceData befindet sich im "object" Feld. "_value_.object"
   * @returns {got.PlainOptions}
   */
  function tokenRequest(_value_){
    return {}
  }
  `;
  constructor(
    private fb: UntypedFormBuilder,
    private vService: ValidatorMessageService,
    private dialogRef: MatDialogRef<CreateDataStreamProfileComponent>,
    private dsService: DatastreamService,
    private appSettings: AppSettings,
    @Inject(MAT_DIALOG_DATA)
    private data: { profileData: IStreamProfiles; apiAlertProfile: boolean }
  ) {
    super(dialogRef);
  }
  buildForm() {
    return this.fb.group({
      name: ["", Validators.required],
      requestURL: [
        "https://",
        Validators.compose([
          Validators.required,
          Validators.pattern("https?://[\\w-]+\\.\\S[^?&]*"),
        ]),
      ],
      requestMethod: [ERequestMethod.GET, Validators.required],
      headers: this.fb.array([]),
      parameters: this.fb.array([]),
      requestBody: null,
      dataInterval: [
        null,
        this.data.apiAlertProfile ? null : Validators.required,
      ],
      responseType: "json",
      bodyType: "json",
      tenantID: null,
      apiType: "REST",
      expertMode: false,
      rejectUnauthorized: true,
    });
  }
  implementOnInit() {
    let title = "Profil erstellen";
    let button = "Erstellen";
    this.ApiProfile = this.data.apiAlertProfile;

    this.form.get("requestURL")?.valueChanges.subscribe({
      next: (val: string) => {
        if (val.includes("?") || val.includes("&")) {
          setTimeout(() => {
            this.form.get("requestURL")?.markAsTouched();
          });
        }
      },
    });
    this.form.get("requestMethod")?.valueChanges.subscribe({
      next: (value) => {
        this.showBody = value !== ERequestMethod.GET;
      },
    });
    this.form.get("apiType")?.valueChanges.subscribe({
      next: (val) => {
        this.SOAP = val == "SOAP";
        if (this.SOAP) {
          setTimeout(() => {
            if (this.bodyEditor) {
              const model = this.bodyEditor.getModel();
              monaco.editor.setModelLanguage(
                model as monaco.editor.ITextModel,
                "javascript"
              );
            }
            this.form.patchValue({
              requestBody: this.data.profileData
                ? this.data.profileData.requestBody.soap
                : this.soapHelp,
            });
          });
        } else if (this.expertMode) {
          if (this.data.profileData) {
            // console.log(this.data.profileData.requestBody);
            this.form.patchValue({
              requestBody:
                this.data.profileData.requestBody.javascript ||
                this.data.profileData.requestBody,
            });
          } else {
            this.form.patchValue({
              requestBody: this.restHelp,
            });
            if (this.bodyEditor) {
              const model = this.bodyEditor.getModel();
              monaco.editor.setModelLanguage(
                model as monaco.editor.ITextModel,
                "json"
              );
            }
          }
        }
      },
    });
    this.form.get("expertMode")?.valueChanges.subscribe({
      next: (value) => {
        this.showBody = false;
        if (value) {
          this.bodyOptions.language = "javascript";
          this.form.patchValue({
            requestBody: this.data.profileData
              ? this.data.profileData.requestBody.javascript
              : this.restHelp,
            bodyType: "javascript",
            parameters: [],
            headers: [],
          });
        } else {
          this.bodyOptions.language = "json";
          this.form.patchValue({ bodyType: "json", requestBody: null });
        }
        setTimeout(() => {
          this.expertMode = value;
          this.showBody =
            this.form.get("requestMethod")?.value !== ERequestMethod.GET;
        }, 100);
      },
    });
    if (this.data.profileData) {
      const temp = Object.assign({}, this.data.profileData);
      title = "Profil anpassen";
      button = "Anpassen";
      if (temp && temp.requestHeader) {
        Object.keys(temp.requestHeader).forEach((head) => {
          this.addHeader(head, temp.requestHeader![head]);
        });
      }
      if (temp.requestParameter) {
        Object.keys(temp.requestParameter).forEach((par) => {
          this.addParams(par, temp.requestParameter![par]);
        });
      } else {
        const urlParams = getUrlVars(this.data.profileData.requestURL);
        Object.keys(urlParams).forEach((par) => {
          this.addParams(par, urlParams[par]);
        });
        if (temp.requestURL.includes("?")) {
          temp.requestURL = temp.requestURL.substr(
            0,
            temp.requestURL.indexOf("?")
          );
        }
      }
      delete temp.requestHeader;
      delete temp.requestParameter;
      if (temp.requestBody) {
        if (temp.apiType == "SOAP") {
          temp.requestBody = temp.requestBody.soap;
        } else if (temp.requestBody.javascript) {
          temp.requestBody = temp.requestBody.javascript;
          (temp as any).expertMode = true;
        } else {
          temp.requestBody = JSON.stringify(temp.requestBody);
        }
      }
      this.form.patchValue(temp);
    }
    this.getBaseDialogHeader().setTitle(title);
    this.getBaseDialogFooter().getFooterButtons()[0].setLabel(button);
  }
  get Headers() {
    return this.form.get("headers") as UntypedFormArray;
  }
  get Params() {
    return this.form.get("parameters") as UntypedFormArray;
  }

  onPasteEvent(evt: ClipboardEvent) {
    evt.stopPropagation();
    const url = evt.clipboardData?.getData("text");
    const urlParams = getUrlVars(url || "");
    Object.keys(urlParams).forEach((par) => {
      this.addParams(par, urlParams[par] || "");
    });
    setTimeout(() => {
      if (url?.includes("?")) {
        this.form.get("requestURL")?.setValue(url.substr(0, url.indexOf("?")));
      }
    });
  }
  // ##########################
  initHeader(_key?: string, _val?: string) {
    const key = new UntypedFormControl(_key, Validators.required);
    const value = new UntypedFormControl(_val, Validators.required);
    return this.fb.group({ key, value });
  }
  addHeader(key?: string, val?: string) {
    const control = <UntypedFormArray>this.form.get("headers");
    control.push(this.initHeader(key, val));
  }
  removeHeader(i: number) {
    const control = <UntypedFormArray>this.form.get("headers");
    control.removeAt(i);
  }
  initParams(_key?: string, _val?: string) {
    const key = new UntypedFormControl(_key, Validators.required);
    const value = new UntypedFormControl(_val);
    return this.fb.group({ key, value });
  }
  addParams(key?: string, val?: string) {
    const control = <UntypedFormArray>this.form.get("parameters");
    control.push(this.initParams(key, val));
  }
  removeParams(i: number) {
    const control = <UntypedFormArray>this.form.get("parameters");
    control.removeAt(i);
  }
  onBodyInit(editor: monaco.editor.ICodeEditor) {
    this.bodyEditor = editor;
    if (this.SOAP || this.expertMode) {
      this.bodyOptions.language = "javascript";
      setMonacoGOTDefaults((window as any).monaco);
    } else {
      this.bodyOptions.language = "json";
    }
    setTimeout(() => {
      this.bodyEditor.trigger("any", "editor.action.formatDocument", "format");
    }, 200);
  }
  getErrorMessage(validator: string, params?: any) {
    return this.vService.getErrorMessage(validator, params);
  }
  // overrite default from BaseDialog.onKeyDown
  onKeyDown(event: KeyboardEvent, form: any) {}

  testProfile() {
    this.request = true;
    const result = this.form.value;
    const form: IStreamProfiles = {
      name: result.name,
      requestURL: result.requestURL,
      requestMethod: result.requestMethod,
      responseType: result.responseType,
      dataInterval: result.dataInterval,
      rejectUnauthorized: result.rejectUnauthorized,
      apiType: result.apiType,
    };
    let contentType = "application/json; charset=UTF-8";
    let headers: { [name: string]: string } =
      result.apiType == "REST"
        ? {
            "Content-Type": contentType,
          }
        : {};
    let parameters: { [name: string]: string } = {};
    result.headers.map((item: any) => {
      if (item.key !== "") {
        headers = { ...headers, ...{ [item.key]: item.value } };
      }
    });
    result.parameters.map((item: any) => {
      if (item.key !== "") {
        parameters = { ...parameters, ...{ [item.key]: item.value } };
      }
    });
    form.requestHeader = headers;
    form.requestParameter = parameters;
    if (result.requestBody !== null) {
      if (result.apiType == "SOAP") {
        form.requestBody = { soap: result.requestBody };
      } else if (result.expertMode) {
        form.requestBody = { javascript: result.requestBody };
      } else {
        form.requestBody = JSON.parse(JSON.stringify(result.requestBody));
      }
    }
    this.dsService.postRequest(form).subscribe({
      next: (data) => {
        // console.log(data);
        this.appSettings.getSwalInfo(JSON.stringify(data));
      },
      error: (err) => {
        this.loading = false;
        this.request = false;
        if (err.status === 200 && err.error && err.error.text) {
          this.appSettings.getSwalInfo(err.error.text);
        } else {
          this.appSettings.getSwalError(err.error?.message || err.message);
        }
      },
      complete: () => {
        this.request = false;
      },
    });
  }
  onMethodChange(evt: MatSelectChange) {
    if (evt.value === "post" || evt.value === "put") {
      this.bodyOptions.language = "json";
    } else {
      this.bodyOptions.language = "javascript";
    }
    this.form.patchValue({
      requestBody: null,
    });
  }
}
