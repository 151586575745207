import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatOptionSelectionChange } from "@angular/material/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { ITenants } from "../../../model/user";
import { AbstractService } from "../../../services/abstract.service";
import { MenuService } from "../menu/menu.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class SidenavComponent
  extends AbstractService
  implements OnInit, OnDestroy
{
  public cloudImage = "./assets/img/logo_rhinecloud_iot.png";
  public menuItems: Array<any> | undefined;
  public Username: string | undefined;
  // public Userrole: string;
  public lastLogin: number | undefined;
  public menuInstance: MenuService;
  public settings: Settings;
  public tenants: ITenants[] | undefined;
  public selectedTenant: string | undefined;
  private menuSubscription: Subscription | undefined | null;
  @Input() Permissions!: string | null;
  constructor(public appSettings: AppSettings, private router: Router) {
    super("");
    this.menuInstance = MenuService.menuInstance!;
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (!this.appSettings.currentUserName()) {
      return;
    }
    this.appSettings.getTenants().subscribe((result) => {
      if (result) {
        this.tenants = result.map((t) => {
          return { name: t.tenantName, tenantID: t.tenantID };
        });
        this.selectedTenant = this.appSettings.currentTenantID();
      }
    });
    this.Username = this.appSettings.currentUserName();
    const lastlogin = this.appSettings.getUserInfo().loginUser.lastLogin;
    // this.Userrole = this.Permissions.toUpperCase();
    if (lastlogin && lastlogin > 0) {
      this.lastLogin = this.appSettings.getUserInfo().loginUser.lastLogin;
    }

    this.menuSubscription = this.menuInstance
      .getVerticalMenu()
      .subscribe((mItems) => {
        this.menuItems = undefined;
        if (mItems && mItems.length > 0) {
          this.menuItems = Object.assign([], mItems);
        }
      });
    this.menuInstance.getUserUIConfig();
    this.appSettings.setTimeOut();
  }
  ngOnDestroy(): void {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }

  onTenantChanged(val: MatOptionSelectionChange) {
    if (val.isUserInput) {
      this.appSettings.setSettingChanged("tenant", val.source.value);
      this.menuInstance.getUserUIConfig(true);
      this.router.navigateByUrl("/");
    }
  }
}
