import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IDeviceProfile } from "../model/broker";
import { AbstractService } from "./abstract.service";

@Injectable({ providedIn: "root" })
export class BrokerService extends AbstractService {
  constructor(private http: HttpClient) {
    super("broker");
  }
  getDeviceProfile(name: string): Observable<IDeviceProfile> {
    const params = new HttpParams({
      fromObject: {
        name,
      },
    });
    return this.http.get<IDeviceProfile>(`${this.url}-device-profile`, {
      headers: this.headers,
      params,
    });
  }
  getDeviceProfilebyID(deviceProfileID: string): Observable<IDeviceProfile> {
    const params = new HttpParams({
      fromObject: {
        deviceProfileID,
      },
    });
    return this.http.get<IDeviceProfile>(`${this.url}-device-profile`, {
      headers: this.headers,
      params,
    });
  }
  createDeviceQueue(payload: string, devEUI: string, fPort?: number) {
    return this.http.post(
      `${this.url}-lora-devices/queue`,
      { devEUI, payload, fPort },
      { headers: this.headers }
    );
  }
  createNbiotDevice(form: any, update?: Boolean) {
    if (update) {
      return this.http.put(`${this.api}/nbiot/devices`, form, {
        headers: this.headers,
      });
    } else {
      return new Observable((s) => {
        s.next();
        s.complete();
      });
    }
  }
}
