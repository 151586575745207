<div class="flex-p">
  <mat-tab-group style="max-width: 800px;">
    <mat-tab label="Allgemein"> Allgemein </mat-tab>
    <mat-tab label="Sicherheit">
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
        <div fxFill fxLayout="row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
          <div class="flex-p"> Zwei-Faktor-Authentifizierung </div>
          <div class="flex-p" fxLayout="row">
            <mat-icon class="menu-icon">{{totp?'lock':'lock_open'}}</mat-icon>
            <span>{{totp?'Eingeschaltet':'Ausgeschaltet'}}</span>

          </div>
          <div class="flex-p" fxLayout="row" fxLayoutGap="8px">
            <button mat-mini-fab color="primary" matTooltip="Wiederhestellungstoken" (click)="showRecoveryCodes()">
              <mat-icon>menu</mat-icon>
            </button>
            <button mat-raised-button [color]="totp?'warn':'primary'" (click)="totp?disableTOTP():enableTOTP()">
              <mat-icon class="menu-icon">{{totp?'lock_open':'lock'}}</mat-icon>
              {{totp?'Ausschalten':'Einschalten'}}
            </button>


          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
