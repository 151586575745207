import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppConstants } from "../../../../app.constants";

@Component({
  selector: "app-diagramm-selector",
  styles: [``],
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          [formControlName]="controlName"
          placeholder="Diagramm-Type"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-option *ngFor="let item of chartTypes" [value]="item.value">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
})
export class DiagrammSelectorComponent implements OnInit {
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Output() selectionChange = new EventEmitter();
  public chartTypes = AppConstants.chartTypes;
  public loading = false;
  public search: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  constructor() {}

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        if (!value) {
          this.chartTypes = AppConstants.chartTypes;
          return;
        }
        this.chartTypes = AppConstants.chartTypes.filter(
          (x) => x.name.toLowerCase().indexOf(value) > -1
        );
      });
  }
  onChanges(evt: { name: string; value: string }): void {
    this.selectionChange.emit(evt);
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
