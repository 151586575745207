import {Injectable} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import {AppConstants} from "../shared/app.constants";
import {UserInfo} from "./abstract.service";

@Injectable({providedIn: "root"})
export class SuperAdminGuard implements CanActivateChild {
  constructor(private info: UserInfo, private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.info.TenantID.toLocaleLowerCase() ===
      AppConstants.superadmin
    ) {
      return true;
    }
    this.router.navigateByUrl("/");
    return false;
  }
}
