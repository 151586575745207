<div>
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="25" fxLayoutGap="10px">
      <ng-container [ngTemplateOutlet]="optionitem" *ngFor="let item of getKeys(decoderForm.value)"
        [ngTemplateOutletContext]="{
          value: decoderForm.value[item],
          name: item,
          formG: decoderForm
        }">
      </ng-container>
      <div fxLayout="row" fxLayoutGap="118px">
        <button mat-raised-button color="warn" (click)="abbrechen()">
          Abbrechen
        </button>
        <button mat-raised-button color="primary" (click)="onSaveClick()" [disabled]="decoderForm.invalid">
          {{ profileID ? "Übernehmen" : "Speichern" }}
        </button>
      </div>
    </div>
    <div fxLayout="column" fxFlex="80">
      <div fxLayout="row">
        <h3>Decoder JS</h3>
        <ngx-monaco-editor fxFlex="100%" class="dvv-code-editor h-min-600p" [options]="editorOptions"
          [(ngModel)]="decoder">
        </ngx-monaco-editor>
      </div>
      <div fxLayout="row">
        <h3>Encoder JS</h3>
        <ngx-monaco-editor fxFlex="100%" class="dvv-code-editor h-min-600p" [options]="editorOptions"
          [(ngModel)]="encoder">
        </ngx-monaco-editor>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <!-- <button mat-button matStepperPrevious>Zurück</button>
    <button mat-button (click)="abbrechen()">Abbrechen</button>
    <button mat-button (click)="onSaveClick()"><span *ngIf="!this.profileID">Erstellen</span><span
        *ngIf="this.profileID">Aktualisieren</span></button> -->
  </div>
</div>

<ng-template #optionitem let-FormG="formG" let-Name="name" let-Value="value">
  <div *ngIf="Name === 'name'" class="bold-text">
    <ng-container [ngTemplateOutlet]="_string" [ngTemplateOutletContext]="{ value: Value, name: Name, formG: FormG }">
    </ng-container>
  </div>
  <div *ngIf="Name === 'manufacturer'" class="bold-text">
    <ng-container [ngTemplateOutlet]="_string" [ngTemplateOutletContext]="{ value: Value, name: Name, formG: FormG }">
    </ng-container>
  </div>
  <div *ngIf="Name === 'deviceType'" class="bold-text">
    <ng-container [ngTemplateOutlet]="_string" [ngTemplateOutletContext]="{ value: Value, name: Name, formG: FormG }">
    </ng-container>
  </div>
  <div *ngIf="Name === 'dataType'" class="bold-text">
    <ng-container [ngTemplateOutlet]="_array" [ngTemplateOutletContext]="{ value: Value, name: Name, formG: FormG }">
    </ng-container>
  </div>
  <div *ngIf="Name === 'tags'" class="bold-text">
    <ng-container [ngTemplateOutlet]="_array" [ngTemplateOutletContext]="{ value: Value, name: Name, formG: FormG }">
    </ng-container>
  </div>
</ng-template>

<ng-template #_string let-FormG="formG" let-Name="name" let-Value="value">
  <div fxLayout="row" style="margin: 0 0 0 5px" [formGroup]="FormG" class="options-container">
    <div class="options-key">{{ Name }}</div>
    <div class="options-value" fxFlex="40">
      <input autocomplete="off" matInput [value]="Value" [formControlName]="Name" class="options-input"
        [ngClass]="{ 'option-error': isValid(Name) }">
    </div>
  </div>
</ng-template>

<ng-template #_boolean let-FormG="formG" let-Name="name" let-Value="value">
  <div fxLayout="row" style="margin: 0 0 0 5px" [formGroup]="FormG" class="options-container">
    <div class="options-key">{{ Name }}</div>
    <div class="options-value" fxFlex="40">
      <mat-checkbox [checked]="Value" [formControlName]="Name"> </mat-checkbox>
    </div>
  </div>
</ng-template>

<ng-template #_array let-FormG="formG" let-Name="name" let-Value="value">
  <div fxLayout="row" style="margin: 0 0 0 5px" [formGroup]="FormG" class="options-container">
    <div class="options-key">{{ Name }}</div>
    <div class="options-value" fxFlex="40">
      <mat-select [formControlName]="Name" multiple *ngIf="Name === 'tags'">
        <mat-option *ngFor="let val of tagsData" [value]="val.key">{{
          val.name
          }}</mat-option>
      </mat-select>
      <mat-select [formControlName]="Name" *ngIf="Name === 'dataType'">
        <mat-option *ngFor="let item of EDataType | enumToArray" [value]="item.index">{{ item.name }}</mat-option>
      </mat-select>
    </div>
  </div>
</ng-template>
