import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { verticalMenuItems } from "./menu";
import { Menu } from "./menu.model";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  static menuInstance: MenuService | null = null;
  private _verticalMenu: BehaviorSubject<Menu[]>;
  private dataStore: {
    mainMenu: Menu[];
  };
  private DashboardCounter = 0;

  constructor(private location: Location, private uiService: UIConfigService) {
    this._verticalMenu = new BehaviorSubject<Menu[]>([]);
    this.dataStore = { mainMenu: [] };
    return (MenuService.menuInstance = MenuService.menuInstance || this);
  }

  public getVerticalMenu(): Observable<Menu[]> {
    return this._verticalMenu.asObservable();
  }
  public getUserUIConfig(reload?: boolean) {
    if (reload) {
      this.dataStore = { mainMenu: [] };
    }
    if (this.dataStore.mainMenu.length <= 0) {
      forkJoin([this.uiService.getDashboards()]).subscribe((result) => {
        const dashboards = result[0];
        verticalMenuItems.forEach((item) => {
          if (item.permission === EPermissions.NONE) {
            this.dataStore.mainMenu.push(item);
          } else if (
            this.uiService.getUIPermissions(item.permission, ERole.READ)
          ) {
            this.dataStore.mainMenu.push(item);
          }
        });
        this.DashboardCounter = 1;
        if (dashboards && dashboards.length > 0) {
          dashboards.forEach((dashboard) => {
            this.dataStore.mainMenu.push(
              new Menu(
                10 + this.DashboardCounter,
                dashboard.name,
                "/dashboards/" + dashboard._id,
                null,
                "timeline",
                null,
                false,
                10,
                EPermissions.DASHBOARD
              )
            );
            this.DashboardCounter++;
            if (this.DashboardCounter > 398) {
              return;
            }
          });
          if (
            this.uiService.getUIPermissions(EPermissions.DASHBOARD, ERole.WRITE)
          ) {
            this.dataStore.mainMenu.push(
              new Menu(
                10 + this.DashboardCounter,
                "Dashboard erstellen",
                "/dashboards/new",
                null,
                "add",
                null,
                false,
                10,
                EPermissions.DASHBOARD
              )
            );
            this.DashboardCounter++;
          }
        } else {
          if (
            this.uiService.getUIPermissions(EPermissions.DASHBOARD, ERole.WRITE)
          ) {
            this.dataStore.mainMenu.push(
              new Menu(
                10 + this.DashboardCounter,
                "Dashboard erstellen",
                "/dashboards/new",
                null,
                "add",
                null,
                false,
                10,
                EPermissions.DASHBOARD
              )
            );
            this.DashboardCounter++;
          }
        }
        this._verticalMenu.next(Object.assign({}, this.dataStore).mainMenu);
      });
    }
  }

  public createDashboardMenuItem(title: string, id: string) {
    if (this.DashboardCounter > 398) {
      return;
    }

    const newDashboard = this.dataStore.mainMenu.pop();
    this.dataStore.mainMenu.push(
      new Menu(
        10 + this.DashboardCounter,
        title,
        "/dashboards/" + id,
        null,
        "timeline",
        null,
        false,
        10,
        EPermissions.DASHBOARD
      )
    );
    if (newDashboard) {
      this.dataStore.mainMenu.push(newDashboard);
    }
    this.DashboardCounter++;
    this._verticalMenu.next(Object.assign({}, this.dataStore).mainMenu);
  }
  public changeDashboardMenuItem(title: string, id: string) {
    this.dataStore.mainMenu.forEach((item) => {
      if (item.routerLink.includes(id)) {
        item.title = title;
      }
    });
    this._verticalMenu.next(Object.assign({}, this.dataStore).mainMenu);
  }
  public deleteDashboardMenuItem(id: string) {
    this.dataStore.mainMenu = this.dataStore.mainMenu.filter(
      (x) => !x.routerLink.includes(id)
    );
    this._verticalMenu.next(Object.assign({}, this.dataStore).mainMenu);
  }
  public expandActiveSubMenu() {
    const routerLink = this.location.path(); // url.substring(1, url.length);
    const activeMenuItem = this.dataStore.mainMenu.filter(
      (item) => item.routerLink === routerLink
    );
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId !== 0) {
        menuItem = this.dataStore.mainMenu.filter(
          (item) => item.id === menuItem.parentId
        )[0];
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId: number) {
    const menuItem = document.getElementById("menu-item-" + menuId);
    const subMenu = document.getElementById("sub-menu-" + menuId);
    if (subMenu && menuItem) {
      if (subMenu.classList.contains("show")) {
        subMenu.classList.remove("show");
        menuItem.classList.remove("expanded");
      } else {
        subMenu.classList.add("show");
        menuItem.classList.add("expanded");
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId: number) {
    const currentMenuItem = menu.filter((item) => item.id === menuId)[0];
    if (currentMenuItem.parentId === 0 && !currentMenuItem.target) {
      // TODO: Duplizierung auflösen.
      menu.forEach((item) => {
        if (item.id !== menuId) {
          const subMenu = document.getElementById("sub-menu-" + item.id);
          const menuItem = document.getElementById("menu-item-" + item.id);
          if (subMenu && menuItem) {
            if (subMenu.classList.contains("show")) {
              subMenu.classList.remove("show");
              menuItem.classList.remove("expanded");
            }
          }
        }
      });
    } else if (currentMenuItem.parentId === 400) {
      const subMenuItems = menu.filter((item) => item.parentId === 400);
      subMenuItems.forEach((item) => {
        if (item.id !== menuId) {
          const subMenu = document.getElementById("sub-menu-" + item.id);
          const menuItem = document.getElementById("menu-item-" + item.id);
          if (subMenu && menuItem) {
            if (subMenu.classList.contains("show")) {
              subMenu.classList.remove("show");
              menuItem.classList.remove("expanded");
            }
          }
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.dataStore.mainMenu = [];
    MenuService.menuInstance = null;
  }
}
