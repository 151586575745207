import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { IEmailTemplate } from "src/app/model/email-template";
import { TemplateService } from "src/app/services/template.service";
import Swal from "sweetalert2";
import { AppSettings } from "../../../../app.settings";
import { IApiRestProfile } from "../../../../model/apiRestProfile";
import { IDevice } from "../../../../model/devices";
import { EDataType } from "../../../../model/enum/dataType";
import {
  EActionType,
  ESubscriptionType,
} from "../../../../model/enum/subscriptions";
import { ISubscription } from "../../../../model/subscriptions";
import { LoraDeviceService } from "../../../../services/lora-devices";
import { isUndefined } from "../../../../shared/abstract/utils";
import { BaseDialog } from "../../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";
import { RestAlertProfilesService } from "../../rest-alert-profiles.service";

@Component({
  templateUrl: "./create-alert.component.html",
  styleUrls: ["./create-alert.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAlertComponent extends BaseDialog implements AfterViewInit {
  public EDataType = EDataType;
  public devices?: IDevice[];
  public _devices_?: IDevice[];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public EActionType = EActionType;
  public dataKeys?: string[];
  public _form: UntypedFormGroup;
  public loading = false;
  public showHelp = false;
  public tenantID: string;
  public _deviceDataProfile: string | undefined;
  public emailTemplates: IEmailTemplate[] | undefined;
  public compFunction = [
    { name: " >= ", key: "gte", tip: "größer oder gleich" },
    { name: " > ", key: "gt", tip: "größer" },
    { name: " < ", key: "lt", tip: "kleiner" },
    { name: " <= ", key: "lte", tip: "kleiner oder gleich" },
    { name: " == ", key: "eq", tip: "ist gleich" },
    { name: " != ", key: "ne", tip: "ist ungleich" },
    { name: "regex", key: "regex", tip: "Text durchsuchen" },
  ];
  public gatter = [
    { name: "UND", key: "AND" },
    { name: "ODER", key: "OR" },
  ];
  public actionType = [
    { name: "Email senden", key: EActionType.SEND_EMAIL },
    { name: "REST API", key: EActionType.REST_API },
  ];
  public restApiProfile?: Observable<IApiRestProfile[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public DATA: { alert: ISubscription; devices: IDevice[] },
    private dialogRef: MatDialogRef<CreateAlertComponent>,
    private ldService: LoraDeviceService,
    private fb: UntypedFormBuilder,
    public cdr: ChangeDetectorRef,
    private rService: RestAlertProfilesService,
    private appSettings: AppSettings,
    private tService: TemplateService
  ) {
    super(dialogRef);
    this._form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      resourceType: ESubscriptionType.DeviceDataAlert,
      dataType: [null, Validators.compose([Validators.required])],
      deviceDataProfile: [null, Validators.compose([Validators.required])],
      devices: [null, Validators.compose([Validators.required])],
      resourceFilter: this.fb.array([]),
      actions: this.fb.array([]),
    });
    this.tenantID = this.appSettings.currentTenantID();
  }

  buildForm() {
    return this._form;
  }
  implementOnInit(): void {
    this.restApiProfile = this.rService.GetRestAlertProfiles(
      undefined,
      "name"
    ) as Observable<IApiRestProfile[]>;
  }
  ngAfterViewInit(): void {
    this.initActions();
    setTimeout(() => {
      this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Abbrechen");
      if (this.DATA.alert && this.DATA.alert.devices) {
        this._devices_ = this.DATA.alert.devices;
        this.onDeviceSelected(this.DATA.alert.devices);
        this._form.patchValue(
          {
            name: this.DATA.alert.name,
            dataType: this.DATA.alert.devices[0].dataType,
            deviceDataProfile:
              this.DATA.alert.devices[0].deviceDataProfileID || "",
            devices: this.DATA.alert.devices,
          },
          { emitEvent: true }
        );
        if (!this.DATA.alert.devices[0].deviceDataProfileID) {
          this.form.get("deviceDataProfile")?.setValidators(null);
        } else {
          this.form
            .get("deviceDataProfile")
            ?.setValidators(Validators.required);
        }

        // this.getBaseDialogHeader().setTitle("Alarm ändern");
        this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Ändern");
      } else {
        // this.getBaseDialogHeader().setTitle("Alarm erstellen");
        this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Erstellen");
        if (this.DATA.devices && this.DATA.devices.length > 0) {
          this._devices_ = this.DATA.devices;

          let deviceDataProfile = "";
          if (this.DATA.devices[0].deviceDataProfileID) {
            this._deviceDataProfile = this.DATA.devices[0].deviceDataProfileID;
            deviceDataProfile = this.DATA.devices[0].deviceDataProfileID;
          } else if (this.DATA.devices[0].dataStreamProfileID) {
            deviceDataProfile = this.DATA.devices[0].dataStreamProfileID;
          }
          this._form.patchValue({
            name: "Alarm für " + this.DATA.devices[0].deviceName,
            dataType: this.DATA.devices[0].dataType,
            devices: this.DATA.devices,
            deviceDataProfile,
          });
          this.onDeviceSelected(this.DATA.devices);
        }
      }
      this.cdr.detectChanges();
    });
  }
  getRestApiProfile() {}
  get resourceFilter() {
    return this._form.get("resourceFilter") as UntypedFormArray;
  }
  get Actions() {
    return this._form.get("actions") as UntypedFormArray;
  }
  initResourceFilter(alert?: ISubscription) {
    const values = alert?.resourceFilter?.values || [
      {
        key: "",
        func: "",
        value: "",
        g: "OR",
      },
    ];
    values.forEach((item) => {
      const key = new UntypedFormControl(item.key, Validators.required);
      this.keyChanged(item.key);
      const func = new UntypedFormControl(item.func, Validators.required);
      const value = new UntypedFormControl(item.value, Validators.required);
      const g = new UntypedFormControl(item.g, Validators.required);
      this.addResourceFilter(this.fb.group({ key, func, value, g }));
    });
  }
  addResourceFilter(gr?: UntypedFormGroup) {
    const control = <UntypedFormArray>this.resourceFilter;
    if (isUndefined(gr)) {
      this.initResourceFilter();
    } else {
      control.push(gr);
    }
  }
  keyChanged(value: string, i = 0) {
    if (value.includes("createdAt")) {
      this.showHelp = true;
    } else {
      this.showHelp = false;
    }
    if (value.includes("Eingeben")) {
      Swal.fire({
        title: "Datenpunkt eingeben",
        showCloseButton: true,
        confirmButtonText: "Übernehmen",
        cancelButtonText: "Abbrechen",
        showCancelButton: true,
        html: `
        <div style="display:flex;flex-direction: column;width: 330px;padding:10px 0 0 135px">
          <input id="key" placeholder="Datenpunkt*" type="text"  class="swal2-input" value="">
        </div>`,
        preConfirm: () => {
          const values = {
            key: (document.getElementById("key") as any).value,
          };
          return values;
        },
      }).then((result) => {
        if (result.isConfirmed && this.dataKeys) {
          this.dataKeys.push(result.value?.key);
        } else {
          const control = <UntypedFormArray>this.resourceFilter;
          if (i >= 0) {
            control.at(i).get("key")?.reset();
          }
        }
      });
    }
  }
  initActions() {
    if (this.DATA.alert) {
      this.DATA.alert.resourceConfig.actions.forEach((item) => {
        const actionType = new UntypedFormControl(item.actionType);
        let adr, num, api, temp;

        if (item.actionType === EActionType.SEND_EMAIL) {
          adr = item.actionConfig.address;
          temp = item.actionConfig.template || "";
        } else if (item.actionType === EActionType.SEND_SMS) {
          num = item.actionConfig.number;
        } else if (item.actionType === EActionType.REST_API) {
          api = item.actionConfig.rest;
        }
        const address = new UntypedFormControl(
          adr,
          adr ? Validators.required : null
        );
        const template = new UntypedFormControl(temp, null);
        const number = new UntypedFormControl(
          num,
          num ? Validators.required : null
        );
        const rest = new UntypedFormControl(
          api,
          api ? Validators.required : null
        );
        this.addAction(
          this.fb.group({ actionType, address, number, rest, template })
        );
      });
    } else {
      this.addAction();
    }
  }
  addAction(gr?: UntypedFormGroup) {
    const control = <UntypedFormArray>this.Actions;
    if (isUndefined(gr)) {
      const actionType = new UntypedFormControl("");
      const address = new UntypedFormControl("");
      const number = new UntypedFormControl("");
      const rest = new UntypedFormControl("");
      const template = new UntypedFormControl("");
      control.push(
        this.fb.group({ actionType, address, number, rest, template })
      );
    } else {
      control.push(gr);
    }
  }
  removeAction(i: number) {
    const control = <UntypedFormArray>this.Actions;
    control.removeAt(i);
  }

  removeResourceFilter(i: number) {
    const control = <UntypedFormArray>this.resourceFilter;
    control.removeAt(i);
  }
  removeAllResourceFilter() {
    const control = <UntypedFormArray>this.resourceFilter;
    control.clear();
  }
  onDeviceTypeChange(type: any) {
    if (type < 3) {
      this._form.addControl(
        "deviceDataProfile",
        new UntypedFormControl(null, Validators.compose([Validators.required]))
      );
      this._form.patchValue({
        deviceDataProfile: null,
        devices: null,
      });
    } else {
      this._form.removeControl("deviceDataProfile", { emitEvent: false });
    }
  }
  onProfileChange(prof: any) {
    this._form.patchValue({
      devices: null,
    });
  }
  onDeviceSelected(device: IDevice[]) {
    this.loading = true;
    this.ldService.getDeviceKeys(device).subscribe({
      next: (res) => {
        this.dataKeys = res;
        this.loading = false;
        this.cdr.detectChanges();
        this.removeAllResourceFilter();
        this.initResourceFilter(this.DATA.alert);
      },
    });
  }
  onActionChange(i: number, val: any) {
    let con = "address";
    let old = "rest";
    if (val == EActionType.REST_API) {
      con = "rest";
      old = "address";
    }
    const tmp = this.Actions.controls[i].get(old);
    if (tmp) {
      tmp.reset();
    }
    this.Actions.controls[i].get("actionType")?.setValue(val);
    this.cdr.detectChanges();
    this.Actions.controls[i].get(con)?.setValidators(null);
    this.Actions.controls[i].get("actionType")?.updateValueAndValidity();
  }
  templateSelected(val: string, index: number) {
    this.Actions.controls[index].get("template")?.setValue(val);
  }
}
