import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MessagesService } from "./messages.service";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MessagesService],
})
export class MessagesComponent {
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  public selectedTab: number = 1;
  public messages: Array<any>;
  public files: Array<any>;
  public meetings: Array<any>;
  constructor(private messagesService: MessagesService) {
    this.messages = messagesService.getMessages();
    this.files = messagesService.getFiles();
    this.meetings = messagesService.getMeetings();
  }

  openMessagesMenu() {
    this.trigger.openMenu();
    this.selectedTab = 0;
  }

  onMouseLeave() {
    this.trigger.closeMenu();
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
}
