import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { SharedUIModule } from "../../shared/components/sharedui/sharedui.module";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { DeviceInfoComponent } from "../device-data/device-info.component";
import { DynamicChartsModule } from "../dynamicboard/dvv-charts/dynamicCharts.module";
import { ChangeDeviceDataComponent } from "./change-device-data/change-device-data.component";
import { CreateDeviceDialogComponent } from "./create-device-dialog/create-device-dialog.component";
import { CreateDeviceQueueComponent } from "./create-device-queue/create-device-queue.component";
import { DeviceComponent } from "./device.component";
import { LoraDeviceProfileComponent } from "./lora-device-profile/lora-device-profile.component";
import { LoraMulticastGroupComponent } from "./lora-multicast-group/lora-multicast-group.component";
import { LoraServiceProfilesComponent } from "./lora-service-profiles/lora-service-profiles.component";
export const routes: Routes = [
  {
    path: "devices",
    component: DeviceComponent,

    data: { breadcrumb: "Geräte", dataType: "lora" },
  },
  {
    path: "devices/public",
    component: DeviceComponent,

    data: { breadcrumb: "Public", isPublic: true },
  },
  {
    path: "devices/:tenantID",
    component: DeviceComponent,

    data: { breadcrumb: "Geräte", dataType: "lora" },
  },

  {
    path: "info/:id/:tenantID",
    component: DeviceInfoComponent,
    data: { isPublic: true, dataType: "lora" },
  },
  {
    path: "public/:id/:tenantID",
    component: DeviceInfoComponent,
    data: { isPublic: true, dataType: "lora" },
  },
  {
    path: "devicedataprofiles",
    loadChildren: () =>
      import("./devicedataprofile/devicedataprofile.module").then(
        (m) => m.DeviceDataProfileModule
      ),
    data: { breadcrumb: "Daten-Profile" },
  },
  {
    path: "service-profiles",
    component: LoraServiceProfilesComponent,
    data: { breadcrumb: "Serviceprofil" },
  },
  {
    path: "device-profiles",
    component: LoraDeviceProfileComponent,
    data: { breadcrumb: "Geräteprofil" },
  },
  {
    path: "multicast",
    component: LoraMulticastGroupComponent,
    data: { breadcrumb: "Multicast Gruppe" },
  },
];
@NgModule({
  declarations: [
    DeviceComponent,
    CreateDeviceDialogComponent,
    ChangeDeviceDataComponent,
    DeviceInfoComponent,
    CreateDeviceQueueComponent,
    LoraServiceProfilesComponent,
    LoraDeviceProfileComponent,
    LoraMulticastGroupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    SharedUIModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
    DynamicChartsModule,
  ],
  // entryComponents: [
  //   CreateDeviceDialogComponent,
  //   ChangeDeviceDataComponent,
  //   CreateNbiotdevDialogComponent,
  // ],
})
export class DevicesModule {}
