// export interface ILogin {
//   loginUser: ILoginUser;
//   tenant: IUTenant;
//   token: string;
// }

// interface IUTenant {
//   tenantID: string;
//   tenantName: string;
//   superadmin: boolean;
// }

// interface ILoginUser {
//   username: string;
//   tenantID: string;
//   groups: string[];
//   permissions: EPermissionType;
// }

export enum EPermissionType {
  Viewer = "viewer",
  User = "user",
  Admin = "admin",
  Api = "api",
}

export interface ILogin {
  exp: number;
  iat: number;
  loginUser: ILoginUser;
  tenant: ITenant;
  token: string;
  tenants: {
    tenantID: string;
    permissions: string;
  }[];
}

interface ITenant {
  tenantID: string;
  createdAt: number;
  updatedAt: number;
  deleted: boolean;
  tenantName: string;
  superadmin: boolean;
}

interface ILoginUser {
  username: string;
  tenantID: string;
  groups: string[];
  uiconfig: IUiconfig;
  permissions: string;
  failedLogins?: number;
  lastLogin?: number;
  totpValidated?: string;
}

interface IUiconfig {
  _id: string;
  tenantID: string;
  configType: string;
  template: boolean;
  permissions: string[];
}
