import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedUIModule} from "../../shared/components/sharedui/sharedui.module";
import {SharedModule} from "../../shared/shared.module";
import {PipesModule} from "../../theme/pipes/pipes.module";
import {CreateUserDialogComponent} from "./create-user-dialog/create-user-dialog.component";
import {UserManagementComponent} from "./user-management/user-management.component";

@NgModule({
  declarations: [
    UserManagementComponent,
    CreateUserDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUIModule,
  ],
  providers: [Clipboard]
  // entryComponents: [CreateUserDialogComponent, ChangePasswordComponent],
})
export class UsersModule { }
