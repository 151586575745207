import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { AppSettings } from "../../../app.settings";
import {
  MIDeviceProfile,
  MIDeviceProfiles,
} from "../../../model/loraDeviceProfile";
import { IPagination } from "../../../model/pagination";
import { LoraDeviceProfilesService } from "../../../services/lora-device-profiles.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { debounce } from "../../../shared/abstract/utils";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  templateUrl: "./lora-device-profile.component.html",
  styleUrls: ["./lora-device-profile.component.scss"],
  animations: [blockTransition],
})
export class LoraDeviceProfileComponent implements OnInit {
  public loading = false;
  public EPermissions = EPermissions;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<MIDeviceProfiles>;
  public displayedColumns = [
    "_id",
    "name",
    "description",
    "macVersion",
    "class",
    "createdAt",
    "action",
  ];
  private search?: string;
  private searchHandler: any;
  private controlls = [
    {
      id: "rxDelay1",
      type: "number",
      desc: "Class A RX1 delay (mandatory for ABP)",
    },
    {
      id: "rxDrOffset1",
      type: "number",
      desc: "RX1 data rate offset (mandatory for ABP)",
    },
    {
      id: "rxDatarate2",
      type: "number",
      desc: "RX2 data rate (mandatory for ABP)",
    },
    {
      id: "rxFreq2",
      type: "number",
      desc: "RX2 channel frequency (mandatory for ABP)",
    },
    {
      id: "maxEirp",
      type: "number",
      desc: "Maximum EIRP supported by the End-Device",
    },
    {
      id: "maxDutyCycle",
      type: "number",
      desc: "Maximum duty cycle supported by the End-Device",
    },
    {
      id: "classBTimeout",
      type: "number",
      desc: "Maximum delay for the End-Device to answer a MAC request or a confirmed DL frame (mandatory if class B mode supported)",
    },
    {
      id: "classCTimeout",
      type: "number",
      desc: "Maximum delay for the End-Device to answer a MAC request or a confirmed DL frame (mandatory if class C mode supported)",
    },
    {
      id: "pingSlotDr",
      type: "number",
      desc: "Mandatory if class B mode supported",
    },
    {
      id: "pingSlotFreq",
      type: "number",
      desc: "Mandatory if class B mode supported",
    },
  ];
  constructor(
    public uiService: UIConfigService,
    private ldpService: LoraDeviceProfilesService,
    private appSettings: AppSettings
  ) {
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.getProfiles();
    }, 500);
  }

  ngOnInit(): void {
    this.getProfiles();
  }
  getProfiles() {
    this.loading = true;
    this.ldpService
      .getLoraDeviceProfiles(undefined, this.pageEvent, this.search)
      .subscribe({
        next: (res) => {
          res = res as IPagination<MIDeviceProfiles>;
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
          } else if (this.data && this.data.data.length <= 1) {
            this.data.data = [];
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
        complete: () => (this.loading = false),
      });
  }
  getClass(el: MIDeviceProfile) {
    if (el.supportsClassB) {
      return "CLASS B";
    } else if (el.supportsClassC) {
      return "CLASS C";
    } else {
      return "CLASS A";
    }
  }
  createServiceProfile() {
    this.createProfileDialog();
  }
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.getProfiles();
  }
  delLoraDeviceProfile(id: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Deviceprofil löschen?",
        "Möchten Sie wirklich den Deviceprofil entfernen?"
      )
      .then((sw) => {
        if (sw.isConfirmed) {
          this.ldpService.delLoraDeviceProfile(id).subscribe({
            next: (res) => {
              if (res.DeletedCount > 0) {
                this.appSettings.getSwalSuccess("Erfolgreich entfernt");
                this.getProfiles();
              } else {
                this.appSettings.getSwalWarn("Es wurde nichts entfernt");
              }
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
  async createProfileDialog(prof?: any) {
    let dProf: any = { deviceProfile: {} };
    if (prof) {
      dProf = { ...prof };
    }
    let ready = false;
    const Queue = Swal.mixin({
      confirmButtonText: prof ? "Anpassen" : "Erstellen",
      showCloseButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: "Erweitert",
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
      customClass: this.appSettings.currentTheme(),
    });
    const getValues = (): any => {
      const values = {
        deviceProfile: {
          supportsClassB: (document.getElementById("supportsClassB") as any)
            .checked,
          supportsClassC: (document.getElementById("supportsClassC") as any)
            .checked,
          macVersion: (document.getElementById("macVersion") as any).value,
          supportsJoin: (document.getElementById("supportsJoin") as any)
            .checked,
          supports32BitFCnt: (
            document.getElementById("supports32BitFCnt") as any
          ).checked,
        },
        name: (document.getElementById("name") as any).value,
        description: (document.getElementById("description") as any).value,
      };
      if (values.name - length <= 1) {
        Swal.showValidationMessage(`Bitte einen Namen eingeben`);
        return false;
      }
      return values;
    };
    const first = await Queue.fire({
      title: "Geräte Typ bestimmen",
      // optional class to show fade-in backdrop animation which was disabled in Queue mixin
      showClass: { backdrop: "swal2-noanimation" },
      html: `
      <div style="display:flex;flex-direction: column;width: 330px;padding:10px 0 0 135px">
          <input id="name" placeholder="Bezeichnung*" type="text"  class="swal2-input" value="${
            dProf.name ?? ""
          }">
          <input id="description" placeholder="Beschreibung" type="text"  class="swal2-input" value="${
            dProf.description ?? ""
          }">
      </div>
      <select  id="macVersion" class="swal2-select">
        <option value="1.0.1" ${
          dProf.deviceProfile.macVersion == "1.0.1" ? "selected" : ""
        }>Version 1.0.1</option>
        <option value="1.0.2" ${
          dProf.deviceProfile.macVersion == "1.0.2" ? "selected" : ""
        }>Version 1.0.2 </option>
        <option value="1.0.3" ${
          dProf.deviceProfile.macVersion == "1.0.3"
            ? "selected"
            : prof
            ? ""
            : "selected"
        }>Version 1.0.3</option>
        <option value="1.0.4" ${
          dProf.deviceProfile.macVersion == "1.0.4" ? "selected" : ""
        }>Version 1.0.4 </option>
        <option value="1.1" ${
          dProf.deviceProfile.macVersion == "1.1" ? "selected" : ""
        }>Version 1.1</option>
      </select>
     <div class="checkbox-container">
        <input type="checkbox" id="supportsJoin" class="checkbox-item" ${
          dProf.deviceProfile.supportsJoin ? "checked" : prof ? "" : "checked"
        }>
        <label for="supportsJoin" class="checkbox-item">OTAA Join</label>
        <input type="checkbox" id="supportsClassB" class="checkbox-item" ${
          dProf.deviceProfile.supportsClassB ? "checked" : ""
        }>
        <label for="supportsClassB" class="checkbox-item">Unterstützt Class B</label>

        <input type="checkbox" id="supportsClassC" class="checkbox-item" ${
          dProf.deviceProfile.supportsClassC ? "checked" : ""
        }>
        <label for="supportsClassC" class="checkbox-item">Unterstützt Class C</label>
        <input type="checkbox" id="supports32BitFCnt" class="checkbox-item" ${
          dProf.deviceProfile.supports32BitFCnt ? "checked" : ""
        }>
        <label for="supports32BitFCnt" class="checkbox-item">Unterstützt 32Bit FCnt</label>
      </div>
      `,
      preConfirm: getValues,
      preDeny: getValues,
    });
    if (first.value) {
      dProf.name = first.value.name;
      dProf.description = first.value.description;
      dProf.deviceProfile = {
        ...dProf.deviceProfile,
        ...first.value.deviceProfile,
      };
    }

    if (first.isDenied) {
      const second = await Queue.fire({
        title: "Erweitert",
        // optional class to show fade-in backdrop animation which was disabled in Queue mixin
        showClass: { backdrop: "swal2-noanimation" },
        showDenyButton: false,
        html: `

        ${this.appSettings.getSwalFormControl(
          this.controlls,
          dProf.deviceProfile
        )}
        <div class="sweet-container">
          <label for="factoryPresetFreqs" class="swal2-label">factoryPresetFreqs</label>
          <input id="factoryPresetFreqs" placeholder="komma getrennt" type="text" title="List of factory-preset frequencies (mandatory for ABP)" class="swal2-input" value="${
            dProf.deviceProfile["factoryPresetFreqs"] ?? ""
          }">
          <label for="regParamsRevision" class="swal2-label">regParamsRevision</label>
          <input id="regParamsRevision"  type="text" class="swal2-input" title="Revision of the Regional Parameters document supported by the End-Device" value="${
            dProf.deviceProfile["regParamsRevision"] ?? "A"
          }">
        </div>
        `,
        preConfirm: () => {
          const data: any = {};
          this.controlls.forEach((e) => {
            if (e.type === "number") {
              data[e.id] = parseInt(
                (document.getElementById(e.id) as any).value
              );
            } else if (e.type === "checkbox") {
              data[e.id] = (document.getElementById(e.id) as any).checked;
            } else if (e.type === "select") {
              data[e.id] = (document.getElementById(e.id) as any).value;
            }
          });
          data["regParamsRevision"] = (
            document.getElementById("regParamsRevision") as any
          ).value;
          const preset = (document.getElementById("factoryPresetFreqs") as any)
            .value as string;
          if (preset.length > 0) {
            data["factoryPresetFreqs"] = preset
              .split(",")
              .map((p) => parseInt(p));
          }
          return data;
        },
      });
      if (second.isConfirmed) {
        dProf.deviceProfile = { ...dProf.deviceProfile, ...second.value };
        ready = true;
      }
    } else if (first.isConfirmed) {
      dProf.deviceProfile["regParamsRevision"] = "A";
      ready = true;
    }
    if (ready) {
      dProf["tenantID"] = this.appSettings.currentTenantID();
      dProf["rfRegion"] = "EU868";
      if (!dProf.deviceProfile["rxFreq2"]) {
        dProf.deviceProfile["rxFreq2"] = 869525000;
      }
      this.ldpService.addLoraDeviceProfile(dProf).subscribe({
        next: (res) => {
          if (res.insertedIDs && res.insertedIDs.length > 0) {
            this.appSettings.getSwalSuccess(
              "Erfolgreich " + (prof ? "angepasst" : "erstellt")
            );
            this.getProfiles();
          } else {
            this.appSettings.getSwalWarn("Es ist ein Fehler aufgetretten.");
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
    }
  }
}
