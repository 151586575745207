import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import {
  IApiRestProfile,
  IApiRestProfilePaginat,
} from "../../model/apiRestProfile";
import { AbstractService, UserInfo } from "../../services/abstract.service";

@Injectable({
  providedIn: "root",
})
export class RestAlertProfilesService extends AbstractService {
  constructor(private http: HttpClient, private info: UserInfo) {
    super("subscriptions/rest-profiles");
  }
  GetRestAlertProfiles(pagination?: PageEvent, fields?: string) {
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tenantID: this.info.TenantID,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (fields) {
      params = params.append("fields", fields);
    }
    return this.http.get<IApiRestProfilePaginat | IApiRestProfile[]>(
      `${this.url}`,
      { headers, params }
    );
  }
  CreateRestAlertProfile(form: IApiRestProfile) {
    let params = new HttpParams({
      fromObject: {
        tenantID: this.info.TenantID,
      },
    });
    return this.http.post(this.url, form, { params });
  }
  UpdateAlertProfile(form: IApiRestProfile) {
    let params = new HttpParams({
      fromObject: {
        tenantID: this.info.TenantID,
      },
    });
    return this.http.put(this.url, form, { params });
  }
  DeleteRestAlertProfile(_id: string) {
    let params = new HttpParams({
      fromObject: {
        tenantID: this.info.TenantID,
      },
    });
    return this.http.delete(`${this.url}/${_id}`, { params });
  }
}
