<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="openDeviceDialog()"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES, undefined, publicdata)&&!publicdata">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab color="primary" (click)="loadDevices()" matTooltip="Aktualisieren">
      <mat-icon>autorenew</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" #search />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter('');search.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div style="display: flex;width: 100%;flex-direction: row-reverse;gap: 10px;">

      <button mat-mini-fab color="primary" (click)="downloadExample()"
        matTooltip="Beispieldatei für Geräteimport herunterladen"
        *ngIf="uiService.getUIPermissions(EPermissions.DEVICES, undefined, publicdata)&&!publicdata">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="handleFileInput()" matTooltip="Geräte importieren"
        *ngIf="uiService.getUIPermissions(EPermissions.DEVICES, undefined, publicdata)&&!publicdata">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
  <div fxLayout="row" @blockTransition>
    <div *ngIf="data?.data" @blockTransition fxFlex="100%">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" [pageIndex]="pageEvent.pageIndex" class="block">
      </mat-paginator>
      <table mat-table *ngIf="data" [dataSource]="data" matSort class="w-100 block" aria-describedby="Geräte"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="devEUI">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            DeviceEUI
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.devEUI }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deviceName">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Beschreibung
          </th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="deviceDataProfileID">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Datenprofile Id
          </th>
          <td mat-cell *matCellDef="let element" class="text-muted">{{ element.deviceDataProfileID }}</td>
        </ng-container> -->
        <ng-container matColumnDef="deviceProfileID">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Geräteprofile Id
          </th>
          <td mat-cell *matCellDef="let element" class="text-muted">{{ element.deviceProfileID }}</td>
        </ng-container>
        <ng-container matColumnDef="lastSeenAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Zuletzt gesehen
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.lastSeenAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Erstellt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.createdAt | localizedDate: "dd.MM.yyyy HH:mm:ss"}}
          </td>
        </ng-container>

        <ng-container matColumnDef="defaultLocation">
          <th mat-header-cell matRipple mat-sort-header *matHeaderCellDef i18n scope="col">
            Ort
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.defaultLocation?.latitude">
              <button mat-icon-button color="primary" (click)="setLocation(element); $event.stopPropagation()">
                <mat-icon>map</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell matRipple *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="devMenu" *ngIf="
                uiService.getUIPermissions(
                  EPermissions.DEVICES,
                  undefined,
                  publicdata
                )
              " (click)="$event.stopPropagation()">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="editDevice(element)">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Anpassen</span>
              </button>
              <button mat-menu-item (click)="createDeviceQueue(element)">
                <mat-icon class="color-primary">send</mat-icon>
                <span>Daten senden</span>
              </button>
              <button mat-menu-item (click)="createMQTTSub(element)"
                *ngIf="uiService.getUIPermissions(EPermissions.MQTT)">
                <mat-icon class="color-primary"> cloud_upload</mat-icon>
                <span>Daten per MQTT empfangen</span>
              </button>
              <button mat-menu-item (click)="createAlertSub(element)"
                *ngIf="uiService.getUIPermissions(EPermissions.ALERTS)">
                <mat-icon class="color-primary">add_alert</mat-icon>
                <span>Alarmierung</span>
              </button>

              <button mat-menu-item (click)="deleteDevice(element.devEUI, element.deviceName)">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"
          (click)="getDeviceInfo(row)" style="cursor: pointer;"></tr>
      </table>
    </div>
    <div *ngIf="!data?.data&&!loading" fxFlex fxLayoutAlign="center center">
      Sie haben noch keine Geräte.
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
