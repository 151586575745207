import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { PageEvent } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { Subscription } from "rxjs";
import { IDataStream } from "../../../../../model/datatstream";
import { IDevice, IDevicePaginat } from "../../../../../model/devices";
import { EDataType } from "../../../../../model/enum/dataType";
import {
  IVirtualDevice,
  IVirtualDevicePaginat,
} from "../../../../../model/virtual-device";
import { DatastreamService } from "../../../../../services/datastream.service";
import { LoraDeviceService } from "../../../../../services/lora-devices";
import { NbiotDevicesService } from "../../../../../services/nbiot-devices.service";
import { VirtualDevicesService } from "../../../../../services/virtual-devices.service";
import { debounce, isDefined } from "../../../../abstract/utils";

@Component({
  selector: "app-device-select",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          #device_select
          [formControlName]="controlName"
          placeholder="Geräte"
          [multiple]="true"
          [value]="values"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-select-trigger>
            {{ values && values[0] ? values[0].deviceName : "" }}
            <span
              *ngIf="values && values.length > 1"
              class="additional-selection"
            >
              (+{{ values.length - 1 }}
              {{ values && values.length > 1 ? "mehr" : "" }})
            </span>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="search"
              [searching]="loading"
              [showToggleAllCheckbox]="true"
              [disableScrollToActiveOnOptionsChanged]="true"
              [clearSearchInput]="false"
              toggleAllCheckboxTooltipMessage="Alle Geräte des Profiles"
              (toggleAll)="toggleAllSelection($event, device_select)"
            ></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngFor="let item of DeviceList" [value]="item">
            {{ item.deviceName }}
            <small>{{ item.description }}</small>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-progress-bar mode="indeterminate" *ngIf="loading">
      </mat-progress-bar>
    </div>
  `,
  styles: [
    `
      .additional-selection {
        opacity: 0.75;
        font-size: 0.75em;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceSelectComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() group!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() devices?: IDevice[] | IDataStream[] | IVirtualDevice[];
  @Input() multiple = true;
  @Input() dataType?: EDataType;
  @Input() tenantID?: string;
  @Input() dataProfileID?: string;
  @Output() selectionChange = new EventEmitter();
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public DeviceList?: IDevice[] | IDataStream[];
  public values?: IDevice[] | IDataStream[];
  public loading = false;
  public search: UntypedFormControl = new UntypedFormControl();
  private subs?: Subscription;
  private scroll?: number;
  private updateHandler: Function;
  @ViewChild("device_select") selectElem?: MatSelect;
  constructor(
    private lService: LoraDeviceService,
    private nService: NbiotDevicesService,
    private vService: VirtualDevicesService,
    private dsService: DatastreamService,
    private ref: ChangeDetectorRef
  ) {
    this.updateHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadDevices(isDefined(this.values));
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["devices"] && changes["devices"].currentValue) {
      if (!this.DeviceList) {
        this.DeviceList = [];
      }
      // console.log("object", changes["devices"].currentValue);
      this.DeviceList.push(...(changes["devices"].currentValue as any));
      this.values = changes["devices"].currentValue;
    }
    if (changes["dataProfileID"] && !changes["dataProfileID"].firstChange) {
      this.onClear();
      this.loadDevices();
    } else if (changes["dataType"]) {
      this.dataType = changes["dataType"].currentValue;
      if (!changes["dataType"].firstChange) {
        this.onClear();
        this.loadDevices();
      }
    }
  }
  ngAfterViewInit(): void {
    this.search.valueChanges.subscribe({
      next: (value) => {
        if (value.length >= 2 || value.length <= 0) {
          this.updateHandler();
        }
      },
    });
    this.loadDevices(isDefined(this.values));
  }
  loadDevices(fetch = false) {
    this.dataType = !!this.dataType ? this.dataType : EDataType.lora; // lora bei Default
    switch (this.dataType) {
      case EDataType.lora:
        if (this.dataProfileID) {
          this.lService
            .getDevicesByDDProfileId(
              this.dataProfileID,
              this.tenantID,
              this.pageEvent,
              this.search.value
            )
            .subscribe({
              next: (dev: IDevicePaginat) => {
                this.addData(fetch, dev);
              },
            });
        } else {
          this.lService
            .getDevices(false, this.tenantID, this.pageEvent, this.search.value)
            .subscribe({
              next: (dev: IDevicePaginat) => {
                this.addData(fetch, dev);
              },
            });
        }
        break;
      // case EDataType.nbiot:
      //   if (this.dataProfileID) {
      //     this.nService
      //       .getDevicesByDDProfileId(
      //         this.dataProfileID,
      //         this.tenantID,
      //         this.pageEvent
      //       )
      //       .subscribe({
      //         next: (dev: IDevicePaginat) => {
      //           this.addData(fetch, dev);
      //         },
      //       });
      //   } else {
      //     this.nService.getDevices(this.tenantID, this.pageEvent).subscribe({
      //       next: (dev: IDevicePaginat) => {
      //         this.addData(fetch, dev);
      //       },
      //     });
      //   }
      //   break;
      case EDataType.datastream:
      case EDataType.inputData:
        if (this.dataProfileID) {
          this.dsService
            .loadDataStreamsbyStreamProfile(
              this.dataProfileID,
              this.tenantID,
              this.pageEvent,
              this.search.value
            )
            .subscribe({
              next: (dev) => {
                dev = dev as IDataStream;
                this.addData(fetch, dev);
              },
            });
        } else {
          this.dsService
            .loadDataStreams(this.tenantID, this.pageEvent, this.search.value, {
              dataType: this.dataType.toString(),
            })
            .subscribe({
              next: (dev) => {
                dev = dev as IDataStream;
                this.addData(fetch, dev);
              },
            });
        }
        break;
      case EDataType.virtual:
        this.vService
          .getDevices(this.tenantID, this.pageEvent, this.search.value)
          .subscribe({
            next: (dev: IVirtualDevicePaginat) => {
              this.addData(fetch, dev);
            },
          });

        break;

      default:
        break;
    }
  }
  toggleAllSelection(check: boolean, matSelect: MatSelect) {
    if (check) {
      matSelect.options.forEach(
        (item: MatOption) => item.value && item.select()
      );
    } else {
      matSelect.options.forEach(
        (item: MatOption) => item.value && item.deselect()
      );
    }
  }
  addData(
    fetch: boolean,
    dev: IDataStream | IDevicePaginat | IVirtualDevicePaginat
  ) {
    this.loading = false;
    if (!this.DeviceList && fetch) {
      this.DeviceList = [];
    }
    if (!dev || !dev.data) {
      return;
    }
    this.pageEvent.length = dev.total || 0;
    if (this.devices) {
      dev.data = (dev.data as any).filter((e: any) => {
        const test = this.devices?.findIndex((d) => d.devEUI === e.devEUI);
        return test && test < 0;
      });
    }
    this.DeviceList = fetch
      ? this.DeviceList?.concat((dev as any).data)
      : (dev as any).data;

    this.ref.detectChanges();
    if (this.selectElem?.panel) {
      setTimeout(() => {
        this.selectElem?.panel.nativeElement.scrollTo(0, this.scroll || 0 + 40);
      });
    }
    this.subs = this.subs
      ? this.subs
      : this.selectElem?.openedChange.subscribe(() => {
          if (this.selectElem?.panelOpen) {
            this.registerPanelScrollEvent();
          }
        });
  }
  registerPanelScrollEvent() {
    const panel = this.selectElem?.panel.nativeElement;
    panel.removeEventListener("scroll", () => {});
    panel.addEventListener("scroll", (event: any) => {
      if (event.target.scrollTop > 160 * (this.pageEvent.pageIndex * 2 + 1)) {
        this.scroll = event.target.scrollTop;
        this.fetchMore();
      }
    });
  }

  private fetchMore() {
    if (
      this.pageEvent.length >
        (this.pageEvent.pageIndex + 1) * this.pageEvent.pageSize &&
      this.loading === false
    ) {
      this.pageEvent.pageIndex++;
      this.loading = true;
      this.ref.detectChanges();
      this.loadDevices(true);
    }
  }
  onChanges(evt: any): void {
    if (evt) {
      this.selectionChange.emit(evt);
      this.values = evt;
    }
  }
  onClear() {
    this.pageEvent.pageIndex = 0;
    this.DeviceList = [];
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
