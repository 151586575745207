import {formatDate} from "@angular/common";
import {EChartsOption} from "echarts";
export const Colors = [
  "#21cba1",
  "#59e4e6",
  "#edafda",
  "#93d7c3",
  "#c8ca00",
  "#a5e7f0",
  "#cbb0e3",
  "#B74776",
  "#140cba",
  "#D34F2B",
  "#8d98b3",
  "#c7b742",
  "#66b552",
  "#b55297",
  "#07a2a4",
  "#c05050",
  "#7eb00a",
  "#5ab1ef",
];
export const echartsDark: EChartsOption = {
  color: Colors,
  graphic: {
    style: {backgroundColor: "#147cba", borderColor: "#fff", fill: "#fff"},
  },
  title: {
    textStyle: {
      fontWeight: "normal",
      color: "#C3C3C3",
    },
    z: 0,
  },
  legend: {
    type: "scroll",
    textStyle: {
      color: "#e0e0e0",
    },
    width: "80%",
    height: "20%",
  },

  dataRange: {
    color: ["#1178ad", "#72bbd0"],
  },
  dataZoom: {
    backgroundColor: "rgba(142, 192, 252, 0.4)",
    fillerColor: "rgba(139, 199, 248, 0.2)",
    borderColor: "#ddd",
    dataBackground: {
      lineStyle: {
        color: "rgba(8, 88, 160, 0.3)",
        width: 1,
        opacity: 0.5,
      },
      areaStyle: {
        color: "rgba(0, 9, 251, 0.7)",
        opacity: 0.5,
      },
    },
  },
  toolbox: {
    show: true,
    padding: [10, 30, 0, 0],
    feature: {
      dataView: {
        show: true,
        readOnly: true,
        backgroundColor: "rgba(0,0,0,0.7)",
        textareaColor: "rgba(0,0,0,0.7)",
        textColor: "#fff",
        title: "Daten",
        lang: ["Daten", "Schließen", "Aktualisieren"],
        buttonColor: "#5a7098",
        optionToContent: function (opt: any) {
          const time = opt.xAxis[0].type === "time";

          const series = opt.series as any[];
          let table = '<table style="width:100%;text-align:center">' + "<tr>";
          for (let index = 0; index < series.length; index++) {
            const el = series[index];
            table += "<th>" + el.name + "</th>";
          }
          table += "</tr><tr>";
          for (let i = 0; i < series.length; i++) {
            table += "<td><textarea cols='20' rows='12'>";
            for (let s = 0; s < series[i].data.length - 1; s++) {
              let el = series[i].data[s];
              if (!time) {
                el += `,${opt.xAxis[0].data[s]}`;
              }
              table += el + "\n";
            }
            table += "</textarea></td>";
          }
          table += "</tr></table>";
          return table;
        },
      },
      saveAsImage: {show: true, title: "Als Bild Speichern"},
      dataZoom: {
        yAxisIndex: false,
      },
    },
    height: "30px",
  },

  tooltip: {
    confine: true,
    appendToBody: true,
    backgroundColor: "rgba(30,30,30,0.9)",
    trigger: "axis",
    show: true,
    axisPointer: {
      type: "cross",
      snap: false,
      label: {
        backgroundColor: "#283b56",
        formatter: function (params) {
          if (isValidDate(params.value)) {
            return formatDate(
              params.value as any,
              "dd.MM.yy HH:mm:ss",
              "de-DE"
            );
          } else if (typeof params.value === "number") {
            return params.value.toFixed(2);
          } else {
            return params.value.toString();
          }
        },
        precision: 2,
      },
    },
  },

  // ç½‘æ ¼
  grid: {
    borderWidth: 0, tooltip: {
      axisPointer: {
        type: "shadow"
      }
    }
  },

  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: "#1790cf",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        width: 1,
        color: "#000000",
      },
    },
    axisLabel: {
      interval: "auto", // {number}
      // formatter: '{value}',
      textStyle: {
        color: "#C3C3C3",
        fontFamily: "sans-serif",
        fontSize: 12,
        // fontWeight: 'bold'
      },
    },
  },

  valueAxis: {
    axisLine: {
      // åæ ‡è½´çº¿
      lineStyle: {
        // å±žæ€§lineStyleæŽ§åˆ¶çº¿æ¡æ ·å¼
        color: "#838383",
      },
    },
    splitArea: {
      areaStyle: {
        color: ["rgba(184,184,184,0.3)", "rgba(126,126,126,0.3)"],
      },
    },
    splitLine: {
      lineStyle: {
        type: "solid",
        width: 1,
        color: "#666",
        opacity: 0.5,
      },
    },
    axisLabel: {
      interval: "auto", // {number}
      rotate: 0,
      margin: 8,
      // formatter: '{value}',
      textStyle: {
        color: "#C3C3C3",
        fontFamily: "sans-serif",
        fontSize: 12,
        fontStyle: "italic",
        // fontWeight: 'bold'
      },
    },
  },
  timeAxis: {
    splitArea: {
      areaStyle: {
        color: ["rgba(184,184,184,0.3)", "rgba(126,126,126,0.3)"],
      },
    },
    axisLine: {
      // åæ ‡è½´çº¿
      lineStyle: {
        // å±žæ€§lineStyleæŽ§åˆ¶çº¿æ¡æ ·å¼
        color: "#838383",
      },
    },
    splitNumber: 5,
    interval: "auto",
    axisLabel: {
      formatter: function (value: any) {
        if (isValidDate(value)) {
          return formatDate(value, "dd.MM.yyyy HH:mm", "de-DE");
        } else if (typeof value === "number") {
          return value.toFixed(2);
        } else {
          return value;
        }
      },
      textStyle: {
        color: "#C3C3C3",
        fontFamily: "sans-serif",
        fontSize: 12,
        fontStyle: "italic",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "solid",
        width: 3,
        opacity: 0.2,
        color: "#eee",
      },
    },
  },

  timeline: {
    lineStyle: {
      color: "#eee",
    },
    emphasis: {
      controlStyle: {
        color: "#1790cf",
      },
    },
    controlStyle: {
      color: "#1790cf",
    },
  },

  k: {
    itemStyle: {
      normal: {
        color: "#1bb2d8", // é˜³çº¿å¡«å……é¢œè‰²
        color0: "#99d2dd", // é˜´çº¿å¡«å……é¢œè‰²
        lineStyle: {
          width: 1,
          color: "#1c7099", // é˜³çº¿è¾¹æ¡†é¢œè‰²
          color0: "#88b0bb", // é˜´çº¿è¾¹æ¡†é¢œè‰²
        },
      },
    },
  },

  map: {
    itemStyle: {
      normal: {
        areaStyle: {
          color: "#C3C3C3",
        },
        label: {
          textStyle: {
            color: "#c12e34",
          },
        },
      },
      emphasis: {
        // ä¹Ÿæ˜¯é€‰ä¸­æ ·å¼
        areaStyle: {
          color: "#99d2dd",
        },
        label: {
          textStyle: {
            color: "#c12e34",
          },
        },
      },
    },
  },

  force: {
    itemStyle: {
      normal: {
        linkStyle: {
          color: "#1790cf",
        },
      },
    },
  },

  chord: {
    padding: 4,
    itemStyle: {
      normal: {
        borderWidth: 3,
        borderColor: "rgba(128, 128, 128, 0.5)",
        chordStyle: {
          lineStyle: {
            color: "rgba(128, 128, 128, 0.5)",
          },
        },
      },
      emphasis: {
        borderWidth: 3,
        borderColor: "rgba(128, 128, 128, 0.5)",
        chordStyle: {
          lineStyle: {
            color: "rgba(128, 128, 128, 0.5)",
          },
        },
      },
    },
  },

  gauge: {
    boundaryGap: [0.1, 0.1],
    radius: "100%",
    center: ["50%", "55%"],

    axisLine: {
      //
      lineStyle: {
        //
        color: [
          [0.2, "#1d9c1d"],
          [0.79, "#6d87ad"],
          [1, "#BE1807"],
        ],
        width: 8,
      },
    },
    axisTick: {
      //
      splitNumber: 10, //
      length: 12, //
      lineStyle: {
        //
        color: "auto",
      },
    },
    axisLabel: {
      backgroundColor: "auto",
      borderRadius: 2,
      color: "#eee",
      padding: 3,
      textShadowBlur: 2,
      textShadowOffsetX: 1,
      textShadowOffsetY: 1,
      textShadowColor: "#222",
    },
    splitLine: {
      //
      length: 20, // length
      lineStyle: {
        //
        color: "auto",
      },
    },
    pointer: {
      width: 5,
    },
    title: {
      color: "#e4e4e4",
    },

    detail: {
      // borderRadius: 3,
      // backgroundColor: '#444',
      // borderColor: '#aaa',
      // shadowBlur: 5,
      // shadowColor: '#333',
      // shadowOffsetX: 0,
      // shadowOffsetY: 3,
      // borderWidth: 2,
      textBorderColor: "#000",
      textBorderWidth: 2,
      textShadowBlur: 2,
      textShadowColor: "#000",
      textShadowOffsetX: 0,
      textShadowOffsetY: 0,
      fontFamily: "Arial",
      width: "50%",
      padding: [55, 0, 0, 0],
      rich: {},
      color: "#eee",
      fontSize: 25,
      fontWeight: 800,
    },
  },
  bar: {
    yAxis: [
      {
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          lineStyle: {
            color: "#ffffff",
          },
        },
        axisLabel: {
          interval: "auto", // {number}
          rotate: 45,
          margin: 8,
          // formatter: '{value}',
          textStyle: {
            color: "white",
            // fontFamily: 'sans-serif',
            // fontSize: 15,
            // fontStyle: 'italic',
            // fontWeight: 'bold'
          },
        },
      },
    ],
    xAxis: [
      {
        splitLine: {
          lineStyle: {
            color: "#ffffff",
          },
        },
        axisLabel: {
          textStyle: {
            color: "white",
          },
        },
      },
    ],

    barMinWidth: 5,
    itemStyle: {
      barBorderRadius: 2,
      // barBorderColor: '#3E3E3E',
      // barBorderWidth: 1,
      // shadowBlur: 4,
      // shadowColor: "#000000",
      // shadowOffsetX: 1,
      // shadowOffsetY: 1,
    },
  },
  line: {
    symbol: "emptyCircle", // Inflection point graphic type
    symbolSize: 7, // Inflection point graphic size
    showSymbol: false,
    lineStyle: {
      width: 2,
    },
    emphasis: {
      lineStyle: {
        width: 4,
        shadowBlur: 1,
      },
    },
    // yAxis: {
    //   boundaryGap: [0, "100%"]
    // },
    // smooth: false,
    // sampling: 'max',
    connectNulls: false,
    animationEasing: "linear",
    animation: true,
    // animationDuration: function(idx) {
    //   // delay for later data is larger
    //   return idx * 100;
    // }
    animationThreshold: 500,
    animationDuration: 500,
    // animationDelay: function(idx) {
    //   // delay for later data is larger
    //   return idx * 100;
    // }
    animationDelay: 50,
    animationDurationUpdate: 500,
    animationDelayUpdate: 50,
  },

  textStyle: {
    fontFamily: "Arial, Verdana, sans-serif",
  },
};
function isValidDate(timestamp: any) {
  return typeof timestamp === "number" && timestamp > 1000000000;
}
