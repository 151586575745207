import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AppSettings } from "../../../../app.settings";
import {
  IApiRestProfile,
  IApiRestProfilePaginat,
} from "../../../../model/apiRestProfile";
import {
  EPermissions,
  UIConfigService,
} from "../../../../services/uiconfig-service";
import { blockTransition } from "../../../../theme/utils/app-animation";
import { CreateDataStreamProfileComponent } from "../../../datastreams/create-profile-dialog/CreateDataStream.component";
import { RestAlertProfilesService } from "../../rest-alert-profiles.service";

@Component({
  templateUrl: "./rest-api-alert.component.html",
  styleUrls: ["./rest-api-alert.component.scss"],
  animations: [blockTransition],
})
export class RestApiAlertComponent implements OnInit {
  public displayedColumns = ["name", "requestURL", "requestMethod", "action"];
  public EPermissions = EPermissions;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public data?: MatTableDataSource<IApiRestProfile>;
  public loading = false;
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    private rService: RestAlertProfilesService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadData();
    return;
  }
  loadData() {
    this.rService.GetRestAlertProfiles(this.pageEvent).subscribe({
      next: (res) => {
        res = res as IApiRestProfilePaginat;
        if (res) {
          this.data = new MatTableDataSource(res.data);
        }
      },
    });
  }
  deleteRest(el: IApiRestProfile) {
    this.appSettings.getSwalDefaultConfirm("Profile löschen?").then((res) => {
      if (res.isConfirmed && el._id) {
        this.rService.DeleteRestAlertProfile(el._id).subscribe({
          next: (res) => {
            this.appSettings.getSwalSuccess("Profile wurde gelöscht.");
            this.loadData();
          },
        });
      }
    });
  }
  createRest(profileData?: IApiRestProfile) {
    if (!this.uiService.getUIPermissions(EPermissions.DEVICES)) {
      return;
    }
    const dialogRef = this.dialog.open(CreateDataStreamProfileComponent, {
      disableClose: true,
      minWidth: 400,
      data: { profileData, apiAlertProfile: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          const form: IApiRestProfile = {
            name: result.name,
            requestURL: result.requestURL,
            requestMethod: result.requestMethod,
            responseType: result.responseType,
            rejectUnauthorized: result.rejectUnauthorized,
            apiType: result.apiType,
          };
          let contentType = "application/json; charset=UTF-8";
          let headers: { [name: string]: string } =
            result.apiType == "REST"
              ? {
                  "Content-Type": contentType,
                }
              : {};
          let parameters: { [name: string]: string } = {};
          result.headers.map((item: any) => {
            if (item.key !== "") {
              headers = { ...headers, ...{ [item.key]: item.value } };
            }
          });
          result.parameters.map((item: any) => {
            if (item.key !== "") {
              parameters = { ...parameters, ...{ [item.key]: item.value } };
            }
          });
          form.requestHeader = headers;
          form.requestParameter = parameters;
          if (result.requestBody !== null) {
            if (result.apiType == "SOAP") {
              form.requestBody = { soap: result.requestBody };
            } else if (result.expertMode) {
              form.requestBody = { javascript: result.requestBody };
            } else {
              form.requestBody = JSON.parse(result.requestBody);
            }
          }
          if (profileData) {
            const update = { ...profileData, ...form };
            this.rService.UpdateAlertProfile(update).subscribe({
              next: (_) => {
                this.loadData();
                this.appSettings.getSwalSuccess("Profil wurde aktualisiert");
              },
            });
          } else {
            this.rService.CreateRestAlertProfile(form).subscribe({
              next: (_) => {
                this.loadData();
                this.appSettings.getSwalSuccess("Profil wurde hinzugefügt");
              },
            });
          }
        }
      },
    });
  }

  applyFilter(filterValue: string) {
    if (this.data) this.data.filter = filterValue.trim().toLowerCase();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
}
