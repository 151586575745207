import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { map, Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import {
  IAddResponse,
  IDeletedResponse,
  IUpdateResponse,
} from "../model/broker";
import { ILoraMulticastGroup } from "../model/loraMulticast";
import { IPagination } from "../model/pagination";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class LoraMulticastGroupService extends AbstractService {
  constructor(public http: HttpClient, private appSettings: AppSettings) {
    super("lora/multicast");
  }
  getLoraMulticastGroup(
    tenantID?: string,
    pagination?: PageEvent,
    search?: string
  ) {
    if (!tenantID) {
      tenantID = this.appSettings.currentTenantID();
    }
    let params = new HttpParams({
      fromObject: {
        tenantID,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    return this.http.get<
      ILoraMulticastGroup[] | IPagination<ILoraMulticastGroup>
    >(this.url, {
      headers: this.headers,
      params,
    });
  }
  delMulticastGroup(id: string) {
    return this.http.delete<IDeletedResponse>(`${this.url}/${id}`);
  }
  addMulticastGroup(form: any): Observable<IAddResponse> {
    form["tenantID"] = this.appSettings.currentTenantID();
    let params = new HttpParams({
      fromObject: {
        tenantID: this.appSettings.currentTenantID(),
      },
    });
    if (form.id) {
      return this.http
        .put<IUpdateResponse>(`${this.url}`, form, {
          params,
          headers: this.headers,
        })
        .pipe(
          map((v) => {
            if (v.ModifiedCount > 0) {
              return { insertedIDs: [form.id] };
            } else {
              return { insertedIDs: [] };
            }
          })
        );
    }
    return this.http.post<IAddResponse>(`${this.url}`, form, {
      params,
      headers: this.headers,
    });
  }
  addDeviceToGroup(id: string, devEUI: string[]) {
    return this.http.post<IUpdateResponse>(
      `${this.url}/devices`,
      { id, devEUI },
      { headers: this.headers }
    );
  }
  delDeviceFromGroup(id: string, devEUI: string[]) {
    return this.http.delete<IUpdateResponse>(`${this.url}/devices`, {
      headers: this.headers,
      body: { id, devEUI },
    });
  }
  addMulticastQueue(frmPayload: string, id: string, fPort?: number) {
    return this.http.post(
      `${this.api}/broker-lora-multicast`,
      { id, frmPayload, fPort },
      { headers: this.headers }
    );
  }
}
