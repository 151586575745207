import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import * as monaco from "monaco-editor";

let isEchart = false;
let isSetVirtual = false;
let isSetGot = false;
let isSetTemplate = false;
const IData = {
  deviceName: "",
  devEUI: "",
  object: "",
  tenantID: "",
  createdAt: "",
};
export function setMonacoDerfaults(_monaco: any) {
  _monaco.languages.registerCompletionItemProvider("javascript", {
    provideCompletionItems: provideCompletionItems,
    // triggerCharacters: [".", "("],
  });

  _monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
    noSuggestionDiagnostics: false,
    diagnosticCodesToIgnore: [2341],
  });
  _monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ES2016,
    allowJs: true,
    checkJs: true,
    allowNonTsExtensions: true,
  });
}
export function setMonacoEchartsDefaults(_monaco: any) {
  setMonacoDerfaults(_monaco);
  if (!isEchart) {
    isEchart = true;
    getEchartsTypes().subscribe((ts) => {
      _monaco.languages.typescript.javascriptDefaults.addExtraLib(
        extraLib + "declare namespace echarts {" + ts + "}",
        "inmemory:filename/echarts.d.ts"
      );
    });
  }
}
export function setMonacoGOTDefaults(_monaco: any) {
  setMonacoDerfaults(_monaco);
  if (!isSetGot) {
    isSetGot = true;
    getGotTypes().subscribe((ts) => {
      _monaco.languages.typescript.javascriptDefaults.addExtraLib(
        `
        interface IDeviceData {
          adr: boolean;
          createdAt: number;
          data: string;
          dataType: number;
          devEUI: string;
          deviceName: string;
          fCnt: number;
          fPort: number;
          object: any; //hier Befinden sich die Gerätedaten!
          rxInfo: RxInfo[];
          tenantID: string;
          txInfo: TxInfo;
        }

        interface TxInfo {
          dr: number;
          frequency: number;
          loraModulationInfo: LoraModulationInfo;
          modulation: number;
        }
        interface LoraModulationInfo {
          bandwidth: number;
          codeRate: string;
          polarizationInversion: boolean;
          spreadingFactor: number;
        }
        interface RxInfo {
          antenna: number;
          board: number;
          channel: number;
          context: string;
          crcStatus: number;
          encryptedFineTimestamp?: EncryptedFineTimestamp;
          fineTimestampType: number;
          gatewayId: string;
          location: Location;
          loraSnr: number;
          rfChain: number;
          rssi: number;
          time?: Time;
          uplinkId: string;
          timeSinceGpsEpoch?: Time;
        }
        interface Time {
          nanos: number;
          seconds: number;
        }
        interface Location {
          accuracy: number;
          altitude: number;
          latitude: number;
          longitude: number;
          source: number;
        }
        interface EncryptedFineTimestamp {
          aesKeyIndex: number;
          encryptedNs: string;
          fpgaId: string;
        }
        interface DefaultLocation {
        }
        interface IAzure{
          /**
            * createSASToken(hubUri: string, saName: string, saKey: string);
            * Generate SAS Token for Azure API
            * => 'SharedAccessSignature sr=...'
            */
          createSASToken(hubUri: string, saName: string, saKey: string);
        }
        interface IModule{
          /**
           * Return the formatted date string in the given format.
           * formatDate(new Date(2014, 1, 11), 'MM/dd/yyyy',"de-DE")
           * => '02/11/2014'
           */
           formatDate(Date: Date, format: string, locale: string): string;

           /**
            * Azure Hilfsfunktionen
            */
           Azure: IAzure;

           /**
            * tokenData: Daten die von der tokenRequest funktion kommen.
            */
           tokenData: {
            error?:any;//wenn Fehler,
            [key:string]:any;
           }

        }` +
          "declare namespace got {" +
          ts +
          "}",
        "inmemory:filename/Got.d.ts"
      );
    });
  }
}
export function setMonacoVirtualDefaults(_monaco: any) {
  setMonacoDerfaults(_monaco);
  if (!isSetVirtual) {
    isSetVirtual = true;
    getVirtualDeviceTypes().subscribe((ts) => {
      _monaco.languages.typescript.javascriptDefaults.addExtraLib(`${ts}`);
    });
  }
}
export function setMonacoTemplateDefaults(_monaco: any) {
  if (!isSetTemplate) {
    isSetTemplate = true;
  } else {
    return;
  }
  _monaco.languages.registerCompletionItemProvider("html", {
    triggerCharacters: ["."],
    provideCompletionItems: function provideCompletionItems(
      model: monaco.editor.ITextModel,
      position: monaco.IPosition
    ): monaco.languages.CompletionList {
      const suggestions = [];
      var last_chars = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 0,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });
      var words = last_chars.replace("\t", "").split(" ");
      var active_typing = words[words.length - 1];
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };

      if (last_chars.match(/_value_\.$/m)) {
        for (const key in IData) {
          // Push handlebars snippets
          suggestions.push({
            label: key,
            kind: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            insertText: `${key}`,
            range: range,
          });
        }
      } else if (!active_typing.includes("_value_.")) {
        suggestions.push({
          label: "_value_",
          kind: monaco.languages.CompletionItemKind.Value,
          documentation: "DeviceData Variable",
          insertText: "_value_",
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        });
      }

      return { suggestions: suggestions as any };
    },
    // triggerCharacters: [".", "("],
  });
}

function provideCompletionItems(
  model: monaco.editor.ITextModel,
  position: monaco.IPosition
): monaco.languages.CompletionList {
  return {
    suggestions: createCompletion(),
  };
}

const extraLib = `
  interface IValue {
      devEUI:string;
      deviceName:string;
      createdAt:number[];
      xValue?:any[];
      yValue?:any[];
      zValue?:any[];
      object?:any[];
      rxInfo?:IRxInfo
  }
  interface IRxInfo{
      bandwidth:[number];
      codeRate:[string];
      dr:[number];
      loraSnr:[any];
      rssi:[any];
  }
  interface IModule{
    /**
     * Return the formatted date string in the given format.
     * formatDate(new Date(2014, 1, 11), 'MM/dd/yyyy',"de-DE")
     * => '02/11/2014'
     */
     formatDate(Date:Date,format:string,locale:string):string;

      echartInstance?:echarts.ECharts;

      /**
       * Connects interaction of multiple chart series.
       */
      connect(group: string | ECharts[]): void;

      /**
       * Util methods about graphics.
       */
      graphic:Graphic;

      /**
       * Get Compass Direction as Text
       */
      CompassDir(val:number):string;
      registerMap(mapName:string,geoJson:any[]);
      /**
       * send Data to Lora Devices
       * @param {string} devEUI  devEUI from Device
       * @param {any} payload data to Send
       * @param {number} fPort Sendport(optional)
       */
       sendDataToLora(devEUI: string, payload: any, fPort?: number):void
  }

`;
function getEchartsTypes() {
  const httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );
  return httpClient.get("assets/ts/echarts/echarts.d.ts", {
    responseType: "text",
  });
}
function getGotTypes() {
  const httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );
  return httpClient.get("assets/ts/Got.d.ts", {
    responseType: "text",
  });
}
function getVirtualDeviceTypes() {
  const httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );
  return httpClient.get("assets/ts/Virtual.d.ts", {
    responseType: "text",
  });
}
function getdataInputDeviceTypes() {
  const httpClient = new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest() })
  );
  return httpClient.get("assets/ts/DataInput.d.ts", {
    responseType: "text",
  });
}

const echartDiagnostic: monaco.languages.typescript.DiagnosticsOptions = {};

function createCompletion(): any[] {
  return [
    {
      label: "clg",
      kind: monaco.languages.CompletionItemKind.Snippet,
      documentation: "Print code",
      insertText: "console.log(${1})",
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    },
    {
      label: "setType",
      kind: monaco.languages.CompletionItemKind.Snippet,
      documentation: "Set Type for Echarts Options Object for Completion",
      insertText: "/**@type echarts.EChartsOption<any>*/",
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    },
  ];
}
