<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column">
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="Gerätename" value="" formControlName="deviceName" />
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="Gerätebeschreibung" value="" formControlName="description" />
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <mat-select placeholder="Data Profile" formControlName="deviceDataProfileID">
            <mat-option *ngFor="let item of deviceDataProfiles" [value]="item._id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <app-lora-service-selector [group]="form" controlName="serviceProfileID"
          class="w-100"></app-lora-service-selector>
      </div>
      <div fxLayout="row">
        <app-lora-device-profile-selector [group]="form" [join]="supportJoin" class="w-100"
          controlName="deviceProfileID"></app-lora-device-profile-selector>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="App Key" value="" formControlName="appKey" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" formGroupName="defaultLocation">
        <mat-form-field fxFlex="50">
          <input matInput placeholder="Latitude" value="" formControlName="latitude" />
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input matInput placeholder="Longitude" value="" formControlName="longitude" />
        </mat-form-field>
        <button mat-icon-button (click)="setLocation()" color="primary">
          <mat-icon>map</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>
<base-dialog-footer #dialogFooter></base-dialog-footer>
