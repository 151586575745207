import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  EActionType,
  ESubscriptionType,
} from "../../../model/enum/subscriptions";
import {
  IAction,
  IAlertDevice,
  ISubscription,
} from "../../../model/subscriptions";
import { IVirtualDevice } from "../../../model/virtual-device";
import { CreateAlertComponent } from "../../../pages/subscriptions/alerting/create-alert/create-alert.component";
import { SubscriptionsService } from "../../../services/subscriptions.service";

export class CreateAlert {
  constructor(
    private dialog: MatDialog,
    private sService: SubscriptionsService
  ) {}
  createAlert(
    tenantID: string,
    alert?: ISubscription,
    devices?: IAlertDevice[] | IVirtualDevice[]
  ) {
    let _id: string | undefined;
    if (alert) {
      _id = alert._id;
    }
    // console.log({ alert });
    const dialogRef = this.dialog.open(CreateAlertComponent, {
      disableClose: true,
      data: alert ? { alert } : { devices },
      maxWidth: 700,
    });
    return new Observable((observer) => {
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result && result.resourceFilter) {
            const actions: IAction[] = [];
            const form: ISubscription = { name: result.name } as any;
            form.tenantID = tenantID;
            form.resourceType = ESubscriptionType.DeviceDataAlert;
            form.resourceFilter = {
              devEUI: result.devices.map((item: any) => {
                return item.devEUI;
              }),
              values: result.resourceFilter.map((item: any) => {
                if (item.func !== "regex") {
                  item.value = Number(item.value);
                }
                return item;
              }),
            };
            result.actions.map((item: any) => {
              if (item.actionType === EActionType.SEND_EMAIL) {
                actions.push({
                  actionType: EActionType.SEND_EMAIL,
                  actionConfig: {
                    address: item.address,
                    template: item.template,
                  },
                });
              } else if (item.actionType === EActionType.SEND_SMS) {
                actions.push({
                  actionType: EActionType.SEND_SMS,
                  actionConfig: { number: item.number },
                });
              } else if (item.actionType === EActionType.REST_API) {
                actions.push({
                  actionType: EActionType.REST_API,
                  actionConfig: { rest: item.rest },
                });
              }
            });
            form.resourceConfig = { actions };
            if (!alert) {
              form.subscriptionConfig = { active: true, silent: false };
            } else if (alert.subscriptionConfig?.active) {
              form.subscriptionConfig = { active: true };
            }
            delete form.devices;
            this.sService.createSubscription(form, _id).subscribe({
              next: (res) => observer.next(res),
              error: (err) => observer.error(err),
              complete: () => observer.complete(),
            });
          }
        },
      });
    });
  }
}
