import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { InvoicesComponent } from "./invoices/invoices.component";
import { MyContractComponent } from "./mycontract/mycontract.component";

@NgModule({
  declarations: [MyContractComponent, InvoicesComponent],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  // entryComponents: [MyContractComponent],
})
export class ContractsModule {}
