import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "app-password-eye",
  template: `
    <mat-icon
      (mousedown)="showPass.emit(true)"
      (touchstart)="showPass.emit(true)"
      (mouseup)="showPass.emit(false)"
      (touchend)="showPass.emit(false)"
      >visibility</mat-icon
    >
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordEyeComponent {
  @Output() showPass: EventEmitter<boolean> = new EventEmitter();
  constructor() {}
}
