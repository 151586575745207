import { AfterViewInit, Component, Inject } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AppSettings } from "../../../app.settings";
import {
  IChartConfig,
  IChartOptions,
} from "../../../model/chartsDefaultConfig";
import { IDeviceDataProfileList } from "../../../model/devicedataprofile";
import { IDevice } from "../../../model/devices";
import { EDataType } from "../../../model/enum/dataType";
import { DeviceDataProfileService } from "../../../services/DeviceDataProfileService";
import { BaseDialog } from "../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";
import { MapViewDialogComponent } from "../../dynamicboard/map-view-dialog/map-view-dialog.component";

@Component({
  selector: "app-change-device-data",
  templateUrl: "./change-device-data.component.html",
  styleUrls: ["./change-device-data.component.scss"],
})
export class ChangeDeviceDataComponent
  extends BaseDialog
  implements AfterViewInit
{
  // public suportJoin = true;
  public deviceDataProfiles?: IDeviceDataProfileList[];
  public defLoc = false;
  public itemData?: IChartConfig;
  public userConfig?: IChartOptions;
  public supportJoin?: boolean;

  constructor(
    private dialogRef: MatDialogRef<ChangeDeviceDataComponent>,
    @Inject(MAT_DIALOG_DATA) public device: IDevice,
    private ddpService: DeviceDataProfileService,
    private appSettings: AppSettings,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder
  ) {
    super(dialogRef);
  }
  buildForm(): UntypedFormGroup {
    return this.fb.group({
      devEUI: [],
      deviceDataProfileID: [null, Validators.required],
      deviceProfileID: "",
      serviceProfileID: "",
      deviceName: ["", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required])],
      defaultLocation: new UntypedFormGroup({
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null),
      }),
      appKey: new FormControl<string>(""),
      // appKey: [null],
      // nwkKey: [null],
      // devAddr: [null],
    });
  }
  implementAtInitStart() {
    this.loadDeviceDataProfiles();
  }
  implementOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.device) {
        this.supportJoin = !this.device.abp;
        this.form.patchValue({
          devEUI: this.device.devEUI,
          deviceDataProfileID: this.device.deviceDataProfileID,
          deviceName: this.device.deviceName,
          description: this.device.description,
          deviceProfileID: this.device.deviceProfileID,
          serviceProfileID: this.device.serviceProfileID,
        });
        if (
          this.device.defaultLocation &&
          this.device.defaultLocation.latitude
        ) {
          this.form
            .get("defaultLocation")
            ?.setValue(this.device.defaultLocation);
          this.defLoc = true;
        }
        this.getBaseDialogHeader().setTitle("Gerät bearbeiten");
        this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Ändern");
      } else {
        this.getBaseDialogHeader().setTitle("Gerät erstellen");
      }
    });
  }

  setLocation() {
    this.dialog
      .open(MapViewDialogComponent, {
        data: this.form.get("defaultLocation")?.value,
        width: "100%",
        height: "85%",
      })
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res) {
            this.form.patchValue(res);
          }
        },
      });
  }

  loadDeviceDataProfiles() {
    this.ddpService.getDeviceDataProfile(EDataType.lora, "*").subscribe({
      next: (data) => {
        this.deviceDataProfiles = data;
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
  loadDeviceProfiles() {
    this.ddpService.getDeviceDataProfile(EDataType.lora, "*").subscribe({
      next: (data) => {
        this.deviceDataProfiles = data;
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }

  // onSave() {
  //   this.onSubmit(this.form.value);
  // }
}
