<h1 mat-dialog-title>
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center">
    <span>Daten zum Gerät/Gruppe senden</span>
    <button mat-mini-fab color="warn" class="window-button">
      <mat-icon class="window-button-ico" (click)="onClose()">clear</mat-icon>
    </button>
  </div>
</h1>

<div mat-card-content fxLayout="column">
  <mat-form-field>
    <mat-label>SendePort</mat-label>
    <input matInput placeholder="Port" value="2" type="number" [(ngModel)]="fPort" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Daten zum Senden</mat-label>
    <textarea matInput name="" id="" cols="30" rows="5" [(ngModel)]="data2Send"></textarea>
  </mat-form-field>
  <div>
    Die Daten werden bei der nächsten<br />Verbindung zum Gerät übertragen.
  </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end">
  <!-- <button mat-raised-button color="warn" (click)="onClose()">Abbrechen</button> -->
  <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!data2Send || data2Send.length < 2">
    <span>Senden</span>
  </button>
</div>
