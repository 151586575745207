import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private setting = {
    element: {
      dynamicDownload: {} as unknown as HTMLElement,
    },
  };

  constructor() {}
}
