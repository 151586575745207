import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { ChangeFilter } from "../../../../pages/dynamicboard/dvv-charts/change-filter";
import { Menu } from "../menu.model";
import { MenuService } from "../menu.service";

@Component({
  selector: "app-vertical-menu",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalMenuComponent implements OnInit, OnChanges, AfterViewInit {
  // eslint-disable-next-line
  @Input("menuItems") menuItems!: any;
  // eslint-disable-next-line
  @Input("menuParentId") menuParentId!: any;
  parentMenu!: Array<any>;
  public settings: Settings;
  private menuService: MenuService | null;
  constructor(public appSettings: AppSettings, public router: Router) {
    this.settings = this.appSettings.settings;
    this.menuService = MenuService.menuInstance;
  }

  ngOnInit() {
    this.parentMenu = this.menuItems.filter(
      (item: any) => item.parentId === this.menuParentId
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filter = ChangeFilter.of(changes);
    // console.log(changes)
    filter.notFirstAndEmpty<Menu[]>("menuItems").subscribe((items) => {
      this.parentMenu = items.filter(
        (item) => item.parentId === this.menuParentId
      );
    });
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          const mainContent = document.getElementById("main-content");
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName(
            "mat-drawer-content"
          )[0].scrollTop = 0;
        }
      }
    });
  }

  onClick(menuId: any, theme?: any) {
    if (theme !== undefined && this.appSettings.settings.theme !== theme) {
      this.appSettings.settings.theme = theme;
    }
    this.menuService?.toggleMenuItem(menuId);
    this.menuService?.closeOtherSubMenus(this.menuItems, menuId);
  }
}
