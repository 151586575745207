<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button
      mat-mini-fab
      color="primary"
      (click)="createTemplate()"
      *ngIf="uiService.getUIPermissions(EPermissions.ALERTS)"
    >
      <mat-icon>add</mat-icon>
    </button>

    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($any($event.target).value)"
        placeholder="Suchen"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row" class="flex-p">
    <div *ngIf="data" @blockTransition fxFlex="100%">
      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageEvent.pageSize"
        showFirstLastButtons
        [length]="pageEvent.length"
        (page)="onPagination($event)"
        class="block"
      >
      </mat-paginator>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="w-100 block"
        aria-describedby="MQTT Daten"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Bezeichnung
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell matRipple *matHeaderCellDef i18n scope="col">
            Beschreibung
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell matRipple *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element" class="w-max-100p sub-cell">
            <button
              mat-icon-button
              [matMenuTriggerFor]="devMenu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="createTemplate(element)">
                <mat-icon class="color-ascend">edit</mat-icon>
                <span>Template anpassen</span></button
              ><button mat-menu-item (click)="deleteTemplate(element)">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Template löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="table-element-row"
          (click)="(undefined)"
        ></tr>
      </table>
    </div>
    <div *ngIf="!data" fxFlex>
      <div fxLayoutAlign="center center">Sie haben noch keine Templates.</div>
    </div>
  </div>
</div>
<div
  *ngIf="loading"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="instance-spinner"
>
  <mat-spinner color="primary"></mat-spinner>
</div>
