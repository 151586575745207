import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { LandingPageRoutingModule } from "./landing-page-routing.module";
import { SetSilentComponent } from "./subscriptions/set-silent";

@NgModule({
  declarations: [SetSilentComponent],
  imports: [CommonModule, LandingPageRoutingModule, SharedModule],
})
export class LandingPageModule {}
