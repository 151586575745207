<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content [formGroup]="form">
  <div fxLayout="column" class="w-min-300p">
    <mat-form-field>
      <input matInput placeholder="Benutzername" formControlName="username" value="" />
      <mat-error *ngIf="form.get('username')?.hasError('invalidEmail')">{{
        getErrorMessage("invalidEmail")
        }}</mat-error>
      <mat-error *ngIf="form.get('username')?.hasError('required')">{{
        getErrorMessage("required")
        }}</mat-error>
    </mat-form-field>
    <span fxLayoutAlign="center center">Berechtigungen</span>
    <mat-error fxLayoutAlign="center center" *ngIf="form.get('permissionCheck')?.hasError('required')">
      {{ getErrorMessage("required") }}</mat-error>
    <mat-form-field>
      <mat-select placeholder="Berechtigungen" formControlName="permissions">
        <mat-option *ngFor="let item of PermissionKeys" [value]="item.value" [matTooltip]="item.desc">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!apiPermission&&!DATA?.user">
      <input matInput placeholder="Passwort" formControlName="password" autocomplete="new-password"
        [type]="showPass ? 'text' : 'password'" value="" />
      <app-password-eye matSuffix (showPass)="showPass = $event"></app-password-eye>
      <mat-error *ngIf="form.get('password')?.hasError('required')">{{ getErrorMessage("required") }}
      </mat-error>
      <mat-error *ngIf="form.get('password')?.hasError('invalidPassword')">
        {{ getErrorMessage("invalidPassword") }}
      </mat-error>
      <mat-error *ngIf="form.get('password')?.hasError('minlength')">
        {{ getErrorMessage("minlength", 6) }}</mat-error>
      <mat-hint *ngIf="form.get('password')?.errors">Bei API Berechtigung nicht relevant</mat-hint>
    </mat-form-field>

  </div>
</div>
<base-dialog-footer #dialogFooter></base-dialog-footer>
