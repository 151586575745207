import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {SharedUIModule} from "../../shared/components/sharedui/sharedui.module";
import {SharedModule} from "../../shared/shared.module";
import {PipesModule} from "../../theme/pipes/pipes.module";
import {GroupsComponent} from "./groups.component";
import {DevicesDialogComponent} from "./taged-devices/devices-dialog/devices-dialog.component";
import {TagedDevicesComponent} from "./taged-devices/taged-devices.component";

@NgModule({
  declarations: [
    GroupsComponent,
    TagedDevicesComponent,
    DevicesDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedUIModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
})
export class GroupsModule { }
