import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserInfo } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class CloudLoginGuard implements CanActivate {
  constructor(private info: UserInfo, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const session = this.info.userInfo;
    if (!session) {
      this.router.navigate(["login"]);
      return false;
    }
    if (!session.exp || session.exp > 0) {
      return true;
    } else {
      this.router.navigate(["logout"], { state: { expired: true } });
      return false;
    }
  }
}
