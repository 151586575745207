/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "localizedDate",
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor() {}

  transform(value: any, pattern = "mediumDate"): any {
    if (!value) {
      value = 0;
    }

    if (value.toString().length < 13) {
      value *= 1000;
    }
    const date = new Date(value + new Date().getTimezoneOffset() * 60 * 1000);
    const datePipe: DatePipe = new DatePipe("DE");
    return datePipe.transform(createDateAsUTC(date), pattern);
  }
}
function createDateAsUTC(date: Date) {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
}
