import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-create-device-queue",
  templateUrl: "./create-device-queue.component.html",
  styleUrls: ["./create-device-queue.component.scss"],
})
export class CreateDeviceQueueComponent {
  data2Send?: string;
  fPort = 1;

  constructor(private dialogRef: MatDialogRef<CreateDeviceQueueComponent>) {}

  onClose() {
    this.dialogRef.close();
  }
  onSave() {
    this.dialogRef.close({ payload: this.data2Send, fPort: this.fPort });
  }
}
