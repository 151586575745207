import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import { AppSettings } from "../app.settings";
import { IDataStream, IStreamProfiles } from "../model/datatstream";
import { EResponseType } from "../model/enum/datastream.enum";
import { getParams } from "../shared/abstract/utils";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class DatastreamService extends AbstractService {
  constructor(private http: HttpClient, private appSetting: AppSettings) {
    super("datastream");
  }

  postRequest(body: IStreamProfiles): Observable<any> {
    const url = `${this.api}/broker-datastream`;
    const opts = { responseType: "json" };
    if (body?.responseType === EResponseType.TEXT) {
      opts.responseType = "text";
    }
    return this.http.post<any>(url, body, opts as any);
  }
  //----- DataStream Profiles -------------
  loadStreamProfiles(
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string }
  ) {
    let params = new HttpParams();
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    params = params.append("tenantID", this.appSetting.currentTenantID());
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IStreamProfiles | IStreamProfiles[]>(
      `${this.url}/profiles`,
      { params }
    );
  }
  createDataStreamProfile(form: IStreamProfiles) {
    form.tenantID = this.appSetting.currentTenantID();
    return this.http.post(`${this.url}/profiles`, form);
  }
  updateDataStreamProfile(form: IStreamProfiles, create = false) {
    if (create) {
      return this.createDataStreamProfile(form);
    }
    return this.http.put(`${this.url}/profiles`, form);
  }
  deleteDataStreamProfile(id: string) {
    return this.http.delete(`${this.url}/profiles/${id}`);
  }
  //----- DataStream -------------
  createDataStream(form: IDataStream) {
    form.tenantID = this.appSetting.currentTenantID();
    return this.http.post(`${this.url}/devices`, form);
  }
  updateDataStream(form: IDataStream, create = false) {
    if (create) {
      return this.createDataStream(form);
    }
    return this.http.put(`${this.url}/devices`, form);
  }
  loadDataStreams(
    tenantID?: string,
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string }
  ) {
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : this.appSetting.currentTenantID(),
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IDataStream | IDataStream[]>(`${this.url}/devices`, {
      params,
    });
  }

  loadDataStream(devEUI: string) {
    return this.http.get<IDataStream | IDataStream[]>(
      `${this.url}/devices/${devEUI}`
    );
  }
  getDataStreamQuery(body: any) {
    const headers = this.headers;
    return firstValueFrom(
      this.http.post<IDataStream | IDataStream[]>(
        this.url + "/devices-query",
        body,
        { headers }
      )
    );
  }
  loadDataStreamsbyStreamProfile(
    profileID: string,
    tenantID?: string,
    pagination?: PageEvent,
    search?: string
  ) {
    let params = new HttpParams({
      fromObject: {
        dataStreamProfileID: profileID,
        tenantID: tenantID ? tenantID : "*",
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    return this.http.get<IDataStream | IDataStream[]>(`${this.url}/devices`, {
      params,
    });
  }
  deleteDataStream(id: string) {
    return this.http.delete<IDataStream[]>(`${this.url}/devices/${id}`);
  }
  getDevicesByTag(tagKey: string, pagination?: PageEvent) {
    const url = this.url + "/devices";
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tags: tagKey,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    return this.http.get<IDataStream & IDataStream[]>(url, { headers, params });
  }
  getDevice(devEUI: string | string[]) {
    const headers = this.headers;
    const params = new HttpParams({
      fromObject: {
        devEUI: devEUI,
        tenantID: this.appSetting.currentTenantID(),
      },
    });
    return this.http.get<IDataStream & IDataStream[]>(this.url, {
      headers,
      params,
    });
  }
}
