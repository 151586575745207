import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ECharts } from "echarts";
import {
  IChartConfig,
  IChartOptions,
} from "../../../model/chartsDefaultConfig";
import { json2str } from "../../../shared/abstract/utils";
import { BaseDialog } from "../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";

@Component({
  templateUrl: "./map-view-dialog.component.html",
  styleUrls: ["./map-view-dialog.component.scss"],
})
export class MapViewDialogComponent
  extends BaseDialog
  implements OnInit, AfterViewInit
{
  public itemData!: IChartConfig;
  public userConfig?: IChartOptions;
  public _form!: UntypedFormGroup;
  private chartInstance?: ECharts;
  constructor(
    private dialogRef: MatDialogRef<MapViewDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public defLoc?: { latitude: number; longitude: number; readOnly?: boolean }
  ) {
    super(dialogRef);
  }
  ngAfterViewInit() {
    return;
  }
  buildForm() {
    return this.fb.group({
      defaultLocation: new UntypedFormGroup({
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null),
      }),
    });
  }
  setChartInstance(evt: ECharts) {
    this.chartInstance = evt;
  }
  implementOnInit(): void {
    if (this.defLoc?.readOnly) {
      this.getBaseDialogFooter().setFooterButtons([]);
    } else {
      this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Setzen");
    }
    this.defLoc = this.defLoc?.latitude
      ? this.defLoc
      : {
          latitude: 51.42837236638304,
          longitude: 6.753995418548585,
        };
    this.itemData = {
      chartData: {
        chartType: "map",
        achsen: 0,
        userOptions: {},
        options: `function getChartConfig(_value_, _option_, _module_) {
            return ${this.getMapView(
              this.defLoc.latitude,
              this.defLoc.longitude
            )};
        }`,
      },
      devices: [],
      timeRange: 1,
      title: "map",
      widgetData: { cols: 0, name: "", rows: 0, x: 0, y: 0 },
    };
    this.form.patchValue({ defaultLocation: this.defLoc });
  }

  onChartsChanges(evt: any) {
    if (evt && evt["dblclickEvent"]) {
      const lat = evt["dblclickEvent"].latlng.lat;
      const lng = evt["dblclickEvent"].latlng.lng;

      this.form
        .get("defaultLocation")
        ?.patchValue({ latitude: lat, longitude: lng });

      this.chartInstance?.setOption({
        series: [
          {
            data: [{ value: [lng, lat] }],
          },
        ],
      });
    }
  }
  getMapView(lat: number, long: number) {
    let _opts_ = {
      leaflet: {
        center: [long, lat],
        layerControl: {
          position: "topleft",
        },
        roam: true,
        tiles: [
          {
            label: "Hybrid",
            urlTemplate:
              "https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=xI4JQjtggJFBiOxFwFFF",
            options: {
              tileSize: 512,
              zoomOffset: -1,
              minZoom: 1,
              attribution:
                '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
              crossOrigin: true,
              maxZoom: 21,
            },
          },
          {
            label: "Map",
            urlTemplate:
              "https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=xI4JQjtggJFBiOxFwFFF",
            options: {
              tileSize: 512,
              zoomOffset: -1,
              minZoom: 1,
              attribution:
                '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
              crossOrigin: true,
              maxZoom: 21,
            },
          },
        ],
        defaultLayer: "Map",
        zoom: 19,
      },
      series: [
        {
          coordinateSystem: "leaflet",
          data: [{ value: [long, lat], name: "" }],
          hoverAnimation: true,
          itemStyle: {
            normal: { show: false, color: "red" },
          },
          label: { show: false },
          symbol: "pin",
          symbolSize: 30,
          type: "scatter",
          zlevel: 2,
          animation: false,
        },
      ],
    };
    return json2str(_opts_);
  }
}
