<div fxLayout="column" class="flex-p">
  <div fxLayout="row" fxLayoutGap="10px">
    <button mat-mini-fab color="primary" (click)="createUserDialog(undefined)"
      *ngIf="uiService.getUIPermissions(EPermissions.USERMANAGEMENT)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field *ngIf="uiService.getUIPermissions(EPermissions.USERMANAGEMENT)">
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen"
        autocomplete="new-password" />
    </mat-form-field>
  </div>
  <div class="flex-p" fxLayout="row">
    <div *ngIf="data" fxFlex @blockTransition>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)" class="block"></mat-paginator>
      <table mat-table [dataSource]="data" matSort class="w-100 block" aria-describedby="User Verwaltung">
        <ng-container matColumnDef="tenantID">
          <th mat-header-cell mat-sort-header *matHeaderCellDef scope="col">
            TenantID
          </th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            {{ element.tenantID }}
          </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell mat-sort-header *matHeaderCellDef scope="col">
            Benutzername
          </th>
          >
          <td mat-cell *matCellDef="let element">{{ element.username }}</td>
          >
        </ng-container>

        <ng-container matColumnDef="permissions">
          <th mat-header-cell mat-sort-header *matHeaderCellDef scope="col">
            Berechtigungen
          </th>
          >
          <td mat-cell *matCellDef="let element">{{ element.permissions }}</td>
          >
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell mat-sort-header *matHeaderCellDef scope="col">
            Erstellt am
          </th>
          >
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.createdAt | localizedDate: "dd.MM.yyyy HH:mm" }}
          </td>
          >
        </ng-container>
        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell mat-sort-header *matHeaderCellDef scope="col">
            Letzte Anmeldung
          </th>
          >
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.lastLogin | localizedDate: "dd.MM.yyyy HH:mm" }}
          </td>
          >
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          >
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="userMenu"
              *ngIf="uiService.getUIPermissions(EPermissions.USER)">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item (click)="showToken(element.username)" *ngIf="element.permissions === 'api'">
                <mat-icon class="color-primary">vpn_key</mat-icon>
                <span>Token anzeigen</span>
              </button>
              <button mat-menu-item (click)="restDevices(element.username)" *ngIf="element.permissions === 'api'">
                <mat-icon class="color-primary">cloud_queue</mat-icon>
                <span>Geräte Verwalten</span>
              </button>
              <button mat-menu-item (click)="resetPassword(element.username)"
                *ngIf="element.permissions !== 'api'||uiService.getUIPermissions(EPermissions.USER)">
                <mat-icon class="color-primary">replay</mat-icon>
                <span>Passwort ändern</span>
              </button>
              <button mat-menu-item [disabled]="true" *ngIf="uiService.getUIPermissions(EPermissions.USERMANAGEMENT)">
                <mat-icon class="color-ascend">block</mat-icon>
                <span>Benutzer sperren</span>
              </button>
              <button mat-menu-item [disabled]="true" *ngIf="uiService.getUIPermissions(EPermissions.USERMANAGEMENT)">
                <mat-icon class="color-primary">verified_user</mat-icon>
                <span>Sperrung aufheben</span>
              </button>
              <button mat-menu-item *ngIf="uiService.getUIPermissions(EPermissions.USERMANAGEMENT)"
                (click)="createUserDialog(element)">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Benutzer anpassen</span>
              </button>
              <button mat-menu-item (click)="deleteUser(element)"
                *ngIf="uiService.getUIPermissions(EPermissions.USERMANAGEMENT)">
                <mat-icon class="color-warn">delete</mat-icon>
                <span>Benutzer löschen</span>
              </button>
            </mat-menu>
          </td>
          >
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row"></tr>
      </table>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
