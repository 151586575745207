<div fxLayout="column" class="flex-p" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <button mat-mini-fab color="primary" (click)="loadLogs()" matTooltip="Aktualisieren">
      <mat-icon>autorenew</mat-icon>
    </button>
    <mat-checkbox (change)="LogTypeSelect($event.checked)">
      Nur Fehler anzeigen
    </mat-checkbox>
  </div>
  <div fxLayout="row">
    <div fxFill>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)">
      </mat-paginator>
      <table mat-table *ngIf="logData" [dataSource]="logData" class="w-100 block" fxFill aria-describedby="Logs">
        <ng-container matColumnDef="logEvent">
          <th mat-header-cell *matHeaderCellDef scope="col">Log Type</th>
          <td mat-cell *matCellDef="let element" class="w-100p w-max-100p">
            {{ getLogTypeText(element.logEvent) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="logMessage">
          <th mat-header-cell *matHeaderCellDef scope="col">Meldung</th>
          <td mat-cell *matCellDef="let element" class="w-max-100p">
            <ngx-json-viewer fxLayoutAlign="start center" [json]="{ message: element.logMessage }" [expanded]="false">
            </ngx-json-viewer>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef scope="col" class="w-200p w-max-100p">
            Erstellt am
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date:'short' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" style="height: 35px"
          [style.color]="row.logEvent === 4 ? '' : '#ff0000'"></tr>
      </table>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
