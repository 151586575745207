import { EPermissions } from "../../../services/uiconfig-service";
import { Menu } from "./menu.model";
/* eslint */
export const verticalMenuItems = [
  // id 10 darf nicht verändert werden
  new Menu(
    10,
    "Dashboards",
    "/dashboards",
    null,
    "dashboard",
    null,
    true,
    0,
    EPermissions.DASHBOARD
  ),
  // ----- Administartion -------------
  new Menu(
    400,
    "Administration",
    "/admin",
    null,
    "build",
    null,
    true,
    0,
    EPermissions.DEVICES
  ),
  new Menu(
    401,
    "Benutzerwaltung",
    "/admin/users",
    null,
    "supervisor_account",
    null,
    false,
    400,
    EPermissions.USER
  ),
  //LORA ############################################
  new Menu(
    410,
    "LoRa",
    "",
    null,
    "settings_input_antenna",
    null,
    true,
    400,
    EPermissions.DEVICES
  ),
  new Menu(
    412,
    "Geräte",
    "/lora/devices",
    null,
    "settings_input_antenna",
    null,
    false,
    410,
    EPermissions.DEVICES
  ),
  new Menu(
    413,
    "Daten Profile",
    "/lora/devicedataprofiles/",
    null,
    "swap_vert",
    null,
    false,
    410,
    EPermissions.TAGS
  ),
  new Menu(
    414,
    "Multicast Gruppen",
    "/lora/multicast",
    null,
    "call_split",
    null,
    false,
    410,
    EPermissions.DEVICES
  ),
  new Menu(
    415,
    "Geräte Profile",
    "/lora/device-profiles",
    null,
    "wifi",
    null,
    false,
    410,
    EPermissions.DEVICES
  ),
  new Menu(
    417,
    "Service Profile",
    "/lora/service-profiles",
    null,
    "wifi",
    null,
    false,
    410,
    EPermissions.DEVICES
  ),

  // DatenQuellen ############################################
  new Menu(
    420,
    "Datenquellen",
    "",
    null,
    "cloud_download",
    null,
    true,
    400,
    EPermissions.DEVICES
  ),
  new Menu(
    422,
    "Geräte",
    "/datastreams/devices",
    null,
    "wifi_tethering",
    null,
    false,
    420,
    EPermissions.DEVICES
  ),
  new Menu(
    423,
    "Profile",
    "/datastreams/profile",
    null,
    "swap_vert",
    null,
    false,
    420,
    EPermissions.TAGS
  ),

  //Virtuell     ############################################
  new Menu(
    430,
    "Virtuell",
    "/virtual/devices",
    null,
    "extension",
    null,
    true,
    400,
    EPermissions.DEVICES
  ),
  new Menu(
    432,
    "Geräte",
    "/virtual/devices",
    null,
    "settings_input_antenna",
    null,
    false,
    430,
    EPermissions.DEVICES
  ),

  new Menu(
    440,
    "Meine Gruppen",
    "/admin/groups",
    null,
    "group_work",
    null,
    false,
    400,
    EPermissions.TAGS
  ),
  new Menu(
    470,
    "Öffentliche IoT Geräte",
    "/lora/devices/public",
    null,
    "cloud",
    null,
    false,
    400,
    EPermissions.DEVICES
  ),
  new Menu(
    498,
    "Logs",
    "/admin/logs",
    null,
    "subject",
    null,
    false,
    400,
    EPermissions.DEVICES
  ),
  new Menu(
    499,
    "Charts",
    "/admin/chartsadmin",
    null,
    "assessment",
    null,
    false,
    400,
    EPermissions.CHARTMANAGEMENT
  ),
  new Menu(
    500,
    "Abonnements",
    "/subscriptions",
    null,
    "subscriptions",
    null,
    true,
    400,
    EPermissions.DEVICES
  ),
  new Menu(
    510,
    "Alarmierung",
    "/subscriptions/device-data-alert",
    null,
    "notifications",
    null,
    true,
    500,
    EPermissions.ALERTS
  ),
  new Menu(
    511,
    "Alarm",
    "/subscriptions/device-data-alert",
    null,
    "notifications",
    null,
    false,
    510,
    EPermissions.ALERTS
  ),
  new Menu(
    512,
    "Email Templates",
    "/subscriptions/email_template",
    null,
    "email",
    null,
    false,
    510,
    EPermissions.ALERTS
  ),
  new Menu(
    514,
    "RESTful API Profile",
    "/subscriptions/restful-api-profiles",
    null,
    "extension",
    null,
    false,
    500,
    EPermissions.API_PROFILES
  ),
  new Menu(
    515,
    "MQTT",
    "/subscriptions/device-data-mqtt",
    null,
    "cloud_upload",
    null,
    false,
    500,
    EPermissions.MQTT
  ),
  new Menu(
    517,
    "API Daten",
    "/subscriptions/api-devices",
    null,
    "cloud_upload",
    null,
    false,
    500,
    EPermissions.DEVICE_DATA_API
  ),

  new Menu(
    518,
    "API Application",
    "/subscriptions/data-input",
    null,
    "present_to_all",
    null,
    false,
    500,
    EPermissions.API_APPLICATION
  ),
  new Menu(
    519,
    "Daten Weiterleitung",
    "/subscriptions/forwarding",
    null,
    "fast_forward",
    null,
    false,
    500,
    EPermissions.DATA_FORWARD
  ),
  // ----- Administartion -------------
  // new Menu(
  //   515,
  //   "Mein Vertrag",
  //   "/contract",
  //   null,
  //   "perm_identity",
  //   null,
  //   true,
  //   0,
  //   EPermissions.CONTRACT
  // ),
  // new Menu(
  //   550,
  //   "Meine Vertragsoptionen",
  //   "/contract/options",
  //   null,
  //   "settings_input_antenna",
  //   null,
  //   false,
  //   510,
  //   EPermissions.CONTRACT
  // ),
  // new Menu(
  //   570,
  //   "Meine Rechnungen",
  //   "/contract/invoices",
  //   null,
  //   "settings_input_antenna",
  //   null,
  //   false,
  //   510,
  //   EPermissions.CONTRACT
  // ),
  new Menu(
    600,
    "Superadmin",
    "/superadmin",
    null,
    "settings",
    null,
    true,
    0,
    EPermissions.TENANTMANAGEMENT
  ),
  new Menu(
    608,
    "Gerätetypen",
    "/superadmin/tagsadmin",
    null,
    "group_work",
    null,
    false,
    600,
    EPermissions.TAGS
  ),
  new Menu(
    602,
    "Tenants",
    "/superadmin/tenants",
    null,
    "account_box",
    null,
    false,
    600,
    EPermissions.TENANTMANAGEMENT
  ),
  new Menu(
    604,
    "Produkte",
    "/superadmin/contractadmin",
    null,
    "account_balance_wallet",
    null,
    false,
    600,
    EPermissions.CHARTMANAGEMENT
  ),
  new Menu(
    700,
    "Gateways",
    "/superadmin",
    null,
    "settings",
    null,
    true,
    600,
    EPermissions.TENANTMANAGEMENT
  ),
  new Menu(
    701,
    "Gateways",
    "/superadmin/gateways",
    null,
    "wifi_tethering",
    null,
    false,
    700,
    EPermissions.TENANTMANAGEMENT
  ),
];
