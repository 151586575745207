import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

export function emailValidator(control: UntypedFormControl): {
  [key: string]: any;
} {
  const emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
  return {};
}

export function passwordValidator(control: UntypedFormControl) {
  // Minimum eight characters, at least one letter, one number and one special character
  //   const passRegexp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{1,}$/;
  const passRegexp =
    /(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[@$!%*#?§&,.<>;:\_\-\+\"\(\)=\']){1,}).{8,}$/;
  if (control.value && !passRegexp.test(control.value)) {
    return { invalidPassword: true };
  }
  return {};
}

export function matchingPasswords(
  passwordKey: string,
  passwordConfirmationKey: string
) {
  return (group: UntypedFormGroup) => {
    const password = group.controls[passwordKey];
    const passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true });
    }
  };
}
