/**
 * Environment Configuration for production use
 */
export const environment = {
  production: true,
  cookie: false,
  api: "https://iot.rhinecloud.de/iotapi",
  sentryURL: "https://f19769816ef2458db5f997d6bb521be9@cd.dvv.de/6",
  sentryENV: "MASTER",
};
