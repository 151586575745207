import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenewPasswordComponent } from './renew-password.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from '../../services/token-interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { AppSettings } from '../../app.settings';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';

export const routes: Routes = [{ path: 'renewpassword', component: RenewPasswordComponent }];

@NgModule({
  declarations: [RenewPasswordComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forChild(routes),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatSidenavModule,
    FlexLayoutModule,
  ],
  providers: [HttpClient, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },AppSettings],
})
export class RenewPasswordModule {}
