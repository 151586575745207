import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { IDevice } from "../../model/devices";
import { BrokerService } from "../../services/broker.service";
import { NbiotDevicesService } from "../../services/nbiot-devices.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../services/uiconfig-service";
import { changeRouteData } from "../../shared/abstract/utils";
import { blockTransition } from "../../theme/utils/app-animation";
import { CreateNbiotdevDialogComponent } from "./create-nbiotdev-dialog/create-nbiotdev-dialog.component";

@Component({
  templateUrl: "./nbiot-devices.component.html",
  styleUrls: ["./nbiot-devices.component.scss"],
  animations: [blockTransition],
})
export class NbiotDevicesComponent implements OnInit {
  public settings: Settings;
  @ViewChild(MatSort) sort!: MatSort;
  public loading = false;
  public publicdata = false;
  public data?: MatTableDataSource<IDevice>;
  public EPermissions = EPermissions;
  public ERole = ERole;
  public displayedColumns = [
    "deviceName",
    "devEUI",
    "description",
    "lastSeenAt",
    "action",
  ];
  public tenantID: any;
  // Pagination
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };

  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    private dService: NbiotDevicesService,
    private bService: BrokerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {
      if (params && params.tenantID) {
        this.tenantID = params.tenantID;
      } else {
        this.tenantID = this.appSettings.currentTenantID();
      }
    });
  }

  ngOnInit(): void {
    this.loadDevices();
  }
  loadDevices() {
    this.loading = true;
    this.dService.getDevices(this.tenantID, this.pageEvent).subscribe({
      next: (data) => {
        if (!data) {
          this.loading = false;
          return;
        }
        if (data.total) {
          this.data = new MatTableDataSource(data.data);
          this.pageEvent.length = data.total;
        } else if (Array.isArray(data)) {
          this.data = new MatTableDataSource(data);
          this.pageEvent.length = data.length;
        } else {
          this.data = new MatTableDataSource<IDevice>([]);
        }
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
  deleteDevice(deviceID: string, name: string) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Gerät Löschen",
        `Wollen Sie wirklich das Gerät <strong>${name}</strong> löschen?`
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.dService.deleteDevice(deviceID).subscribe({
            next: (_) => {
              this.loadDevices();
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }

  editDevice(device: IDevice) {
    const dialogRef = this.dialog.open(CreateNbiotdevDialogComponent, {
      data: device,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((values) => {
      if (values) {
        const deviceDataProfileID = values.deviceDataProfileID;
        values.deviceKeys = { devEUI: values.devEUI };
        delete values.deviceDataProfileID;
        delete values.devEUI;
        this.dService
          .createNbiotDevice(
            {
              device: values,
              deviceDataProfileID,
            },
            true
          )
          .subscribe({
            next: (res) => {
              this.loadDevices();
            },
            error: (err) => {
              this.appSettings.getSwalError(err.error?.message || err.message);
            },
          });
      }
    });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDevices();
  }
  openDeviceDialog() {
    const dialogRef = this.dialog.open(CreateNbiotdevDialogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((values) => {
      if (values) {
        values["tenantID"] = this.tenantID;
        this.dService.createNbiotDevice({ device: values }).subscribe({
          next: (res) => {
            this.loadDevices();
          },
          error: (err) => {
            this.appSettings.getSwalError(err.error?.message || err.message);
          },
        });
      }
    });
  }
  applyFilter(filterValue: string) {
    if (this.data) this.data.filter = filterValue.trim().toLowerCase();
  }
  getDeviceInfo(row: IDevice) {
    changeRouteData(this.router, "NBIoT/" + row.deviceName, "nbiot/devices");
    if (this.appSettings.currentTenantID() === "superadmin") {
      this.router.navigate([
        "superadmin",
        "tenants",
        {
          outlets: {
            _management: ["devices", "nbiot", row.devEUI, this.tenantID],
          },
        },
      ]);
    } else {
      if (this.publicdata) {
        this.router.navigateByUrl(
          "nbiot/devices/public/" + row.devEUI + "/public"
        );
      } else {
        this.router.navigateByUrl(
          "nbiot/devices/nbiot/" +
            row.devEUI +
            "/" +
            this.appSettings.currentTenantID()
        );
      }
    }
  }
}
