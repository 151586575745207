import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PageEvent} from "@angular/material/paginator";
import {IStreamProfiles} from '../model/datatstream';
import {
  ICreateDeviceDataProfile,
  IDeviceDataProfileList,
  IDeviceDataProfileListPaginat
} from "../model/devicedataprofile";
import {EDataType} from "../model/enum/dataType";
import {AbstractService, UserInfo} from "./abstract.service";
@Injectable({
  providedIn: "root",
})
export class DeviceDataProfileService extends AbstractService {
  constructor(public http: HttpClient, private info: UserInfo) {
    super("");
  }
  /**
   * Getting data of device-data-profiles
   */
  getDeviceDataProfile(
    dataType: EDataType = 0,
    tenantID?: string,
    pagination?: PageEvent, search?: string, par?: {[param: string]: string}
  ) {
    const headers = this.headers;
    const url = `${this.url}${dataType == 2 ? "datastream" : EDataType[dataType]
      }/profiles`;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : this.info.TenantID,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      Object.keys(par).forEach((key: string) => {
        params = params.append(key, par[key]);
      });
    }

    return this.http.get<
      IDeviceDataProfileList[] & IDeviceDataProfileListPaginat
    >(url, {headers, params});
  }

  /**
   *
   * @param ID
   */
  getDeviceDataProfilebyID(ID: string, dataType: EDataType, fields?: string) {
    const headers = this.headers;
    let params = new HttpParams({});
    if (fields) {
      params = params.append("fields", fields);
    }
    // params = params.append("tenantID", this.info.TenantID);
    const url = `${this.url}${EDataType[dataType]}/profiles/${ID}`;
    return this.http.get<ICreateDeviceDataProfile | IStreamProfiles>(url, {headers, params});
  }
  createDeviceDataProfile(data: any, dataType: EDataType) {
    const headers = this.headers;
    const url = `${this.url}${EDataType[dataType]}/profiles`;
    return this.http.post(url, data, {headers});
  }
  updateDeviceDataProfile(data: any, dataType: EDataType) {
    const headers = this.headers;
    const url = `${this.url}${EDataType[dataType]}/profiles`;
    return this.http.put(url, data, {headers});
  }
  /**
   * Removing an devicedataprofile
   * @param devicedataprofileID id to be deleted
   */
  deleteDeviceDataProfile(devicedataprofileID: string, dataType: EDataType) {
    const headers = this.headers;
    const url =
      `${this.url}${EDataType[dataType]}/profiles/` + devicedataprofileID;
    return this.http.delete(url, {headers});
  }
  getMyTenantID() {
    return this.info.TenantID;
  }
}
