<mat-grid-list cols="2" rowHeight="200px">
  <mat-grid-tile
      *ngFor="let tile of data"
      [colspan]="tile.cols"
      [rowspan]="tile.rows">
    <img class="tileimage" [src]="tile.text" [alt]="tile.text"/>
    <div class="header">{{tile.title}}</div>
    <button class="dvvButton" (click)="openDetails()">Details</button>
  </mat-grid-tile>
</mat-grid-list>
