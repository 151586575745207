import { UntypedFormGroup } from "@angular/forms";
import { BaseDialogFooterButtonComponent } from "./base-dialog-footer-button.component";

export class BaseDialogFooterSaveButtonComponent extends BaseDialogFooterButtonComponent {
  constructor() {
    super();

    this.setId("input-dialog-save");
    this.setLabel("Erstellen");
    this.setColor("primary");
    this.setCloseValue("closeValue");
    this.setDisabled(true);
    this.setTabindex(2);
  }

  /**
   * Updates given save button by a form by checking validation and values of it
   *
   * @param form - Form that has been changed
   */
  public updateSaveButtonByForm(form: UntypedFormGroup) {
    this.closeValue = form.value;
    this.disabled = form.invalid;
  }
}
