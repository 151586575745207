<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>
<div mat-dialog-content>
  <div class="flex-p" fxFlexFill *ngIf="showTable">
    <div fxLayout="row" fxLayoutGap="6px" *ngIf="!apiApp">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" />
      </mat-form-field>
      <mat-form-field *ngIf="!data.multicast">
        <mat-select placeholder="Geräteart" [value]="dataType" (selectionChange)="deviceTypeSelect($event)">
          <mat-option *ngFor="let item of EDataType | enumToArray" [value]="item.index">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="data.multicast&&!data.multicast.del">
        <app-profile-select [dataType]="dataType" [group]="form" controlName="dataProfile"
          (selectionChange)="datenProfileSelect($event)"></app-profile-select>
      </ng-container>
      <mat-slide-toggle *ngIf="!data.multicast" (change)="publicSelect($event.checked)">Public</mat-slide-toggle>
    </div>
    <table mat-table [dataSource]="devices" *ngIf="devices" class="w-100 block" aria-describedby="Geräte">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef scope="col">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; let i = index">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row.devEUI) : null"
            [checked]="selection.isSelected(row.devEUI)" [aria-label]="checkboxLabel(row, i)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef scope="col">Bezeichnung</th>
        <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
      </ng-container>
      <ng-container matColumnDef="devEUI">
        <th mat-header-cell *matHeaderCellDef scope="col">devEUI</th>
        <td mat-cell *matCellDef="let element">{{ element.devEUI }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['select', 'name', 'devEUI']"></tr>
      <tr mat-row *matRowDef="
          let row;
          columns: ['select', 'name', 'devEUI'];
          let i = index
        " (click)="selection.toggle(row.devEUI)"></tr>
    </table>
    <mat-paginator #devPaginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize"
      showFirstLastButtons [length]="pageEvent.length" (page)="onPagination($event)">
    </mat-paginator>
  </div>

  <div fxFlex="100%" fxFlexAlign="center" *ngIf="!showTable && !loading">
    <span>Es stehen keine Geräte für die Auswahl zur Verfügung</span>
  </div>
  <div *ngIf="!showTable && loading" fxFill fxLayoutAlign="center center" class="instance-spinner">
    <mat-spinner color="primary"></mat-spinner>
  </div>
</div>
<base-dialog-footer #dialogFooter></base-dialog-footer>
