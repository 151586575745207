import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { map, Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import {
  IAddResponse,
  IDeletedResponse,
  IUpdateResponse,
} from "../model/broker";
import { MIDeviceProfiles } from "../model/loraDeviceProfile";
import { IPagination } from "../model/pagination";
import { getParams } from "../shared/abstract/utils";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class LoraDeviceProfilesService extends AbstractService {
  constructor(public http: HttpClient, private appSettings: AppSettings) {
    super("lora/device-profiles");
  }
  getLoraDeviceProfiles(
    tenantID?: string,
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string | boolean | number }
  ) {
    if (!tenantID) {
      tenantID = this.appSettings.currentTenantID();
    }
    let params = new HttpParams({
      fromObject: {
        tenantID,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<MIDeviceProfiles[] | IPagination<MIDeviceProfiles>>(
      this.url,
      {
        headers: this.headers,
        params,
      }
    );
  }
  delLoraDeviceProfile(id: string) {
    return this.http.delete<IDeletedResponse>(`${this.url}/${id}`);
  }
  addLoraDeviceProfile(form: any): Observable<IAddResponse> {
    form["tenantID"] = this.appSettings.currentTenantID();
    let params = new HttpParams({
      fromObject: {
        tenantID: this.appSettings.currentTenantID(),
      },
    });
    if (form.deviceProfile.id) {
      return this.http
        .put<IUpdateResponse>(`${this.url}`, form, {
          params,
          headers: this.headers,
        })
        .pipe(
          map((v) => {
            if (v.ModifiedCount > 0) {
              return { insertedIDs: [form.deviceProfile.id] };
            } else {
              return { insertedIDs: [] };
            }
          })
        );
    }
    return this.http.post<IAddResponse>(`${this.url}`, form, {
      params,
      headers: this.headers,
    });
  }
}
