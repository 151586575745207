import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { ESubscriptionType } from "../../../model/enum/subscriptions";
import {
  IAlertSubscrPaginat,
  IResourceFilter,
  ISubscription,
} from "../../../model/subscriptions";
import { SubscriptionsService } from "../../../services/subscriptions.service";
import {
  EPermissions,
  ERole,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { blockTransition } from "../../../theme/utils/app-animation";

@Component({
  selector: "app-mqdata",
  templateUrl: "./mqdata.component.html",
  styleUrls: ["./mqdata.component.scss"],
  animations: [blockTransition],
})
export class MqdataComponent implements OnInit {
  public displayedColumns = ["name", "devices", "topic", "createdAt", "action"];
  public settings: Settings;
  public EPermissions = EPermissions;
  public ERole = ERole;
  @ViewChild(MatSort) sort!: MatSort;
  public loading = false;
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };

  public data?: MatTableDataSource<ISubscription>;
  private myTenantID: string;
  constructor(
    public uiService: UIConfigService,
    public appSettings: AppSettings,
    private sService: SubscriptionsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {});
    this.myTenantID = appSettings.currentTenantID();
  }

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    this.loading = true;
    this.sService
      .getSubscriptions(
        undefined,
        ESubscriptionType.DeviceDataMQ,
        this.pageEvent,
        undefined,
        { fields: "name,resourceFilter,createdAt" }
      )
      .subscribe({
        next: (res) => {
          res = res as IAlertSubscrPaginat;
          // console.log(res);
          if (res && res.data) {
            this.data = new MatTableDataSource(res.data);
            this.pageEvent.length = res.total;
          } else {
            this.data = new MatTableDataSource<ISubscription>([]);
          }
          this.loading = false;
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadData();
  }
  applyFilter(filterValue: string) {
    if (this.data) this.data.filter = filterValue.trim().toLowerCase();
  }
  getDevices(filter: IResourceFilter) {
    if (filter.deviceName) {
      return filter.deviceName;
    } else if (filter.groupName) {
      return filter.groupName;
    }
    return "";
  }
  getMQTopic(filter: IResourceFilter) {
    if (filter.devEUI) {
      return `${this.myTenantID}/devicedata/${filter.devEUI}`;
    } else if (filter.groupKey) {
      return `${this.myTenantID}/group/${filter.groupKey}, ${this.myTenantID}/devicedata/'devEUI von Geräten'`;
    }
    return "";
  }
  deleteSubscription(elem: ISubscription) {
    this.appSettings
      .getSwalDefaultConfirm(
        "Subscription löschen?",
        "Wollen Sie wirklich keine Daten mehr per MQTT von <b>" +
          elem.name +
          "</b>" +
          " bekommen?"
      )
      .then((result) => {
        if (result.isConfirmed && elem._id) {
          this.sService.deleteSubscription(elem._id).subscribe({
            next: () => {
              this.loadData();
              this.appSettings.getSwalSuccess(
                "MQTT Subscription wurde entfernt"
              );
            },
            error: (err) =>
              this.appSettings.getSwalError(err.error?.message || err.message),
          });
        }
      });
  }
}
