import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { firstValueFrom, map, Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { IDevice, IDevicePaginat, IGetDeviceKeys } from "../model/devices";
import { getParams } from "../shared/abstract/utils";
import { AbstractService } from "./abstract.service";

@Injectable({
  providedIn: "root",
})
export class LoraDeviceService extends AbstractService {
  /**
   * Getting all devices
   * @param http httpclient
   */
  constructor(public http: HttpClient, private appSettings: AppSettings) {
    super("lora/devices");
  }

  /**
   * Get the devices
   * @param isPublic if tenantspecific devices or public devices
   */
  getDevices(
    isPublic = false,
    tenantID?: string,
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string }
  ): Observable<IDevicePaginat & IDevice[]> {
    if (tenantID) {
      return this.getDevicesByTenantId(tenantID, pagination, search, par);
    }
    const url = this.url;
    let params = new HttpParams();
    if (isPublic === true) {
      params = params.append("tenantID", "public");
    } else {
      params = params.append("tenantID", this.appSettings.currentTenantID());
    }
    const headers = this.headers;

    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IDevicePaginat & IDevice[]>(url, { headers, params });
  }

  getDevice(devEUI: string | string[]): Observable<IDevice | IDevice[]> {
    const headers = this.headers;
    if (Array.isArray(devEUI)) {
      devEUI = devEUI.join(",");
    }
    const params = new HttpParams({
      fromObject: {
        devEUI: devEUI,
        tenantID: this.appSettings.currentTenantID(),
      },
    });
    return this.http
      .get<IDevice | IDevice[]>(this.url, { headers, params })
      .pipe(
        map((item) => {
          if (Array.isArray(item)) {
            item.map((i) => delete i.deviceKeys);
          } else {
            delete item.deviceKeys;
          }
          return item;
        })
      );
  }
  getDevicesQuery(body: any) {
    const headers = this.headers;
    return firstValueFrom(
      this.http.post<IDevice | IDevice[]>(this.url + "-query", body, {
        headers,
      })
    );
  }
  /**
   *
   *
   * @param {string} tenantID
   * @returns IDevice[]
   * @memberof DeviceService
   */
  getDevicesByTenantId(
    tenantID?: string,
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string }
  ): Observable<IDevicePaginat & IDevice[]> {
    const url = this.url;
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : this.appSettings.currentTenantID(),
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IDevicePaginat & IDevice[]>(url, { headers, params });
  }

  getDevicesByTag(tagKey: string, pagination?: PageEvent) {
    const url = this.url;
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tags: tagKey,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    return this.http.get<IDevice[] & IDevicePaginat>(url, { headers, params });
  }

  /**
   * Getting devices for tenant by their ProfileID
   * @param ddProfileID: string
   * @param tenantID?: string
   * @memberof DeviceService
   */
  getDevicesByDDProfileId(
    ddProfileID: string,
    tenantID?: string,
    pagination?: PageEvent,
    search?: string,
    par?: { [param: string]: string }
  ): Observable<IDevicePaginat & IDevice[]> {
    const url = `${this.url}?deviceDataProfileID=${ddProfileID}`;
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID || this.appSettings.currentTenantID(),
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    if (search) {
      params = params.append("search", search);
    }
    if (par) {
      params = getParams(params, par);
    }
    return this.http.get<IDevicePaginat & IDevice[]>(url, { headers, params });
  }
  /**
   *
   *
   * @param {string[]} devEUI
   * @returns {Observable<string[]>}
   * @memberof DeviceServicek
   */
  getDeviceKeys(
    devices: IDevice[],
    tenantID?: string,
    _public?: boolean
  ): Observable<string[]> {
    return new Observable((observer) => {
      if (!devices || devices.length <= 0) {
        observer.next();
        observer.complete();
      } else {
        const body: any = {
          devEUI: devices.map((item) => {
            return item.devEUI;
          }),
        };
        const params = new HttpParams({
          fromObject: {
            tenantID: tenantID
              ? tenantID
              : _public
              ? "public"
              : this.appSettings.currentTenantID(),
          },
        });
        body["tenantID"] = tenantID
          ? tenantID
          : _public
          ? "public"
          : this.appSettings.currentTenantID();
        // body["limitLast"] = true;
        // body["projection"] = {
        //   devEUI: 1,
        //   "data.object": 1,
        // };
        const headers = this.headers;
        const url = `${this.api}/device-data-keys`;
        this.http
          .post<IGetDeviceKeys[]>(url, body, { headers, params })
          .subscribe((devData) => {
            if (devData && devData[0].object) {
              const r = this.getDeepKeys(devData[0].object);
              // console.log("keys", r);
              observer.next(r);
            } else {
              observer.next();
            }
            observer.complete();
          });
      }
    });
  }
  private getDeepKeys(obj: any, sub = false) {
    let keys: any[] = [];
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        keys.push(key);
        let subkeys;
        if (Array.isArray(obj[key])) {
          subkeys = this.getDeepKeys(obj[key][0], true);
          for (let i = 0; i < obj[key].length; i++) {
            keys = keys.concat(
              subkeys.map(function (subkey) {
                return key + "." + i + "." + subkey;
              })
            );
          }
          // subkeys = this.getDeepKeys(obj[key][0], true);
        } else {
          subkeys = this.getDeepKeys(obj[key], true);
          keys = keys.concat(
            subkeys.map(function (subkey) {
              return key + "." + subkey;
            })
          );
        }
      } else {
        keys.push(key);
      }
    }
    if (!sub) {
      keys.push("createdAt");
      keys.push("object");
    }
    return keys;
  }
  /**
   * Creating a device
   * @todo implementing different key types
   * @param devEUI
   * @param deviceName
   * @param deviceDescription
   * @param nwkKey
   */
  public createDevice(
    {
      devEUI,
      deviceName,
      description,
      appKey,
      nwkKey,
      deviceDataProfileID,
      deviceProfileID,
      devAddr,
      defaultLocation,
      serviceProfileID,
    }: any,
    tenantID?: string,
    update = false
  ): Observable<any> {
    const url = `${this.url}`;
    const headers = this.headers;
    let params = new HttpParams();
    const data = [];
    let device = {
      device: {
        description,
        deviceName,
        deviceKeys: {
          appKey,
          devEUI,
          nwkKey,
          devAddr,
        },
        defaultLocation,
      },
      deviceDataProfileID,
      deviceProfileID,
      serviceProfileID,
    };
    if (tenantID) {
      params = params.append("tenantID", tenantID);
    }
    defaultLocation
      ? (device.device["defaultLocation"] = defaultLocation)
      : null;
    data.push(device);
    if (update) {
      return this.http.put<any>(url, data, { headers, params });
    }
    return this.http.post<any>(url, data, { headers, params });
  }

  /**
   * Deleting a device
   * @param deviceID Id fo the device to be deleted
   */
  public deleteDevice(deviceID: string) {
    const headers = this.headers;
    const url = `${this.url}/` + deviceID;
    return this.http.delete(url, { headers });
  }
  public downloadExample() {
    const url = `${this.url}/import`;
    return this.http.get(url, { responseType: "blob" });
  }
  public uploadImportFile(data: any) {
    const url = `${this.url}/import`;
    const formData = new FormData();
    formData.append("excel_file", data);
    return this.http.post<{ errors: any[]; results: any[]; rows: number }>(
      url,
      formData
    );
  }
}
