<mat-sidenav-container>

  <div fxLayout="row" fxLayoutAlign="center center" class="h-100">

        <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">

            <mat-card class="p-0 mat-elevation-z24 box">
                <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
                    <button mat-fab color="accent" class="mat-elevation-z12">
                        <mat-icon>warning</mat-icon>
                    </button>
                    <h1 class="error" *ngIf="!expired">Auf Wiedersehen</h1>
                    <h1 class="error" *ngIf="expired">Ihre Sitzung ist abgelaufen</h1>
                </div>
                <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
                    <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner">
                        <!--<span class="box-content-header server-error">{{statusText}}</span>-->
                        <p class="box-text" *ngIf="!expired">Kommen Sie uns bald wieder besuchen.</p>
                        <p class="box-text" *ngIf="expired">Sie müssen sich erneut anmelden.</p>
                        <!--<p class="box-text">Versuchen Sie es gleich nochmal.</p>-->
                    </mat-card>
                    <button mat-raised-button color="primary" class="mat-elevation-z12 box-button" type="button" (click)="goHome()">Zur Anmeldung</button>
                </mat-card-content>
            </mat-card>

          </div>

  </div>

</mat-sidenav-container>
