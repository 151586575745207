import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { AppSettings } from "src/app/app.settings";
import { TemplateService } from "src/app/services/template.service";

@Component({
  selector: "app-template-selector",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [``],

  template: `
    <div>
      <mat-form-field class="w-100">
        <mat-select
          #temp_select
          placeholder="Template"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
          [value]="value"
        >
          <mat-option [value]=""> Default </mat-option>
          <mat-option *ngFor="let item of templates" [value]="item._id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
})
export class TemplateSelectorComponent implements OnInit {
  @Input() type!: string;
  @Input() value!: string;
  @Output() selectionChange = new EventEmitter();
  @ViewChild("temp_select") selectElem?: MatSelect;
  public loading = false;
  public search: UntypedFormControl = new UntypedFormControl();
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public templates: any[] | undefined;
  private scroll?: number;
  constructor(
    private tService: TemplateService,
    private appSettings: AppSettings,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadTemplates();
  }
  loadTemplates(fetch = false) {
    this.loading = true;
    this.tService
      .getTemplates(this.type, this.pageEvent, this.search.value, {
        fields: "name",
      })
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (res && res.data) {
            if (fetch && this.templates) {
              this.templates = this.templates.concat(res.data);
            } else {
              this.templates = res.data;
            }
            this.ref.detectChanges();
          }
        },
        error: (err) =>
          this.appSettings.getSwalError(err.error?.message || err.message),
      });
  }
  onChanges(evt: { name: string; value: string }): void {
    this.selectionChange.emit(evt);
  }
  private fetchMore() {
    if (
      this.pageEvent.length >
        (this.pageEvent.pageIndex + 1) * this.pageEvent.pageSize &&
      this.loading === false
    ) {
      this.pageEvent.pageIndex++;
      this.loading = true;
      this.ref.detectChanges();
      this.loadTemplates(true);
    }
  }
  registerPanelScrollEvent() {
    const panel = this.selectElem?.panel.nativeElement;
    panel.removeEventListener("scroll", () => {});
    panel.addEventListener("scroll", (event: any) => {
      if (event.target.scrollTop > 160 * (this.pageEvent.pageIndex * 2 + 1)) {
        this.scroll = event.target.scrollTop;
        this.fetchMore();
      }
    });
  }
}
