<ng-container>
<div class="flex-p" >
  <div class="banner">
    <div class="marketing">
      IoT by DVV
      <ul>
      <li>LORA</li>
      <li>NearBand</li>
      <li>individuelle IOT Lösungen</li>
    </ul>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
      <div class="teaser">
        <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="avatar-header-image"></div>
            <mat-card-title>Aktuelles</mat-card-title>
            <mat-card-subtitle>06.03.2020 - WestVisions Hackerthon</mat-card-subtitle>
          </mat-card-header>
          <img mat-card-image src="./assets/img/banner/banner_gateways.jpg" alt="Gateways">
          <mat-card-content>
            <p>
              Viel Spaß auf dem WestVisions Hackerthon der Krankikom in Duisburg.
            </p>
          </mat-card-content>
        </mat-card>
    </div>
    <div class="teaser">
      <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="avatar-header-image"></div>
            <mat-card-title>Störungen</mat-card-title>
            <mat-card-subtitle>Keine</mat-card-subtitle>
          </mat-card-header>
          <div class="news"><mat-icon class="success xl">check_circle</mat-icon></div>
          <mat-card-content>
            <p>
              Es liegen keine Störungen in der Infrastruktur vor.
            </p>
          </mat-card-content>
        </mat-card>
  </div>
  </div>
  </div>
</ng-container>
