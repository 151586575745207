import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from "@angular/router";
import { Subscription } from "rxjs";
import { AppSettings } from "./app.settings";
import { Settings } from "./app.settings.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public settings: Settings;
  private _subscription?: Subscription;
  constructor(
    injector: Injector,
    public appSettings: AppSettings,
    private router: Router
  ) {
    // const PopupElement = createCustomElement(TableButtonbarComponent, {
    //   injector,
    // });
    // Register the custom element with the browser.
    // customElements.define("popup-element", PopupElement);

    this.settings = this.appSettings.settings;
    //TODO: Entscheidung, welche Sprache wir unterstützen wollen.
    //      translate.addLangs(['en','de']);
  }

  ngOnInit() {
    this._subscription = this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.settings.loadingSpinner = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.settings.loadingSpinner = false;
      }
    });
  }
  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
}
