import { formatDate } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { connect, ECharts, graphic, registerMap } from "echarts";
import { environment } from "../../../environments/environment";
import { IChartOptions, IChartsData } from "../../model/chartsDefaultConfig";
import { IGetDeviceData } from "../../model/devices";
import { InjectorInstance } from "../../services/abstract.service";
import "./sunjs";

export function debounce(fn: any, delay: number) {
  let timer: NodeJS.Timeout | undefined = undefined;
  return function () {
    const self: any = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(self, args);
    }, delay);
  };
}
export function getDeepValue(obj: any, arg: any[]) {
  let current = obj;
  for (let i = 0; i < arg.length; i++) {
    if (current[arg[i]] || current[arg[i]] === false || current[arg[i]] === 0) {
      current = current[arg[i]];
    } else {
      return undefined;
    }
  }
  return current;
}
export function SetDeepValue(obj: any, arg: any[], value: any) {
  let i;
  for (i = 0; i < arg.length - 1; i++) {
    obj = obj[arg[i]];
  }
  obj[arg[i]] = value;
}
export function getDeepKeys(obj: any, sub = false) {
  let keys: any[] = [];
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      let subkeys;
      if (Array.isArray(obj[key])) {
        subkeys = getDeepKeys(obj[key][0], true);
      } else {
        subkeys = getDeepKeys(obj[key], true);
      }
      keys = keys.concat(
        subkeys.map(function (subkey) {
          return key + "." + subkey;
        })
      );
    } else {
      keys.push(key);
    }
  }
  if (!sub) {
    keys.push("createdAt");
    keys.push("object");
  }
  return keys;
}
function sendDataToLora(devEUI: string, payload: any, fPort?: number) {
  // const injector = Injector.create({
  //   providers: [{ provide: HttpClient, deps: [] }],
  // });
  // const httpClient: HttpClient = injector.get(HttpClient);
  const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
  httpClient
    .post(`${environment.api}/devices-broker-queue`, {
      devEUI,
      payload,
      fPort,
    })
    .subscribe({
      next: (res) => {},
      error: (err) => {
        console.log(err.error);
      },
    });
}
export function initChartConfig(
  Values: IChartsData[] | IGetDeviceData[],
  options: string,
  echartInstance?: ECharts,
  opts?: any
): Promise<IChartOptions> {
  if (!Values || Values.length <= 0) {
    Values = [
      {
        devEUI: "dummy",
        deviceName: "Keine Daten",
        xValue: [0],
        yValue: [0],
        zValue: [0],
        createdAt: [0],
      },
    ];
  }
  return new Promise((resolve, reject) => {
    try {
      let configFunc: any = new Function(
        "_value_",
        "_option_",
        "_module_",
        `${options} return getChartConfig(_value_,_option_,_module_)`
      );
      opts = opts ? opts : ({} as any);
      const object = configFunc(Values, opts, {
        formatDate,
        echartInstance,
        connect,
        graphic,
        CompassDir,
        sendDataToLora,
        registerMap,
      });
      configFunc = null;
      resolve(object);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
export function initVirtualFunktion(script: string, _module_: any) {
  return new Promise((resolve, reject) => {
    try {
      let deviceFunc: any = new Function(
        "_module_",
        `${script} return GetDataForDevice(_module_)`
      );
      const mods = Object.assign({}, { formatDate }, _module_);
      const object = deviceFunc(mods);
      deviceFunc = null;
      resolve(object);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
export function getStreamParser(func: string, value: any, _devEUI_: string) {
  return new Promise((resolve, reject) => {
    try {
      let parseFunc: any = new Function(
        "_value_,_devEUI_",
        func + " return Parse(_value_,_devEUI_)"
      );
      const object = parseFunc(value, _devEUI_);
      parseFunc = undefined;
      resolve(object);
    } catch (err) {
      reject(err);
    }
  });
}
export function ITerateObject(data: any) {
  for (const [key, value] of Object.entries(data)) {
    if (typeof value == "object") {
      ITerateObject(value);
    } else if (typeof value == "string" && value.indexOf("function") > -1) {
      data[key] = eval("(" + value + ")");
    }
  }
}
export function assignDeepValue(obj: any, keyPath: any[], value: any) {
  const lastKeyIndex = keyPath.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i];
    if (!(key in obj)) {
      if (typeof keyPath[i + 1] === "number") {
        obj[key] = [];
      } else {
        obj[key] = {};
      }
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
}
export function getChartOptsRegex(config: any): RegExp {
  switch (config) {
    case "min":
      return /_min_\s\S\s*(.*?);/gm;
    case "max":
      return /_max_\s\S\s*(.*?);/gm;
    case "axisLine_color":
      return /_axisLine_color_\s\S\s*\"*(.*?)\"\s*;/gm;
    default:
      return /^([\s\S\w\W]*?)*/gm;
  }
}
export function convert2Number(val: any) {
  if (!Array.isArray(val)) {
    if (val.includes("%")) {
      return val;
    }
    let tmp = 0;
    if (val.includes(".")) {
      tmp = parseFloat(val);
    } else {
      tmp = parseInt(val, 10);
    }
    if (!isNaN(tmp)) {
      return tmp;
    }
  } else {
    val
      .filter((item) => !item.includes("%"))
      .forEach((item, i) => {
        const tmp = parseInt(item, 10);
        if (!isNaN(tmp)) {
          val[i] = tmp;
        }
      });
  }
  return val;
}
export function getKeys(_in: any) {
  return Object.keys(_in);
}
export function isObject(val: any) {
  return typeof val === "object";
}
export function isBoolean(val: any) {
  return typeof val === "boolean";
}
export function isArray(val: any) {
  return Array.isArray(val);
}
export function getRandom(min = 10, max = 200) {
  return Math.floor(Math.random() * (max - min) + min);
}
// TODO: Not used.
export function Comparsion2String(com: string) {
  switch (com) {
    case "gte":
      return " >= ";
    case "gt":
      return " > ";
    case "lte":
      return " <= ";
    case "lt":
      return " < ";
    case "ne":
      return " != ";
    case "eq":
      return " == ";

    default:
      return com;
  }
}
/**
 * Any value to String with JSON.stringify
 * @param value: any
 */
export function json2str(data: any): string {
  if (isObject(data))
    return JSON.stringify(data, (name, value) => {
      if (value instanceof Error) {
        return {
          // Pull all enumerable properties, supporting properties on custom Errors
          ...value,
          // Explicitly pull Error's non-enumerable properties
          name: value.name,
          message: value.message,
          // stack: value.stack,
        };
      }

      return value;
    });
  else return data;
}
export function getUrlVars(url: string) {
  var vars: Record<any, any> = {};
  var parts = url.replace(
    /[?&]+([^=&]+)=?([^&]*)/gi,
    function (m: any, key: any, value: any) {
      vars[key] = value;
      return "";
    }
  );
  return vars;
}
export function getUrlParam(
  url: string,
  parameter: string,
  defaultvalue: string
) {
  var urlparameter = defaultvalue;
  if (url.indexOf(parameter) > -1) {
    urlparameter = getUrlVars(url)[parameter];
  }
  return urlparameter;
}
export function isDefined(value: any) {
  return !isUndefined(value);
}
function is(value: any, type: any): boolean {
  return typeof value === type;
}
export function isUndefined(value: any): true | false {
  return is(value, "undefined") || value == null;
}

export function isString(value: any) {
  return is(value, "string");
}

export function isNumber(value: any) {
  return is(value, "number");
}

export function isFunction(value: any) {
  return is(value, "function");
}
function CompassDir(value: any) {
  const sector = [
    "N",
    "NNO",
    "NO",
    "ONO",
    "O",
    "OSO",
    "SO",
    "SSO",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
    "N",
  ];
  return sector[Math.round((value % 360) / 22.5 + 1)];
}
export function changeRouteData(
  router: Router,
  breadcrumb: string,
  dataType: string | number,
  rootPath = ""
) {
  const route = router.config.find(
    (r) => r.path === rootPath && r.data && r.data.dataType === dataType
  );
  if (route && route.path && route.data) {
    route.data.breadcrumb = breadcrumb;
  }
}
export function getParams(
  params: HttpParams,
  par: { [param: string]: string | boolean | number }
) {
  Object.keys(par).forEach((key: string) => {
    params = params.append(key, par[key]);
  });
  return params;
}
