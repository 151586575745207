import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SubscriptionGuard } from "../../services/subscription.guard";
import { EPermissions } from "../../services/uiconfig-service";
import { CreateDatastreamDeviceComponent } from "../datastreams/create-datastream-device/create-datastream-device.component";
import { ManageAlertingComponent } from "./alerting/manage-alerting/manage-alerting.component";
import { RestApiAlertComponent } from "./alerting/rest-api-alert/rest-api-alert.component";
import { ApiUserComponent } from "./api-user/api-user.component";
import { DataInputComponent } from "./data-input/data-input.component";
import { CreateForwardingComponent } from "./forwarding/create-forwarding/create-forwarding.component";
import { ForwardingComponent } from "./forwarding/forwarding.component";
import { MqdataComponent } from "./mqdata/mqdata.component";
import { CreateTemplateComponent } from "./template/email/create-template/create-template.component";
import { EmailComponent } from "./template/email/email.component";

export const routes: Routes = [
  {
    path: "device-data-alert",
    component: ManageAlertingComponent,
    data: { breadcrumb: "Alarmierung", permissions: EPermissions.ALERTS },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "restful-api-profiles",
    component: RestApiAlertComponent,
    data: {
      breadcrumb: "RESTful API Profile",
      permissions: EPermissions.API_PROFILES,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "device-data-mqtt",
    component: MqdataComponent,
    data: { breadcrumb: "MQTT", permissions: EPermissions.MQTT },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "api-devices",
    component: ApiUserComponent,
    data: {
      breadcrumb: "API Daten",
      permissions: EPermissions.DEVICE_DATA_API,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "forwarding",
    component: ForwardingComponent,
    data: {
      breadcrumb: "Weiterleitung",
      permissions: EPermissions.DATA_FORWARD,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "forwarding-add",
    component: CreateForwardingComponent,
    data: {
      breadcrumb: "Weiterleitung / Hinzufügen",
      permissions: EPermissions.DATA_FORWARD,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "data-input",
    component: DataInputComponent,
    data: {
      breadcrumb: "API Application",
      permissions: EPermissions.API_APPLICATION,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "data-input/createdevice",
    component: CreateDatastreamDeviceComponent,
    data: {
      breadcrumb: "API Application / Gerät Hinzufügen",
      permissions: EPermissions.API_APPLICATION,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "email_template",
    component: EmailComponent,
    data: {
      breadcrumb: "Email Templates",
      permissions: EPermissions.ALERTS,
    },
    canActivate: [SubscriptionGuard],
  },
  {
    path: "email_template/create",
    pathMatch: "full",
    component: CreateTemplateComponent,
    data: {
      breadcrumb: "Create",
      permissions: EPermissions.ALERTS,
    },
    canActivate: [SubscriptionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
