<mat-toolbar color="primary" [fxLayoutAlign]="
    settings.menuType !== 'mini' ? 'space-between center' : 'center center'
  " class="sidenav-header">
  <!--<a mat-raised-button color="accent" routerLink="/" (click)="closeSubMenus()" class="small-logo">G</a>-->
  <!-- <a *ngIf="settings.menuType == 'default'" class="logo" routerLink="/" (click)="closeSubMenus()"><img style="height: 33px;" [src]="cloudImage" alt="cloud-image"></a> -->
  <!-- <svg *ngIf="settings.menuType != 'mini'" class="pin" (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned"> -->
  <!-- <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" /> -->
  <!-- <path *ngIf="settings.sidenavIsPinned" d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" /> -->
  <!-- </svg> -->
</mat-toolbar>

<div fxLayout="column" fxLayoutAlign="center center" class="user-block transition-2"
  [class.show]="settings.sidenavUserBlock">
  <div [fxLayout]="settings.menuType !== 'default' ? 'column' : 'row'" [fxLayoutAlign]="
      settings.menuType !== 'default' ? 'center center' : 'space-around center'
    " class="user-info-wrapper">
    <div class="user-info">
      <p class="name">{{ Username }}</p>
      <p *ngIf="Permissions" style="text-transform:uppercase; font-size: smaller;"> {{ Permissions }}</p>
      <mat-select *ngIf="settings.multiTenant" [value]="selectedTenant" class="w-200p">
        <mat-option *ngFor="let tenant of tenants" [value]="tenant.tenantID"
          (onSelectionChange)="onTenantChanged($event)">
          {{tenant.name}}
        </mat-option>
      </mat-select>
      <p fxLayout="column" fxLayoutAlign="center center" *ngIf="lastLogin&&lastLogin >0">
        <small *ngIf="lastLogin"> Letzte Anmeldung</small>
        <small>{{ lastLogin|date:"short" }}</small>
      </p>

      <!-- <br> <small class="muted-text">Eingelogt seit:
                        {{Logintime | localizedDate:"dd MMMM, yyyy HH:mm" }}</small> -->
    </div>
  </div>
  <div *ngIf="settings.menuType !== 'mini'" fxLayout="row" fxLayoutAlign="space-around center" class="w-100 muted-text">
    <!--        <button mat-icon-button><mat-icon>person_outline</mat-icon></button>
                <a mat-icon-button routerLink="/mailbox">
                    <mat-icon>mail_outline</mat-icon>
                </a>
        -->
    <a mat-icon-button routerLink="/logout">
      <mat-icon [matTooltip]="'Abmelden'" matTooltipPosition="above">power_settings_new</mat-icon>
    </a>
  </div>
</div>

<perfect-scrollbar id="sidenav-menu-outer" class="sidenav-menu-outer"
  [class.user-block-show]="settings.sidenavUserBlock">
  <span *ngIf="!menuItems">laden....</span>
  <app-vertical-menu *ngIf="menuItems" [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>
</perfect-scrollbar>
