import { OverlayContainer } from "@angular/cdk/overlay";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import {
  ErrorHandler,
  Injectable,
  Injector,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as echarts from "echarts";

import { Map3DChart } from "echarts-gl/charts";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { MonacoEditorModule } from "ngx-monaco-editor-v2";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { AppSettings } from "./app.settings";
import { SaveChartConfigComponent } from "./pages/charts-admin/charts-admin.component";
import { ContractsModule } from "./pages/contracts/contracts.module";
import { DataStreamsModule } from "./pages/datastreams/datastreams.module";
import { GroupsModule } from "./pages/groups/groups.module";
import { LandingPageModule } from "./pages/landing-page/landing-page/landing-page.module";
import { LogViewerComponent } from "./pages/log-viewer/log-viewer.component";
import { LogOutComponent } from "./pages/logout/logout.component";
import { DeviceDataProfileModule } from "./pages/lora-devices/devicedataprofile/devicedataprofile.module";
import { DevicesModule } from "./pages/lora-devices/devices-lora.module";
import { MarketplaceModule } from "./pages/marketplace/marketplace.module";
import { NbiotDevicesModule } from "./pages/nbiot-devices/devices-nbiot.module";
import { PagesComponent } from "./pages/pages.component";
import { RenewPasswordModule } from "./pages/renew-password/renew-password.module";
import { ResetPasswordModule } from "./pages/reset-password/reset-password.module";
import { SubscriptionModule } from "./pages/subscriptions/subscription.module";
import { DisableControlDirective } from "./pages/users/disable-control.directive";
import { UsersModule } from "./pages/users/users.module";
import { VirtualDevicesModule } from "./pages/virtual-devices/virtual-devices.module";
import { WelcomeModule } from "./pages/welcome/welcome.module";
import { SendDataInjectable } from "./services/abstract.service";
import { CloudLoginGuard } from "./services/cloud-login.guard";
import { GlobalVariablesService } from "./services/global-variables.service";
import { TokenInterceptor } from "./services/token-interceptor";
import { UserLoginService } from "./services/user-login.service";
import { WindowRef } from "./services/WindowRef";
import "./shared/components/liquidFill/liquidFill";
import "./shared/components/map-controller/echarts-leaflet";
import { SharedUIModule } from "./shared/components/sharedui/sharedui.module";
import { MatPaginatorIntlDe } from "./shared/MatPaginatorIntlDe";
import { SharedModule } from "./shared/shared.module";
import { ApplicationsComponent } from "./theme/components/applications/applications.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";
import { FlagsMenuComponent } from "./theme/components/flags-menu/flags-menu.component";
import { FullScreenComponent } from "./theme/components/fullscreen/fullscreen.component";
import { VerticalMenuComponent } from "./theme/components/menu/vertical-menu/vertical-menu.component";
import { MessagesComponent } from "./theme/components/messages/messages.component";
import { SidenavComponent } from "./theme/components/sidenav/sidenav.component";
import { UserMenuComponent } from "./theme/components/user-menu/user-menu.component";
import { UserSettingsModule } from "./theme/components/user-menu/user-settings/user-settings.module";
import { PipesModule } from "./theme/pipes/pipes.module";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { UtilsService } from "./theme/utils/utils-service.service";

echarts.use([Map3DChart]);
// Sentry Connection

// export let InjectorInstance: Injector;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    console.log(err);
  }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
};

registerLocaleData(localeDe);
// export const ENDPOINT: InjectionToken<string> = new InjectionToken<string>('ENDPOINT');

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SharedModule,
    PipesModule,
    AppRoutingModule,
    HttpClientModule,
    UsersModule,
    DeviceDataProfileModule,
    DevicesModule,
    NbiotDevicesModule,
    GroupsModule,
    ContractsModule,
    ResetPasswordModule,
    RenewPasswordModule,
    MatGridListModule,
    DataStreamsModule,
    MarketplaceModule,
    WelcomeModule,
    SharedUIModule,
    SubscriptionModule,
    NgxJsonViewerModule,
    LandingPageModule,
    UserSettingsModule,
    VirtualDevicesModule,
    MonacoEditorModule.forRoot({
      defaultOptions: {
        language: "javascript",
        automaticLayout: true,
        range: {
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: 1,
          endColumn: 1,
        },
      },
    }),
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    LogOutComponent,
    SidenavComponent,
    VerticalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    DisableControlDirective,
    LogViewerComponent,
    SaveChartConfigComponent,
  ],
  // entryComponents: [SaveChartConfigComponent],
  providers: [
    AppSettings,
    GlobalVariablesService,
    DatePipe,
    { provide: LOCALE_ID, useValue: "de-DE" },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
    CloudLoginGuard,
    UserLoginService,
    HttpClient,
    UtilsService,
    WindowRef,
    // { provide: ENDPOINT, useValue: '' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    new SendDataInjectable(this.injector);
  }
}
