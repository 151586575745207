<div fxLayout="column" fxFill [formGroup]="form" class="flex-p">
  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    class="flex-p"
    fxLayoutGap="8px"
  >
    <button mat-raised-button color="warn" (click)="abbrechen()">
      Abbrechen
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="createStreamDevice()"
      [disabled]="form.invalid"
    >
      {{ buttonLabel }}
    </button>
  </div>
  <mat-divider> </mat-divider>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <input matInput placeholder="Bezeichnung" formControlName="deviceName" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <input
        matInput
        placeholder="Beschreibung"
        formControlName="description"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <input
        matInput
        placeholder="Primärschlüssel (devEUI)"
        formControlName="devEUI"
      />
      <mat-hint *ngIf="form.get('devEUI')?.untouched"
        >Leer lassen, um automatisch eine ID generieren zu lassen</mat-hint
      >
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="dataType === 3">
    <mat-form-field class="w-min-300p flex-p">
      <mat-select
        placeholder="Häufigkeit"
        formControlName="interval"
        class="w-min-300p"
      >
        <mat-option *ngFor="let item of Turnus" [value]="item.value">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="dataType === 2 && !apiApp"
  >
    <app-profile-select
      class="w-min-300p"
      style="min-width: 300px !important"
      [dataType]="2"
      controlName="dataStreamProfileID"
      [group]="form"
      placeholder="Datenquelle Profil"
      (selectionChange)="profileChanaged($event)"
      (profileLoaded)="onProfileLoaded($event)"
    >
    </app-profile-select>
    <!-- <mat-form-field class="w-min-300p">
      <mat-select
        placeholder="Datenquelle Profil"
        formControlName="dataStreamProfile"
      >
        <mat-option *ngFor="let item of streamProfiles" [value]="item">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <button
      mat-icon-button
      color="primary"
      [disabled]="form.get('dataStreamProfileID')?.invalid"
      matTooltip="Anpassen"
      (click)="editDataStreamProfile()"
      style="position: absolute; margin-left: 345px"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <span fxLayoutAlign="center center" class="mt-2">Geräte-Position</span>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="6px"
    formGroupName="defaultLocation"
  >
    <mat-form-field>
      <input
        matInput
        placeholder="Latitude"
        formControlName="latitude"
        type="number"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Longitude"
        formControlName="longitude"
        type="number"
      />
    </mat-form-field>
    <button mat-icon-button color="primary" (click)="setLocation()">
      <mat-icon>map</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!apiApp">
    <button
      *ngIf="dataType === 2"
      mat-raised-button
      color="primary"
      (click)="loadStreamData()"
      [disabled]="form.get('dataStreamProfileID')?.invalid"
    >
      Daten holen
    </button>
    <button
      *ngIf="dataType === 3"
      mat-raised-button
      color="primary"
      (click)="getSkriptForVirtual()"
    >
      Skript ausführen
    </button>
    <mat-spinner
      diameter="40"
      style="position: absolute; margin-left: 80px"
      *ngIf="loading"
    >
    </mat-spinner>
  </div>
  <!-- resize: vertical;overflow: auto; -->
  <div fxLayout="row" style="min-height: 400px">
    <h3 class="w-200p">Daten konvertieren</h3>
    <ngx-monaco-editor
      *ngIf="dataType === 2 || dataType === 4"
      fxLayout="row"
      class="dvv-code-editor w-100 flex-p"
      style="height: 100%"
      [options]="ParserOptions"
      formControlName="dataStreamFunction"
      (onInit)="onMonacoInit($event)"
    ></ngx-monaco-editor>
    <ngx-monaco-editor
      *ngIf="dataType === 3"
      fxLayout="row"
      class="dvv-code-editor w-100 flex-p"
      style="height: 100%"
      [options]="ParserOptions"
      formControlName="deviceDataFunction"
      (onInit)="onMonacoInit($event)"
    >
    </ngx-monaco-editor>
  </div>
  <div fxLayout="row" *ngIf="!apiApp">
    <h3 class="w-200p">Daten betrachten</h3>
    <ngx-json-viewer
      [json]="requestData"
      [expanded]="false"
      class="w-100 flex-p"
    ></ngx-json-viewer>
  </div>
</div>
