export enum ESubscriptionType {
  DeviceData = "DEVICE_DATA", // Subscriptions to device data
  ApiDeviceData = "DEVICE_DATA_API", // Subscriptions to device data
  DeviceDataMQ = "DEVICE_DATA_MQ", // Subscriptions to device data using MQ
  DeviceDataForward = "DEVICE_DATA_FORWARD", // Subscriptions to device data using MQ
  CustomerDevice = "CUSTOMER_DEVICE", // Own devices
  SharedDeviceData = "SHARED_DEVICE_DATA", // Subscriptions to shared device data
  CustomerMQTT = "CUSTOMER_MQTT", // Own MQ_TT Instance
  CustomerMQTTAlert = "CUSTOMER_MQTT_ALERT", // Alerts based upon customer mqtt data
  DeviceDataAlert = "DEVICE_DATA_ALERT",
  DEviceDataInput = "DEVICE_DATA_INPUT", // Daten per POST
}

export enum EActionType {
  SEND_EMAIL = "SEND_MAIL",
  SEND_SMS = "SEND_SMS",
  REST_API = "REST_API",
}
