<div fxLayout="column" class="flex-p" fxLayoutGap="8px">

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <button mat-mini-fab color="primary" (click)="createDataStream()" matTooltip="Erstellen"
      *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
      <mat-icon>add</mat-icon>
    </button>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Suchen" #search />
      <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="applyFilter('');search.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <div fxFill @blockTransition *ngIf="streamProfiles">
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageEvent.pageSize" showFirstLastButtons
        [length]="pageEvent.length" (page)="onPagination($event)">
      </mat-paginator>
      <table mat-table [dataSource]="streamProfiles" matSort class="w-100 block" fxFill
        aria-describedby="Datastream Profiles" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bezeichnung</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="mainLink">
          <th mat-header-cell *matHeaderCellDef scope="col">Adresse</th>
          <td mat-cell *matCellDef="let element">
            {{ element.requestURL.substring(0, 60) + "..." }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastSeenAt">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
            Zuletzt aktualisiert
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.lastSeenAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dataInterval">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Häufigkeit</th>
          <td mat-cell *matCellDef="let element">
            {{ element.interval }}
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>
            Profile geändert
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: gray; line-height: 30px;">today</mat-icon>
            {{ element.updatedAt | localizedDate: "dd.MM.yyyy HH:mm:ss" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="responseType">
          <th mat-header-cell *matHeaderCellDef scope="col">Response Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element.responseType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef scope="col">Erstellt am</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col">Aktion</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="devMenu" (click)="$event.stopPropagation()"
              *ngIf="uiService.getUIPermissions(EPermissions.DEVICES)">
              <mat-icon class="color-primary">more_vert</mat-icon>
            </button>
            <mat-menu #devMenu="matMenu">
              <button mat-menu-item (click)="createDataStream(element)">
                <mat-icon class="color-primary">edit</mat-icon>
                <span>Anpassen</span>
              </button>
              <button mat-menu-item (click)="deleteDataStream(element)">
                <mat-icon class="color-ascend">delete</mat-icon>
                <span>Löschen</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row" style="height: 35px"></tr>
      </table>
    </div>
  </div>
</div>
<div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="instance-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
