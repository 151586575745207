<base-dialog-header (onClose)="onClose()" #dialogHeader></base-dialog-header>

<div mat-dialog-content [formGroup]="form">
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <input
        matInput
        placeholder="Gerätename"
        value=""
        formControlName="deviceName"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <input
        matInput
        placeholder="Gerätebeschreibung"
        value=""
        formControlName="description"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <input
        matInput
        placeholder="devEUI(IMEI)"
        value=""
        formControlName="devEUI"
      />
    </mat-form-field>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-select
        placeholder="Data Profile"
        formControlName="deviceDataProfileID"
      >
        <mat-option *ngFor="let item of deviceDataProfiles" [value]="item._id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<base-dialog-footer #dialogFooter></base-dialog-footer>
