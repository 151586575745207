import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ELogEvents } from "../../model/enum/log-events";
import { ILogEvent, ILogEventPaginate } from "../../model/logEvents";
import { UserInfo } from "../../services/abstract.service";
import { SystemLogService } from "../../services/system-log.service";

@Component({
  selector: "app-log-viewer",
  templateUrl: "./log-viewer.component.html",
  styleUrls: ["./log-viewer.component.scss"],
})
export class LogViewerComponent implements OnInit {
  public onlyErrors = false;
  public testData = [
    {
      _id: "5ed5d423ee9b22001170ddca",
      tenantID: "public",
      logEvent: 4,
      logMessage: {
        text: "JOIN SUCCESS",
        device: "dd4e54501000057e",
      },
      createdAt: "2020-06-02T04:22:59.589Z",
    },
    {
      _id: "5ed6065cee9b22001170de44",
      tenantID: "public",
      logEvent: 4,
      logMessage: {
        text: "JOIN SUCCESS",
        device: "70b3d57ba0000df3",
      },
      createdAt: "2020-06-02T07:57:16.526Z",
    },
  ];
  public logData?: ILogEvent[];
  public displayedColumns = ["logEvent", "logMessage", "createdAt"];
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public loading = false;
  constructor(private slService: SystemLogService, private info: UserInfo) {}

  ngOnInit(): void {
    this.loadLogs();
  }

  loadLogs(eventType?: number) {
    this.loading = true;
    this.slService
      .getLogs(this.info.TenantID, eventType, this.pageEvent)
      .subscribe({
        next: (result) => {
          result = result as ILogEventPaginate;
          if (result && result.data) {
            this.pageEvent.length = result.total ? result.total : 0;
            this.logData = result.data;
          }
        },
        complete: () => (this.loading = false),
      });
  }
  getLogTypeText(type: ELogEvents) {
    switch (type) {
      case ELogEvents.DEBUG:
        return "Debug";
      case ELogEvents.ERROR:
        return "Error";
      case ELogEvents.INFO:
        return "Info";
      case ELogEvents.LOG:
        return "Log";
      case ELogEvents.WARN:
        return "Warn";
      default:
        return "NA";
    }
  }
  LogTypeSelect(val: boolean) {
    if (val) {
      this.loadLogs(0);
    }
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadLogs();
  }
}
