import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule, Routes} from '@angular/router';
import {TotpService} from '../services/totp.service';
import {UserSettingsComponent} from './user-settings.component';

export const routes: Routes = [
  {
    path: "settings",
    component: UserSettingsComponent,
    data: {breadcrumb: "Einstellungen"},
    pathMatch: "full",
  },

];

@NgModule({
  declarations: [UserSettingsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    ClipboardModule
  ],
  providers: [TotpService]
})
export class UserSettingsModule { }
