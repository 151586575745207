import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { SharedUIModule } from "../../shared/components/sharedui/sharedui.module";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../theme/pipes/pipes.module";
import { DeviceInfoComponent } from "../device-data/device-info.component";
import { DynamicChartsModule } from "../dynamicboard/dvv-charts/dynamicCharts.module";
import { CreateNbiotdevDialogComponent } from "./create-nbiotdev-dialog/create-nbiotdev-dialog.component";
import { NbiotDevicesComponent } from "./nbiot-devices.component";

export const routes: Routes = [
  {
    path: "devices",
    component: NbiotDevicesComponent,
    pathMatch: "full",
    data: { breadcrumb: "NB IoT" },
  },
  {
    path: "devices/:tenantID",
    component: NbiotDevicesComponent,
    pathMatch: "full",
    data: { breadcrumb: "NB IoT" },
  },
  {
    path: "info/:id/:tenantID",
    component: DeviceInfoComponent,
    data: { isPublic: false, dataType: "nbiot" },
  },
];
@NgModule({
  declarations: [NbiotDevicesComponent, CreateNbiotdevDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    SharedUIModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
    DynamicChartsModule,
  ],
  // entryComponents: [
  //   CreateDeviceDialogComponent,
  //   ChangeDeviceDataComponent,
  //   CreateNbiotdevDialogComponent,
  // ],
})
export class NbiotDevicesModule {}
