import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UserInfo } from "../../../services/abstract.service";

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = "../assets/img/users/user.jpg";
  public Username: string | undefined;
  public Permissions: string | undefined;
  constructor(private info: UserInfo) {}

  ngOnInit() {
    this.Permissions = this.info.Permissions;
    this.Username = this.info.UserName;
  }
}
