import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { AppSettings } from "../../../app.settings";
import { IStreamProfiles } from "../../../model/datatstream";
import { ISortEvent } from "../../../model/sort";
import { DatastreamService } from "../../../services/datastream.service";
import {
  EPermissions,
  UIConfigService,
} from "../../../services/uiconfig-service";
import { debounce } from "../../../shared/abstract/utils";
import { AppConstants } from "../../../shared/app.constants";
import { blockTransition } from "../../../theme/utils/app-animation";
import { CreateDataStreamProfileComponent } from "../create-profile-dialog/CreateDataStream.component";
@Component({
  selector: "app-datastreams",
  templateUrl: "./datastreams-profile.component.html",
  styleUrls: ["./datastreams-profile.component.scss"],
  animations: [blockTransition],
})
export class DataStreamsProfileComponent implements OnInit {
  public loading = false;
  public data = [];
  public displayedColumns = [
    "name",
    "mainLink",
    "lastSeenAt",
    "dataInterval",
    "updatedAt",
    "action",
  ];
  public pageSizeOptions = [5, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public streamProfiles: IStreamProfiles[] = [];
  public EPermissions = EPermissions;
  private search?: string;
  private searchHandler: any;
  private params?: { [param: string]: string };
  constructor(
    private dialog: MatDialog,
    private dsService: DatastreamService,
    private appSettings: AppSettings,
    public uiService: UIConfigService
  ) {
    this.searchHandler = debounce(() => {
      this.pageEvent.pageIndex = 0;
      this.loadProfiles();
    }, 500);
  }
  ngOnInit() {
    this.loadProfiles();
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadProfiles();
  }
  loadProfiles() {
    this.loading = true;
    const int = AppConstants.Turnus;
    this.dsService
      .loadStreamProfiles(this.pageEvent, this.search, this.params)
      .subscribe({
        next: (res) => {
          res = res as IStreamProfiles;
          if (res && res.data) {
            this.streamProfiles = res.data;
            this.streamProfiles.map((item) => {
              item.interval = int.find(
                (x) => x.value == item.dataInterval
              )?.name;
            });
            this.pageEvent.length = res.total || 0;
            this.pageEvent.pageSize = res.pageSize || 10;
          }
          this.loading = false;
        },
        complete: () => (this.loading = false),
      });
  }
  createDataStream(profile?: IStreamProfiles) {
    const dialogRef = this.dialog.open(CreateDataStreamProfileComponent, {
      disableClose: true,
      minWidth: 400,
      data: { profileData: profile },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          const form: IStreamProfiles = {
            name: result.name,
            requestURL: result.requestURL,
            requestMethod: result.requestMethod,
            responseType: result.responseType,
            dataInterval: result.dataInterval,
            rejectUnauthorized: result.rejectUnauthorized,
            apiType: result.apiType,
          };
          let contentType = "application/json; charset=UTF-8";
          let headers: { [name: string]: string } =
            result.apiType == "REST"
              ? {
                  "Content-Type": contentType,
                }
              : {};
          let parameters: { [name: string]: string } = {};
          result.headers.map((item: any) => {
            if (item.key !== "") {
              headers = { ...headers, ...{ [item.key]: item.value } };
            }
          });
          result.parameters.map((item: any) => {
            if (item.key !== "") {
              parameters = { ...parameters, ...{ [item.key]: item.value } };
            }
          });
          form.requestHeader = headers;
          form.requestParameter = parameters;
          if (result.requestBody !== null) {
            if (result.apiType == "SOAP") {
              form.requestBody = { soap: result.requestBody };
            } else if (result.expertMode) {
              form.requestBody = { javascript: result.requestBody };
            } else {
              form.requestBody =
                JSON.parse(JSON.stringify(result.requestBody)) || {};
            }
          }
          const update = { ...profile, ...form };
          this.dsService
            .updateDataStreamProfile(update, profile === undefined)
            .subscribe({
              next: (_) => {
                this.loadProfiles();
                this.appSettings.getSwalSuccess("Profil wurde aktualisiert");
              },
              error: (err) => {
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                );
              },
            });
        }
      },
    });
  }
  deleteDataStream(profile?: IStreamProfiles) {
    this.appSettings
      .getSwalDefaultConfirm(
        "DataStream Profile löschen?",
        "Wollen Sie wirklich Profile <strong>" +
          profile?.name +
          "</strong>" +
          " löschen?<br><br>" +
          "<strong>Bitte beachten Sie, dass auch alle Geräte, die mit diesem Profile verknüpft sind, gelöscht werden!</strong>"
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.appSettings
            .getSwalDefaultConfirm(
              "DataStream Geräte löschen?",
              "Sind Sie sich ganz sicher? <br><br> " +
                "<strong>Es werden alle Geräte, die mit diesem Profil verknüpft sind, gelöscht!</strong>"
            )
            .then((result2) => {
              if (result2.isConfirmed && profile?._id) {
                this.dsService.deleteDataStreamProfile(profile._id).subscribe({
                  next: (_) => {
                    this.loadProfiles(),
                      this.appSettings.getSwalSuccess("Profil wurde gelöscht!");
                  },
                  error: (err) =>
                    this.appSettings.getSwalError(
                      err.error?.message || err.message
                    ),
                });
              }
            });
        }
      });
  }
  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.searchHandler();
  }
  sortData(evt: ISortEvent) {
    this.params = {};
    if (evt.direction !== "") {
      this.params["sort"] = `${evt.direction == "desc" ? "-" : ""}${
        evt.active
      }`;
    }
    this.loadProfiles();
  }
}
