import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { AppSettings } from "../../../../app.settings";
import { IDataStream } from "../../../../model/datatstream";
import { IDeviceDataProfileList } from "../../../../model/devicedataprofile";
import { IDevice, IDevicePaginat } from "../../../../model/devices";
import { EDataType } from "../../../../model/enum/dataType";
import {
  IVirtualDevice,
  IVirtualDevicePaginat,
} from "../../../../model/virtual-device";
import { DatastreamService } from "../../../../services/datastream.service";
import { LoraDeviceService } from "../../../../services/lora-devices";
import { NbiotDevicesService } from "../../../../services/nbiot-devices.service";
import { VirtualDevicesService } from "../../../../services/virtual-devices.service";
import { BaseDialog } from "../../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";

@Component({
  selector: "app-devices-dialog",
  templateUrl: "./devices-dialog.component.html",
  styleUrls: ["./devices-dialog.component.scss"],
})
export class DevicesDialogComponent
  extends BaseDialog
  implements AfterViewInit
{
  public devices?: MatTableDataSource<IDevice>;
  public selection = new SelectionModel<string>(true, []);
  public showTable = false;
  public pageSizeOptions = [3, 10, 25, 50];
  public pageEvent: PageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
  public loading = false;
  public EDataType = EDataType;
  public dataType = 0;
  public apiApp = false;
  public dataProfileList: IDeviceDataProfileList[] | undefined;
  public dataProfileId: string | undefined;
  private removed: string[] = [];
  private search: string | undefined;
  // private nbIotDevices = false;
  public dataStreamDevices = false;
  private _public = false;
  @ViewChild("devPaginator") paginator!: MatPaginator;
  constructor(
    private dialogRef: MatDialogRef<DevicesDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      devEUIs: string[];
      apiApp?: boolean;
      multicast: { del: boolean; devices: IDevice[] };
    },
    private dService: LoraDeviceService,
    private vService: VirtualDevicesService,
    private nService: NbiotDevicesService,
    private dsService: DatastreamService,
    private appSettings: AppSettings
  ) {
    super(dialogRef);
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      selected: [null, Validators.required],
      dataProfile: null,
    });
  }

  implementOnInit(): void {
    this.getBaseDialogHeader().setTitle("Gerät(e) zur Gruppe hinzufügen");
    this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Hinzufügen");
    this.selection.changed.subscribe({
      next: (val) => {
        if (this.data.multicast && !this.data.multicast.del) {
          if (val.removed.length > 0) {
            setTimeout(() => {
              val.removed.forEach((x) => this.selection.toggle(x));
            }, 100);
            return;
          }
          if (this.selection.selected) {
            const sel = this.selection.selected;
            const diff = sel.filter((x) => !this.data.devEUIs.includes(x));
            this.form.get("selected")?.setValue(diff);
          }
        } else {
          this.form.get("selected")?.setValue(this.selection.selected);
        }
      },
    });
    if (this.data.devEUIs && this.data.devEUIs.length > 0) {
      this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Ändern");
      if (this.data.multicast?.del) {
        this.getBaseDialogFooter().getFooterButtons()[0].setLabel("Entfernen");
      }
    }
    if (this.data.multicast) {
      this.dataType = EDataType.lora;
      this.showTable = true;
      if (!this.data.multicast.del) {
        return;
      }
    }
    if (this.data.apiApp) {
      this.apiApp = this.dataStreamDevices = true;
      this.dataType = 4;
    }
    this.loadDevices();
  }

  loadDevices() {
    if (this.data.multicast && this.data.multicast.del) {
      this.devices = new MatTableDataSource(this.data.multicast.devices);
      this.devices.paginator = this.paginator;
      return;
    }
    this.loading = true;
    let observer: Observable<
      | (IDevicePaginat & IDevice[])
      | IDataStream
      | (IVirtualDevicePaginat & IVirtualDevice[])
    >;
    let tenant = undefined;
    if (this._public) {
      tenant = "public";
    }
    // if (this.nbIotDevices) {
    //   observer = this.nService.getDevices(tenant, this.pageEvent);
    // } else
    if (this.data.multicast && this.dataProfileId) {
      observer = this.dService.getDevicesByDDProfileId(
        this.dataProfileId,
        undefined,
        this.pageEvent,
        this.search
      );
    } else if (this.dataType == 2 || this.dataType == 4) {
      observer = this.dsService.loadDataStreams(
        tenant,
        this.pageEvent,
        undefined,
        { dataType: this.dataType.toString() }
      ) as Observable<IDataStream>;
    } else if (this.dataType == 1) {
      observer = this.nService.getDevices(tenant, this.pageEvent);
    } else if (this.dataType == 3) {
      observer = this.vService.getDevices(tenant, this.pageEvent, this.search);
    } else {
      observer = this.dService.getDevicesByTenantId(
        tenant,
        this.pageEvent,
        this.search
      );
    }
    observer.subscribe({
      next: (result) => {
        if (result && result.data) {
          this.pageEvent.length = result.total || 0;
          this.devices = new MatTableDataSource(result.data);
          if (this.data.devEUIs) {
            this.selection.select(...this.data.devEUIs);
          }
          if (this.devices.filteredData.length > 0) {
            this.showTable = true;
          }
        } else {
          this.devices = new MatTableDataSource<IDevice>([]);
        }
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
      complete: () => {
        this.loading = false;
      },
    });
  }

  ngAfterViewInit() {
    return;
  }
  isAllSelected() {
    if (!this.devices) {
      return false;
    }
    if (this.pageEvent.length < this.pageEvent.pageSize) {
      return this.selection.selected.length >= this.pageEvent.length;
    } else {
      return this.selection.selected.length >= this.pageEvent.pageSize;
    }
  }
  applyFilter(filterValue: string) {
    if (this.devices) this.devices.filter = filterValue.trim().toLowerCase();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.devices) {
      this.isAllSelected()
        ? this.selection.clear()
        : this.devices.data.forEach((row) => this.selection.select(row.devEUI));
    }
  }

  checkboxLabel(row?: IDevice, index = 0): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row.devEUI) ? "deselect" : "select"
    } row ${index + 1}`;
  }
  onPagination(evt: PageEvent) {
    this.pageEvent = evt;
    this.loadDevices();
  }
  deviceTypeSelect(evt: MatSelectChange) {
    this.dataType = evt.value;
    this.pageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
    this.loadDevices();
  }
  async datenProfileSelect(evt: string) {
    this.dataProfileId = evt;
    this.pageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
    this.loadDevices();
  }
  publicSelect(val: boolean) {
    this._public = val;
    this.pageEvent = { length: 0, pageIndex: 0, pageSize: 10 };
    this.loadDevices();
  }
}
