import {
  AfterViewInit,
  Component,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IUserInfo } from "../../../model/user";
import { BaseDialog } from "../../../shared/components/sharedui/base-dialog/abstract.base-dialog.component";
import { ValidatorMessageService } from "../../../shared/components/validator-message.service";
import {
  emailValidator,
  passwordValidator,
} from "../../../validators/main-validators";

@Component({
  selector: "app-create-user-dialog",
  templateUrl: "./create-user-dialog.component.html",
  styleUrls: ["./create-user-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateUserDialogComponent
  extends BaseDialog
  implements AfterViewInit
{
  public PermissionKeys = [
    {
      name: "Admin - (Darf den gesamten Tenant verwalten)",
      value: "admin",
      desc: "Der Admin Benutzer hat lesenden und schreibenden Zugriff auf alle Bereiche.",
    },
    {
      name: "Benutzer - (Darf alles sehen)",
      value: "user",
      desc: "Der Benutzer darf nur alles lesen. Er darf nichts erstellen.",
    },
    {
      name: "Viewer - (Nur Dashboards betrachten)",
      value: "viewer",
      desc: "Der Viewer darf nur Dashboards und Charts sehen.",
    },
    {
      name: "API - (Nur REST API Abfragen)",
      value: "api",
      desc: "Der API Token",
    },
    {
      name: "MQTT - (Daten über MQTT bekommen)",
      value: "mqtt",
      desc: "User für MQTT Broker",
    },
  ];
  public showPass = false;
  public apiPermission = false;
  constructor(
    private dialogRef: MatDialogRef<CreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public DATA: { user: IUserInfo },
    public fb: UntypedFormBuilder,
    private vService: ValidatorMessageService
  ) {
    super(dialogRef);
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      username: ["", Validators.compose([Validators.required, emailValidator])],
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          passwordValidator,
        ]),
      ],
      permissions: [null, Validators.required],
    });
  }

  implementOnInit(): void {
    setTimeout(() => {
      if (this.DATA?.user) {
        this.getBaseDialogHeader().setTitle("Benutzer anpassen");
        const fBut = this.buildFooterButtons();
        fBut[0].setLabel("Speichern");
        this.getBaseDialogFooter().setFooterButtons(fBut);
        this.form.patchValue(this.DATA.user);
        this.form.removeControl("password");
      } else {
        this.getBaseDialogHeader().setTitle("Benutzer erstellen");
        this.getBaseDialogFooter().setFooterButtons(this.buildFooterButtons());
        this.form.get("permissions")?.valueChanges.subscribe((value) => {
          if (value === "api") {
            this.apiPermission = true;
            this.form.get("password")?.disable();
          } else {
            this.apiPermission = false;
            this.form.get("password")?.enable();
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {
    return;
  }

  _control(key: string) {
    return this.form.get(key);
  }

  getErrorMessage(validator: string, params?: any) {
    return this.vService.getErrorMessage(validator, params);
  }
}
