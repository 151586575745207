import { Clipboard } from "@angular/cdk/clipboard";
import { Component, OnInit } from "@angular/core";
import { firstValueFrom, map, Observable } from "rxjs";
import Swal from "sweetalert2";
import { AppSettings } from "../../../../app.settings";
import { TotpService } from "../services/totp.service";

@Component({
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
  public totp = false;
  private totpId: string | null | undefined;
  public codes: Observable<string[]> | undefined;
  public codesStr: string | undefined;
  constructor(
    private tService: TotpService,
    private appSettings: AppSettings,
    private clipB: Clipboard
  ) {}

  ngOnInit(): void {
    if (this.appSettings.isTotpSet()) {
      this.totp = true;
      this.totpId = sessionStorage.getItem("totp");
    }
  }

  enableTOTP() {
    Swal.fire({
      title: "2 Faktor Authentifzierung",
      text: "Sie möchten 2 Faktor Authentifzierung einschalten?",
      confirmButtonText: "JA",
      showLoaderOnConfirm: true,
      cancelButtonText: "Abbrechen",
      showCancelButton: true,
      preConfirm: async () => {
        return firstValueFrom(this.tService.generateTOTP()).then(
          (result) => {
            if (result && result.InsertedID) {
              this.totpId = result.InsertedID;
              return result;
            }
            return undefined;
          },
          (err) => {
            Swal.fire({
              text: err.message,
              icon: "error",
            });
            return Promise.reject();
          }
        );
      },
    })
      .then((r1) => {
        if (r1.isConfirmed) {
          return Swal.fire({
            title: "Aktiviert",
            html: `Damit Sie die 2 Faktor Authentifizierung nutzen können,
          müssen Sie auf Ihrem Mobiltelefon
          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator</a>
           oder <a href="https://play.google.com/store/apps/details?id=com.authy.authy">Twilio Authy</a> installieren.
           Danach sollten Sie mit einer App den QR Code einscannen!`,
            confirmButtonText: "QR Code Anzeigen",
            cancelButtonText: "Abbrechen",
            showCancelButton: true,
          });
        }
        return undefined;
      })
      .then((r2) => {
        if (r2?.isConfirmed && this.totpId) {
          return firstValueFrom(this.tService.getQrTOTP(this.totpId));
        }
        return undefined;
      })
      .then((qr) => {
        if (qr?.image) {
          return Swal.fire({
            title: "Authentifizierung",
            text: "In dem nächsten Schritt werden Sie aufgefordert den Token \
            zu validieren und somit die 2 Factor Authentifizierung freischalten.",
            imageUrl: qr.image,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "QR Code",
            confirmButtonText: "Token Validieren",
            cancelButtonText: "Abbrechen",
            showCancelButton: true,
          });
        } else {
          Swal.fire({
            text: "Es ist ein Fehler ausgetretten. Versuchen Sie es bitte noch ein mal.",
            icon: "error",
          });
          return Promise.reject();
        }
      })
      .then((r3) => {
        if (r3.isConfirmed) {
          return Swal.fire({
            title: "Geben Sie Ihr Token ein",
            input: "text",
            inputLabel: "hier den Token eingeben",
            inputValue: "",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            inputValidator: (value) => {
              if (!value || value.length < 6) {
                return "Der Token muss min. 6 Zeichen haben";
              }
              return null;
            },
            confirmButtonText: "Token Validieren",
            cancelButtonText: "Abbrechen",
            preConfirm: (token) => {
              return firstValueFrom(this.tService.validateTOTP(token));
            },
          });
        }
        return undefined;
      })
      .then((valid) => {
        if (valid && valid.isConfirmed && valid.value?.valid) {
          this.totp = true;
          Swal.fire({
            title: "Erfolgreich",
            text: "Sie haben erfolgreich die 2 Factor Authentifizierung freigescheltet.\
           Ab sofort können Sie sich nur mit 2 Factor Authentifizierung anmleden.",
            confirmButtonText: "OK",
          });
        }
      });
  }
  showRecoveryCodes() {
    if (!this.totpId) {
      return;
    }
    this.tService.getRecoveryCodes(this.totpId).subscribe((res) => {
      // const codes = res.join("\n")
      AppSettings.Swal.fire({
        title: "Wiederhestellungstoken",
        showCloseButton: true,
        html: `
          <div class="codes-container">
          ${res.map((c) => `<div class="codes-item">${c}</div>`).join("")}
          </div>
          `,
        confirmButtonText: "Kopieren",
      }).then((result) => {
        if (result.isConfirmed) {
          this.clipB.copy(res.join("\n"));
        }
      });
    });
  }
  disableTOTP() {
    if (this.totpId) {
      Swal.fire({
        showLoaderOnConfirm: true,
        title: "Deaktivieren?",
        text: "Möchten Sie wirklich die 2 Faktor Identifizierung ausschalten?",
        confirmButtonText: "JA",
        showCloseButton: true,
        preConfirm: () => {
          if (!this.totpId) {
            return;
          }
          return firstValueFrom(this.tService.disableTOTP(this.totpId));
        },
      }).then((r) => {
        if (r.isConfirmed) {
          this.appSettings.removeTotpSet();
          this.totp = false;
          this.appSettings.getSwalSuccess(
            "2 Faktor Identifizierung deaktiviert"
          );
        }
      });
    }
  }
  getRecoveryCodes() {
    if (!this.totpId) {
      return;
    }
    this.codes = this.tService.getRecoveryCodes(this.totpId);
    return this.codes.pipe(
      map((r) => {
        this.codesStr = r.join("\n");
      })
    );
  }
}
