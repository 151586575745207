<div class="w-100 h-100">
  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    class="flex-p"
    fxLayoutGap="8px"
  >
    <button mat-raised-button color="warn" (click)="abbrechen()">
      Abbrechen
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="add()"
      [disabled]="!name"
    >
      {{ update ? "Ändern" : "Erstellen" }}
    </button>
  </div>
  <mat-divider> </mat-divider>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <input matInput placeholder="Bezeichnung" [(ngModel)]="name" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="w-min-300p">
      <input matInput placeholder="Beschreibung" [(ngModel)]="description" />
    </mat-form-field>
  </div>
  <p>EJS Template: https://ejs.co/</p>
  <p>Daten befinden sich in der Variable '_value_'</p>
  <div fxLayout="row" style="min-height: 800px">
    <ngx-monaco-editor
      fxLayout="row"
      class="dvv-code-editor w-100 flex-p"
      [options]="ParserOptions"
      (onInit)="onMonacoInit($event)"
      [(ngModel)]="template"
    >
    </ngx-monaco-editor>
  </div>
  <div fxLayout="row"></div>
</div>
