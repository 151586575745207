import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {registerTheme} from 'echarts';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {ChartTheme} from '../../../model/enum/chartTheme';
import {echartsDark} from '../../../theme/echarts/themeDark';
import {echartsLight} from '../../../theme/echarts/themeLight';
import {DynamicChartsComponent} from "./dynamicCharts.component";

@NgModule({
  declarations: [DynamicChartsComponent],
  imports: [CommonModule, NgxJsonViewerModule],
  exports: [DynamicChartsComponent],
  providers: [],
})

export class DynamicChartsModule {

}
registerTheme(ChartTheme.dvvdark, echartsDark);
registerTheme(ChartTheme.greendark, echartsDark);
registerTheme(ChartTheme.pinkdark, echartsDark);
registerTheme(ChartTheme.bluedark, echartsDark);
registerTheme(ChartTheme.dvvlight, echartsLight);
registerTheme(ChartTheme.indigolight, echartsLight);
registerTheme(ChartTheme.redlight, echartsLight);
registerTheme(ChartTheme.teallight, echartsLight);
