import { HttpHeaders } from "@angular/common/http";
import { Injectable, Injector, OnDestroy } from "@angular/core";
// import { TenantService } from "./tenant.service";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ILogin } from "../model/login";
import { AppConstants } from "../shared/app.constants";
import { ExpiredMessageComponent } from "../shared/components/sharedui/expired-message/expired-message.component";
/**
 * Abstract Service Class as basic
 */
export abstract class AbstractService {
  private _api = environment.api;
  private _headers: HttpHeaders;
  private _url = `${this.api}/${this.endpoint}`;

  constructor(private endpoint: string) {
    this._headers = new HttpHeaders().append(
      "Content-Type",
      `application/json`
    );
  }

  public get api() {
    return this._api;
  }

  public get headers() {
    return this._headers;
  }

  public get url() {
    return this._url;
  }
  /**
   * Get User Info extracted from Token. If no Token provided return null;
   *
   * @returns {ILogin||null}
   * @memberof UserLoginService
   */
}
@Injectable({
  providedIn: "root",
})
export class UserInfo implements OnDestroy {
  static instance: UserInfo;
  private expired?: number;
  private timeOut: any;
  private Info?: ILogin;
  private tenantID!: string;
  private userName!: string;
  private permissions!: string;
  private jwt = new JwtHelperService();
  constructor(
    private router: Router,
    private dialog: MatDialog // private tService: TenantService
  ) {
    this.getTokenInfo();
    return (UserInfo.instance = UserInfo.instance || this);
  }
  private getTokenInfo() {
    const token =
      localStorage.getItem(AppConstants.loraApiToken) ||
      sessionStorage.getItem(AppConstants.loraApiToken);
    this.Info = this.jwt.decodeToken(token || undefined);
    if (this.Info && this.Info.tenant) {
      this.tenantID = this.Info.tenant.tenantID;
    }
  }

  public parseToken(token?: string) {
    return this.jwt.decodeToken(token) as ILogin;
  }

  public setSessionInfo(token: string): Observable<any> {
    return new Observable((observer) => {
      const obj = this.parseToken(token);
      this.Info = obj;
      this.tenantID = obj.tenant.tenantID;
      this.userName = obj.loginUser.username;
      observer.next(obj);
      observer.complete();
    });
  }
  public updateSession() {
    if (!this.Info) {
      this.Info = {} as ILogin;
    }
    const token =
      localStorage.getItem(AppConstants.loraApiToken) ||
      sessionStorage.getItem(AppConstants.loraApiToken);
    const obj = this.parseToken(token || undefined);
    this.Info.exp = obj?.exp;
    this.Info.iat = obj?.iat;
    this.Info.token = obj?.token;
  }
  public set TenantID(id: string) {
    this.tenantID = id;
  }
  public get TenantID() {
    if (this.tenantID) {
      return this.tenantID;
    }
    const config = localStorage.getItem("config-" + this.UserName);
    if (config) {
      const tenant = JSON.parse(config).config?.tenant;
      if (!tenant) {
        if (!this.Info) {
          this.getTokenInfo();
        }
        return this.TenantID;
      } else {
        this.TenantID = tenant;
      }
    }
    return this.tenantID;
  }
  public set UserName(name: string) {
    this.UserName = name;
  }
  public get UserName() {
    return this.userName || this.Info?.loginUser?.username || "";
  }
  public set Permissions(perm: string) {
    this.permissions = perm;
  }
  public get Permissions() {
    return this.permissions || this.Info?.loginUser?.permissions || "";
  }
  public get userInfo(): ILogin {
    if (this.Info) {
      return this.Info;
    } else {
      this.getTokenInfo();
      return this.Info as unknown as ILogin;
    }
  }
  public isTotpSet() {
    return (
      this.userInfo.loginUser.totpValidated != "" &&
      this.userInfo.loginUser.totpValidated == "valid"
    );
  }
  public removeTotpSet() {
    return delete this.userInfo.loginUser.totpValidated;
  }
  public _setTimeOut() {
    this._clearTimeOut();
    if (!this.Info) {
      this.router.navigate(["login"]);
      return;
    }
    if (!this.Info.exp) {
      return;
    }
    this.updateSession();

    this.expired = this.Info.exp * 1000 - new Date().getTime();

    if (this.expired <= 0) {
      // abgelaufen
      this.router.navigate(["logout"], { state: { expired: true } });
    } else if (this.expired <= 120000) {
      this.dialog
        .open(ExpiredMessageComponent)
        .afterClosed()
        .subscribe({
          next: (result) => {
            if (result) {
              setTimeout(() => {
                this._setTimeOut();
              }, 1000);
            }
          },
        });
      this.timeOut = setTimeout(() => {
        this._setTimeOut();
      }, this.expired);
    } else {
      this.timeOut = setTimeout(() => {
        this._setTimeOut();
      }, this.expired - 120000);
    }
  }
  private _clearTimeOut() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }
  ngOnDestroy(): void {
    this.Info = undefined;
    this._clearTimeOut();
  }
}

export let InjectorInstance: Injector;
@Injectable({
  providedIn: "root",
})
export class SendDataInjectable {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
