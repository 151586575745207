<div #Dvvchart [style.height]="'100%'" [style.width]="'100%'" [style.backgroud-color]="'red'" class="chart-object"
  (scroll)="onScroll($any($event))">
  <ng-container *ngIf="item?.chartData?.chartType === 'json'&&jsonData" [ngTemplateOutlet]="JSONViewer">
  </ng-container>
</div>
<ng-template #JSONViewer>
  <ngx-json-viewer [json]="jsonData" [expanded]="false" style="margin-right: 10px;">
  </ngx-json-viewer>
</ng-template>

<!-- //  class="chart-object" important for Resizing -->
