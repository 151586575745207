import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractService } from "../../../../services/abstract.service";

@Injectable({
  providedIn: "root",
})
export class TotpService extends AbstractService {
  constructor(private http: HttpClient) {
    super("totp");
  }
  generateTOTP() {
    return this.http.post<{ InsertedID: string }>(
      this.url,
      {},
      { headers: this.headers }
    );
  }
  getQrTOTP(id: string) {
    return this.http.get<{ image: string; key: string }>(`${this.url}/${id}`, {
      headers: this.headers,
    });
  }
  validateTOTP(code: string) {
    return this.checkTOTPPassCode(code, true);
  }
  disableTOTP(id: string) {
    return this.http.delete(`${this.url}/${id}`, { headers: this.headers });
  }

  checkTOTPPassCode(passcode: string, validate = false) {
    let params = new HttpParams();
    if (validate) {
      params = params.append("validate", "true");
    }
    return this.http.put<{ valid: boolean }>(
      `${this.url}`,
      { passcode },
      { headers: this.headers, params }
    );
  }

  getRecoveryCodes(id: string) {
    return this.http.patch<string[]>(
      `${this.url}/${id}`,
      {},
      { headers: this.headers }
    );
  }
}
