import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-applications",
  templateUrl: "./applications.component.html",
  styleUrls: ["./applications.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ApplicationsComponent {
  constructor() {}
}
