import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { subHours } from "date-fns";
import { init } from "echarts";
import { Subscription, timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { IData } from "../../model/devices";
import { BrokerService } from "../../services/broker.service";
import { DeviceDataService } from "../../services/devicedata.services";
import { CreateDeviceQueueComponent } from "../lora-devices/create-device-queue/create-device-queue.component";

@Component({
  selector: "app-device-info",
  templateUrl: "./device-info.component.html",
  styleUrls: ["./device-info.component.scss"],
})
export class DeviceInfoComponent implements OnInit, OnDestroy {
  public settings: Settings;
  public deviceData?: IData[];
  public TimeRangeOptions = [
    { key: "Letzten Wert", value: 0 },
    { key: "1 Std", value: 1 },
    { key: "3 Std", value: 3 },
    { key: "7 Std", value: 7 },
    { key: "9 Std", value: 9 },
    { key: "12 Std", value: 12 },
    { key: "24 Std", value: 27 },
  ];
  public showLoraData = true;
  @ViewChild("rssiDIV", { static: false }) rssiDIV!: ElementRef;
  @ViewChild("loraSnrDIV", { static: false }) loraSnrDIV!: ElementRef;
  private devEUI?: string;
  public tenantID?: string;
  private getLastTime?: number;
  private alive = true;
  private timer?: Subscription;
  private dataType = "lora";
  private pageEvent: PageEvent | undefined;
  constructor(
    public appSettings: AppSettings,
    private ngZone: NgZone,
    private router: Router,
    private ddService: DeviceDataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private bService: BrokerService
  ) {
    this.settings = this.appSettings.settings;
    this.route.params.subscribe((params) => {
      if (params) {
        this.devEUI = params.id;
        this.tenantID = params.tenantID;
      }
    });
    this.route.data.subscribe({
      next: (data) => {
        if (data.dataType) {
          this.dataType = data.dataType;
          if (
            this.dataType === "datastreams" ||
            this.dataType === "nbiot" ||
            this.dataType === "virtual"
          ) {
            this.showLoraData = false;
          }
        }
      },
    });
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.pageEvent =
        this.router.getCurrentNavigation()?.extras.state?.pageEvent;
    }
  }

  ngOnInit(): void {
    this.loadData();
  }
  goBack() {
    // changeRouteData(this.router, "Geräte", this.dataType);

    if (
      this.appSettings.currentTenantID() === "superadmin" &&
      this.deviceData &&
      this.tenantID !== "superadmin" &&
      this.tenantID !== "public"
    ) {
      this.router.navigate(
        [
          "superadmin",
          "tenants",
          {
            outlets: { _management: [this.dataType, "devices", this.tenantID] },
          },
        ],
        { state: { pageEvent: this.pageEvent } }
      );
    } else {
      this.router.navigateByUrl(this.dataType + "/devices/" + this.tenantID, {
        state: { pageEvent: this.pageEvent },
      });
    }
  }
  loadData() {
    this.timer = timer(300, 5000)
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        let limitFrom = 0;
        let limitLast = true;
        if (this.getLastTime !== undefined) {
          limitFrom = this.getLastTime;
          limitLast = false;
        }
        if (this.devEUI)
          this.ddService
            .getDataByDevice(
              this.devEUI,
              limitFrom,
              0,
              this.tenantID,
              limitLast,
              this.dataType
            )
            .subscribe({
              next: (data) => {
                if (!data || !data[0] || !data[0].data) {
                  return;
                }
                let daten = data[0].data;

                if (this.deviceData) {
                  this.deviceData.push(...daten);
                } else {
                  this.deviceData = daten;
                }
                this.getLastTime = daten[daten.length - 1].createdAt + 1000;
                this.deviceData = [...this.deviceData];
                if (this.showLoraData) {
                  setTimeout(() => {
                    this.initRSSI(daten[daten.length - 1]);
                    this.initSNR(daten[daten.length - 1]);
                  });
                }
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
      });
  }
  initRSSI(data: IData) {
    if (data.rxInfo.length <= 0) {
      return;
    }
    const rssiChart = this.ngZone.runOutsideAngular(() =>
      init(this.rssiDIV.nativeElement, this.settings.theme, {
        renderer: "canvas",
      })
    );
    const rssiValue = data.rxInfo ? data.rxInfo[0].rssi * -1 : 0;
    rssiChart.setOption({
      toolbox: { show: false },
      series: [
        {
          splitLine: { show: false },
          type: "gauge",
          axisLabel: { show: false },
          pointer: { show: false },
          axisLine: {
            lineStyle: {
              color: [
                [(120 - rssiValue) / 120, "#2ec7c9"],
                [1, "#ddd"],
              ],
              width: 20,
            },
          },
          min: 30,
          max: 120,
          radius: "100%",
          axisTick: { show: false },
          data: [{ value: 120 - rssiValue }],
          detail: {
            formatter: "-" + rssiValue,
            textStyle: { padding: [60, 0, 0, 0] },
            offsetCenter: [0, "0%"],
            fontSize: 20,
          },
        },
      ],
    } as any);
  }
  initSNR(data: IData) {
    if (data.rxInfo.length <= 0) {
      return;
    }
    const snrChart = this.ngZone.runOutsideAngular(() =>
      init(this.loraSnrDIV.nativeElement, this.settings.theme, {
        renderer: "canvas",
      })
    );
    const snrValue = data.rxInfo[0].loraSnr;
    snrChart.setOption({
      toolbox: { show: false },
      series: [
        {
          splitLine: { show: false },
          type: "gauge",
          axisLabel: { show: false },
          pointer: { show: false },
          axisLine: {
            lineStyle: {
              color: [
                [(20 - snrValue) / 30, "#2ec7c9"],
                [1, "#ddd"],
              ],
              width: 20,
            },
          },
          min: 0,
          max: 30,
          radius: "100%",
          axisTick: { show: false },
          data: [{ value: (20 + snrValue).toFixed(1) }],
          detail: {
            formatter: "" + snrValue.toFixed(1),
            textStyle: { padding: [60, 0, 0, 0] },
            offsetCenter: [0, "0%"],
            fontSize: 20,
          },
        },
      ],
    } as any);
  }
  timeRangeChanged(val: number) {
    this.timer?.unsubscribe();
    this.deviceData = undefined;
    this.getLastTime = subHours(new Date(), val).getTime();
    if (val === 0) {
      this.getLastTime = undefined;
    }
    this.loadData();
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.timer?.unsubscribe();
  }
  createDeviceQueue() {
    const dialogRef = this.dialog.open(CreateDeviceQueueComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res && this.devEUI) {
          const data = res.payload;
          this.bService
            .createDeviceQueue(data, this.devEUI, res.fPort)
            .subscribe({
              next: (resp: any) => {
                if (resp.success) {
                  this.appSettings.getSwalSuccess(
                    "DownloadQueue wurde erfolgreich erstellt."
                  );
                }
                // console.log(resp);
              },
              error: (err) =>
                this.appSettings.getSwalError(
                  err.error?.message || err.message
                ),
            });
        }
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
}
