import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { AppSettings } from "../../app.settings";
import { Settings } from "../../app.settings.model";
import { AppConstants } from "../../shared/app.constants";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
})
export class LogOutComponent implements OnInit, AfterViewInit {
  public settings: Settings;
  public expired = false;
  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.router.getCurrentNavigation()?.extras.state)
      )
      .subscribe((data) => {
        if (data) {
          this.expired = data.expired;
        }
      });
  }
  ngOnInit() {
    this.dialog.closeAll();
    localStorage.removeItem(AppConstants.loraApiToken);
    sessionStorage.removeItem(AppConstants.loraApiToken);
    sessionStorage.removeItem("totp");
  }
  goHome() {
    this.router.navigate(["/login"]);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }
}
