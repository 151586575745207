import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {MatSelect} from "@angular/material/select";
import {Subscription} from "rxjs";
import {ScrollEvent} from "src/app/theme/directives/detect-scroll/detect-scroll.directive";
import {AppSettings} from "../../../../../app.settings";
import {
  IApiRestProfile,
  IApiRestProfilePaginat
} from "../../../../../model/apiRestProfile";
import {EDataType} from "../../../../../model/enum/dataType";
import {RestAlertProfilesService} from "../../../../../pages/subscriptions/rest-alert-profiles.service";

@Component({
  selector: "app-restapi-select",
  template: `
    <div [formGroup]="group">
      <mat-form-field class="w-100">
        <mat-select
          #restapi_select
          [formControlName]="controlName"
          placeholder="REST Api Profile"
          [multiple]="false"
          (selectionChange)="onChanges($event.value)"
        >
          <mat-option *ngFor="let item of profileList" [value]="item._id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestapiProfileSelectComponent implements OnInit, OnDestroy {
  @Input()
  group!: UntypedFormGroup;
  @Input()
  controlName!: string;
  @Input()
  dataType!: EDataType;
  @Input()
  tenantID!: string;
  @Output() selectionChange = new EventEmitter();
  @Output() profileLoaded = new EventEmitter();
  public loading = false;
  public pageEvent: PageEvent = {length: 0, pageIndex: 0, pageSize: 10};
  public profileList: IApiRestProfile[] | undefined;
  private subs!: Subscription;
  @ViewChild("restapi_select")
  selectElem!: MatSelect;
  constructor(
    private rService: RestAlertProfilesService,
    private appSettings: AppSettings,
    private ref: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.loadProfiles();
  }

  loadProfiles(fetch = false) {
    this.loading = true;
    this.profileList = fetch ? this.profileList : undefined;
    this.rService.GetRestAlertProfiles(this.pageEvent, "name").subscribe({
      next: (prof) => {
        prof = prof as IApiRestProfilePaginat;
        if (prof && prof.data) {
          if (fetch && this.profileList) {
            this.profileList = this.profileList.concat(prof.data);
          } else {
            this.profileList = prof.data;
          }
          this.loading = false;
          this.ref.detectChanges();
          this.subs = this.subs
            ? this.subs
            : this.selectElem.openedChange.subscribe(() => {
              if (this.selectElem.panelOpen) {
                this.registerPanelScrollEvent();
              }
            });
          this.profileLoaded.emit(this.profileList);
        }
        return;
      },
      error: (err) =>
        this.appSettings.getSwalError(err.error?.message || err.message),
    });
  }
  onScrollToEnd() {
    this.fetchMore();
  }
  private fetchMore() {
    this.pageEvent.pageIndex++;
    if (
      this.pageEvent.length >
      this.pageEvent.pageIndex * this.pageEvent.pageSize
    ) {
      this.loadProfiles(true);
    }
  }
  onChanges(evt: any): void {
    this.selectionChange.emit(evt);
  }
  onSearch(evt: {term: string; items: any[]}) { }
  registerPanelScrollEvent() {
    const panel = this.selectElem.panel.nativeElement;
    panel.removeEventListener("scroll", () => { });
    panel.addEventListener("scroll", (event: ScrollEvent) => {
      if (event.scrollTop > 160) {
        this.fetchMore();
      }
    });
  }
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
