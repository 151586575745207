import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  // eslint-disable-next-line
  selector: "base-dialog-header",
  templateUrl: "./base-dialog-header.component.html",
  styleUrls: ["./base-dialog-header.component.scss"],
})
export class BaseDialogHeaderComponent {
  @Input()
  title?: string;

  @Input()
  description?: string;
  // eslint-disable-next-line
  @Output("onClose")
  // eslint-disable-next-line
  onClose: EventEmitter<any> = new EventEmitter();

  constructor() {}

  /**
   * Calls close method of abstract base component by emitting the closing event
   */
  closeDialog() {
    this.onClose.emit();
  }

  public getTitle(): string {
    return this.title || "";
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public getDescription(): string {
    return this.description || "";
  }

  public setDescription(description: string): void {
    this.description = description;
  }
}
