import { Component, Input } from "@angular/core";
import { BaseDialogFooterButtonComponent } from "../base-dialog-footer-button/base-dialog-footer-button.component";

@Component({
  // eslint-disable-next-line
  selector: "base-dialog-footer",
  templateUrl: "./base-dialog-footer.component.html",
  styleUrls: ["./base-dialog-footer.component.scss"],
})
export class BaseDialogFooterComponent {
  @Input()
  footerButtons: BaseDialogFooterButtonComponent[] = [];

  constructor() {}

  /**
   * Updates footer button - if found - in corresponding footer component
   *
   * @param footerButton - Footer button that should be updated
   */
  updateFooterButton(footerButton: BaseDialogFooterButtonComponent): void {
    // let foundButton: BaseDialogFooterButtonComponent = _.first(
    //   this.getFooterButtons().filter((button) => button.id === footerButton.id)
    // );
    // let foundButton = footerButton;
  }

  /**
   * Returns a footer class based on the number of buttons initialized
   */
  public receiveFooterClass(): string {
    if (this.getFooterButtons()) {
      return this.getFooterButtons().length > 1 ? "multiple" : "single";
    }
    return "";
  }

  public getFooterButtons(): BaseDialogFooterButtonComponent[] {
    return this.footerButtons;
  }

  public setFooterButtons(
    footerButtons: BaseDialogFooterButtonComponent[]
  ): void {
    this.footerButtons = footerButtons;
  }
}
