import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { UIConfigService } from "./uiconfig-service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionGuard implements CanActivate {
  constructor(private uiService: UIConfigService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.data.permissions) {
      return true;
      if (this.uiService.getUIPermissions(route.data.permissions)) {
        return true;
      } else {
        this.router.navigate([""]);
        return false;
      }
    }
    return true;
  }
}
