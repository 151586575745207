import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { firstValueFrom } from "rxjs/internal/firstValueFrom";
import { Observable } from "rxjs/internal/Observable";
import { IDevice, IDevicePaginat } from "../model/devices";
import { AbstractService, UserInfo } from "./abstract.service";
import { BrokerService } from "./broker.service";

@Injectable({
  providedIn: "root",
})
export class NbiotDevicesService extends AbstractService {
  constructor(
    public http: HttpClient,
    private info: UserInfo,
    private bService: BrokerService
  ) {
    super("nbiot/devices");
  }
  getDevices(tenantID?: string, pagination?: PageEvent) {
    const url = this.url;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : this.info.TenantID,
      },
    });
    const headers = this.headers;
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    return this.http.get<IDevicePaginat & IDevice[]>(url, {
      params,
      headers,
    });
  }
  getDevice(devEUI: string | string[]): Observable<IDevice | IDevice[]> {
    const headers = this.headers;
    const params = new HttpParams({
      fromObject: {
        devEUI: devEUI,
        tenantID: this.info.TenantID,
      },
    });
    return this.http.get<IDevice | IDevice[]>(this.url, { headers, params });
  }

  getDevicesQuery(body: any) {
    const headers = this.headers;
    return firstValueFrom(
      this.http.post<IDevice | IDevice[]>(this.url + "-query", body, {
        headers,
      })
    );
  }
  /**
   * Getting devices for tenant by their ProfileID
   * @param ddProfileID: string
   * @param tenantID?: string
   * @memberof DeviceService
   */
  getDevicesByDDProfileId(
    ddProfileID: string,
    tenantID?: string,
    pagination?: PageEvent
  ) {
    const url = `${this.url}?deviceDataProfileID=${ddProfileID}`;
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tenantID: tenantID ? tenantID : "*",
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    return this.http.get<IDevice[] | IDevicePaginat>(url, { headers, params });
  }
  getDevicesByTag(tagKey: string, pagination?: PageEvent) {
    const url = this.url;
    const headers = this.headers;
    let params = new HttpParams({
      fromObject: {
        tags: tagKey,
      },
    });
    if (pagination) {
      params = params.append("page", pagination.pageIndex.toString());
      params = params.append("pageSize", pagination.pageSize.toString());
    }
    return this.http.get<IDevice[] & IDevicePaginat>(url, { headers, params });
  }
  deleteDevice(deviceID: string) {
    const headers = this.headers;
    const url = `${this.url}/` + deviceID;
    return this.http.delete(url, { headers });
  }
  createNbiotDevice(form: any, update?: Boolean) {
    return this.bService.createNbiotDevice(form, update);
  }
}
