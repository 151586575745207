import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from "@angular/core";
import { ImageTooltipComponent } from "./image-tooltip.component";

// eslint-disable-next-line
@Directive({ selector: "[imageTooltip]" })
export class ImageTooltipDirective implements OnInit {
  @Input("imageTooltip") text = "";
  // eslint-disable-next-line
  @Input("image") image = "";
  private overlayRef!: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom",
          offsetY: -8,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener("mouseenter")
  show() {
    const tooltipRef: ComponentRef<ImageTooltipComponent> =
      this.overlayRef.attach(new ComponentPortal(ImageTooltipComponent));
    tooltipRef.instance.text = this.text;
    tooltipRef.instance.image = this.image;
  }

  @HostListener("mouseout")
  hide() {
    this.overlayRef.detach();
  }
}
